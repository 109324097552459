import { useSocialAuthentications } from "@cashbook/data-store/auth"
import {
  Box,
  CBButton,
  GoogleVectorIcon,
  MailIcon,
  SpinnerIcon,
  Stack,
  Text,
} from "@cashbook/web-components"
import { Recaptcha } from "./VerifyPhoneNumber"
import config from "../config"

type SocialAuthProps = {
  onOtherWaysToLogin: () => void
  onContinueWithEmail: () => void
}
export default function SocialAuthentications({
  onOtherWaysToLogin,
  onContinueWithEmail,
}: SocialAuthProps) {
  const { loading, setCaptchaVerifier, googleAuthentication } =
    useSocialAuthentications()
  return (
    <Box
      maxWidth="sm"
      marginX="auto"
      borderWidth="1"
      borderColor="borderOutline"
      rounded="md"
      padding="8"
      width="full"
    >
      <Recaptcha onLoad={setCaptchaVerifier} />
      <Stack gap="4">
        <Text as="h4" fontSize="b1">
          Choose one option to continue
        </Text>
        <CBButton
          size="lg"
          iconPlacement="left"
          loading={loading}
          onClick={googleAuthentication}
        >
          {loading ? <SpinnerIcon /> : <GoogleVectorIcon />} Continue with
          Google
        </CBButton>
        <CBButton
          size="lg"
          disabled={loading}
          iconPlacement="left"
          onClick={onContinueWithEmail}
        >
          <MailIcon />
          Continue with Email
        </CBButton>
        <Box marginTop="2" width="full">
          <Stack gap="6" alignItems="center">
            <Text fontSize="c2">
              By continuing, you are indicating that you accept our{" "}
              <Text
                as="a"
                href={config.tosUrl}
                target="_blank"
                color="textPrimary"
              >
                Terms of Service
              </Text>{" "}
              and{" "}
              <Text
                as="a"
                target="_blank"
                href={config.privacyPolicyUrl}
                color="textPrimary"
              >
                Privacy Policy
              </Text>
              .
            </Text>
            <Text fontSize="b1">OR</Text>
            <CBButton
              fullWidth
              size="lg"
              iconPlacement="left"
              onClick={onOtherWaysToLogin}
            >
              Other ways to login
            </CBButton>
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}
