import { useParams } from "react-router-dom"
import Page from "./Page"
import BookFieldPartySettingsPage from "../BookFieldPartySettingsPage"
import { usePartyOrContact } from "@cashbook/data-store/books"

export default function SmBookFieldPartySettingsPage() {
  const { bookId, businessId } = useParams()
  const { partyOrContact } = usePartyOrContact()
  return (
    <Page
      title={`${partyOrContact} Field`}
      backTo={`/m/businesses/${businessId}/cashbooks/${bookId}/settings/fields`}
    >
      <BookFieldPartySettingsPage />
    </Page>
  )
}
