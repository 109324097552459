import {
  ArrowLeftIcon,
  Heading,
  WhatsAppIcon,
  Text,
  Box,
  DataLoadingFallback,
  getButtonClassName,
  Inline,
  Stack,
} from "@cashbook/web-components"
import { Link, Navigate, NavLink, Outlet } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { WhatsAppContactLink } from "../HelpDocs"
import { useHelpDocs } from "@cashbook/data-store/help-docs"

export default function HelpDocsListPage() {
  return (
    <Box>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading docs..." />}
        traceId="loading_docs"
      >
        <ErrorBoundary>
          <RedirectToFirstDoc />
        </ErrorBoundary>
      </SuspenseWithPerf>
    </Box>
  )
}

function RedirectToFirstDoc() {
  const { helpDocs } = useHelpDocs()
  if (!helpDocs.length)
    return (
      <Stack maxWidth="4xl" gap="4">
        <Heading as="h2" fontSize="3xl">
          :: Work in Progress ::
        </Heading>
        <Text>
          We are working on our help docs for you to easily resolve support.
        </Text>
        <Text>
          If you need any support, please feel free to send us a message.
        </Text>
        <WhatsAppContactLink />
      </Stack>
    )
  return <Navigate to={`/docs/${helpDocs[0].id}`} replace />
}

export function HelpDocsLayout() {
  return (
    <Box bgColor="white" width="full">
      <Inline padding="4" alignItems="center" borderBottomWidth="1">
        <Box>
          <Box paddingRight="2" marginRight="4" borderRightWidth="1">
            <Box as={Link} to={`/businesses`} paddingX="2">
              <ArrowLeftIcon />
            </Box>
          </Box>
        </Box>
        <Box flex="1">
          <Heading as="h1" fontSize="xl">
            Help Docs
          </Heading>
        </Box>
        <Box>
          <WhatsAppContactLink>
            {(props) => (
              <a
                {...props}
                className={getButtonClassName({
                  level: "secondary",
                  status: "success",
                })}
              >
                <WhatsAppIcon size="6" /> Contact Us
              </a>
            )}
          </WhatsAppContactLink>
        </Box>
      </Inline>
      <Box>
        <Inline>
          <Box
            as="aside"
            paddingX="4"
            paddingTop="4"
            paddingBottom="16"
            style={{ width: "400px" }}
          >
            <SuspenseWithPerf
              fallback={<DataLoadingFallback label="Loading docs..." />}
              traceId="loading_docs"
            >
              <HelpDocsList />
            </SuspenseWithPerf>
          </Box>
          <Box
            as="section"
            paddingTop="8"
            paddingBottom="16"
            paddingX="8"
            flex="1"
            minWidth="0"
            borderLeftWidth="1"
          >
            <Outlet />
          </Box>
        </Inline>
      </Box>
    </Box>
  )
}

function HelpDocsList() {
  const { categories } = useHelpDocs()
  return (
    <Stack as="ol" gap="6">
      {categories.map((category) => (
        <Stack as="li" key={category.id} gap="3">
          {category.name ? (
            <Heading
              as="h4"
              textTransform="uppercase"
              fontSize="sm"
              fontWeight="medium"
            >
              {category.name}
            </Heading>
          ) : null}
          <Stack gap="1" as="ul">
            {category.docs.map((doc) => (
              <Box as="li" key={doc.id}>
                <Box
                  as={NavLink}
                  to={`/docs/${doc.id}`}
                  display="block"
                  width="full"
                  paddingX="2"
                  paddingY="1"
                  className="hover:text-blue-900 current-page:bg-blue-100 current-page:text-blue-900"
                >
                  <Text fontSize="sm" as="span">
                    {doc.title}
                  </Text>
                </Box>
              </Box>
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}
