import { useBusinesses } from "@cashbook/data-store/businesses"
import { Box, Heading, PageMeta, Stack } from "@cashbook/web-components"
import { Navigate, useNavigate } from "react-router-dom"
import { AddNewBusiness } from "../Business"

export default function AddFirstBusiness({
  routePrefix = "",
}: {
  routePrefix?: string
}) {
  const navigate = useNavigate()
  const { businesses } = useBusinesses()
  if (businesses.length > 0) {
    return (
      <Navigate
        to={`${routePrefix}/businesses/${businesses[0].id}/cashbooks`}
        replace
      />
    )
  }
  return (
    <>
      <PageMeta>
        <title>Choose Category/Business Type</title>
      </PageMeta>
      <Stack gap="6" width="full" paddingY="8">
        <Box
          className="max-w-[84%]"
          marginX="auto"
          padding="8"
          bgColor="white"
          rounded="md"
          borderWidth="1"
          width="full"
          borderColor="gray100"
        >
          <Stack gap="6">
            <Heading as="h2" fontSize="lg">
              Add your first business
            </Heading>
            <AddNewBusiness
              onSuccess={(businessId) =>
                navigate(`${routePrefix}/businesses/${businessId}/cashbooks`)
              }
            />
          </Stack>
        </Box>
      </Stack>
    </>
  )
}
