import classNames from "classnames"

export function Switch({
  id,
  on,
  onToggle,
  label,
  disabled,
}: {
  id?: string
  on: boolean
  onToggle: () => void
  label: string
  disabled?: boolean
}) {
  const height = 24
  const sliderWidth = 40
  return (
    <label
      className={classNames(
        disabled ? "cursor-not-allowed pointer-events-none" : "cursor-pointer",
        `relative inline-flex flex-shrink-0 rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 py-1`
      )}
      style={{
        height: `${height}px`,
        width: `${sliderWidth}px`,
      }}
    >
      <span className="sr-only">{label}</span>
      <span
        className={classNames(
          "block w-full rounded-full transition-all",
          on ? "bg-blue-100" : "bg-gray-500 bg-opacity-40"
        )}
      ></span>
      <span
        aria-hidden="true"
        className={`${
          on
            ? `translate-x-[16px] bg-blue-900 origin-center`
            : "translate-x-0 bg-white"
        } absolute left-0 top-0 pointer-events-none inline-block rounded-full shadow-lg transform ring-0 transition ease-in-out duration-200`}
        style={{
          height: `${height}px`,
          width: `${height}px`,
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        }}
      />
      <input
        type="checkbox"
        id={id}
        checked={on}
        onChange={() => (!disabled ? onToggle() : undefined)}
        className="invisible absolute"
      />
    </label>
  )
}
