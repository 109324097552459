import { getDeviceOS } from "@cashbook/device-info"
import { logError } from "@cashbook/util-logging"
import {
  Box,
  Button,
  CheckIcon,
  CopyIcon,
  CopyToClipboard,
  Inline,
  Stack,
  Text,
  WhatsAppFilledIcon,
} from "@cashbook/web-components"
import React, { useEffect } from "react"
import { getWhatsAppSharingLink } from "@cashbook/util-general"
import config from "./config"

const deviceOs = getDeviceOS()
const isIndexedDbNotSupported: boolean = !window.indexedDB ? true : false

export default function AppNotSupported({
  children,
}: {
  children: React.ReactNode
}) {
  useEffect(() => {
    if (isIndexedDbNotSupported) {
      logError(`IndexedDB is not supported by the browser in ${deviceOs}`)
      return
    }
  }, [])
  return isIndexedDbNotSupported ? <NotSupportedComponent /> : <>{children}</>
}

export function NotSupportedComponent({ error }: { error?: Error }) {
  const supportPhoneNumber = config.supportPhoneNumber

  useEffect(() => {
    if (error?.message) {
      logError({ ...error, message: `${error.message} in ${deviceOs} ` })
    }
  }, [error])

  const handleSupportClick = () => {
    const link = getWhatsAppSharingLink({
      text: `Hi, I am unable to use ${config.appTitle} web app in my browser.`,
      phoneNumber: supportPhoneNumber,
    })
    window.open(link, "_blank")
  }

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      minHeight="screen"
      minWidth="screenMd"
      gap="4"
    >
      <Stack maxWidth="md" gap="1" textAlign="center">
        <Text fontSize="s3">
          Sorry, {config.appTitle} can not be accessed using this browser.
        </Text>
        <Text fontSize="c2">
          Please use a different browser. We support latest version of Google
          Chrome, Microsoft Edge, Mozilla Firefox and Brave. Please contact us
          if you need any more assistance.
        </Text>
      </Stack>
      <Inline gap="4">
        <Button size="lg" level="secondary" onClick={handleSupportClick}>
          <Box>
            <WhatsAppFilledIcon />
          </Box>
          Contact Us
        </Button>
        <CopyToClipboard>
          {({ copy, copied }) => (
            <Button
              size="lg"
              level="secondary"
              onClick={() => {
                copy(window.location.href)
              }}
            >
              <Box>
                {copied ? (
                  <CheckIcon color="green500" />
                ) : (
                  <CopyIcon color="blue900" />
                )}
              </Box>
              {copied ? "Copied" : "Copy"} Link
            </Button>
          )}
        </CopyToClipboard>
      </Inline>
    </Stack>
  )
}
