import {
  Alert,
  Box,
  Button,
  CancelFilledIcon,
  CancelIcon,
  CheckCircleSolidIcon,
  Circle,
  FormField,
  formikOnSubmitWithErrorHandling,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  SpinnerIcon,
  Stack,
  Text,
  TrashIcon,
} from "@cashbook/web-components"
import { useOverlayTriggerState } from "@react-stately/overlays"
import { Form, Formik, useField } from "formik"
import { useMemo, useState } from "react"
import toast from "react-hot-toast"
import { SuspenseWithPerf } from "reactfire"
import { Optional } from "utility-types"
import * as Validator from "yup"
import {
  AddMember,
  AddMemberInDialog,
  SelectRole,
  PendingInvitationDetails,
  ShareInvitationActions,
} from "./AddMember"
import {
  BOOK_PERMISSIONS,
  getRoleDetails,
  TBookMember as TBookMemberType,
  useAddBook,
  useBook,
  useDeleteBook,
  TBook,
  useDuplicateBook,
  usePartyOrContact,
  getAllRolesWithPermissions,
  T_AVAILABLE_ROLES,
  getRoleDetailsForMember,
} from "@cashbook/data-store/books"
import { useProfile } from "@cashbook/data-store/users"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { getColorForString } from "generate-colors"
import {
  OperationalBookRoles,
  T_AVAILABLE_BUSINESS_ROLES,
  useBusiness,
  useUpdateBusinessUser,
} from "@cashbook/data-store/businesses"
import { sleep } from "@cashbook/util-general"
import { useSyncedStorageState } from "@cashbook/data-store/storage"

export {
  useToggleCategoryField,
  useTogglePaymentModeField,
  AddCategoryFieldInDialog,
  ImportCategoryFieldInDialog,
  EditCategoryFieldInDialog,
  DeleteCategoryFieldInDialog,
  AddPaymentModeFieldInDialog,
  ImportPaymentModeFieldInDialog,
  EditPaymentModeFieldInDialog,
  DeletePaymentModeFieldInDialog,
  useFetchCategorySuggestions,
  SelectParty,
  SelectCategory,
  SelectPaymentMode,
  useTogglePartyField,
  AddPartyFieldInDialog,
  EditPartyFieldInDialog,
  DeletePartyFieldInDialog,
  ImportPartyFieldInDialog,
  AskForPartyPhoneNumber,
} from "./EntryFields"

export type { TSelectFieldOption } from "./EntryFields"

export {
  AddMemberInDialog,
  PendingInvitationDetails,
  ShareInvitationActions,
  AddMember,
}

export type Book = TBook
export type TBookMember = TBookMemberType

type AddBookDataSchema = {
  name: string
}

type AddBookFormProps = {
  initialValues?: Optional<AddBookDataSchema>
  onSubmit: (data: AddBookDataSchema) => Promise<void>
  onCancel?: () => void
}

type AddBookProps = Omit<AddBookFormProps, "onSubmit"> & {
  onSuccess?: (bookId: string) => void
  onCancel?: () => void
}

const addBookDataValidationSchema = Validator.object().shape({
  name: Validator.string()
    .required(
      "Please provide a name for the book e.g. Daily Expense, Bills etc."
    )
    .max(191, "Please use 191 or fewer characters"),
})

export function AddBook({
  ownerId,
  businessId,
  booksCount,
  userRole,
  suggestedTerm,
  onSuccess,
  ...props
}: AddBookProps & {
  ownerId: string
  businessId: string
  userRole: T_AVAILABLE_BUSINESS_ROLES
  booksCount?: number
  suggestedTerm?: string
}) {
  const addBook = useAddBook()
  const { business } = useBusiness(businessId)
  const numOfPartners = business.partners.length
  return (
    <AddBookForm
      {...props}
      numOfPartners={numOfPartners}
      onSubmit={async (values) => {
        const bookId = await addBook({ ...values, ownerId, businessId })
        trackEvent(TrackingEvents.ADD_NEW_BOOK, {
          bookName: values.name,
          role: userRole || "staff",
          suggestedTerm: suggestedTerm,
          groupBook: false,
          countBooks: booksCount || 0,
        })
        await sleep(2000).then(() => {
          if (bookId) {
            onSuccess?.(bookId)
          }
        })
      }}
    />
  )
}

export function AddBookInDialog({
  children,
  onSuccess,
  ...props
}: Omit<AddBookProps, "onCancel"> & {
  ownerId: string
  businessId: string
  userRole: T_AVAILABLE_BUSINESS_ROLES
  children: (props: { add: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ add: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title="Add New Book"
        autoFocus={false}
      >
        <AddBook
          {...props}
          onSuccess={(...args) => {
            if (args.length) {
              state.close()
              onSuccess?.(...args)
            }
          }}
          onCancel={state.close}
        />
      </Modal>
    </>
  )
}

type EditBookProps = AddBookProps & { bookId: string }

function EditBook({ onSuccess, bookId, ...props }: EditBookProps) {
  const { book, updateBook } = useBook(bookId)
  const initialValues = useMemo(() => {
    return { name: book.name }
  }, [book])
  return (
    <AddBookForm
      {...props}
      initialValues={initialValues}
      onSubmit={async (values) => {
        await updateBook(values)
        onSuccess?.(book.id)
      }}
    />
  )
}

export function EditBookInDialog({
  children,
  onSuccess,
  ...props
}: Omit<EditBookProps, "onCancel"> & {
  children: (props: { edit: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ edit: state.open })}
      <Modal isOpen={state.isOpen} onClose={state.close} title="Rename Book">
        <SuspenseWithPerf
          fallback={
            <div className="text-center py-8">
              <SpinnerIcon />
            </div>
          }
          traceId="loading_book_details_to_edit"
        >
          <EditBook
            {...props}
            onSuccess={(bookId) => {
              state.close()
              onSuccess?.(bookId)
            }}
            onCancel={state.close}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function AddBookForm({
  onSubmit,
  numOfPartners,
  initialValues: initialValuesProp,
}: AddBookFormProps & {
  numOfPartners?: number
}) {
  const initialValues: AddBookDataSchema = useMemo(
    () => ({
      name: initialValuesProp?.name || "",
    }),
    [initialValuesProp]
  )
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addBookDataValidationSchema}
      validateOnBlur={false}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        await onSubmit(values)
      })}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <ModalBody>
            <FormField
              type="text"
              name="name"
              label="Book Name"
              placeholder="e.g. Daily Expense"
              autoFocus
            />
            {status ? <Alert status="error">{status}</Alert> : null}
          </ModalBody>
          {numOfPartners && numOfPartners > 0 ? (
            <Alert status="info" marginBottom="0" rounded="none">
              <Text fontSize="sm">
                Partners will automatically get access to this book
              </Text>
            </Alert>
          ) : null}
          <ModalFooter>
            <Button type="submit" disabled={isSubmitting} size="lg">
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

export function DeleteBook({
  book,
  children,
  onSuccess,
}: {
  book: TBook
  children: (props: { onDelete: () => void }) => React.ReactNode
  onSuccess?: () => void
}) {
  const { user } = useProfile()
  const { id } = getRoleDetailsForMember(book, user.uid)
  const deleteBook = useDeleteBook(book.id)
  const isBookShared = book.sharedWith.length > 1
  const state = useOverlayTriggerState({})
  const onCancelDeleteBook = () => {
    state.close()
    trackEvent(TrackingEvents.BOOK_DELETION_CANCELLED, {
      sharedBook: isBookShared,
      role: id,
    })
  }
  return (
    <>
      {children({ onDelete: state.open })}
      <Modal
        isOpen={state.isOpen}
        title="Delete Book"
        isDismissable
        onClose={onCancelDeleteBook}
      >
        <Formik
          initialValues={{ confirmed: true, bookName: "" }}
          onSubmit={formikOnSubmitWithErrorHandling(async (values, actions) => {
            const bookName = values.bookName
            if (bookName !== book.name) {
              actions.setErrors({
                bookName: `Book name doesn’t match. Please type ${book.name} in the required field to confirm`,
              })
              return
            }
            await deleteBook()
            toast.success(`"${bookName}" book deleted`)
            // No need to close as the book is deleted
            state.close()
            trackEvent(TrackingEvents.BOOK_DELETED, {
              sharedBook: isBookShared,
              role: id,
            })
            onSuccess?.()
          })}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <Alert status="warning">
                  {isBookShared
                    ? "Are you sure? If you delete this book, all members of this book will lose its data permanently. There is no way to recover it."
                    : "Are you sure? You will lose all entries of this book permanently."}
                </Alert>
                <Stack gap="2">
                  <Inline gap="1">
                    Please type <Text fontWeight="semibold">{book.name}</Text>{" "}
                    to confirm
                  </Inline>
                  <FormField
                    type="text"
                    name="bookName"
                    placeholder={"Your book name"}
                    required
                    autoFocus
                  />
                </Stack>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  level="secondary"
                  status="error"
                >
                  <TrashIcon />{" "}
                  {isSubmitting ? "Deleting book..." : "Yes, Delete"}
                </Button>
                <Button
                  disabled={isSubmitting}
                  level="tertiary"
                  onClick={onCancelDeleteBook}
                >
                  <CancelIcon /> Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

function EditMemberRole({
  bookId,
  member,
  onSuccess,
}: {
  bookId: string
  member: Optional<TBookMember, "uid">
  onCancel?: () => void
  onSuccess?: () => void
}) {
  const {
    book,
    hideEntriesByOthers,
    hideBalancesAndReportsForEditor,
    checkIfAuthenticatedMemberCan,
  } = useBook(bookId)
  const canAddAdmin = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.ASSIGN_ADMIN_ROLE
  )
  const updateMemberRole = useUpdateBusinessUser("book_role_change")
  return (
    <Formik
      initialValues={{ oldRole: member.role.id, role: member.role.id }}
      validationSchema={Validator.object().shape({
        oldRole: Validator.string().required(),
        role: Validator.string()
          .required()
          .when(
            "oldRole",
            (oldRole: string, schema: Validator.StringSchema) => {
              if (!oldRole) return schema
              return schema.test(
                "different-then-old",
                "Please select a new role to update.",
                (newRole) => oldRole !== newRole
              )
            }
          ),
      })}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        await updateMemberRole({
          userId: member.uid,
          email: member?.email,
          phoneNumber: member?.phoneNumber,
          role: values.role as OperationalBookRoles,
          bookId: bookId,
        })
        toast.success(
          `${member.name} has been changed to ${
            getRoleDetails(values.role).title
          } in ${book.name}`
        )
        onSuccess?.()
      })}
    >
      {({ isSubmitting, status, values, submitForm }) => (
        <Form noValidate>
          <ModalBody className="relative">
            <Inline
              fontSize="sm"
              position="absolute"
              top="0"
              width="full"
              left="0"
              backgroundColor="gray100"
              alignItems="center"
              gap="1"
              paddingX="8"
              paddingY="3"
            >
              <Text color="gray500">Book:</Text>
              <Text fontWeight="semibold">{book.name}</Text>
            </Inline>
            <Box paddingY="12">
              <SelectRole
                name="role"
                canAddAdmin={canAddAdmin}
                hideEntriesByOthers={hideEntriesByOthers}
                hideNetBalance={hideBalancesAndReportsForEditor}
              />
            </Box>
            {values.role !== "admin" && status ? (
              <Alert status="error">{status}</Alert>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <ConfirmEditRoleInModal
              name={member.name}
              bookName={book.name}
              status={status}
              isLoading={isSubmitting}
              onSubmit={submitForm}
              toRole={values.role}
            >
              {({ onConfirm }) => (
                <Button
                  level="primary"
                  disabled={isSubmitting}
                  size="lg"
                  onClick={() => {
                    values.role === "admin" ? onConfirm() : submitForm()
                  }}
                >
                  {isSubmitting ? "Updating..." : "Update"}
                </Button>
              )}
            </ConfirmEditRoleInModal>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

function ConfirmEditRoleInModal({
  name,
  status,
  toRole,
  bookName,
  isLoading,
  onSubmit,
  children,
}: {
  name: string
  status?: string
  bookName: string
  isLoading: boolean
  toRole: T_AVAILABLE_ROLES
  onSubmit: () => void
  children: (props: { onConfirm: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const confirmedRolePointers = useMemo(() => {
    return [
      `${name} will have full access to book activities`,
      "They will be able to add data operator or viewer from your team",
    ]
  }, [name])
  return (
    <>
      {children({ onConfirm: state.open })}
      <Modal
        isOpen={state.isOpen}
        title={`Change ${name}'s role to ${toRole} in ${bookName}`}
        onClose={state.close}
      >
        <ModalBody>
          <Stack gap="4">
            <Text>Are you sure?</Text>
            {confirmedRolePointers.map((pointer) => (
              <Inline key={pointer} alignItems="center" gap="4">
                <Circle size="2" backgroundColor="gray400" />
                <Text>{pointer}</Text>
              </Inline>
            ))}
            <Alert status="info" marginTop="4">
              <Text fontSize="sm">
                This role will only be limited to this book
              </Text>
            </Alert>
            {status ? <Alert status="error">{status}</Alert> : null}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={onSubmit}
            level="primary"
            size="lg"
          >
            {isLoading ? "Change role..." : "Change"}
          </Button>
          <Button disabled={isLoading} onClick={state.close} size="lg">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function EditMemberRoleInDialog({
  children,
  ...props
}: React.ComponentProps<typeof EditMemberRole> & {
  children: (props: { onEdit: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({ defaultOpen: false })
  return (
    <>
      {children({ onEdit: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        placement="right"
        title={`Change ${props.member.name}’s Role`}
        isDismissable
        onBackPress={state.close}
      >
        <SuspenseWithPerf
          traceId="loading_book_details"
          fallback={
            <div className="text-center py-16">
              <SpinnerIcon />
            </div>
          }
        >
          <EditMemberRole
            {...props}
            onSuccess={state.close}
            onCancel={state.close}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

type MemberForRemoveFromBook = {
  userId?: string
  email?: string
  phoneNumber?: string
  name: string
  invitationId?: string
}
function RemoveMemberFromBook({
  bookId,
  member,
  onSuccess,
  onCancel,
}: {
  bookId: string
  member: MemberForRemoveFromBook
  onSuccess?: () => void
  onCancel?: () => void
}) {
  const removeMember = useUpdateBusinessUser("remove_book")
  const removeMemberFromBooksPointers = useMemo(() => {
    return [
      `${member.name} will lose access to this book`,
      `We will also notify ${member.name} that they have been removed from this book.`,
    ]
  }, [member.name])
  return (
    <Formik
      initialValues={{}}
      onSubmit={formikOnSubmitWithErrorHandling(async () => {
        await removeMember({
          userId: member.userId,
          email: member?.email,
          phoneNumber: member?.phoneNumber,
          bookId: bookId,
        })
        trackEvent(TrackingEvents.MEMBER_REMOVED, {
          from: "bookDetails",
          isInvited: Boolean(member.invitationId),
        })

        toast.success(`${member.name} has been removed from this book`)
        onSuccess?.()
      })}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <ModalBody>
            <Stack gap="4">
              <Text>Are you sure?</Text>
              {removeMemberFromBooksPointers.map((pointer) => (
                <Inline key={pointer} alignItems="center" gap="4">
                  <Circle size="2" backgroundColor="gray400" />
                  <Text>{pointer}</Text>
                </Inline>
              ))}
              <Alert status="info" marginTop="4">
                <Text fontSize="sm">
                  {member.name} will still be a part of your business
                </Text>
              </Alert>
            </Stack>
            {status ? <Alert status="error">{status}</Alert> : null}
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              disabled={isSubmitting}
              size="lg"
              status="error"
            >
              {isSubmitting ? "Removing..." : "Remove"}
            </Button>
            <Button size="lg" onClick={onCancel}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

export function RemoveMemberFromBookInDialog({
  children,
  member,
  ...props
}: React.ComponentProps<typeof RemoveMemberFromBook> & {
  children: (props: { onRemove: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({ defaultOpen: false })
  const { book } = useBook(props.bookId)
  return (
    <>
      {children({ onRemove: state.open })}
      <SuspenseWithPerf
        traceId="loading_book_details"
        fallback={
          <div className="text-center py-16">
            <SpinnerIcon />
          </div>
        }
      >
        <Modal
          isOpen={state.isOpen}
          onClose={state.close}
          title={`Remove ${member.name} from ${book.name}?`}
          isDismissable
        >
          <RemoveMemberFromBook
            {...props}
            member={member}
            onSuccess={() => {
              state.close()
            }}
            onCancel={state.close}
          />
        </Modal>
      </SuspenseWithPerf>
    </>
  )
}

type MEMBER_AVATAR = {
  id: string
  name?: string
  size?: React.ComponentProps<typeof Box>["size"]
  fontSize?: React.ComponentProps<typeof Text>["fontSize"]
}
export function MemberAvatar({ id, name, size, fontSize }: MEMBER_AVATAR) {
  const [r, g, b] = getColorForString(id || name || "cb")
  return (
    <Box
      size={size || "14"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      rounded="full"
      bgColor="blue100"
      style={{
        color: `rgb(${r}, ${g}, ${b})`,
        background: `rgba(${r}, ${g}, ${b}, .1)`,
      }}
    >
      <Text
        textTransform="uppercase"
        fontWeight="semibold"
        fontSize={fontSize || "xl"}
      >
        {name ? name.charAt(0) : "CB"}
      </Text>
    </Box>
  )
}

export function DuplicateBookInDialog({
  children,
  onSuccess,
  ...props
}: {
  book: TBook
  bookName: string
  onSuccess: (newBookId: string) => void
  children: (props: { duplicate: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const onDuplicateClick = () => {
    state.open()
    trackEvent(TrackingEvents.DUPLICATE_BOOK_CLICKED)
  }
  return (
    <>
      {children({ duplicate: onDuplicateClick })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        placement="right"
        title={`Duplicate ${props.bookName}`}
        isDismissable
      >
        <DuplicateBook
          {...props}
          onCancel={() => state.close()}
          onSuccess={(newBookId) => {
            state.close()
            onSuccess(newBookId)
          }}
        />
      </Modal>
    </>
  )
}

export type DuplicateSettingsType = {
  id: string
  label: string
}

const settingsCanBeDuplicated: DuplicateSettingsType[] = [
  {
    id: "members",
    label: "Members & Roles",
  },
  {
    id: "categories",
    label: "Categories",
  },
  {
    id: "paymentModes",
    label: "Payment Modes",
  },
  {
    id: "parties",
    label: `Party Settings`,
  },
  {
    id: "customFields",
    label: "Custom Fields",
  },
]

function DuplicateBook({
  book,
  bookName,
  onCancel,
  onSuccess,
}: {
  book: TBook
  bookName: string
  onCancel: () => void
  onSuccess: (newBookId: string) => void
}) {
  const { user } = useProfile()
  const duplicateBook = useDuplicateBook(book.id)
  const { partyOrContact } = usePartyOrContact()
  const { id } = getRoleDetailsForMember(book, user.uid)
  return (
    <>
      <Alert status="info" rounded="none">
        <Inline>Create new book with same settings as {bookName}</Inline>
      </Alert>
      <Formik
        initialValues={{
          name: "" as string,
          duplicate: settingsCanBeDuplicated.map(
            (setting) => setting.id
          ) as Array<string>,
        }}
        onSubmit={formikOnSubmitWithErrorHandling(async (values, actions) => {
          try {
            const { newBookId } = await duplicateBook({ ...values })
            toast.success(`New Book duplicated as "${values.name}"`)
            trackEvent(TrackingEvents.DUPLICATE_BOOK_CREATED, {
              memberIncluded: values.duplicate.includes("members"),
              partiesIncluded: values.duplicate.includes("parties"),
              categoryIncluded: values.duplicate.includes("categories"),
              paymentModeIncluded: values.duplicate.includes("paymentModes"),
              membersCount: book?.sharedWith ? book?.sharedWith?.length : 0,
              partiesCount: book?.parties ? book?.parties.length : 0,
              categoriesCount: book?.categories ? book?.categories.length : 0,
              paymentModesCount: book?.paymentModes
                ? book?.paymentModes.length
                : 0,
              role: id as T_AVAILABLE_BUSINESS_ROLES,
            })
            onSuccess(newBookId)
          } catch (e) {
            const error = e as Error
            actions.setErrors({ name: error.message })
          }
        })}
      >
        {({ isSubmitting, values, setValues }) => (
          <Form noValidate>
            <ModalBody>
              <Stack gap="6">
                <Stack gap="6">
                  <Text>Step 1 : Choose New Book Name</Text>
                  <FormField
                    type="text"
                    name="name"
                    label="Enter new book name"
                    placeholder={"Enter new book name"}
                    required
                    autoFocus
                    className="pb-2"
                  />
                </Stack>
                <Stack gap="6">
                  <Text>Step 2 : Choose settings to duplicate</Text>
                  <Stack as="ol" gap="4">
                    {settingsCanBeDuplicated.map(({ label, id }) => {
                      const isChecked = values.duplicate.some((setting) =>
                        setting.includes(id)
                      )
                      return (
                        <Box
                          as="li"
                          borderWidth="1"
                          rounded="md"
                          className={`${
                            isChecked ? "bg-[#EEEDFA]" : "bg-red"
                          } border border-[#E0E0E0]`}
                          key={id}
                        >
                          <Box
                            as="label"
                            paddingX="6"
                            paddingY="4"
                            display="block"
                            width="full"
                            cursor="pointer"
                          >
                            <FormField
                              key={id}
                              value={id}
                              id={`duplicate_${id}`}
                              checked={isChecked}
                              type="checkbox"
                              noMargin
                              name="duplicate"
                              className="border-2 border-[#707070] h-4 w-4"
                              label={id === "parties" ? partyOrContact : label}
                              onChange={() => {
                                setValues({
                                  ...values,
                                  duplicate: isChecked
                                    ? values.duplicate.filter(
                                        (setting) => setting !== id
                                      )
                                    : [...values.duplicate, id],
                                })
                              }}
                            />
                          </Box>
                        </Box>
                      )
                    })}
                  </Stack>
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter className="sticky bottom-0 z-10 bg-white">
              <Button
                type="submit"
                level="primary"
                size="lg"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Creating new book..." : "Add New Book"}
              </Button>
              <Button level="tertiary" size="lg" onClick={onCancel}>
                <CancelIcon /> Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

export function AllRolesAndPermissionsInModal({
  children,
  ...props
}: React.ComponentProps<typeof AllRolesAndPermissionsBox> & {
  children: (props: { view: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({
        view: () => state.open(),
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title="Roles & Permissions"
        placement="right"
        isDismissable
      >
        <ModalBody>
          <AllRolesAndPermissionsBox {...props} />
        </ModalBody>
        <ModalFooter>
          <Button size="lg" level="primary" onClick={state.close}>
            Ok, Got it
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function AllRolesAndPermissionsBox({
  userRole,
  hideNetBalance,
  hideEntriesByOthers,
}: {
  userRole: T_AVAILABLE_ROLES
  hideNetBalance?: boolean
  hideEntriesByOthers?: boolean
}) {
  const allRoles = getAllRolesWithPermissions().sort((a, b) => {
    //Prefer user role to be at first in line
    if (userRole === a.id) return -1
    if (userRole === b.id) return 1
    // now prefer owners, partners, then admins, then editors and last to viewers
    const roles = ["viewer", "editor", "admin", "partner", "owner"]
    const aRoleIndex = roles.indexOf(userRole)
    const bRoleIndex = roles.indexOf(userRole)
    if (aRoleIndex !== bRoleIndex) {
      // prefer the owner roles
      return aRoleIndex > bRoleIndex ? -1 : 1
    }
    return -1
  })
  const [selectedRole, setSelectedRole] = useState<T_AVAILABLE_ROLES>(userRole)
  const { permissionsDescription, restrictionsDescription } =
    getRoleDetails(selectedRole)
  return (
    <Stack borderWidth="1" rounded="md" padding="6" gap="6">
      <Inline gap="4" as="ul" flexWrap="wrap">
        {allRoles.map(({ role, title, id }) => {
          return (
            <Inline
              key={role}
              as="li"
              paddingX="4"
              paddingY="2"
              rounded="full"
              cursor="pointer"
              borderWidth="1"
              borderColor={
                selectedRole !== role
                  ? "gray200"
                  : bookRolesColorCodes[role].borderColor
              }
              bgColor={
                selectedRole !== role
                  ? "gray100"
                  : bookRolesColorCodes[role].backgroundColor
              }
              color={
                selectedRole === role
                  ? bookRolesColorCodes[role].color
                  : undefined
              }
              onClick={() => setSelectedRole(id)}
            >
              <Text>{title}</Text>
              <Text>{userRole === role ? `(You)` : ""}</Text>
            </Inline>
          )
        })}
      </Inline>
      <Stack gap="8">
        <Stack gap="6">
          <Text color="gray500" fontWeight="semibold">
            Permissions
          </Text>
          <Stack as="ul" gap="4">
            {permissionsDescription.map((permission) => {
              if (selectedRole === "editor") {
                if (
                  (hideEntriesByOthers &&
                    permission
                      .toLowerCase()
                      .includes("View entries by everyone".toLowerCase())) ||
                  (hideNetBalance &&
                    permission
                      .toLowerCase()
                      .includes(
                        "View net balance & download PDF or Excel".toLowerCase()
                      ))
                ) {
                  return null
                }
              }
              return (
                <Inline as="li" key={permission} alignItems="center" gap="3">
                  <Box>
                    <CheckCircleSolidIcon color="green500" />
                  </Box>
                  <Text fontWeight="medium">{permission}</Text>
                </Inline>
              )
            })}
          </Stack>
        </Stack>
        {restrictionsDescription && restrictionsDescription.length ? (
          <Stack gap="6">
            <Text color="gray500" fontWeight="semibold">
              Restrictions
            </Text>
            <Stack gap="4">
              {restrictionsDescription.map((restriction) => (
                <Inline as="li" key={restriction} alignItems="center" gap="3">
                  <CancelFilledIcon
                    size={{ xs: "8", sm: "6" }}
                    color="red900"
                  />
                  <Text>{restriction}</Text>
                </Inline>
              ))}
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  )
}

export function AllRolesAndPermissionsView({
  defaultRole,
  exceptRoles,
  hideNetBalance,
  hideEntriesByOthers,
}: {
  defaultRole?: T_AVAILABLE_ROLES
  exceptRoles?: Array<string>
  hideNetBalance?: boolean
  hideEntriesByOthers?: boolean
}) {
  const allRoles = getAllRolesWithPermissions()
    .reverse()
    .filter((role) => (exceptRoles ? !exceptRoles.includes(role.id) : true))
  const [selectedRole, setSelectedRole] = useState<T_AVAILABLE_ROLES>(
    defaultRole || allRoles[0].id
  )
  const { title, permissionsDescription, restrictionsDescription } =
    getRoleDetails(selectedRole)
  const updatedPermissions = useMemo(() => {
    return permissionsDescription.filter((permission) => {
      if (selectedRole === "editor") {
        if (
          (hideEntriesByOthers &&
            permission
              .toLowerCase()
              .includes("View entries by everyone".toLowerCase())) ||
          (hideNetBalance &&
            permission
              .toLowerCase()
              .includes(
                "View net balance & download PDF or Excel".toLowerCase()
              ))
        )
          return null
      }
      return permission
    })
  }, [
    hideEntriesByOthers,
    hideNetBalance,
    permissionsDescription,
    selectedRole,
  ])

  return (
    <Stack gap="6">
      <Inline gap="2" flexWrap="wrap">
        {allRoles.map(({ role, title, id }) => {
          return (
            <Box
              key={role}
              as="label"
              paddingX="4"
              paddingY="2"
              display="inlineBlock"
              rounded="full"
              cursor="pointer"
              borderWidth="1"
              borderColor={selectedRole !== role ? "gray100" : "blue900"}
              bgColor={selectedRole !== role ? "gray100" : "blue100"}
              color={selectedRole === role ? "blue900" : undefined}
              onClick={() => setSelectedRole(id)}
            >
              {title}
            </Box>
          )
        })}
      </Inline>
      <PermissionsAndRestrictionsBox
        roleTitle={title}
        permissions={updatedPermissions}
        restrictions={restrictionsDescription}
      />
    </Stack>
  )
}

export function PermissionsAndRestrictionsBox({
  roleTitle,
  permissions,
  restrictions,
}: {
  roleTitle: string
  permissions: Array<string>
  restrictions?: Array<string>
}) {
  return (
    <Box borderWidth="1" paddingX="6" paddingY="4" rounded="md">
      <Stack gap="8">
        <Stack gap="6">
          <Text color="gray500" fontWeight="semibold">
            Permissions
          </Text>
          <Stack as="ul" gap="4">
            {permissions.map((permission) => (
              <Inline as="li" key={permission} alignItems="center" gap="3">
                <Box>
                  <CheckCircleSolidIcon color="green500" />
                </Box>
                <Text fontWeight="medium">{permission}</Text>
              </Inline>
            ))}
          </Stack>
        </Stack>
        {restrictions && restrictions.length ? (
          <Stack gap="6">
            <Text color="gray500" fontWeight="semibold">
              Restrictions
            </Text>
            <Stack gap="4">
              {restrictions.map((restriction) => (
                <Inline as="li" key={restriction} alignItems="center" gap="3">
                  <CancelFilledIcon
                    size={{ xs: "8", sm: "6" }}
                    color="red900"
                  />
                  <Text>{restriction}</Text>
                </Inline>
              ))}
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  )
}

function getColorsForRoleDetails(role: T_AVAILABLE_ROLES) {
  const color =
    role === "editor" || role === "viewer"
      ? "gray900"
      : bookRolesColorCodes[role].color
  const bgColor =
    role === "viewer"
      ? "white"
      : role === "editor"
      ? "gray100"
      : bookRolesColorCodes[role].backgroundColor
  return {
    color,
    background: bgColor,
  }
}

export function RoleDetails({
  role,
}: {
  role: ReturnType<typeof getRoleDetails>
}) {
  return (
    <Box
      display="inlineBlock"
      paddingX="2"
      paddingY="1"
      rounded="md"
      borderWidth={role.id === "viewer" ? "1" : "0"}
      borderColor={role.id === "viewer" ? "gray200" : undefined}
      bgColor={getColorsForRoleDetails(role.id).background}
      color={getColorsForRoleDetails(role.id).color}
    >
      <Text fontSize="sm" fontWeight="medium">
        {role.title}
      </Text>
    </Box>
  )
}

export function SelectRolesForStaff({
  fieldName,
  hideNetBalance,
  hideEntriesByOthers,
}: {
  fieldName: string
  hideNetBalance?: boolean
  hideEntriesByOthers?: boolean
}) {
  const roles = getAllRolesWithPermissions().filter(
    (role) => role.id !== "owner" && role.id !== "partner"
  )
  const [{ value }, meta] = useField<T_AVAILABLE_ROLES>(fieldName)

  const { permissionsDescription, restrictionsDescription } =
    getRoleDetails(value)

  return (
    <Stack gap="6" borderWidth="1" rounded="md" padding="6">
      <Inline as="ul" gap="4">
        {roles.map((role) => (
          <FormField
            key={role.id}
            label={
              <Box
                as="li"
                backgroundColor={value === role.id ? "blue100" : "gray100"}
                rounded="full"
                display="inlineBlock"
                paddingX="4"
                paddingY="2"
                borderWidth="1"
                color={value === role.id ? "blue900" : "gray500"}
                borderColor={value === role.id ? "blue200" : "gray200"}
                cursor="pointer"
              >
                <Text as="span" fontWeight="medium" fontSize="base">
                  {role.title}
                </Text>
              </Box>
            }
            type="radio"
            name={fieldName}
            value={role.id}
            id={`${fieldName}.${role.id}`}
            noMargin
            invisibleInput
            hideError
          />
        ))}
      </Inline>
      {meta.error && meta.touched ? (
        <Box
          paddingX="2"
          paddingY="1"
          bgColor="red100"
          borderWidth="1"
          borderColor="red100"
          color="red900"
          fontSize="sm"
          rounded="md"
        >
          {meta.error}
        </Box>
      ) : null}
      {permissionsDescription.length ? (
        <Stack gap="4">
          <Text color="gray500" fontWeight="semibold">
            Permissions
          </Text>
          <Stack as="ul" gap="4">
            {permissionsDescription.map((permission) => {
              if (value === "editor") {
                if (
                  (hideEntriesByOthers &&
                    permission
                      .toLowerCase()
                      .includes("View entries by everyone".toLowerCase())) ||
                  (hideNetBalance &&
                    permission
                      .toLowerCase()
                      .includes(
                        "View net balance & download PDF or Excel".toLowerCase()
                      ))
                )
                  return null
              }

              return (
                <Inline as="li" key={permission} gap="3" alignItems="center">
                  <Box>
                    <CheckCircleSolidIcon color="green500" />
                  </Box>
                  <Text>{permission}</Text>
                </Inline>
              )
            })}
          </Stack>
        </Stack>
      ) : null}
      {restrictionsDescription.length ? (
        <Stack gap="4">
          <Text color="gray500" fontWeight="semibold">
            Restrictions
          </Text>
          <Stack as="ul" gap="4">
            {restrictionsDescription.map((restriction) => (
              <Inline as="li" key={restriction} gap="3" alignItems="center">
                <Box>
                  <CancelFilledIcon color="red500" />
                </Box>
                <Text>{restriction}</Text>
              </Inline>
            ))}
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  )
}

export function BookRolesAndPermissions({
  role,
  bookId,
  hideNetBalance,
  hideEntriesByOthers,
}: {
  bookId: string
  role: T_AVAILABLE_ROLES
  hideNetBalance?: boolean
  hideEntriesByOthers?: boolean
}) {
  const { title, permissionsDescription, restrictionsDescription } =
    getRoleDetails(role)

  const [bookRolesAndPermissions, setBookRolesAndPermissions] =
    useSyncedStorageState<{ [key: string]: boolean }>(
      "bookRolesAndPermissions",
      {}
    )

  const state = useOverlayTriggerState({
    isOpen:
      bookRolesAndPermissions[bookId] === undefined &&
      role !== "owner" &&
      role !== "partner"
        ? true
        : false,
  })

  function onClose() {
    state.close()
    setBookRolesAndPermissions({ ...bookRolesAndPermissions, [bookId]: true })
  }

  const updatedPermissions = useMemo(() => {
    return permissionsDescription.filter((permission) => {
      if (role === "editor") {
        if (
          (hideEntriesByOthers &&
            permission
              .toLowerCase()
              .includes("View entries by everyone".toLowerCase())) ||
          (hideNetBalance &&
            permission
              .toLowerCase()
              .includes(
                "View net balance & download PDF or Excel".toLowerCase()
              ))
        )
          return null
      }
      return permission
    })
  }, [hideEntriesByOthers, hideNetBalance, permissionsDescription, role])

  return (
    <Modal
      title={`Roles & Permissions`}
      isOpen={state.isOpen}
      onClose={onClose}
      isDismissable
      placement="right"
    >
      <ModalBody>
        <Stack gap="6">
          <Box borderWidth="1" rounded="md" paddingX="6" paddingY="4">
            <Text as="span" color="gray500">
              Your current role in this book:{" "}
              <Text as="span" fontWeight="semibold" color="black">
                {title}
              </Text>
            </Text>
          </Box>
          <PermissionsAndRestrictionsBox
            roleTitle={title}
            permissions={updatedPermissions}
            restrictions={restrictionsDescription}
          />
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button
          size="lg"
          level="primary"
          onClick={() => {
            onClose?.()
            state.close()
          }}
        >
          Ok, Got it
        </Button>
      </ModalFooter>
    </Modal>
  )
}

//Book Roles Chip Color management service
type Colors = React.ComponentProps<typeof Box>["color"]
type BorderColors = React.ComponentProps<typeof Box>["borderColor"]
type BackgroundColors = React.ComponentProps<typeof Box>["backgroundColor"]
type BookRolesColorCodes = {
  [key in T_AVAILABLE_ROLES]: {
    color: Colors
    borderColor: BorderColors
    backgroundColor: BackgroundColors
  }
}
export const bookRolesColorCodes: BookRolesColorCodes = {
  admin: {
    color: "blue900",
    borderColor: "blue200",
    backgroundColor: "blue100",
  },
  editor: {
    color: "blue900",
    borderColor: "blue200",
    backgroundColor: "blue100",
  },
  viewer: {
    color: "blue900",
    borderColor: "blue200",
    backgroundColor: "blue100",
  },
  partner: {
    color: "orange900",
    borderColor: "orange200",
    backgroundColor: "orange100",
  },
  owner: {
    color: "green900",
    borderColor: "green500",
    backgroundColor: "green100",
  },
}
