import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Alert,
  AppDownloadIcon,
  CheckCircleSolidIcon,
  InformationCircleIcon,
  LogoutIcon,
  PageMeta,
  PencilIcon,
  Switch,
  Time,
  Box,
  Text,
  Heading,
  Inline,
  Stack,
  InformationWarningIcon,
  VerifiedIcon,
  CBButton,
  AddEmailVectorIcon,
  Circle,
  PlusIcon,
  VerifyEmailVectorIcon,
  Button,
} from "@cashbook/web-components"
import { useEffect, useState } from "react"
import { useUser } from "reactfire"
import config from "../config"
import {
  CannotChangePhoneNumberModal,
  SendVerificationMailInDialog,
  UpdateProfileInDialog,
} from "../Profile"
import appImageSrc from "../Profile/app_image.png"
import { useProfile } from "@cashbook/data-store/users"
import { useToggleNotificationSubscription } from "@cashbook/data-store/notifications"
import { LogoutOptionsInModal } from "../Auth"
import { isPhoneNumberIndian, isVisitorIndian } from "@cashbook/util-general"
import classNames from "classnames"
import { toast } from "react-hot-toast"

export default function ProfilePage() {
  const { data: authUser } = useUser()
  const { user } = useProfile()

  const [isCannotChangeNumber, setIsCannotChangeNumber] =
    useState<boolean>(false)

  useEffect(() => {
    trackEvent(TrackingEvents.PROFILE_SCREEN_VISIT)
  }, [])
  const {
    hasSubscribed,
    toggleSubscription,
    isUpdating: isUpdatingNotificationSubscriptions,
    isSupportedByBrowser,
    hasBlocked,
  } = useToggleNotificationSubscription()

  const walletEnabled = false

  const isUserIndian = user.phoneNumber
    ? isPhoneNumberIndian(user.phoneNumber)
    : isVisitorIndian()

  const [isEmailAdded, setIsEmailAdded] = useState<boolean>(
    Boolean(user.email?.length)
  )

  return (
    <>
      <PageMeta>
        <title>Your Profile</title>
      </PageMeta>
      <Stack width="full" paddingY="8" alignItems="center" gap="16">
        <Stack maxWidth="lg" gap="2" width="full">
          <Stack
            padding={{ xs: "0", md: "8" }}
            bgColor="white"
            rounded="md"
            gap="8"
          >
            <Stack gap="3">
              <Stack gap="2">
                <Text fontSize="sm" fontWeight="medium" color="gray500">
                  Name
                </Text>
                <Heading as="h2" fontWeight="medium" fontSize="2xl">
                  {user
                    ? user.displayName
                    : authUser?.displayName || `${config.appTitle} User`}
                </Heading>
                {user ? (
                  <Box>
                    <Stack gap="2">
                      {user.phoneNumber ? (
                        <Text fontSize="b3">{user.phoneNumber}</Text>
                      ) : null}
                      {isEmailAdded && user.email ? (
                        <Stack gap="2">
                          <Stack gap="1">
                            <Inline gap="2" alignItems="center">
                              <Text fontSize="b3">{user.email}</Text>
                              {!user.emailVerified ? (
                                <InformationWarningIcon
                                  size="4"
                                  color="iconError"
                                />
                              ) : null}
                            </Inline>
                            {user.emailVerified ? (
                              <Inline
                                gap="3"
                                color="textCashIn"
                                padding="4"
                                rounded="md"
                                marginTop="1"
                                position="relative"
                                backgroundColor="surfaceSuccessLowest"
                              >
                                <Box
                                  className={classNames(
                                    "absolute w-2 left-[4%] h-2 -bottom-3 -top-1"
                                  )}
                                  backgroundColor="surfaceSuccessLowest"
                                  tabIndex={0}
                                  style={{
                                    transform: "translateX(-50%) rotate(45deg)",
                                    rotate: "",
                                  }}
                                />
                                <VerifiedIcon size="6" color="iconSuccess" />
                                <Stack gap="2" className="pt-[2px]">
                                  <Text fontSize="s4">Verified Email ID</Text>
                                  <Text fontSize="c2">
                                    Use this Email ID to log in to the{" "}
                                    {!isUserIndian ? "mobile app & " : ""}
                                    web.cashbook.in on your laptop/desktop
                                  </Text>
                                </Stack>
                              </Inline>
                            ) : (
                              <AddOrVerifyEmailBanner
                                isUserIndian={isUserIndian}
                                email={user.email}
                                onSuccess={() => {
                                  toast.success("Email verified successfully!")
                                }}
                              />
                            )}
                          </Stack>
                        </Stack>
                      ) : (
                        <AddOrVerifyEmailBanner
                          isUserIndian={isUserIndian}
                          onSuccess={() => {
                            setIsEmailAdded(true)
                            toast.success(
                              "Email added & verified successfully!"
                            )
                          }}
                        />
                      )}
                    </Stack>
                  </Box>
                ) : null}
              </Stack>
              <Inline as="footer" gap="4" collapseBelow="sm">
                <UpdateProfileInDialog
                  isIndianUser={isUserIndian}
                  onClickChangePhoneNumber={
                    walletEnabled
                      ? () => {
                          setIsCannotChangeNumber(true)
                        }
                      : undefined
                  }
                >
                  {({ update }) => (
                    <CBButton onClick={update} iconPlacement="left">
                      <PencilIcon /> Edit Profile
                    </CBButton>
                  )}
                </UpdateProfileInDialog>
                <LogoutOptionsInModal>
                  {({ onOpen }) => (
                    <Button level="tertiary" status="error" onClick={onOpen}>
                      <Box>
                        <LogoutIcon />
                      </Box>
                      Logout
                    </Button>
                  )}
                </LogoutOptionsInModal>
              </Inline>
            </Stack>
            <Box paddingTop="8" borderTopWidth="1">
              <Box marginBottom="4">
                <Text color="gray500" fontWeight="medium">
                  Preferences
                </Text>
              </Box>
              <Stack as="ol">
                <Inline gap="4">
                  <Stack flex="1" minWidth="0" gap="2">
                    <Stack gap="1">
                      <Heading as="h4" fontWeight="medium">
                        Notifications
                      </Heading>
                      <Text fontSize="sm" color="gray500" fontWeight="normal">
                        Get notified for entries from group books
                      </Text>
                    </Stack>
                    {!isSupportedByBrowser ? (
                      <Text fontSize="xs" fontWeight="medium" color="yellow600">
                        <InformationCircleIcon /> This browser doesn't support
                        this feature.
                      </Text>
                    ) : hasBlocked ? (
                      <Alert status="warning">
                        Notifications has been blocked in this browser. Please
                        re-enable manually.
                      </Alert>
                    ) : null}
                  </Stack>
                  <Box>
                    <Switch
                      label="Toggle Notifications"
                      on={hasSubscribed}
                      disabled={
                        isUpdatingNotificationSubscriptions ||
                        !isSupportedByBrowser
                      }
                      onToggle={async () => {
                        try {
                          await toggleSubscription()
                        } catch (e) {
                          const error = e as Error
                          alert(
                            error.message || "Sorry! Please try after some time"
                          )
                        }
                      }}
                    />
                  </Box>
                </Inline>
              </Stack>
            </Box>
          </Stack>
          {user.metadata?.creationTime ? (
            <Box textAlign="right">
              <Text fontSize="sm" color="gray500">
                Since <Time date={new Date(user.metadata.creationTime)} />
              </Text>
            </Box>
          ) : null}
        </Stack>
        <Box maxWidth="lg">
          <Inline
            as="a"
            rounded="lg"
            overflow="hidden"
            borderWidth="4"
            borderColor="blue900"
            className="shadow-lg"
            href={config.appDownloadLink}
            target="_blank"
            rel="noreferrer nopener"
            collapseBelow="sm"
          >
            <Box width={{ xs: "full", md: "1/2" }}>
              <img src={appImageSrc} alt="Mobile App" />
            </Box>
            <Inline
              bgColor="blue900"
              paddingY="4"
              color="white"
              width={{ xs: "full", md: "1/2" }}
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <Box marginBottom="1">
                  <Text fontSize="xs">Checkout the</Text>
                </Box>
                <Box marginBottom="2">
                  <Heading as="h3" fontSize="2xl">
                    Mobile App
                  </Heading>
                </Box>
                <Stack gap="2" fontSize="sm" fontWeight="medium">
                  <Inline gap="2">
                    <CheckCircleSolidIcon color="green100" size="4" />
                    <Text>Offline Support</Text>
                  </Inline>
                  <Inline gap="2">
                    <CheckCircleSolidIcon color="green100" size="4" />
                    <Text>Book Sharing</Text>
                  </Inline>
                  <Inline gap="2">
                    <CheckCircleSolidIcon color="green100" size="4" />
                    <Text>Data Backup</Text>
                  </Inline>
                </Stack>
                <Box
                  bgColor="blue100"
                  paddingX="4"
                  paddingY="2"
                  marginTop="4"
                  rounded="md"
                  className="shadow"
                >
                  <Text color="black">
                    <AppDownloadIcon /> Download Now
                  </Text>
                </Box>
              </Box>
            </Inline>
          </Inline>
        </Box>
      </Stack>
      {isCannotChangeNumber && (
        <CannotChangePhoneNumberModal
          phoneNumber={user.phoneNumber || ""}
          onClose={() => {
            setIsCannotChangeNumber(false)
          }}
        />
      )}
    </>
  )
}

function AddOrVerifyEmailBanner({
  email,
  isUserIndian,
  onSuccess,
}: {
  email?: string
  isUserIndian: boolean
  onSuccess?: () => void
}) {
  return (
    <Box
      rounded="md"
      marginY="4"
      backgroundColor={!email ? "surfaceSuccessLowest" : "surfaceWarningLowest"}
    >
      <Inline gap="4" padding="4">
        {!email ? (
          <AddEmailVectorIcon size="9" />
        ) : (
          <VerifyEmailVectorIcon size="9" />
        )}
        <Stack gap="4">
          <Text fontSize="s4">
            {!email ? "Add Email ID to Profile" : "Verify Email ID"}
          </Text>
          <Stack as="ul" gap="2">
            <Inline as="li" gap="4" alignItems="center">
              <Circle size="2" backgroundColor="iconLowest" />
              <Text fontSize="c2" color="textMedium">
                Login via verified email on{" "}
                {!isUserIndian ? "mobile app & " : ""}
                desktop
              </Text>
            </Inline>
            <Inline as="li" gap="4">
              <Circle
                size="2"
                className="mt-[2px]"
                backgroundColor="iconLowest"
              />
              <Text fontSize="c2" color="textMedium">
                Get monthly summary on email <br />
                <Text as="span" color="textWarning">
                  (Coming Soon)
                </Text>
              </Text>
            </Inline>
          </Stack>
        </Stack>
      </Inline>
      <Stack
        paddingY={!email ? "2" : "3"}
        paddingX="4"
        borderTopWidth="1"
        borderColor={!email ? "borderSuccess" : "borderWarning"}
        justifyContent="center"
        alignItems="center"
      >
        <SendVerificationMailInDialog onSuccess={onSuccess}>
          {({ openModal }) => (
            <CBButton
              onClick={openModal}
              status={!email ? "cashIn" : "warning"}
              inline
              fullWidth
            >
              {!email ? <PlusIcon /> : null}
              {!email ? "Add Email" : "Verify Email"}
            </CBButton>
          )}
        </SendVerificationMailInDialog>
      </Stack>
    </Box>
  )
}
