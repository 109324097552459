import {
  Button,
  FormField,
  Modal,
  ModalBody,
  ModalFooter,
  SpinnerIcon,
  Box,
  Stack,
  Text,
  Alert,
  formikOnSubmitWithErrorHandling,
  Inline,
  CheckIcon,
  CBButton,
  VerifiedIcon,
  GoogleVectorIcon,
  Circle,
} from "@cashbook/web-components"
import { useOverlayTriggerState } from "@react-stately/overlays"
import { Form, Formik } from "formik"
import { useMemo, useState } from "react"
import { Navigate, Outlet, useNavigate } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import * as Validator from "yup"
import { OnlyAfterAuthenticated } from "../Auth"
import {
  useProfile,
  useHasCompletedProfile,
  useVerifyEmail,
  useVerifyWithSocials,
} from "@cashbook/data-store/users"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { EmailValidator, OTPValidator } from "@cashbook/util-general"
import { WhatsAppContactLink } from "../HelpDocs"
import config from "../config"
import { toast } from "react-hot-toast"

const updateProfileValidationSchema = Validator.object().shape({
  displayName: Validator.string()
    .required("Please provide your name.")
    .max(191, "Please use 191 or fewer characters for the name"),
  email: EmailValidator,
})

const CHANGE_EMAIL_POINTERS = {
  title:
    "Changing email address will transfer all your data from old email to new email address",
  pointers: [
    "Your data is linked to your email address",
    "You will need to use your new email for login to see your data after you change your email",
  ],
}

export function UpdateProfile({
  isIndianUser,
  showEmailDisclaimer,
  onCancel,
  onSuccess,
  clickEmailChange,
  onClickChangePhoneNumber,
}: {
  isIndianUser: boolean
  showEmailDisclaimer: boolean
  onCancel: () => void
  onSuccess?: () => void
  clickEmailChange: () => void
  onClickChangePhoneNumber?: () => void
}) {
  const navigate = useNavigate()
  const { user, update } = useProfile()

  const [sentOTPForEmailVerification, setSentOTPForEmailVerification] =
    useState<string | undefined>(undefined)

  const initialValues = useMemo(() => {
    return {
      displayName: user.displayName,
      email: user.email || "",
      phoneNumber: user.phoneNumber || "",
    }
  }, [user])

  function onClickForChangeNumber() {
    if (onClickChangePhoneNumber) {
      return onClickChangePhoneNumber()
    }
    navigate("/change-phone-number")
  }

  function onClickChangeEmailNext() {
    navigate("/change-email-address")
  }

  const otpValidationModal = useOverlayTriggerState({})

  const {
    sendingVerificationCode,
    secondsRemainingToResend,
    verifyEmailWithOtp,
    sendVerificationOtp,
    resendVerificationOtp,
  } = useVerifyEmail()

  return (
    <>
      {showEmailDisclaimer ? (
        <>
          <ModalBody>
            <Stack gap="6" alignItems="center" justifyContent="center">
              <ChangeEmailVectorIcon />

              <Text fontSize="s3">{CHANGE_EMAIL_POINTERS.title}</Text>
              <Stack as="ul" gap="4">
                {CHANGE_EMAIL_POINTERS.pointers.map((item) => (
                  <Inline as="li" gap="4" key={item}>
                    <Circle
                      className="mt-1"
                      size="2"
                      backgroundColor="iconLowest"
                    />
                    <Text>{item}</Text>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <CBButton
              level="primary"
              size="lg"
              onClick={onClickChangeEmailNext}
            >
              Next
            </CBButton>
          </ModalFooter>
        </>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={updateProfileValidationSchema}
          onSubmit={formikOnSubmitWithErrorHandling(
            async ({ phoneNumber, email, ...values }, actions) => {
              if (values.displayName !== initialValues.displayName) {
                trackEvent(TrackingEvents.USER_PROFILE_NAME_UPDATED)
              }
              const payload: {
                displayName: string | undefined
                email?: string
              } = {
                ...values,
              }
              if (email.length) {
                delete payload["email"]
              }
              await update({ ...payload })
              if (email.length && initialValues.email !== email) {
                try {
                  await sendVerificationOtp(email)
                  setSentOTPForEmailVerification(email)
                  otpValidationModal.open()
                } catch (e) {
                  const err = e as Error
                  actions.setStatus(err.message)
                }
              } else {
                toast.success("Updated user profile successfully!")
                onSuccess?.()
              }
            }
          )}
        >
          {({ isSubmitting, status }) => (
            <Form noValidate>
              <ModalBody>
                <FormField
                  name="displayName"
                  label="Your Name"
                  placeholder="First Last Name"
                  type="text"
                  required
                />
                <FormField
                  name="email"
                  label="Email"
                  disabled={Boolean(user.email?.length)}
                  placeholder="e.g. username@example.com"
                  type="email"
                  help={
                    user.email?.length ? (
                      <CBButton
                        level="tertiary"
                        inline
                        onClick={() => {
                          if (isIndianUser) {
                            return onClickChangeEmailNext()
                          }
                          clickEmailChange()
                        }}
                      >
                        <Text className="pl-2" fontSize="c2">
                          Change Email
                        </Text>
                      </CBButton>
                    ) : null
                  }
                />
                {isIndianUser || user.phoneNumber ? (
                  <FormField
                    name="phoneNumber"
                    label="Mobile Number"
                    type="text"
                    disabled
                    help={
                      isIndianUser && user.phoneNumber ? (
                        <CBButton
                          level="tertiary"
                          inline
                          onClick={onClickForChangeNumber}
                        >
                          <Text className="pl-2" fontSize="c2">
                            Change mobile number
                          </Text>
                        </CBButton>
                      ) : null
                    }
                  />
                ) : null}
                {status ? (
                  <Alert status="error" margin="0">
                    {status}
                  </Alert>
                ) : null}
              </ModalBody>
              <ModalFooter>
                <Button type="submit" disabled={isSubmitting} size="lg">
                  {isSubmitting ? "Saving..." : "Save"}
                </Button>
                {onCancel ? (
                  <Button type="button" onClick={onCancel} size="lg">
                    Cancel
                  </Button>
                ) : null}
              </ModalFooter>
            </Form>
          )}
        </Formik>
      )}
      <EmailVerificationViaOTP
        isOpen={otpValidationModal.isOpen}
        onClose={otpValidationModal.close}
        email={sentOTPForEmailVerification || ""}
        sendingVerificationCode={sendingVerificationCode}
        secondsRemainingToResend={secondsRemainingToResend}
        verifyOtp={verifyEmailWithOtp}
        onSuccess={() => {
          onSuccess?.()
          toast.success("Profile update & email verified successfully!")
        }}
        resendVerificationOtp={resendVerificationOtp}
      />
    </>
  )
}

function ChangeEmailVectorIcon() {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.5"
        x="8"
        y="8"
        width="80"
        height="80"
        rx="40"
        fill="#EDEFFB"
      />
      <rect x="16" y="16" width="64" height="64" rx="32" fill="#EDEFFB" />
      <g clipPath="url(#ChangeEmailVectorIcon)">
        <path
          d="M91.3041 74.0871L77.9998 82.6957L64.6955 74.0871V60.7827C64.6863 60.3597 65.0218 60.0094 65.4449 60.0002C65.456 60 65.467 60 65.4781 60.0002H90.5215C90.9445 59.991 91.2949 60.3265 91.3041 60.7496C91.3043 60.7607 91.3043 60.7717 91.3041 60.7828V74.0871Z"
          fill="#FFFBF3"
        />
        <path
          d="M64.6956 74.0871L60 70.9566L64.6956 66.261V74.0871Z"
          fill="#F6B445"
        />
        <path
          d="M96.0003 70.9566L91.3047 74.0871V66.261L96.0003 70.9566Z"
          fill="#F6B445"
        />
        <path
          d="M91.3051 74.0166L96.0008 70.9565V92.8695C95.9993 93.6996 95.6702 94.4955 95.0851 95.0843L89.74 89.7391L81.1313 81.1304L80.9121 80.7939L91.3051 74.0166Z"
          fill="#FED049"
        />
        <path
          d="M89.7394 89.7391L95.0846 95.0843C94.4958 95.6694 93.6999 95.9985 92.8698 95.9999H63.1308C62.3007 95.9985 61.5048 95.6694 60.916 95.0843L66.2612 89.7391L74.8698 81.1305L75.089 80.7939L78.0003 82.6957L80.9115 80.7939L81.1307 81.1305L89.7394 89.7391Z"
          fill="#F6B445"
        />
        <path
          d="M75.0886 80.7939L74.8694 81.1304L66.2608 89.7391L60.9156 95.0842C60.3305 94.4954 60.0015 93.6995 60 92.8694V70.9565L75.0886 80.7939Z"
          fill="#FED049"
        />
        <path
          d="M78 86.9999C82.9705 86.9999 86.9999 82.9705 86.9999 78C86.9999 73.0294 82.9705 69 78 69C73.0294 69 69 73.0294 69 78C69 82.9705 73.0294 86.9999 78 86.9999Z"
          fill="#179F51"
        />
        <path
          d="M86.9999 78.0002C86.9999 82.9502 82.9499 87.0001 78 87.0001C75.1312 87.0001 72.6 85.7064 70.9688 83.6252C72.4875 84.8627 74.4562 85.5939 76.5937 85.5939C81.5437 85.5939 85.5937 81.5439 85.5937 76.594C85.5937 74.4565 84.8624 72.4877 83.6249 70.969C85.7062 72.6002 86.9999 75.1314 86.9999 78.0002Z"
          fill="#179F51"
        />
        <path
          d="M82.8933 75.4124L77.0434 81.8249C76.6496 82.2749 75.9746 82.2749 75.5809 81.8249L73.1059 79.0686C72.7684 78.6749 72.7684 78.0561 73.1621 77.6624C73.5559 77.3249 74.1746 77.3249 74.5684 77.7186L76.3121 79.6874L81.4308 74.1186C81.8246 73.7249 82.4433 73.6686 82.8371 74.0624C83.2308 74.3999 83.2308 75.0749 82.8933 75.4124Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#ChangeEmailVectorIconClip)">
        <path
          d="M31.3041 14.0871L17.9998 22.6957L4.6955 14.0871V0.782739C4.68632 0.359693 5.02184 0.00935477 5.44489 0.000183623C5.45597 -3.64845e-05 5.46705 -3.64845e-05 5.47813 0.000183623H30.5215C30.9445 -0.00898752 31.2949 0.32653 31.3041 0.749576C31.3043 0.760655 31.3043 0.771734 31.3041 0.782813V14.0871Z"
          fill="#FFFBF3"
        />
        <path
          d="M4.69563 14.0871L0 10.9566L4.69563 6.26099V14.0871Z"
          fill="#F6B445"
        />
        <path
          d="M36.0003 10.9566L31.3047 14.0871V6.26099L36.0003 10.9566Z"
          fill="#F6B445"
        />
        <path
          d="M31.3051 14.0166L36.0008 10.9565V32.8695C35.9993 33.6996 35.6702 34.4955 35.0851 35.0843L29.74 29.7391L21.1313 21.1304L20.9121 20.7939L31.3051 14.0166Z"
          fill="#FED049"
        />
        <path
          d="M29.7394 29.7391L35.0846 35.0843C34.4958 35.6694 33.6999 35.9985 32.8698 35.9999H3.13081C2.30071 35.9985 1.5048 35.6694 0.916016 35.0843L6.26118 29.7391L14.8698 21.1305L15.089 20.7939L18.0003 22.6957L20.9115 20.7939L21.1307 21.1305L29.7394 29.7391Z"
          fill="#F6B445"
        />
        <path
          d="M15.0886 20.7939L14.8694 21.1304L6.26082 29.7391L0.915648 35.0842C0.330528 34.4954 0.00146738 33.6995 0 32.8694V10.9565L15.0886 20.7939Z"
          fill="#FED049"
        />
        <path
          d="M18 26.9999C22.9705 26.9999 26.9999 22.9705 26.9999 18C26.9999 13.0294 22.9705 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9705 13.0294 26.9999 18 26.9999Z"
          fill="#179F51"
        />
        <path
          d="M26.9999 18.0002C26.9999 22.9502 22.9499 27.0001 18 27.0001C15.1312 27.0001 12.6 25.7064 10.9688 23.6252C12.4875 24.8627 14.4562 25.5939 16.5937 25.5939C21.5437 25.5939 25.5937 21.5439 25.5937 16.594C25.5937 14.4565 24.8624 12.4877 23.6249 10.969C25.7062 12.6002 26.9999 15.1314 26.9999 18.0002Z"
          fill="#179F51"
        />
        <path
          d="M22.8933 15.4124L17.0434 21.8249C16.6496 22.2749 15.9746 22.2749 15.5809 21.8249L13.1059 19.0686C12.7684 18.6749 12.7684 18.0561 13.1621 17.6624C13.5559 17.3249 14.1746 17.3249 14.5684 17.7186L16.3121 19.6874L21.4308 14.1186C21.8246 13.7249 22.4433 13.6686 22.8371 14.0624C23.2308 14.3999 23.2308 15.0749 22.8933 15.4124Z"
          fill="white"
        />
      </g>
      <path
        d="M54.3703 48.7141L49.4135 43.7573L47.9993 45.1715L52.9561 50.1283L50.8348 52.2496L56.4845 52.2425L56.4916 46.5928L54.3703 48.7141ZM39.514 43.7573L39.5069 49.407L41.6282 47.2857L46.585 52.2425L47.9993 50.8283L43.0424 45.8715L45.1638 43.7502L39.514 43.7573ZM54.3703 48.7141L49.4135 43.7573L47.9993 45.1715L52.9561 50.1283L50.8348 52.2496L56.4845 52.2425L56.4916 46.5928L54.3703 48.7141ZM39.514 43.7573L39.5069 49.407L41.6282 47.2857L46.585 52.2425L47.9993 50.8283L43.0424 45.8715L45.1638 43.7502L39.514 43.7573Z"
        fill="#4863D4"
      />
      <defs>
        <clipPath id="ChangeEmailVectorIcon">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="translate(60 60)"
          />
        </clipPath>
        <clipPath id="ChangeEmailVectorIconClip">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function UpdateProfileInDialog({
  isIndianUser,
  children,
  onClickChangePhoneNumber,
}: {
  isIndianUser: boolean
  onClickChangePhoneNumber?: () => void
  children: (props: { update: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const [showEmailChangeDisclaimer, setShowEmailChangeDisclaimer] =
    useState<boolean>(false)
  return (
    <>
      {children({ update: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title={
          showEmailChangeDisclaimer ? "Change Email?" : "Update Profile Details"
        }
        onBackPress={
          showEmailChangeDisclaimer
            ? () => setShowEmailChangeDisclaimer(false)
            : undefined
        }
      >
        <SuspenseWithPerf
          fallback={
            <Box textAlign="center" paddingY="8">
              <SpinnerIcon />
            </Box>
          }
          traceId="loading_profile_to_update"
        >
          <UpdateProfile
            onCancel={state.close}
            onSuccess={state.close}
            isIndianUser={isIndianUser}
            showEmailDisclaimer={showEmailChangeDisclaimer}
            onClickChangePhoneNumber={onClickChangePhoneNumber}
            clickEmailChange={() => setShowEmailChangeDisclaimer(true)}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

export function UpdateUserEmail({
  email,
  step,
  setStep,
  onCancel,
  onSuccess,
}: {
  email?: string | null
  step: EmailVerificationSteps
  setStep: (step: EmailVerificationSteps) => void
  onSuccess: () => void
  onCancel: () => void
}) {
  const [emailToBeVerified, setEmailToBeVerified] = useState<
    string | undefined
  >(undefined)
  const {
    sendingVerificationCode,
    secondsRemainingToResend,
    sendVerificationOtp,
    verifyEmailWithOtp,
    resendVerificationOtp,
  } = useVerifyEmail()
  const { loading, verifyEmailWithGoogle } = useVerifyWithSocials()
  const initialValues = useMemo(() => {
    return {
      email: email || "",
    }
  }, [email])

  async function verifyWithGoogle() {
    try {
      const userEmail = await verifyEmailWithGoogle()
      if (userEmail?.length) {
        setStep("email_verified")
        setEmailToBeVerified(userEmail)
      }
    } catch (e) {
      const error = e as Error
      toast.error(error.message)
    }
  }

  return step === "email_verified" && emailToBeVerified ? (
    <EmailVerificationCompleted email={emailToBeVerified} onClose={onSuccess} />
  ) : step === "verify_email" && emailToBeVerified ? (
    <Formik
      initialValues={{
        email: emailToBeVerified as string,
        otp: "" as string,
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Validator.object().shape({
        email: EmailValidator.required(),
        otp: OTPValidator.required("Please enter a valid verification code."),
      })}
      onSubmit={formikOnSubmitWithErrorHandling(
        async ({ email, otp }, { setFieldError }) => {
          try {
            await verifyEmailWithOtp(email, otp)
            setStep("email_verified")
            setEmailToBeVerified(email)
          } catch (e) {
            const error = e as Error
            setFieldError("otp", error.message)
          }
        }
      )}
    >
      {({ values, status, isSubmitting }) => (
        <Form noValidate>
          <ModalBody>
            <Stack gap="6">
              <Stack gap="3">
                <Text fontSize="s1">
                  Please enter the 6-digit OTP sent to {values.email}
                </Text>
                <Text fontSize="b3">Use only the latest OTP sent on email</Text>
              </Stack>
              <FormField
                type="text"
                name="otp"
                inputMode="numeric"
                autoFocus
                placeholder="e.g. 123456"
                autoComplete="off"
                maxLength={6}
              />
            </Stack>
            {status ? <Alert status="error">{status}</Alert> : null}
          </ModalBody>
          <ModalFooter>
            <CBButton
              type="submit"
              size="lg"
              disabled={!values.otp?.length}
              loading={isSubmitting}
            >
              Verify
            </CBButton>
            <CBButton
              type="button"
              key="resending_otp"
              disabled={Boolean(
                !sendingVerificationCode && secondsRemainingToResend
              )}
              loading={sendingVerificationCode || isSubmitting}
              size="lg"
              onClick={async () => {
                if (!sendingVerificationCode && !secondsRemainingToResend) {
                  resendVerificationOtp(values.email)
                }
              }}
            >
              {sendingVerificationCode ? (
                "Resending OTP"
              ) : secondsRemainingToResend ? (
                <>
                  Resend OTP in{" "}
                  <span
                    style={{
                      fontVariantNumeric: "tabular-nums",
                    }}
                  >
                    {secondsRemainingToResend}
                  </span>
                </>
              ) : (
                "Resend OTP"
              )}
            </CBButton>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  ) : (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={Validator.object().shape({
        email: EmailValidator.required("Please enter a valid email address!"),
      })}
      onSubmit={formikOnSubmitWithErrorHandling(
        async (values, { setFieldError }) => {
          try {
            await sendVerificationOtp(values.email)
            setStep("verify_email")
            setEmailToBeVerified(values.email)
          } catch (e) {
            const error = e as Error
            setFieldError("email", error.message)
          }
        }
      )}
    >
      {({ isSubmitting, status, values }) => (
        <Form noValidate>
          <ModalBody>
            <Stack gap="8">
              <CBButton
                size="lg"
                type="button"
                iconPlacement="left"
                disabled={isSubmitting}
                loading={loading}
                onClick={verifyWithGoogle}
              >
                {loading ? <SpinnerIcon /> : <GoogleVectorIcon />}
                Continue with Google
              </CBButton>
              <Inline gap="2" alignItems="center">
                <Box
                  as="hr"
                  className="h-[1px]"
                  flex="1"
                  backgroundColor="borderOutline"
                />
                <Text fontSize="b3">Or</Text>
                <Box
                  as="hr"
                  className="h-[1px]"
                  flex="1"
                  backgroundColor="borderOutline"
                />
              </Inline>
              <FormField
                name="email"
                label="Email"
                disabled={isSubmitting}
                placeholder="e.g. username@example.com"
                type="email"
              />
            </Stack>
            {status ? <Alert status="error">{status}</Alert> : null}
          </ModalBody>
          <ModalFooter>
            <CBButton
              type="submit"
              disabled={!values.email.length || loading}
              loading={isSubmitting}
              size="lg"
              onClick={() => {
                trackEvent(TrackingEvents.VERIFY_EMAIL_CLICKED, {
                  email: values.email,
                  from: "verifyEmailPopup",
                  oldEmail: initialValues.email || null,
                })
              }}
            >
              {isSubmitting
                ? "Sending verification OTP..."
                : "Send verification OTP"}
            </CBButton>
            {onCancel ? (
              <CBButton type="button" onClick={() => onCancel()} size="lg">
                Cancel
              </CBButton>
            ) : null}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

function EmailVerificationCompleted({
  email,
  onClose,
}: {
  email: string
  onClose: () => void
}) {
  return (
    <>
      <ModalFooter>
        <Stack
          alignItems="center"
          justifyContent="center"
          gap="6"
          paddingBottom="4"
        >
          <VerifiedIcon size="18" color="iconSuccess" />
          <Stack textAlign="center" gap="3">
            <Text fontSize="s1">Your email is now verified successfully</Text>
            <Text fontSize="b3">
              Use this Email ID:{" "}
              <Text as="span" fontSize="s4" className="break-all">
                {email}
              </Text>{" "}
              to log in to the web.cashbook.in on your laptop/desktop
            </Text>
          </Stack>
        </Stack>
      </ModalFooter>
      <ModalFooter>
        <CBButton
          onClick={onClose}
          size="lg"
          level="primary"
          iconPlacement="left"
        >
          <CheckIcon />
          Ok, got it
        </CBButton>
      </ModalFooter>
    </>
  )
}

export function EmailVerificationViaOTP({
  email,
  isOpen,
  sendingVerificationCode,
  secondsRemainingToResend,
  onClose,
  verifyOtp,
  onSuccess,
  resendVerificationOtp,
}: {
  email: string
  sendingVerificationCode: boolean
  secondsRemainingToResend: number
  isOpen: boolean
  onClose: () => void
  onSuccess?: () => void
  resendVerificationOtp: (email: string) => void
  verifyOtp: (email: string, otp: string) => Promise<boolean>
}) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title="Enter OTP">
        <Formik
          validateOnBlur={false}
          validateOnMount={false}
          validateOnChange={false}
          validationSchema={Validator.object().shape({
            otp: OTPValidator.required(
              "Please enter a valid verification code."
            ),
          })}
          initialValues={{
            email: email as string,
            otp: "" as string,
          }}
          onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
            if (values.email?.length && values.otp?.length) {
              try {
                await verifyOtp(values.email, values.otp)
                onSuccess?.()
              } catch (e) {
                const error = e as Error
                throw error
              }
            }
          })}
        >
          {({ values, status, isSubmitting }) => (
            <Form noValidate>
              <ModalBody>
                <Stack gap="6">
                  <Stack gap="3">
                    <Text fontSize="s1">
                      Please enter the 6-digit OTP sent to {email}
                    </Text>
                    <Text>Use only the latest OTP sent on email</Text>
                  </Stack>
                  <FormField
                    type="text"
                    name="otp"
                    inputMode="numeric"
                    autoFocus
                    placeholder="e.g. 123456"
                    autoComplete="off"
                    maxLength={6}
                  />
                </Stack>
                {status ? <Alert status="error">{status}</Alert> : null}
              </ModalBody>
              <ModalFooter>
                <CBButton
                  type="submit"
                  size="lg"
                  disabled={sendingVerificationCode || !values.otp?.length}
                  loading={isSubmitting}
                >
                  Verify
                </CBButton>
                <CBButton
                  type="button"
                  key="resending_otp"
                  disabled={
                    Boolean(
                      !sendingVerificationCode && secondsRemainingToResend
                    ) || isSubmitting
                  }
                  loading={sendingVerificationCode}
                  size="lg"
                  onClick={async () => {
                    if (
                      !sendingVerificationCode &&
                      !secondsRemainingToResend &&
                      values.email
                    ) {
                      resendVerificationOtp(values.email)
                    }
                  }}
                >
                  {sendingVerificationCode ? (
                    "Resending OTP"
                  ) : secondsRemainingToResend ? (
                    <>
                      Resend OTP in{" "}
                      <span
                        style={{
                          fontVariantNumeric: "tabular-nums",
                        }}
                      >
                        {secondsRemainingToResend}
                      </span>
                    </>
                  ) : (
                    "Resend OTP"
                  )}
                </CBButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

type EmailVerificationSteps = "add_email" | "email_verified" | "verify_email"
export function SendVerificationMailInDialog({
  children,
  onSuccess,
}: {
  onSuccess?: () => void
  children: (props: { openModal: () => void }) => React.ReactNode
}) {
  const {
    user: { email },
  } = useProfile()
  const state = useOverlayTriggerState({})
  const [step, setStep] = useState<EmailVerificationSteps>("add_email")
  function onClose() {
    state.close()
    setStep("add_email")
  }
  return (
    <>
      {children({ openModal: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={onClose}
        title={
          step === "verify_email"
            ? "Enter OTP"
            : step === "email_verified"
            ? "Email Verified"
            : `Add Email Address`
        }
        onBackPress={
          step === "verify_email" ? () => setStep("add_email") : undefined
        }
      >
        <UpdateUserEmail
          email={email}
          step={step}
          setStep={setStep}
          onSuccess={() => {
            onClose()
            onSuccess?.()
          }}
          onCancel={onClose}
        />
      </Modal>
    </>
  )
}

export function RedirectUnlessProfileIsCompleted({
  children,
  redirectTo = "/onboarding",
}: {
  children?: React.ReactNode
  redirectTo?: string
}) {
  return (
    <OnlyAfterAuthenticated>
      <EnsureProfileIsCompleted redirectTo={redirectTo}>
        {children || <Outlet />}
      </EnsureProfileIsCompleted>
    </OnlyAfterAuthenticated>
  )
}

export function OnlyAfterProfileCompletion({
  children,
}: {
  children?: React.ReactNode
}) {
  return (
    <OnlyAfterAuthenticated>
      <EnsureProfileIsCompleted>{children}</EnsureProfileIsCompleted>
    </OnlyAfterAuthenticated>
  )
}

function EnsureProfileIsCompleted({
  children,
  redirectTo,
}: {
  children?: React.ReactNode
  redirectTo?: string | false
}) {
  const hasCompleted = useHasCompletedProfile()
  if (hasCompleted === "loading") {
    return redirectTo ? (
      <Box>
        <SpinnerIcon /> Loading account information...{" "}
      </Box>
    ) : null
  }
  if (hasCompleted === false) {
    return redirectTo ? <Navigate to={redirectTo} replace /> : null
  }
  return <>{children}</>
}

export function CannotChangePhoneNumberModal({
  phoneNumber,
  onClose,
}: {
  phoneNumber: string
  onClose: () => void
}) {
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="sm"
      isDismissable
      title={"Change Phone Number"}
    >
      <ModalBody>
        <Stack textAlign="center" alignItems="center" gap="3">
          <Stack gap="6" textAlign="center" alignItems="center">
            <svg
              width="149"
              height="81"
              viewBox="0 0 149 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M53.6669 70.5007H27.0003C25.6419 70.6945 24.2571 70.5695 22.9554 70.1355C21.6538 69.7015 20.471 68.9704 19.5008 68.0002C18.5306 67.03 17.7995 65.8472 17.3655 64.5456C16.9315 63.2439 16.8064 61.8591 17.0002 60.5007V20.5007C16.8064 19.1424 16.9315 17.7576 17.3655 16.4559C17.7995 15.1543 18.5306 13.9715 19.5008 13.0013C20.471 12.0311 21.6538 11.3 22.9554 10.866C24.2571 10.432 25.6419 10.3069 27.0003 10.5007H44.9303C46.2554 10.501 47.5263 11.0273 48.4636 11.9641L62.2036 25.7041C63.1403 26.6414 63.6666 27.9122 63.6669 29.2374V60.5007C63.8607 61.8591 63.7357 63.2439 63.3017 64.5456C62.8677 65.8472 62.1366 67.03 61.1664 68.0002C60.1962 68.9704 59.0134 69.7015 57.7117 70.1355C56.4101 70.5695 55.0253 70.6945 53.6669 70.5007Z"
                fill="#F5F5F5"
              />
              <path
                d="M37.8337 34.8339V59.5005C37.8337 59.7657 37.7283 60.0201 37.5408 60.2076C37.3533 60.3952 37.0989 60.5005 36.8337 60.5005H32.0004C31.3212 60.5974 30.6288 60.5349 29.978 60.3179C29.3271 60.1009 28.7358 59.7354 28.2506 59.2503C27.7655 58.7651 27.4 58.1738 27.183 57.5229C26.966 56.8721 26.9035 56.1797 27.0004 55.5005V38.8339C26.9035 38.1547 26.966 37.4623 27.183 36.8115C27.4 36.1606 27.7655 35.5693 28.2506 35.0841C28.7358 34.599 29.3271 34.2335 29.978 34.0165C30.6288 33.7995 31.3212 33.737 32.0004 33.8339H36.8337C37.0989 33.8339 37.3533 33.9392 37.5408 34.1268C37.7283 34.3143 37.8337 34.5686 37.8337 34.8339ZM48.667 33.8339H43.8337C43.7024 33.8339 43.5723 33.8597 43.451 33.91C43.3297 33.9602 43.2195 34.0339 43.1266 34.1268C43.0337 34.2196 42.9601 34.3299 42.9098 34.4512C42.8596 34.5725 42.8337 34.7025 42.8337 34.8339V43.6672C42.8337 43.7985 42.8596 43.9286 42.9098 44.0499C42.9601 44.1712 43.0337 44.2814 43.1266 44.3743C43.2195 44.4672 43.3297 44.5408 43.451 44.5911C43.5723 44.6413 43.7024 44.6672 43.8337 44.6672H52.667C52.9323 44.6672 53.1866 44.5618 53.3741 44.3743C53.5617 44.1868 53.667 43.9324 53.667 43.6672V38.8339C53.7639 38.1547 53.7014 37.4623 53.4844 36.8115C53.2674 36.1606 52.9019 35.5693 52.4168 35.0841C51.9317 34.599 51.3403 34.2335 50.6894 34.0165C50.0386 33.7995 49.3462 33.737 48.667 33.8339ZM53.667 55.5005V50.6672C53.667 50.402 53.5617 50.1476 53.3741 49.9601C53.1866 49.7726 52.9323 49.6672 52.667 49.6672H43.8337C43.5685 49.6672 43.3141 49.7726 43.1266 49.9601C42.9391 50.1476 42.8337 50.402 42.8337 50.6672V59.5005C42.8337 59.7657 42.9391 60.0201 43.1266 60.2076C43.3141 60.3952 43.5685 60.5005 43.8337 60.5005H48.667C49.3462 60.5974 50.0386 60.5349 50.6894 60.3179C51.3403 60.1009 51.9317 59.7354 52.4168 59.2503C52.9019 58.7651 53.2674 58.1738 53.4844 57.5229C53.7014 56.8721 53.7639 56.1797 53.667 55.5005Z"
                fill="#BDBDBD"
              />
              <path
                d="M122 70.5007H95.3337C93.9754 70.6945 92.5906 70.5695 91.2889 70.1355C89.9873 69.7015 88.8045 68.9704 87.8343 68.0002C86.8641 67.03 86.133 65.8472 85.699 64.5456C85.265 63.2439 85.1399 61.8591 85.3337 60.5007V20.5007C85.1399 19.1424 85.265 17.7576 85.699 16.4559C86.133 15.1543 86.8641 13.9715 87.8343 13.0013C88.8045 12.0311 89.9873 11.3 91.2889 10.866C92.5906 10.432 93.9754 10.3069 95.3337 10.5007H113.264C114.589 10.501 115.86 11.0273 116.797 11.9641L130.537 25.7041C131.474 26.6414 132 27.9122 132 29.2374V60.5007C132.194 61.8591 132.069 63.2439 131.635 64.5456C131.201 65.8472 130.47 67.03 129.5 68.0002C128.53 68.9704 127.347 69.7015 126.045 70.1355C124.744 70.5695 123.359 70.6945 122 70.5007Z"
                fill="#F5F5F5"
              />
              <path
                d="M106.167 34.8339V59.5005C106.167 59.7657 106.062 60.0201 105.874 60.2076C105.687 60.3952 105.432 60.5005 105.167 60.5005H100.334C99.6547 60.5974 98.9623 60.5349 98.3115 60.3179C97.6606 60.1009 97.0693 59.7354 96.5841 59.2503C96.099 58.7651 95.7335 58.1738 95.5165 57.5229C95.2995 56.8721 95.237 56.1797 95.3339 55.5005V38.8339C95.237 38.1547 95.2995 37.4623 95.5165 36.8115C95.7335 36.1606 96.099 35.5693 96.5841 35.0841C97.0693 34.599 97.6606 34.2335 98.3115 34.0165C98.9623 33.7995 99.6547 33.737 100.334 33.8339H105.167C105.432 33.8339 105.687 33.9392 105.874 34.1268C106.062 34.3143 106.167 34.5686 106.167 34.8339ZM117.001 33.8339H112.167C112.036 33.8339 111.906 33.8597 111.785 33.91C111.663 33.9602 111.553 34.0339 111.46 34.1268C111.367 34.2196 111.294 34.3299 111.243 34.4512C111.193 34.5725 111.167 34.7025 111.167 34.8339V43.6672C111.167 43.7985 111.193 43.9286 111.243 44.0499C111.294 44.1712 111.367 44.2814 111.46 44.3743C111.553 44.4672 111.663 44.5408 111.785 44.5911C111.906 44.6413 112.036 44.6672 112.167 44.6672H121.001C121.266 44.6672 121.52 44.5618 121.708 44.3743C121.895 44.1868 122.001 43.9324 122.001 43.6672V38.8339C122.097 38.1547 122.035 37.4623 121.818 36.8115C121.601 36.1606 121.235 35.5693 120.75 35.0841C120.265 34.599 119.674 34.2335 119.023 34.0165C118.372 33.7995 117.68 33.737 117.001 33.8339ZM122.001 55.5005V50.6672C122.001 50.402 121.895 50.1476 121.708 49.9601C121.52 49.7726 121.266 49.6672 121.001 49.6672H112.167C111.902 49.6672 111.648 49.7726 111.46 49.9601C111.273 50.1476 111.167 50.402 111.167 50.6672V59.5005C111.167 59.7657 111.273 60.0201 111.46 60.2076C111.648 60.3952 111.902 60.5005 112.167 60.5005H117.001C117.68 60.5974 118.372 60.5349 119.023 60.3179C119.674 60.1009 120.265 59.7354 120.75 59.2503C121.235 58.7651 121.601 58.1738 121.818 57.5229C122.035 56.8721 122.097 56.1797 122.001 55.5005Z"
                fill="#BDBDBD"
              />
              <path
                d="M95.5249 41.168C95.5249 52.5819 86.2721 61.8346 74.8583 61.8346C63.4444 61.8346 54.1917 52.5819 54.1917 41.168C54.1917 29.7541 63.4445 20.5013 74.8583 20.5013C86.2722 20.5013 95.5249 29.7541 95.5249 41.168Z"
                fill="#F5F5F5"
                stroke="white"
                strokeWidth="6.66667"
              />
              <path
                d="M84.209 44.8641L81.8967 42.5519C81.4614 42.1166 81.4614 41.411 81.8967 40.9756L84.209 38.6634C84.6443 38.2281 84.6443 37.5225 84.209 37.0872L80.1309 33.0091C79.6956 32.5738 78.9899 32.5738 78.5546 33.0091L76.2424 35.3213C75.8071 35.7566 75.1014 35.7566 74.6661 35.3213L72.3539 33.0091C71.9186 32.5738 71.2129 32.5738 70.7776 33.0091L66.6995 37.0872C66.2642 37.5225 66.2642 38.2281 66.6995 38.6634L69.0118 40.9756C69.4471 41.411 69.4471 42.1166 69.0118 42.5519L66.6995 44.8641C66.2642 45.2994 66.2642 46.0051 66.6995 46.4404L70.7776 50.5184C71.2129 50.9537 71.9186 50.9537 72.3539 50.5184L74.6661 48.2062C75.1014 47.7709 75.8071 47.7709 76.2424 48.2062L78.5546 50.5184C78.9899 50.9537 79.6956 50.9537 80.1309 50.5184L84.209 46.4404C84.6443 46.0051 84.6443 45.2994 84.209 44.8641Z"
                fill="#C93B3B"
              />
              <path
                d="M84.1838 37.0628L80.1554 33.0345C79.7065 32.5855 78.9784 32.5855 78.5292 33.0345L76.2446 35.319L76.8571 35.9315C77.931 37.0054 77.931 38.7462 76.8571 39.8201C75.7833 40.8939 75.7833 42.6347 76.8571 43.7086C77.931 44.7824 77.931 46.5233 76.8571 47.5971L76.2446 48.2096L78.5292 50.4942C78.9782 50.9431 79.7063 50.9431 80.1554 50.4942L84.1838 46.4661C84.6327 46.0171 84.6327 45.289 84.1838 44.8398L81.9215 42.5775C81.4725 42.1286 81.4725 41.4004 81.9215 40.9515L84.1838 38.6892C84.6327 38.2399 84.6327 37.5117 84.1838 37.0628Z"
                fill="#E5646E"
              />
            </svg>
            <Text fontSize="lg" fontWeight="semibold">
              Can’t Change Phone Number
            </Text>
          </Stack>
          <Text>
            Phone number can’t be changed since UPI wallet is activated using
            this number. Contact us to know more.
          </Text>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} level="primary" size="lg">
          <Box>
            <CheckIcon />
          </Box>
          Ok, Got it.
        </Button>
        <WhatsAppContactLink
          size="lg"
          onClick={onClose}
          text={`Hi, I want to change phone number (UPI wallet active). Current phone number: ${phoneNumber}. For version Cashbook-Web (${config.appVersion}).`}
        />
      </ModalFooter>
    </Modal>
  )
}
