import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { Text } from "@cashbook/web-components"
import config from "../config"

function TermsAndConditionsBox({ fromText }: { fromText?: boolean }) {
  return (
    <Text color="gray500" fontSize="xs" fontWeight="medium">
      By clicking send OTP, you are indicating that you accept our{" "}
      <Text
        as="a"
        href={config.tosUrl}
        target="_blank"
        rel="noopener noreferrer"
        color="blue900"
        onClick={() => {
          trackEvent(TrackingEvents.T_AND_C_VIEWED, {
            from: "login",
          })
        }}
      >
        Terms of Service
      </Text>{" "}
      and{" "}
      <Text
        as="a"
        href={config.privacyPolicyUrl}
        target="_blank"
        rel="noopener noreferrer"
        color="blue900"
        onClick={() => {
          trackEvent(TrackingEvents.PRIVACY_POLICY_VIEWED, {
            from: "login",
          })
        }}
      >
        Privacy Policy
      </Text>
      .{fromText ? " An SMS may be sent. Message & data rates may apply." : ""}
    </Text>
  )
}

export default TermsAndConditionsBox
