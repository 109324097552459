import { useMemo } from "react"

export function useDeviceType() {
  const deviceWidthOnMount = useMemo(() => {
    return window.innerWidth
  }, [])
  const isSmallDevice = useMemo(() => {
    return deviceWidthOnMount < 768
  }, [deviceWidthOnMount])
  return {
    isSmallDevice: isSmallDevice,
  }
}

//Can add more OSes over the time and usage
type DeviceNames = "windows" | "ios" | "android"
export function getDeviceOS() {
  const userAgent = navigator.userAgent || navigator.vendor
  let deviceOS = "" as DeviceNames
  if (/windows phone/i.test(userAgent)) {
    deviceOS = "windows"
  }

  if (/android/i.test(userAgent)) {
    deviceOS = "android"
  }

  if (
    /iPad|iPhone|iPod/.test(userAgent) ||
    navigator.userAgent.includes("Mac")
  ) {
    deviceOS = "ios"
  }

  return deviceOS
}

type BrowserNames = "firefox" | "chrome" | "edge" | "safari" | "ie" | "unknown"
export function getCurrentBrowser() {
  const userAgent = window.navigator.userAgent
  let browser: BrowserNames = "unknown"

  if (/Firefox/i.test(userAgent)) {
    browser = "firefox"
  } else if (/Chrome/i.test(userAgent)) {
    browser = "chrome"
  } else if (/Safari/i.test(userAgent)) {
    browser = "safari"
  } else if (/Edge/i.test(userAgent)) {
    browser = "edge"
  } else if (/Trident/i.test(userAgent)) {
    browser = "ie"
  }

  return browser
}
