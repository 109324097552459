import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  AppDownloadIcon,
  ArrowLeftIcon,
  BookIcon,
  Box,
  Button,
  ChevronDownIcon,
  Heading,
  Inline,
  LogoIcon,
  LogoutIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemHeader,
  MenuLink,
  MenuList,
  PlusIcon,
  Stack,
  SupportIcon,
  Text,
  BusinessBuildingIcon,
  GearIcon,
  DataLoadingFallback,
  Circle,
  CashbookPaymentsIcon,
  CashbookPaymentsColoredIcon,
} from "@cashbook/web-components"
import {
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useSyncExternalStore,
} from "react"
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { SuspenseWithPerf, useUser } from "reactfire"
import {
  getBusinessRoleDetailsForMember,
  TBusiness,
  useBusinesses,
  useNewBusinesses,
} from "@cashbook/data-store/businesses"
import config from "./config"
import { LogoutOptionsInModal } from "./Auth"
import { useAppUpdateStatus } from "./support/AppUpdatesProvider"
import {
  ShortcutForKeyboardShortcuts,
  ShowKeyboardShortcutsInDialog,
} from "./support/KeyboardShortcuts"
import { CSSTransition } from "react-transition-group"
import { pluralize, sleep } from "@cashbook/util-general"
import { AddNewBusinessInModal } from "./Business"
import { useBooksForBusinessId } from "@cashbook/data-store/books"
import { useProfile } from "@cashbook/data-store/users"
import { showPaymentsStore } from "@cashbook/data-store/storage"

export function DashboardLayout() {
  return (
    <Stack minHeight="screen" minWidth="screenMd">
      <Header />
      <Inline flex="1">
        <Outlet />
      </Inline>
    </Stack>
  )
}

export function Header() {
  const { data: user } = useUser()
  const [isUpdateAvailable, updateApp] = useAppUpdateStatus()
  const updateNotificationRef = useRef<HTMLDivElement | null>(null)

  if (!user) return null
  return (
    <Inline
      borderBottomWidth="1"
      zIndex="10"
      bgColor="white"
      width="full"
      alignItems="center"
      justifyContent="center"
      className="shadow"
    >
      <Box bgColor="white" maxWidth="screen2xl" width="full">
        <Inline
          justifyContent="between"
          alignItems="stretch"
          paddingX="4"
          paddingY="2"
        >
          <Inline alignItems="center" className="flex items-center">
            <Heading as="h1" fontSize="base" fontWeight="medium">
              <Inline as={Link} to="/" alignItems="center">
                <LogoIcon size="8" color="blue900" />
                <Text
                  as="span"
                  fontSize="md"
                  fontWeight="semibold"
                  color="blue900"
                >
                  {config.appTitle.toUpperCase()}
                </Text>
              </Inline>
            </Heading>
          </Inline>
          <Inline gap="8" alignItems="center">
            <CSSTransition
              in={isUpdateAvailable}
              timeout={200}
              nodeRef={updateNotificationRef}
              classNames={{
                // FADE-in-out
                // enter start position
                enter: "transform translate-y-2 scale-95 opacity-0",
                enterActive:
                  "transition duration-200 ease-in transform !translate-y-0 !scale-100 !opacity-100",
                // enter end position
                enterDone: "transform translate-y-0 scale-100 opacity-100",
                // exiting position
                exitActive:
                  "transition duration-200 ease-in transform !translate-y-2 !scale-95 !opacity-0",
              }}
              unmountOnExit
            >
              <Box ref={updateNotificationRef}>
                <Button level="primary" size="sm" onClick={updateApp}>
                  <ArrowLeftIcon rotate="90" size="4" />
                  Update App
                </Button>
              </Box>
            </CSSTransition>
            <ShortcutForKeyboardShortcuts />
            <ShowKeyboardShortcutsInDialog>
              {({ onShow }) => (
                <Inline
                  as="button"
                  gap="2"
                  paddingY="1"
                  paddingX="2"
                  alignItems="center"
                  onClick={() => onShow()}
                >
                  <Box>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 5H4C2.9 5 2.01 5.9 2.01 7L2 17C2 18.1 2.9 19 4 19H20C21.1 19 22 18.1 22 17V7C22 5.9 21.1 5 20 5ZM11 8H13V10H11V8ZM11 11H13V13H11V11ZM8 8H10V10H8V8ZM8 11H10V13H8V11ZM7 13H5V11H7V13ZM7 10H5V8H7V10ZM16 17H8V15H16V17ZM16 13H14V11H16V13ZM16 10H14V8H16V10ZM19 13H17V11H19V13ZM19 10H17V8H19V10Z"
                        fill="url(#gradientKeyboard)"
                      />
                      <defs>
                        <linearGradient
                          id="gradientKeyboard"
                          x1="2"
                          y1="12.7079"
                          x2="22.0052"
                          y2="12.8516"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#3739D0" />
                          <stop offset="1" stopColor="#4586E8" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Box>
                  <Text
                    as="span"
                    style={{ opacity: 1 }}
                    fontWeight="semibold"
                    color="textPrimary"
                    fontSize="bt"
                    title="Click to open keyboard shortcut help"
                  >
                    Shortcuts
                  </Text>
                </Inline>
              )}
            </ShowKeyboardShortcutsInDialog>
            <LogoutOptionsInModal>
              {({ onOpen }) => (
                <Menu>
                  <MenuButton inline>
                    <Inline
                      size="8"
                      rounded="full"
                      justifyContent="center"
                      alignItems="center"
                      bgColor="blue100"
                    >
                      <Text
                        color="blue900"
                        as="span"
                        textTransform="uppercase"
                        fontWeight="medium"
                      >
                        {user.displayName ? user.displayName[0] : "CB"}
                      </Text>
                    </Inline>
                    <Text as="span" color="gray900" fontWeight="medium">
                      {user.displayName}
                    </Text>
                    <ChevronDownIcon />
                  </MenuButton>
                  <MenuList align="bottom-right" className="z-100">
                    <MenuLink
                      to={user.displayName ? "/profile" : "/onboarding"}
                      className="border-b py-4 mb-2"
                    >
                      <Inline alignItems="center" gap="4" className="w-60">
                        <Box>
                          <Stack
                            size="12"
                            justifyContent="center"
                            alignItems="center"
                            rounded="full"
                            bgColor="blue100"
                          >
                            <Text
                              textTransform="uppercase"
                              fontSize="lg"
                              color="blue900"
                            >
                              {user.displayName ? user.displayName[0] : "CB"}
                            </Text>
                          </Stack>
                        </Box>
                        <Stack gap="1">
                          <Heading as="h4" fontWeight="medium">
                            {user.displayName || `${config.appTitle} User`}
                          </Heading>
                          <Text
                            fontSize="sm"
                            color="gray500"
                            className="tracking-wider"
                          >
                            {user.phoneNumber}
                          </Text>
                          <Text
                            fontSize="xs"
                            color="blue900"
                            fontWeight="medium"
                          >
                            Your Profile{" "}
                            <ChevronDownIcon rotate="270" size="4" />
                          </Text>
                        </Stack>
                      </Inline>
                    </MenuLink>
                    <MenuLink
                      to={"/docs"}
                      onClick={() => {
                        trackEvent(TrackingEvents.HELP_CLICKED, {
                          from: "ThreeDotsIcon",
                        })
                      }}
                    >
                      <SupportIcon /> Help &amp; Support
                    </MenuLink>
                    <MenuItemHeader className="border-t mt-2">
                      Settings
                    </MenuItemHeader>

                    <MenuItem action="logout" onClick={onOpen}>
                      <LogoutIcon /> Logout
                    </MenuItem>

                    <MenuItemHeader className="border-t mt-2">
                      Mobile App
                    </MenuItemHeader>
                    <MenuItem
                      action="download_app"
                      href={config.appDownloadLink}
                    >
                      <AppDownloadIcon /> Download App
                    </MenuItem>
                    <MenuItemHeader className="py-2 px-2 text-gray-500">
                      &copy; {config.appTitle} • Version {config.appVersion}
                    </MenuItemHeader>
                  </MenuList>
                </Menu>
              )}
            </LogoutOptionsInModal>
          </Inline>
        </Inline>
      </Box>
    </Inline>
  )
}

export function BusinessLayout() {
  const maxHeight = "calc(100vh - 52px)"
  return (
    <Stack flex="1" maxWidth="screen2xl" marginX="auto" minWidth="screenMd">
      <Inline flex="1" width="full" maxWidth="full">
        <Box as="aside" position="relative" zIndex="10" className="w-60">
          <BusinessSidebar maxHeight={maxHeight} />
        </Box>
        <Stack
          as="main"
          flex="1"
          bgColor="white"
          maxWidth="full"
          overflow="auto"
        >
          <Box
            flex="1"
            overflow="auto"
            style={{
              maxHeight,
            }}
          >
            <Outlet />
          </Box>
        </Stack>
      </Inline>
    </Stack>
  )
}

export function BusinessSidebar({
  maxHeight,
  routePrefix = "",
}: {
  maxHeight?: string
  routePrefix?: string
}) {
  const { businessId } = useParams()
  const { businessesSaved } = useNewBusinesses()
  const { businesses } = useBusinesses()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { user } = useProfile()
  const businessRef = useRef<{ [id: string]: HTMLLIElement }>({})
  const showPayments = useSyncExternalStore(
    showPaymentsStore.subscribe,
    showPaymentsStore.getShowPaymentsList
  )

  useEffect(() => {
    if (businesses.length && businessId && businessRef.current[businessId]) {
      sleep(1000).then(() => {
        businessRef.current[businessId]?.scrollIntoView?.({
          behavior: "smooth",
          block: "center",
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const businessesForPayments = useMemo(() => {
    if (!businesses.length) {
      return []
    }
    return businesses.map((business) => {
      if (
        (showPayments[business.id]?.is_payments_enabled &&
          !!showPayments[business.id]?.corpShortName) ||
        showPayments[business.id]?.show_payments_tab ||
        showPayments[business.id]?.is_card_user
      ) {
        if (showPayments[business.id].role === "owner") {
          return {
            ...business,
            showPayments: true,
            showPaymentsActiveIndicator:
              !showPayments[business.id]?.is_card_user,
          }
        } else if (
          showPayments[business.id].role === "partner" &&
          showPayments[business.id].is_payments_enabled &&
          !!showPayments[business.id].corpShortName &&
          (!!showPayments[business.id].can_enable_wallet ||
            !!showPayments[business.id].min_kyc)
        ) {
          return {
            ...business,
            showPayments: true,
            showPaymentsActiveIndicator: true,
          }
        }
      }
      const showPaymentsActiveIndicator =
        business.payments?.enabled && business.ownerId === user.uid
      return {
        ...business,
        showPayments: false,
        showPaymentsActiveIndicator: showPaymentsActiveIndicator,
      }
    })
  }, [businesses, showPayments, user.uid])

  if (!businesses.length) return <Navigate to="/add-first-business" replace />
  return (
    <Box
      position="relative"
      color="white"
      paddingBottom="16"
      overflow="auto"
      minHeight="full"
      flex="1"
      className="bg-[#2C324B]"
      style={{
        maxHeight: maxHeight,
      }}
    >
      <Box position="sticky" top="0" zIndex="10">
        <Box paddingX="3" paddingY="4" bgColor="gray900">
          <AddNewBusinessInModal
            onSuccess={(newBusinessId) =>
              navigate(`${routePrefix}/businesses/${newBusinessId}/cashbooks`)
            }
          >
            {({ add }) => (
              <Inline
                as="button"
                onClick={add}
                padding="1"
                alignItems="center"
                gap="4"
                fontWeight="semibold"
                rounded="md"
                width="full"
                className="bg-blue-900 bg-opacity-20"
              >
                <Stack
                  as="span"
                  size="8"
                  alignItems="center"
                  justifyContent="center"
                  bgColor="blue900"
                  rounded="md"
                >
                  <PlusIcon size="6" />
                </Stack>
                <Text as="span">Add New Business</Text>
              </Inline>
            )}
          </AddNewBusinessInModal>
        </Box>
      </Box>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading businesses" />}
        traceId="sidebar_businesses"
      >
        <Box position="relative" zIndex="0" paddingX="3">
          {businessesForPayments?.length ? (
            <Stack as="ol" reversed gap="1" paddingTop="1">
              {businessesForPayments.map((b, index) => {
                const isNewBusiness =
                  businessesSaved[b.id] !== undefined && !businessesSaved[b.id]
                return (
                  <Box
                    as="li"
                    ref={(ref: HTMLLIElement) =>
                      (businessRef.current[b.id] = ref)
                    }
                    key={b.id}
                    borderTopWidth={index === 0 ? "0" : "1"}
                    className="border-[#54586A]"
                    cursor="pointer"
                    onClick={() => {
                      if (isNewBusiness) {
                        return navigate(
                          `${routePrefix}/new-business-transition/${b.id}`
                        )
                      }
                      businessRef.current[b.id]?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      })
                      if (businessId !== b.id) {
                        trackEvent(TrackingEvents.BUSINESS_SWITCHED)
                      }
                      navigate(`${routePrefix}/businesses/${b.id}/cashbooks`)
                    }}
                  >
                    <Stack gap="2" paddingY="2">
                      <Inline
                        gap="4"
                        paddingX="3"
                        paddingY="4"
                        rounded="md"
                        alignItems="center"
                        bgColor={
                          b.id === businessId ? "blue900" : "transparent"
                        }
                      >
                        <Box
                          width="6"
                          height="6"
                          rounded="md"
                          borderColor="borderOutline"
                          bgColor={b.logo?.image_url ? "transparent" : "white"}
                          position="relative"
                        >
                          {b.showPaymentsActiveIndicator && (
                            <Box
                              position="absolute"
                              zIndex="10"
                              style={{
                                top: -8,
                                right: -8,
                              }}
                            >
                              <Stack
                                backgroundColor="surfaceDefault"
                                borderWidth="1"
                                borderRadius="full"
                                borderColor="borderOutline"
                                alignItems="center"
                                justifyContent="center"
                                width="4"
                                height="4"
                              >
                                <CashbookPaymentsColoredIcon size="3" />
                              </Stack>
                            </Box>
                          )}
                          {b.logo?.image_url ? (
                            <img
                              className="h-6 w-6 rounded-md"
                              alt="Business Logo"
                              src={b.logo.image_url}
                            />
                          ) : (
                            <Stack
                              height="full"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <BusinessBuildingIcon color="gray400" size="4" />
                            </Stack>
                          )}
                        </Box>
                        <Stack flex="1" minWidth="0" gap="1">
                          <Inline gap="2" alignItems="center">
                            <Heading
                              as="h5"
                              fontWeight="semibold"
                              className="break-words"
                            >
                              {b.name}
                            </Heading>
                            {isNewBusiness && (
                              <Circle size="2" backgroundColor="red500" />
                            )}
                          </Inline>
                          <BusinessRoleAndBooksDetails
                            business={b}
                            userId={user.uid}
                          />
                        </Stack>
                      </Inline>
                      {pathname.includes(`${b.id}`) ? (
                        <Stack>
                          <Inline
                            paddingX="6"
                            paddingY="3"
                            gap="2"
                            alignItems="center"
                            bgColor={
                              pathname.includes(`${b.id}/cashbooks`)
                                ? "gray900"
                                : "transparent"
                            }
                            rounded="md"
                            onClick={(event: MouseEvent) => {
                              event.stopPropagation()
                              navigate(`${b.id}/cashbooks`)
                            }}
                          >
                            <BookIcon size="5" />
                            <Text fontWeight="normal">Cashbooks</Text>
                          </Inline>
                          {b.showPayments ? (
                            <Inline
                              id="payments_tab"
                              paddingX="6"
                              paddingY="3"
                              gap="2"
                              alignItems="center"
                              rounded="md"
                              bgColor={
                                pathname.includes(`${b.id}/payments`)
                                  ? "gray900"
                                  : "transparent"
                              }
                              onClick={(e: MouseEvent) => {
                                e.stopPropagation()
                                navigate(`${b.id}/payments`)
                              }}
                            >
                              <CashbookPaymentsIcon size="5" />
                              <Text fontWeight="normal">Payments</Text>
                            </Inline>
                          ) : null}
                          <Inline
                            paddingX="6"
                            paddingY="3"
                            gap="2"
                            alignItems="center"
                            rounded="md"
                            bgColor={
                              pathname.includes(`${b.id}/business-settings`)
                                ? "gray900"
                                : "transparent"
                            }
                            onClick={(e: MouseEvent) => {
                              e.stopPropagation()
                              navigate(`${b.id}/business-settings`)
                            }}
                          >
                            <GearIcon size="5" />
                            <Text fontWeight="normal">Business Settings</Text>
                          </Inline>
                        </Stack>
                      ) : null}
                    </Stack>
                  </Box>
                )
              })}
            </Stack>
          ) : (
            <Box textAlign="center" paddingY="8">
              <Text>No Businesses yet!</Text>
            </Box>
          )}
        </Box>
      </SuspenseWithPerf>
    </Box>
  )
}

function BusinessRoleAndBooksDetails({
  userId,
  business,
}: {
  userId: string
  business: TBusiness
}) {
  const { title } = getBusinessRoleDetailsForMember(business, userId)
  const { getBooksForTeamMember } = useBooksForBusinessId(business.id)
  const books = getBooksForTeamMember(userId)
  return (
    <Inline fontSize="sm" fontWeight="normal" gap="1">
      <Text>Role: {title}</Text>•
      <Text>
        {books.length} {pluralize("book", books.length)}
      </Text>
    </Inline>
  )
}
