import { dateToTimestamp, timeStampToDate } from "@cashbook/util-dates"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Alert,
  DataLoadingFallback,
  ErrorMessage,
  ExclamationIcon,
  FormField,
  formikOnSubmitWithErrorHandling,
  Modal,
  ModalBody,
  ModalFooter,
  useOverlayTriggerState,
  Box,
  Inline,
  Stack,
  Text,
  CancelIcon,
  SearchIcon,
  TransactionDate,
  CBButton,
  ArrowLeftIcon,
  Circle,
  PlusIcon,
  MinusIcon,
} from "@cashbook/web-components"
import { Form, Formik, useField } from "formik"
import { useCallback, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { Link } from "react-router-dom"
import { SuspenseWithPerf, useUser } from "reactfire"
import { $PropertyType, Optional, Required } from "utility-types"
import * as Validator from "yup"
import {
  BOOK_PERMISSIONS,
  checkIfMemberCan,
  TTransaction,
  TBook,
  useBook,
  useTransferEntries,
  useBooksForBusinessId,
  getAllMandatoryFields,
  findMatchingMandatoryCustomFieldIds,
} from "@cashbook/data-store/books"
import {
  BUSINESS_PERMISSIONS,
  useBusiness,
} from "@cashbook/data-store/businesses"
import { AddBookInDialog } from "../Books"
import { pluralize, useMount } from "@cashbook/util-general"
import { Amount } from "../support/Intl"
import { Radio } from "../common"

export type TActionType = "copy" | "move" | "opposite"

type TActionProps = {
  bookId: string
  businessId: string
  transactions: TTransaction[]
  onSuccess: () => void
  onBack?: () => void
}

export function PerformActionInDialog({
  children,
  onSuccess: propOnSuccess,
  ...props
}: Optional<Omit<TActionProps, "onBack">, "onSuccess"> & {
  children: (props: {
    handleAction: (action: TActionType) => void
  }) => React.ReactNode
}) {
  const [action, setAction] = useState<TActionType | null>(null)
  const onBack = useCallback(() => {
    setAction(null)
  }, [])
  const onSuccess = useCallback(() => {
    setAction(null)
    propOnSuccess?.()
  }, [propOnSuccess])
  return (
    <>
      {children({
        handleAction: (action: TActionType) => {
          setAction(action)
        },
      })}
      <Modal
        isOpen={Boolean(action)}
        onClose={onBack}
        placement="right"
        title={`${
          action === "copy"
            ? "Copy"
            : action === "move"
            ? "Move"
            : action === "opposite"
            ? "Add Opposite"
            : null
        } ${props.transactions.length} ${pluralize(
          "Entry",
          props.transactions.length
        )}`}
      >
        <SuspenseWithPerf
          traceId="loading_entry_action_data"
          fallback={<DataLoadingFallback label="Please wait..." />}
        >
          {action === "copy" ? (
            <TransferAction
              {...props}
              onBack={onBack}
              action="copy"
              actionLabel="Copy"
              bookSelectionLabel="Select a book where you want to duplicate this entry"
              onSuccess={({ destinationBook, transactions }) => {
                toast.success(
                  (t) => (
                    <Inline gap="4" alignItems="center">
                      <Text fontSize="b3">
                        {transactions.length}{" "}
                        {pluralize("entry", transactions.length)} copied to
                        &ldquo;{destinationBook.name}&rdquo;
                      </Text>
                      <Box height="4" className="w-[1px] bg-[#3A4060]" />
                      <Box>
                        <Text
                          as={Link}
                          to={`/businesses/${props.businessId}/cashbooks/${destinationBook.id}/transactions`}
                          className="whitespace-pre"
                          fontSize="s4"
                          onClick={() => toast.dismiss(t.id)}
                        >
                          Open Book
                        </Text>
                      </Box>
                    </Inline>
                  ),
                  {
                    duration: 5000,
                  }
                )
                onSuccess()
              }}
            />
          ) : action === "move" ? (
            <TransferAction
              {...props}
              onBack={onBack}
              action="move"
              actionLabel="Move"
              bookSelectionLabel="Select a book where you want to move this entry"
              onSuccess={({ destinationBook, transactions }) => {
                toast.success(
                  (t) => (
                    <Inline gap="4" alignItems="center">
                      <Text fontSize="b3">
                        {transactions.length}{" "}
                        {pluralize("entry", transactions.length)} moved to
                        &ldquo;
                        {destinationBook.name}&rdquo;
                      </Text>
                      <Box>
                        <Text
                          as={Link}
                          to={`/businesses/${props.businessId}/cashbooks/${destinationBook.id}/transactions`}
                          fontSize="s4"
                          className="whitespace-pre"
                          onClick={() => toast.dismiss(t.id)}
                        >
                          Open Book
                        </Text>
                      </Box>
                    </Inline>
                  ),
                  {
                    duration: 5000,
                  }
                )
                onSuccess()
              }}
            />
          ) : action === "opposite" ? (
            <TransferAction
              {...props}
              onBack={onBack}
              action="opposite"
              actionLabel="Add Opposite"
              bookSelectionLabel="Select a book where you want to add opposite entry"
              onSuccess={({ destinationBook, transactions }) => {
                toast.success(
                  (t) => (
                    <Inline gap="4" alignItems="center">
                      <Text fontSize="b3">
                        {transactions.length} opposite{" "}
                        {pluralize("entry", transactions.length)} created to
                        &ldquo;{destinationBook.name}&rdquo;
                      </Text>
                      <Box>
                        <Text
                          as={Link}
                          to={`/businesses/${props.businessId}/cashbooks/${destinationBook.id}/transactions`}
                          fontSize="s4"
                          className="whitespace-pre"
                          onClick={() => toast.dismiss(t.id)}
                        >
                          Open Book
                        </Text>
                      </Box>
                    </Inline>
                  ),
                  {
                    duration: 5000,
                  }
                )
                onSuccess()
              }}
            />
          ) : null}
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function TransferAction({
  onSuccess,
  actionLabel,
  bookSelectionLabel,
  action,
  ...props
}: Omit<TActionProps, "onSuccess"> &
  Pick<
    React.ComponentProps<typeof ActionForm>,
    "actionLabel" | "bookSelectionLabel"
  > & {
    action: TActionType
    onSuccess: (data: {
      sourceBook: TBook
      destinationBook: TBook
      transactions: Array<TTransaction>
    }) => void
  }) {
  const confirmationState = useOverlayTriggerState({})
  const { data: user } = useUser()
  const [formData, setFormData] = useState<TActionFormParams | undefined>()
  return !confirmationState.isOpen ||
    !formData ||
    !formData.destinationBook ||
    !formData.shouldSelectCreator ? (
    <ActionForm
      {...props}
      initialValues={formData}
      onSubmit={async (values) => {
        setFormData(values)
        confirmationState.open()
      }}
      action={action}
      actionLabel={actionLabel}
      userId={user?.uid || "missing"}
      onSuccess={onSuccess}
      bookSelectionLabel={bookSelectionLabel}
    />
  ) : (
    <SuspenseWithPerf
      fallback={<DataLoadingFallback label="Loading..." />}
      traceId="loading_data"
    >
      <ActionPreviewAndConfirmation
        sourceBook={formData.sourceBook}
        destinationBook={formData.destinationBook}
        transactions={formData.transactions}
        action={action}
        userId={user?.uid || "missing"}
        deleteTransactionsFromSource={action === "move"}
        addOppositeTransactionsToDestination={action === "opposite"}
        actionLabel={actionLabel}
        onCancel={confirmationState.close}
        onSuccess={onSuccess}
      />
    </SuspenseWithPerf>
  )
}

type TActionFormParams = {
  sourceBook: TBook
  transactions: Array<TTransaction>
  destinationBook?: TBook
  creator: "original" | "me"
  shouldSelectCreator?: boolean
}

function ActionForm({
  bookId,
  transactions,
  onSubmit,
  onBack,
  action,
  businessId,
  actionLabel,
  userId,
  onSuccess,
  bookSelectionLabel,
  initialValues: propInitialValues,
}: Omit<TActionProps, "onSuccess"> & {
  onSubmit: (values: TActionFormParams) => Promise<void>
  actionLabel: string
  action: TActionType
  bookSelectionLabel: string
  userId: string
  onSuccess: (data: {
    sourceBook: TBook
    destinationBook: TBook
    transactions: TTransaction[]
  }) => void
  initialValues?: Optional<TActionFormParams>
}) {
  const { book } = useBook(bookId)
  const initialValues: TActionFormParams = useMemo(() => {
    return {
      sourceBook: propInitialValues?.sourceBook || book,
      destinationBook: propInitialValues?.destinationBook || undefined,
      creator: propInitialValues?.creator || "original",
      transactions: propInitialValues?.transactions || transactions,
      shouldSelectCreator: Boolean(
        (propInitialValues?.transactions || transactions).find(
          (t) => t.createdBy !== userId
        )?.id
      ),
    }
  }, [book, transactions, propInitialValues, userId])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Validator.object().shape({
        sourceBook: Validator.mixed().required("Please select a source book"),
        transactions: Validator.array().min(
          1,
          "Please select atleast on entry"
        ),
        destinationBook: Validator.mixed().required(
          "Please select a destination book."
        ),
        creator: Validator.string().required(
          "Please select the creator of new entry."
        ),
      })}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        await onSubmit(values)
      })}
    >
      {({ status, values }) => (
        <Form noValidate>
          <ModalBody>
            <Stack gap="4" marginBottom="24">
              <Text fontSize="b3">{bookSelectionLabel}</Text>
              <SelectDestinationBook
                name="destinationBook"
                sourceBook={book}
                userId={userId}
                businessId={businessId}
              />
            </Stack>
            {status ? <Alert status="error">{status}</Alert> : null}
          </ModalBody>
          <ModalFooter actionsLayout="row" position="relative">
            <Inline
              position="absolute"
              className="bottom-[100%]"
              width="full"
              left="0"
              paddingX="6"
              paddingY="4"
              gap="6"
              justifyContent="center"
              backgroundColor="surfaceNeutralLowest"
            >
              <Stack gap="2" width="full">
                <Text fontSize="c2" color="textMedium">
                  {action === "move" ? "Moving" : "Copy"} from
                </Text>
                <Text fontSize="b3">{book.name}</Text>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <ArrowLeftIcon className="rotate-180" />
              </Stack>
              <Stack gap="2" width="full">
                <Text fontSize="c2" color="textMedium">
                  {action === "move" ? "Moving" : "Copy"} to
                </Text>
                <Text fontSize="b3">
                  {values.destinationBook?.name || "Please Select a valid book"}
                </Text>
              </Stack>
            </Inline>
            {values.destinationBook && !values.shouldSelectCreator ? (
              <ConfirmationModal
                destinationBook={values.destinationBook}
                sourceBook={values.sourceBook}
                transactions={values.transactions}
                actionLabel={actionLabel}
                action={action}
                userId={userId}
                onSuccess={onSuccess}
                onCancel={() => onBack?.()}
              >
                {({ open: openConfirmationModal }) => (
                  <MissingFieldsInfoModal
                    destinationBook={values.destinationBook}
                    sourceBook={values.sourceBook}
                    transactions={values.transactions}
                    actionLabel={actionLabel}
                    action={action}
                    userId={userId}
                    onSuccess={onSuccess}
                    onCancel={() => onBack?.()}
                  >
                    {({ open: openMissingFieldsModal }) => (
                      <CBButton
                        type="button"
                        size="lg"
                        level="primary"
                        onClick={() => {
                          if (values.destinationBook) {
                            const mandatoryFields = getAllMandatoryFields(
                              values.destinationBook.preferences,
                              values.destinationBook.customFields
                            )
                            const isCategoryMandatoryField =
                              mandatoryFields.includes("category")
                            const isPaymentModeMandatoryField =
                              mandatoryFields.includes("paymentMode")
                            let isCustomFieldsMandatory = false
                            const totalMandatoryFields = mandatoryFields.length
                            let totalMandatoryCustomFields = 0
                            if (
                              isCategoryMandatoryField &&
                              isPaymentModeMandatoryField
                            ) {
                              if (mandatoryFields.length > 2) {
                                isCustomFieldsMandatory = true
                                totalMandatoryCustomFields =
                                  totalMandatoryFields - 2
                              }
                            } else if (
                              (isCategoryMandatoryField &&
                                !isPaymentModeMandatoryField) ||
                              (!isCategoryMandatoryField &&
                                isPaymentModeMandatoryField)
                            ) {
                              if (mandatoryFields.length > 1) {
                                isCustomFieldsMandatory = true
                                totalMandatoryCustomFields =
                                  totalMandatoryFields - 1
                              }
                            } else {
                              if (mandatoryFields.length > 0) {
                                totalMandatoryCustomFields =
                                  totalMandatoryFields
                                isCustomFieldsMandatory = true
                              }
                            }

                            const commonMandatoryFieldsIds =
                              findMatchingMandatoryCustomFieldIds(
                                values.sourceBook,
                                values.destinationBook
                              )
                            let isCategoryTagged = true
                            let isPaymentModeTagged = true
                            let isCustomFieldTagged = true
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            transactions.forEach((entry: any) => {
                              if (
                                isCategoryMandatoryField &&
                                !entry.categoryId
                              ) {
                                isCategoryTagged = false
                              }
                              if (
                                isPaymentModeMandatoryField &&
                                !entry.paymentModeId
                              ) {
                                isPaymentModeTagged = false
                              }
                              if (isCustomFieldsMandatory) {
                                if (commonMandatoryFieldsIds.length === 0) {
                                  isCustomFieldTagged = false
                                } else if (
                                  commonMandatoryFieldsIds.length <
                                  totalMandatoryCustomFields
                                ) {
                                  isCustomFieldTagged = false
                                } else if (
                                  commonMandatoryFieldsIds.length ===
                                  totalMandatoryCustomFields
                                ) {
                                  commonMandatoryFieldsIds.forEach(
                                    (fieldId: string) => {
                                      if (!entry[fieldId]) {
                                        isCustomFieldTagged = false
                                      }
                                    }
                                  )
                                }
                              }
                            })

                            if (
                              (isCategoryMandatoryField && !isCategoryTagged) ||
                              (isPaymentModeMandatoryField &&
                                !isPaymentModeTagged) ||
                              (isCustomFieldsMandatory && !isCustomFieldTagged)
                            ) {
                              openMissingFieldsModal()
                              return
                            }
                          }
                          openConfirmationModal()
                        }}
                        disabled={!values.destinationBook?.id}
                      >
                        {actionLabel}
                      </CBButton>
                    )}
                  </MissingFieldsInfoModal>
                )}
              </ConfirmationModal>
            ) : (
              <CBButton
                type="submit"
                size="lg"
                disabled={!values.destinationBook?.id}
              >
                {actionLabel}
              </CBButton>
            )}

            {onBack ? (
              <CBButton onClick={onBack} size="lg">
                Cancel
              </CBButton>
            ) : null}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

function ActionPreviewAndConfirmation({
  onCancel,
  ...props
}: Omit<Required<TActionFormParams, "destinationBook">, "creator"> &
  Pick<React.ComponentProps<typeof ActionForm>, "actionLabel" | "userId"> & {
    deleteTransactionsFromSource?: boolean
    addOppositeTransactionsToDestination?: boolean
    action: "copy" | "move" | "opposite"
    onSuccess: (data: {
      sourceBook: TBook
      destinationBook: TBook
      transactions: Array<TTransaction>
    }) => void
    onCancel: () => void
  }) {
  const [keepCreatorsFromSource, setKeepCreatorsFromSource] =
    useState<boolean>(true)

  return (
    <Box position="relative">
      <Box position="absolute" top="0" width="full">
        <Alert status="info">
          <Text fontSize="c2" color="textAlt2">
            {props.transactions.length}{" "}
            {pluralize("entry", props.transactions.length)} will be{" "}
            {props.action === "move" ? "moved" : props.action} to '
            {props.destinationBook.name}' from '{props.sourceBook.name}'
          </Text>
        </Alert>
      </Box>
      <ModalBody>
        <Formik
          initialValues={{ creator: "original" as "original" | "me" }}
          onSubmit={({ creator }, { setFieldValue }) => {
            setFieldValue("creator", creator)
          }}
        >
          {({ values }) => (
            <Form noValidate>
              <Stack marginTop="12">
                <SelectCreator
                  name="creator"
                  defaultValue={values.creator}
                  onChange={(value) => {
                    setKeepCreatorsFromSource(value === "original")
                  }}
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter actionsLayout="row">
        <ConfirmationModal
          onCancel={onCancel}
          keepCreatorsFromSource={keepCreatorsFromSource}
          {...props}
        >
          {({ open }) => (
            <CBButton level="primary" size="lg" onClick={open}>
              Continue
            </CBButton>
          )}
        </ConfirmationModal>
        <CBButton onClick={onCancel} size="lg">
          Back
        </CBButton>
      </ModalFooter>
    </Box>
  )
}

function ConfirmationModal({
  transactions,
  keepCreatorsFromSource,
  deleteTransactionsFromSource,
  addOppositeTransactionsToDestination,
  action,
  userId,
  onSuccess,
  actionLabel,
  onCancel,
  children,
  ...props
}: Omit<Required<TActionFormParams, "destinationBook">, "creator"> &
  Pick<React.ComponentProps<typeof ActionForm>, "actionLabel"> & {
    deleteTransactionsFromSource?: boolean
    addOppositeTransactionsToDestination?: boolean
    action: "copy" | "move" | "opposite"
    keepCreatorsFromSource?: boolean
    onSuccess: (data: {
      sourceBook: TBook
      destinationBook: TBook
      transactions: Array<TTransaction>
    }) => void
    userId: string
    onCancel: () => void
    children: (props: { open: () => void }) => React.ReactNode
  }) {
  const confirmation = useOverlayTriggerState({})
  const { book: sourceBook, involvedUsers: sourceBookInvolvedUsers } = useBook(
    props.sourceBook.id
  )
  const {
    book: destinationBook,
    involvedUsers: destinationBookInvolvedUsers,
    authMemberDetails: destinationAuthMemberDetails,
  } = useBook(props.destinationBook.id)

  // create a list of creator
  const creatorsToAddToInvolvedUsers = useMemo(() => {
    if (!keepCreatorsFromSource) return []
    return transactions
      .map((t) => t.createdBy)
      .filter((creatorId): creatorId is string => Boolean(creatorId))
      .reduce<Array<string>>((uniqueCreators, creatorId) => {
        if (uniqueCreators.indexOf(creatorId) === -1) {
          uniqueCreators.push(creatorId)
        }
        return uniqueCreators
      }, [])
      .map((creatorId) => {
        const involvedUserDetails = sourceBookInvolvedUsers.find(
          (u) => u.id === creatorId
        )
        if (!involvedUserDetails) return undefined

        const { id, name, email, phoneNumber } = involvedUserDetails
        return {
          id,
          name,
          email: email || "",
          phoneNumber: phoneNumber || "",
        }
      })
      .filter((creator) =>
        Boolean(
          creator &&
            // also check that the member is not already added to destination book
            destinationBookInvolvedUsers.findIndex(
              (u) => u.id === creator.id
            ) === -1
        )
      )
  }, [
    destinationBookInvolvedUsers,
    keepCreatorsFromSource,
    sourceBookInvolvedUsers,
    transactions,
  ])

  const transactionRawDataForTransfer = useMemo(() => {
    return transactions.map((transaction) => ({
      id: transaction.id,
      remark: transaction.remark,
      amount: transaction.amount,
      imageUrl: transaction.imageUrl || null,
      thumbUrl: transaction.thumbUrl || null,
      type: addOppositeTransactionsToDestination
        ? transaction.type === "cash-in"
          ? "cash-out"
          : "cash-in"
        : transaction.type,
      cashBookId: destinationBook.id,
      createdAt: transaction.createdAt,
      createdBy: keepCreatorsFromSource ? transaction.createdBy : userId,
      sourceBook: {
        id: sourceBook.id,
        name: sourceBook.name,
      },
      date: dateToTimestamp(timeStampToDate(transaction.date)),
    }))
  }, [
    transactions,
    addOppositeTransactionsToDestination,
    keepCreatorsFromSource,
    destinationBook,
    sourceBook,
    userId,
  ])

  const transferEntries = useTransferEntries()

  const transfer = useCallback(async () => {
    const creatorsExceptAuthUser = creatorsToAddToInvolvedUsers.filter(
      (u) => u?.id !== userId
    )
    trackEvent(TrackingEvents.ENTRY_ACTION_STARTED, {
      destinationBookRole: destinationAuthMemberDetails.role.id,
      sharedBook: sourceBook.sharedWith.length > 1,
      destinationSharedBook: destinationBook.sharedWith.length > 1,
      // all entries are done by the authenticated user ?
      entryBySelf: creatorsExceptAuthUser.length === 0,
      keepOriginalCreator: Boolean(keepCreatorsFromSource),
      operation: action,
      from:
        transactionRawDataForTransfer.length > 1 ? "multiEntry" : "singleEntry",
    })
    await transferEntries({
      type: action,
      srcBookId: sourceBook.id,
      destBookId: destinationBook.id,
      transactionIds: transactionRawDataForTransfer.map((t) => t.id),
      keepOriginalCreator: Boolean(keepCreatorsFromSource),
    })
    trackEvent(TrackingEvents.ENTRY_ACTION_COMPLETED, {
      destinationBookRole: destinationAuthMemberDetails.role.id,
      sharedBook: sourceBook.sharedWith.length > 1,
      destinationSharedBook: destinationBook.sharedWith.length > 1,
      // all entries are done by the authenticated user ?
      entryBySelf: creatorsExceptAuthUser.length === 0,
      keepOriginalCreator: Boolean(keepCreatorsFromSource),
      operation: action,
      from:
        transactionRawDataForTransfer.length > 1 ? "multiEntry" : "singleEntry",
    })
  }, [
    creatorsToAddToInvolvedUsers,
    destinationAuthMemberDetails,
    destinationBook,
    keepCreatorsFromSource,
    sourceBook,
    userId,
    action,
    transactionRawDataForTransfer,
    transferEntries,
  ])

  const numOfEntries = transactions.length

  return (
    <>
      {children({
        open: confirmation.open,
      })}
      <Modal
        isDismissable
        isOpen={confirmation.isOpen}
        onClose={confirmation.close}
        title={`${
          action === "move"
            ? "Move"
            : action === "copy"
            ? "Copy & Paste"
            : `Copy ${numOfEntries} Opposite`
        } ${action !== "opposite" ? numOfEntries : ""} ${pluralize(
          "Entry",
          numOfEntries
        )}`}
      >
        <ModalBody>
          <Stack gap="4">
            <Text fontSize="b3">Are you sure?</Text>
            {action === "move" ? (
              <Stack gap="4" as="ul">
                <Inline as="li" gap="4">
                  <Box marginTop="1">
                    <Circle size="2" backgroundColor="iconLow" />
                  </Box>
                  <Text>
                    {pluralize("Entry", numOfEntries)} will get deleted from
                    current book and added in <b>{destinationBook.name}</b>
                  </Text>
                </Inline>
                <Inline as="li" gap="4">
                  <Box marginTop="1">
                    <Circle size="2" backgroundColor="iconLow" />
                  </Box>
                  <Text>This will change the net balance of both books</Text>
                </Inline>
              </Stack>
            ) : action === "copy" ? (
              <Stack gap="4" as="ul">
                <Inline as="li" gap="4">
                  <Box marginTop="1">
                    <Circle size="2" backgroundColor="iconLow" />
                  </Box>
                  <Text>
                    Same {pluralize("entry", numOfEntries)} will get added in{" "}
                    <b>`{destinationBook.name}`</b>
                  </Text>
                </Inline>
                <Inline as="li" gap="4">
                  <Box marginTop="1">
                    <Circle size="2" backgroundColor="iconLow" />
                  </Box>
                  <Text>
                    This will change the net balance of{" "}
                    <b>`{destinationBook.name}`</b>
                  </Text>
                </Inline>
              </Stack>
            ) : action === "opposite" ? (
              <Stack gap="4" as="ul">
                <Inline as="li" gap="4">
                  <Box marginTop="1">
                    <Circle size="2" backgroundColor="iconLow" />
                  </Box>
                  <Text>
                    ‘Cash In’ entries will be added as ‘Cash Out’ entries in
                    <b>'{destinationBook.name}'</b> and vice versa
                  </Text>
                </Inline>
                <Stack
                  rounded="md"
                  backgroundColor="surfaceNeutralLowest"
                  padding="4"
                  gap="4"
                >
                  <Inline gap="6">
                    <Inline gap="2" width="full" justifyContent="end">
                      <Box
                        backgroundColor="surfaceCashIn"
                        size="6"
                        rounded="full"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <PlusIcon size="5" color="iconOnSurface" />
                      </Box>
                      <Stack gap="1">
                        <Text fontSize="c2">Cash In</Text>
                        <Amount
                          fontSize="c1"
                          color="textCashIn"
                          amount={50000}
                        />
                      </Stack>
                    </Inline>
                    <Box>
                      <ArrowLeftIcon
                        className="rotate-180"
                        color="iconMedium"
                      />
                    </Box>
                    <Inline width="full" gap="2">
                      <Box
                        backgroundColor="surfaceCashOut"
                        size="6"
                        rounded="full"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <MinusIcon size="5" color="iconOnSurface" />
                      </Box>
                      <Stack gap="1">
                        <Text fontSize="c2">Cash Out</Text>
                        <Amount
                          fontSize="c1"
                          color="textCashOut"
                          amount={50000}
                        />
                      </Stack>
                    </Inline>
                  </Inline>
                  <Inline alignItems="center" gap="2">
                    <Box as="hr" backgroundColor="borderOutline" width="full" />
                    <Text fontSize="c1">Or</Text>
                    <Box as="hr" backgroundColor="borderOutline" width="full" />
                  </Inline>
                  <Inline gap="6">
                    <Inline width="full" gap="2" justifyContent="end">
                      <Box
                        backgroundColor="surfaceCashOut"
                        size="6"
                        rounded="full"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <MinusIcon size="5" color="iconOnSurface" />
                      </Box>
                      <Stack gap="1">
                        <Text fontSize="c2">Cash Out</Text>
                        <Amount
                          fontSize="c1"
                          color="textCashOut"
                          amount={50000}
                        />
                      </Stack>
                    </Inline>
                    <Box>
                      <ArrowLeftIcon
                        className="rotate-180"
                        color="iconMedium"
                      />
                    </Box>
                    <Inline gap="2" width="full">
                      <Box
                        backgroundColor="surfaceCashIn"
                        size="6"
                        rounded="full"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <PlusIcon size="5" color="iconOnSurface" />
                      </Box>
                      <Stack gap="1">
                        <Text fontSize="c2">Cash In</Text>
                        <Amount
                          fontSize="c1"
                          color="textCashIn"
                          amount={50000}
                        />
                      </Stack>
                    </Inline>
                  </Inline>
                </Stack>
                <Inline as="li" gap="4">
                  <Box marginTop="1">
                    <Circle size="2" backgroundColor="iconLow" />
                  </Box>
                  <Text>
                    This will change the net balance of
                    <b> '{destinationBook.name}' </b>
                  </Text>
                </Inline>
              </Stack>
            ) : null}
          </Stack>
        </ModalBody>
        <Formik
          initialValues={{}}
          onSubmit={formikOnSubmitWithErrorHandling(async () => {
            await transfer()
            onSuccess({
              sourceBook,
              destinationBook,
              transactions,
            })
          })}
        >
          {({ status, isSubmitting }) => (
            <Form noValidate>
              {status ? (
                <Box paddingX="6">
                  <Alert status="error">{status}</Alert>
                </Box>
              ) : null}
              <ModalFooter>
                <CBButton size="lg" type="submit" loading={isSubmitting}>
                  {isSubmitting
                    ? "Please wait..."
                    : `Yes, 
            ${
              action === "move"
                ? "Move"
                : action === "copy"
                ? "Copy & Paste"
                : "Copy Opposite"
            }`}
                </CBButton>
                <CBButton
                  size="lg"
                  disabled={isSubmitting}
                  onClick={confirmation.close}
                >
                  Cancel
                </CBButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

function MissingFieldsInfoModal({
  transactions,
  keepCreatorsFromSource,
  deleteTransactionsFromSource,
  addOppositeTransactionsToDestination,
  action,
  userId,
  onSuccess,
  actionLabel,
  onCancel,
  children,
  ...props
}: Omit<TActionFormParams, "creator"> &
  Pick<React.ComponentProps<typeof ActionForm>, "actionLabel"> & {
    deleteTransactionsFromSource?: boolean
    addOppositeTransactionsToDestination?: boolean
    action: "copy" | "move" | "opposite"
    keepCreatorsFromSource?: boolean
    onSuccess: (data: {
      sourceBook: TBook
      destinationBook: TBook
      transactions: Array<TTransaction>
    }) => void
    userId: string
    onCancel: () => void
    children: (props: { open: () => void }) => React.ReactNode
  }) {
  const confirmation = useOverlayTriggerState({})
  const numOfEntries = transactions.length

  return (
    <>
      {children({
        open: confirmation.open,
      })}
      <Modal
        isDismissable
        isOpen={confirmation.isOpen}
        onClose={confirmation.close}
        title={`Mandatory Fields Missing`}
      >
        <ModalBody>
          <Stack gap="4">
            <Stack gap="4">
              <Text>
                Some mandatory fields are missing in the selected{" "}
                {pluralize("Entry", numOfEntries).toLowerCase()}. Please review
                and complete all required fields to proceed
              </Text>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <CBButton size="lg" onClick={confirmation.close} level="primary">
            OK
          </CBButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

function SelectDestinationBook({
  name,
  userId,
  sourceBook,
  businessId,
}: {
  name: string
  sourceBook: TBook
  businessId: string
  userId: string
}) {
  const [
    { value },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _meta,
    { setValue },
  ] = useField<TBook | undefined>(name)

  const [searchQuery, setSearchQuery] = useState<string>("")
  const { business, authTeamMemberDetails, checkIfAuthenticatedTeamMemberCan } =
    useBusiness(businessId)
  const { books } = useBooksForBusinessId(businessId)

  const filteredBooks = useMemo(() => {
    return books.filter((book) => {
      if (book.id === sourceBook.id) return false
      if (book.name.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1)
        return false
      return true
    })
  }, [books, sourceBook, searchQuery])

  const canAddBook = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.ADD_NEW_BOOK
  )

  useMount(() => {
    if (filteredBooks.length) {
      const firstValidBook = filteredBooks.find((book) =>
        checkIfMemberCan(book, userId || "", BOOK_PERMISSIONS.ADD_CASH_IN_OUT)
      )
      setValue(firstValidBook)
    }
  })

  return (
    <Stack gap="2">
      {!books.length ? (
        <Box paddingY="4">
          <Alert status="error">
            No books found. Please create some other books to select.
          </Alert>
        </Box>
      ) : (
        <Stack gap="4">
          <Box flex="1">
            <Inline
              position="relative"
              rounded="md"
              height="10"
              paddingRight="2"
              alignItems="stretch"
              gap="2"
              maxWidth="lg"
              width="full"
              borderWidth="1"
              borderColor="borderOutline"
              className="bg-opacity-20 focus-within:border-blue-900 focus-within:ring-1 ring-blue-900"
            >
              <input
                type="search"
                name="q"
                placeholder="Search book name..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.currentTarget.value)}
                disabled={!books.length}
                className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
                onFocus={() => {
                  trackEvent(TrackingEvents.SET_SEARCH_FOCUS)
                }}
              />
              <Inline
                as="button"
                type="button"
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                  if (searchQuery.length) setSearchQuery("")
                }}
              >
                {searchQuery.length ? (
                  <CancelIcon color="iconMedium" />
                ) : (
                  <SearchIcon color="iconMedium" />
                )}
              </Inline>
            </Inline>
          </Box>
          <Stack as="ul">
            {!filteredBooks.length ? (
              <Box as="li">
                <Stack
                  paddingY="4"
                  paddingX="2"
                  gap="4"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="textMedium">
                    No books found for this{" "}
                    {searchQuery.length ? "search" : "action"}
                  </Text>
                  <Box>
                    {canAddBook ? (
                      <AddBookInDialog
                        businessId={businessId}
                        ownerId={business.id}
                        initialValues={{ name: searchQuery }}
                        userRole={authTeamMemberDetails.role.id}
                      >
                        {({ add }) => (
                          <CBButton onClick={add}>Create New Book</CBButton>
                        )}
                      </AddBookInDialog>
                    ) : null}
                  </Box>
                </Stack>
              </Box>
            ) : (
              filteredBooks.map((filteredBook, i) => {
                const canUserAddEntriesToThisBook = checkIfMemberCan(
                  filteredBook,
                  userId || "",
                  BOOK_PERMISSIONS.ADD_CASH_IN_OUT
                )
                const isSelected = value?.id === filteredBook.id
                return (
                  <Box as="li" key={filteredBook.id}>
                    <Inline
                      borderBottomWidth={
                        i !== filteredBooks.length - 1 ? "1" : "0"
                      }
                      as="label"
                      gap="4"
                      paddingY="4"
                      borderColor="borderDividers"
                      cursor={
                        !canUserAddEntriesToThisBook || isSelected
                          ? "disabled"
                          : "pointer"
                      }
                      key={filteredBook.id}
                      onClick={() => {
                        if (!canUserAddEntriesToThisBook || isSelected) return
                        setValue(filteredBook)
                      }}
                    >
                      <Box paddingTop="1">
                        <Radio isSelected={isSelected} />
                      </Box>
                      <Stack gap="2">
                        <Text fontSize="s3">{filteredBook.name}</Text>
                        <Inline fontSize="c2" color="textMedium" gap="1">
                          Created On:
                          <TransactionDate
                            format="MMM do yyyy"
                            timeStamp={filteredBook.createdAt}
                          />{" "}
                          | {filteredBook.sharedWith.length} Members
                        </Inline>
                        {!canUserAddEntriesToThisBook ? (
                          <Text color="textError" fontSize="c2">
                            <ExclamationIcon size="4" /> Can not add entries
                            here
                          </Text>
                        ) : null}
                      </Stack>
                    </Inline>
                  </Box>
                )
              })
            )}
          </Stack>
        </Stack>
      )}
      <ErrorMessage name={name} />
    </Stack>
  )
}

function SelectCreator({
  name,
  defaultValue,
  onChange,
}: {
  name: string
  defaultValue: "original" | "me"
  onChange: (type: "original" | "me") => void
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ value }, _meta, { setValue }] =
    useField<$PropertyType<TActionFormParams, "creator">>(name)

  useMount(() => {
    setValue(defaultValue)
  })

  function onChangeHandler(value: "original" | "me") {
    onChange(value)
    setValue(value)
  }

  return (
    <Stack gap="6">
      <Stack borderWidth="1" rounded="md" overflow="hidden">
        <Box
          padding="4"
          borderTopWidth="1"
          bgColor={value === "original" ? "blue100" : undefined}
        >
          <FormField
            id={`creator_original`}
            name={name}
            type="radio"
            value="original"
            noMargin
            hideError
            label={
              <Stack marginLeft="2" gap="1">
                <Text fontWeight="medium">Keep original creator</Text>
                <Text fontSize="xs" fontWeight="normal" color="gray500">
                  Original creator's name will remain on these entries but don't
                  worry creator will not get access to your book
                </Text>
                <CreatorPreview creator="original" />
              </Stack>
            }
            onChange={() => {
              onChangeHandler("original")
            }}
          />
        </Box>
        <Box
          padding="4"
          borderTopWidth="1"
          bgColor={value === "me" ? "blue100" : undefined}
        >
          <FormField
            id={`creator_me`}
            name={name}
            type="radio"
            value="me"
            noMargin
            hideError
            label={
              <Stack marginLeft="2" gap="1">
                <Text fontWeight="medium">
                  Make You a creator of these entries
                </Text>
                <Text color="gray500" fontWeight="normal" fontSize="xs">
                  This will add these entries in new book with your name
                </Text>
                <CreatorPreview creator="me" />
              </Stack>
            }
            onChange={() => {
              onChangeHandler("me")
            }}
          />
        </Box>
      </Stack>
      <ErrorMessage name={name} />
    </Stack>
  )
}

function CreatorPreview({
  creator,
}: {
  creator: $PropertyType<TActionFormParams, "creator">
}) {
  return (
    <Box borderWidth="1" paddingX="4" paddingY="3" bgColor="white">
      <Inline justifyContent="between">
        <Stack gap="1">
          <Box height="2" width="16" rounded="lg" bgColor="blue100"></Box>
          <Box height="3" rounded="lg" bgColor="blue100" className="w-32"></Box>
          <Text color="blue900">
            by {creator === "original" ? "Original Creator" : "You"}
          </Text>
        </Stack>
        <Stack gap="3">
          <Box height="3" width="12" rounded="lg" bgColor="green900"></Box>
          <Box height="2" width="12" rounded="lg" bgColor="blue100"></Box>
        </Stack>
      </Inline>
    </Box>
  )
}
