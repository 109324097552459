import {
  Button,
  ChevronDownIcon,
  getButtonClassName,
  PageMeta,
  Heading,
  Text,
  Box,
  Stack,
  WhatsAppIcon,
  ModalFooter,
} from "@cashbook/web-components"
import { Link } from "react-router-dom"
import config from "../config"
import { WhatsAppContactLink } from "../HelpDocs"

export default function PremiumBenefits() {
  return (
    <Box paddingY="12" width="full">
      <PageMeta>
        <title>Premium Benefits</title>
      </PageMeta>
      <Stack alignItems="center">
        <Stack maxWidth="2xl" gap="8">
          <Stack as="section" gap="8">
            <Box textAlign="center">
              <Heading as="h2" fontSize="3xl" fontWeight="medium">
                {config.appTitle} Premium!
              </Heading>
            </Box>
            <Stack padding="8" bgColor="white" rounded="lg" gap="8">
              <Stack gap="4">
                <Box>
                  <Text color="gray500">
                    Including all the benefits from free version, you will get
                  </Text>
                </Box>
                <Stack as="ul" className="list-disc ml-8" gap="2">
                  <Box as="li">
                    <Text>
                      Transfer entries from one book to other book feature might
                      be part of {config.appTitle} premium. It is on free trial
                      for now.{" "}
                    </Text>
                  </Box>
                  <Box as="li">
                    <Text>
                      Map heading for your entry imports to quickly import data
                      from any platform, without modifying the original files.
                    </Text>
                  </Box>
                  <Box as="li">
                    <Text>
                      Premium package will be released in a few months. We will
                      release many more new features which will be part of
                      premium package.
                    </Text>
                  </Box>
                  <Box as="li">
                    <Text>
                      Let us know if you want any feature in premium package
                    </Text>
                  </Box>
                </Stack>
              </Stack>
              <Box textAlign="center">
                <Button level="secondary" disabled>
                  Upgrade to Premium{" "}
                  <Box
                    bgColor="yellow100"
                    display="inlineBlock"
                    borderWidth="1"
                    borderColor="yellow300"
                    paddingX="2"
                    paddingY="px"
                    rounded="lg"
                  >
                    <Text as="span" color="yellow800" fontSize="sm">
                      Coming Soon
                    </Text>
                  </Box>
                </Button>
              </Box>
              <Box textAlign="center">
                <ModalFooter>
                  <WhatsAppContactLink
                    text={`Hi, I want these new features in ${config.appTitle} Premium.`}
                  >
                    {(props) => (
                      <a
                        {...props}
                        className={getButtonClassName({
                          level: "primary",
                          status: "success",
                        })}
                      >
                        <WhatsAppIcon /> Request Your Feature
                      </a>
                    )}
                  </WhatsAppContactLink>
                  <Link
                    to="/"
                    className={getButtonClassName({ level: "tertiary" })}
                  >
                    <ChevronDownIcon rotate="90" /> Back to Dashboard
                  </Link>
                </ModalFooter>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
