import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { ArrowLeftIcon, Inline, Text, Button } from "@cashbook/web-components"

type TListener = (isAvailable: boolean) => void

const store = {
  state: false as boolean,
  swRegisteration: null as ServiceWorkerRegistration | null,
  listeners: [] as Array<TListener>,
  subscribe: (fn: TListener) => store.listeners.push(fn),
  unsubscribe: (fn: TListener) => {
    const index = store.listeners.indexOf(fn)
    if (index !== -1) {
      store.listeners.splice(index, 1)
    }
  },
  update: () => {
    trackEvent(TrackingEvents.APP_UPDATE_CLICKED)
    store.swRegisteration?.waiting?.postMessage({ type: "SKIP_WAITING" })
    setTimeout(() => {
      // eslint-disable-next-line no-self-assign
      window.location = window.location
    }, 500)
  },
  setUpdateAvailable: (reg: ServiceWorkerRegistration) => {
    trackEvent(TrackingEvents.APP_UPDATE_AVAILABLE)
    store.swRegisteration = reg
    store.state = true
    setTimeout(() => {
      store.listeners.forEach((l) => l(true))
    }, 11000)
    toast(
      (t) => (
        <Inline gap="4" alignItems="center">
          <Text fontWeight="medium">App update available</Text>
          <Button
            onClick={() => {
              toast.dismiss(t.id)
              updateApp()
            }}
            size="sm"
            level="primary"
            minWidth="auto"
          >
            <ArrowLeftIcon rotate="90" size="4" />
            <Text as="span" fontWeight="medium" fontSize="sm">
              Update
            </Text>
          </Button>
        </Inline>
      ),
      {
        duration: 10000,
        id: "applicationUpdate",
      }
    )
  },
}

export function useAppUpdateStatus(): [
  isUpdateAvailable: boolean,
  update: () => void
] {
  const [isUpdateAvailable, setAvailablityStatus] = useState(store.state)
  useEffect(() => {
    store.subscribe(setAvailablityStatus)
    return () => store.unsubscribe(setAvailablityStatus)
  }, [])
  return [isUpdateAvailable, store.update]
}

export const setUpdateAvailable = store.setUpdateAvailable
export const updateApp = store.update
