const LIGHT_COLORS = {
  //Blue
  blue5: "#F6F7FD",
  blue10: "#EDEFFB",
  blue20: "#DAE0F6",
  blue30: "#B6C1EE",
  blue40: "#91A1E5",
  blue50: "#4863D4",
  blue60: "#4159BF",
  blue70: "#3A4FAA",
  blue80: "#2B3B7F",
  blue90: "#1D2855",

  //Green
  green5: "#F3FAF6",
  green10: "#E8F5EE",
  green20: "#D1ECDC",
  green30: "#A2D9B9",
  green40: "#74C597",
  green50: "#179F51",
  green60: "#158F49",
  green70: "#127F41",
  green80: "#0E5F31",
  green90: "#094020",

  //Red
  red5: "#FCF5F5",
  red10: "#FAEBEB",
  red20: "#F4D8D8",
  red30: "#E9B1B1",
  red40: "#DF8989",
  red50: "#C93B3B",
  red60: "#B53535",
  red70: "#A12F2F",
  red80: "#792323",
  red90: "#501818",

  //Brown
  brown5: "#FCF7F3",
  brown10: "#F8EFE7",
  brown20: "#F2DFCF",
  brown30: "#E5C09E",
  brown40: "#D7A06E",
  brown50: "#BD610D",
  brown60: "#AA570C",
  brown70: "#974E0A",
  brown80: "#713A08",
  brown90: "#4C2705",

  //Grey
  grey0: "#FFFFFF",
  grey5: "#FAFAFA",
  grey10: "#F5F5F5",
  grey20: "#EEEEEE",
  grey30: "#E0E0E0",
  grey40: "#BDBDBD",
  grey50: "#9E9E9E",
  grey60: "#757575",
  grey70: "#616161",
  grey80: "#424242",
  grey90: "#212121",

  //Navy Blue
  navyBlue5: "#F3F8FC",
  navyBlue10: "#E7F2F9",
  navyBlue20: "#D0E4F4",
  navyBlue30: "#A1CAE8",
  navyBlue40: "#71AFDD",
  navyBlue50: "#137AC6",
  navyBlue60: "#116EB2",
  navyBlue70: "#0F629E",
  navyBlue80: "#0B4977",
  navyBlue90: "#08314F",

  //Purple
  purple5: "#F6F6FD",
  purple10: "#EEEDFA",
  purple20: "#DDDCF5",
  purple30: "#BAB8EB",
  purple40: "#9895E1",
  purple50: "#534ECD",
  purple60: "#4B46B9",
  purple70: "#423EA4",
  purple80: "#322F7B",
  purple90: "#211F52",
}

export const COLORS = {
  transparent: "transparent",
  red: "red",
  black: "#000",
  white: "#fff",
  blue100: "#EBEEFD",
  blue50: "#f5f6fa",
  blue200: "#B6C1EE",
  blue900: "#4863D4",
  gray100: "#EEEEEE",
  gray200: "#E0E0E0",
  gray400: "#BDBDBD",
  gray500: "#707070",
  gray600: "#404040",
  gray900: "#212121",
  red100: "#f7e1e1",
  red500: "#ef4444",
  red900: "#C93B3B",
  green900: "#01865F",
  green500: "#21B15E",
  green100: "#dff4ed",
  yellow100: "#fef3c7",
  yellow300: "#fcd34d",
  yellow600: "#d97706",
  yellow800: "#92400e",
  category: "#534ECD",
  paymentMode: "#137AC6",
  paymentModeBg: "#E7F2F9",
  orange100: "#F8EFE7",
  orange200: "#D7A06E",
  orange900: "#BD610D",

  //New Design Colors (Should replace top ones)
  backgroundLight1: LIGHT_COLORS["grey0"],
  backgroundLight2: LIGHT_COLORS.grey5,
  backgroundLight3: LIGHT_COLORS.blue5,

  //Surfaces
  surfaceDefault: LIGHT_COLORS.grey0,
  surfacePrimary: LIGHT_COLORS.blue50,
  surfaceError: LIGHT_COLORS.red50,
  surfaceWarning: LIGHT_COLORS.brown50,
  surfaceCashIn: LIGHT_COLORS.green70,
  surfaceCashOut: LIGHT_COLORS.red50,
  surfaceWarning: LIGHT_COLORS.brown50,
  surfaceSuccess: LIGHT_COLORS.green50,
  surfaceAlt1: LIGHT_COLORS.navyBlue50,
  surfaceAlt2: LIGHT_COLORS.purple50,
  surfacePrimaryLowest: LIGHT_COLORS.blue10,
  surfaceSuccessLowest: LIGHT_COLORS.green10,
  surfaceErrorLowest: LIGHT_COLORS.red10,
  surfaceWarningLowest: LIGHT_COLORS.brown10,
  surfaceNeutralLowest: LIGHT_COLORS.grey10,
  surfaceAlt1Lowest: LIGHT_COLORS.navyBlue10,
  surfaceAlt2Lowest: LIGHT_COLORS.purple10,

  //Borders
  borderOutline: LIGHT_COLORS.grey30,
  borderDividers: LIGHT_COLORS.grey20,
  borderPrimary: LIGHT_COLORS.blue50,
  borderSuccess: LIGHT_COLORS.green50,
  borderWarning: LIGHT_COLORS.brown50,
  borderError: LIGHT_COLORS.red50,
  borderAlt1: LIGHT_COLORS.navyBlue50,
  borderAlt2: LIGHT_COLORS.purple50,
  borderPrimaryLow: LIGHT_COLORS.blue30,
  borderSuccessLow: LIGHT_COLORS.green30,
  borderWarningLow: LIGHT_COLORS.brown30,
  borderErrorLow: LIGHT_COLORS.red30,
  borderAlt1Low: LIGHT_COLORS.navyBlue30,
  borderAlt2Low: LIGHT_COLORS.purple30,

  //Texts
  textHigh: LIGHT_COLORS.grey90,
  textMedium: LIGHT_COLORS.grey60,
  textLow: LIGHT_COLORS.grey50,
  textOnSurface: LIGHT_COLORS.grey0,
  textPrimary: LIGHT_COLORS.blue50,
  textCashIn: LIGHT_COLORS.green70,
  textCashOut: LIGHT_COLORS.red50,
  textSuccess: LIGHT_COLORS.green50,
  textWarning: LIGHT_COLORS.brown60,
  textError: LIGHT_COLORS.red50,
  textAlt1: LIGHT_COLORS.navyBlue60,
  textAlt2: LIGHT_COLORS.purple60,

  //Icons
  iconHigh: LIGHT_COLORS.grey90,
  iconMedium: LIGHT_COLORS.grey60,
  iconLow: LIGHT_COLORS.grey50,
  iconLowest: LIGHT_COLORS.grey40,
  iconOnSurface: LIGHT_COLORS.grey0,
  iconPrimary: LIGHT_COLORS.blue50,
  iconCashIn: LIGHT_COLORS.green70,
  iconCashOut: LIGHT_COLORS.red50,
  iconSuccess: LIGHT_COLORS.green50,
  iconWarning: LIGHT_COLORS.brown50,
  iconError: LIGHT_COLORS.red50,
  iconAlt1: LIGHT_COLORS.navyBlue50,
  iconAlt2: LIGHT_COLORS.purple50,

  //Interactions
  interactionsPrimaryHover: LIGHT_COLORS.blue60,
  interactionsPrimaryInProgress: LIGHT_COLORS.blue40,
  interactionsSuccessInProgress: LIGHT_COLORS.green40,
  interactionsWarningInProgress: LIGHT_COLORS.brown40,
  interactionsDangerInProgress: LIGHT_COLORS.red40,
  interactionsNeutralHover: LIGHT_COLORS.grey10,
  interactionsDisabled: LIGHT_COLORS.grey30,
  interactionsCashInHover: LIGHT_COLORS.grey80,
  interactionsCashOutHover: LIGHT_COLORS.red60,
  interactionsSuccessHover: LIGHT_COLORS.green60,
  interactionsErrorHover: LIGHT_COLORS.red60,
  interactionsWarningHover: LIGHT_COLORS.brown60,
} as const

export const BREAKPOINTS = {
  xs: {},
  sm: { "@media": "screen and (min-width: 640px)" },
  md: { "@media": "screen and (min-width: 768px)" },
  lg: { "@media": "screen and (min-width: 1024px)" },
  xl: { "@media": "screen and (min-width: 1280px)" },
  "2xl": { "@media": "screen and (min-width: 1440px)" },
} as const

export const BREAKPOINTS_NAMES = Object.keys(
  BREAKPOINTS
) as never as keyof typeof BREAKPOINTS

export const SPACING = {
  "0": "0",
  px: "1px",
  "1": ".25rem",
  "2": ".5rem",
  "3": ".75rem",
  "4": "1rem",
  "5": "1.25rem",
  "6": "1.5rem",
  "7": "1.75rem",
  "8": "2rem",
  "9": "2.25rem",
  "10": "2.5rem",
  "12": "3rem",
  "14": "3.5rem",
  "16": "4rem",
  "18": "4.5rem",
  "24": "6rem",
  auto: "auto",
} as const

export const SIZES = {
  ...SPACING,
  "1/2": "50%",
  "1/3": "33.33333%",
  full: "100%",
} as const
