import Page from "./Page"
import BusinessTeamSettingsPage from "../BusinessTeamSettingsPage"
import { useParams } from "react-router-dom"

export default function SmBusinessTeamSettings() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <Page
      title="Business Team Details"
      backTo={`/m/businesses/${businessId}/business-settings`}
      bgColor="white"
    >
      <BusinessTeamSettingsPage />
    </Page>
  )
}
