import { getWhatsAppSharingLink, useUnmount } from "@cashbook/util-general"
import {
  ThumbDownIcon,
  ThumbUpIcon,
  Text,
  Heading,
  Box,
  Inline,
  Stack,
  WhatsAppIcon,
  getButtonClassName,
  Button,
} from "@cashbook/web-components"
import { useState } from "react"
import { SuspenseWithPerf, useRemoteConfigNumber } from "reactfire"
import config from "../config"
import {
  THelpDoc,
  useDownvoteHelpDoc,
  useUpvoteHelpDoc,
} from "@cashbook/data-store/help-docs"
import { useDeviceType } from "@cashbook/device-info"

export function CaptureFeedback({ helpDoc }: { helpDoc: THelpDoc }) {
  const upvote = useUpvoteHelpDoc(helpDoc.id)
  const downlvoted = useDownvoteHelpDoc(helpDoc.id)
  const [voteStatus, setVoteStatus] = useState<undefined | "up" | "down">(
    undefined
  )
  // we will capture the feedback user moves to other docs
  useUnmount(() => {
    switch (voteStatus) {
      case "up":
        return upvote()
      case "down":
        return downlvoted()
      default:
        return
    }
  })
  return (
    <Box
      padding="8"
      rounded="lg"
      maxWidth="3xl"
      borderWidth="1"
      borderColor="blue100"
      bgColor="blue50"
    >
      <Stack gap="4">
        <Inline
          gap="4"
          collapseBelow="sm"
          justifyContent="between"
          alignItems={{ xs: "start", sm: "center" }}
        >
          <Box flex="1" minWidth="0">
            <Box marginBottom="1">
              <Heading fontSize={{ xs: "xl", sm: "2xl" }} as="h3">
                Was this helpful ?
              </Heading>
            </Box>
            <Text color="gray500">
              Your feedback helps us improve our docs.
            </Text>
          </Box>
          <Inline gap="8">
            <Inline
              as="button"
              name="upvoted"
              value="1"
              size="16"
              rounded="full"
              alignItems="center"
              justifyContent="center"
              bgColor={
                voteStatus === "up"
                  ? "green900"
                  : { default: "green100", hover: "green500" }
              }
              color={
                voteStatus === "up"
                  ? "white"
                  : { default: "green900", hover: "white" }
              }
              onClick={() => {
                setVoteStatus("up")
              }}
            >
              <ThumbUpIcon size="6" />
            </Inline>
            <Inline
              as="button"
              name="downlvoted"
              value="1"
              size="16"
              rounded="full"
              alignItems="center"
              justifyContent="center"
              bgColor={
                voteStatus === "down"
                  ? "red900"
                  : { default: "red100", hover: "red500" }
              }
              color={
                voteStatus === "down"
                  ? "white"
                  : { default: "red900", hover: "white" }
              }
              onClick={() => {
                setVoteStatus("down")
              }}
            >
              <ThumbDownIcon size="6" />
            </Inline>
          </Inline>
        </Inline>
        {voteStatus === "up" ? (
          <Box paddingTop="4" borderTopWidth="1">
            <Stack maxWidth="lg" gap="2">
              <Inline gap="2" alignItems="center" className="tracking-wider">
                <ThumbUpIcon />
                <Text
                  as="span"
                  color="green900"
                  fontWeight="medium"
                  fontSize="sm"
                  textTransform="uppercase"
                >
                  Helpful
                </Text>
              </Inline>
              <Box>
                <Heading>Thank you for your feedback</Heading>
              </Box>
            </Stack>
          </Box>
        ) : voteStatus === "down" ? (
          <Box paddingTop="4" borderTopWidth="1">
            <Stack maxWidth="lg" gap="8">
              <Stack gap="2">
                <Heading>Thank you for your feedback</Heading>
                <Text fontWeight="normal">
                  We're continually improving our app and docs. We'd love to
                  hear how we can do better.
                </Text>
              </Stack>
              <Box>
                <WhatsAppContactLink>
                  <WhatsAppIcon /> Share Feedback
                </WhatsAppContactLink>
              </Box>
            </Stack>
          </Box>
        ) : null}
      </Stack>
    </Box>
  )
}

export function WhatsAppContactLink(
  props: React.ComponentProps<typeof WhatsAppContactLinkInner>
) {
  return (
    <SuspenseWithPerf fallback={null} traceId="loading_config">
      <WhatsAppContactLinkInner {...props} />
    </SuspenseWithPerf>
  )
}

function WhatsAppContactLinkInner({
  children,
  text,
  size,
  level,
  fullWidth,
  phoneNumber,
  onClick,
}: {
  children?:
    | React.ReactNode
    | ((props: React.HTMLProps<HTMLAnchorElement>) => React.ReactNode)
  text?: string
  phoneNumber?: string
  onClick?: () => void
  size?: React.ComponentProps<typeof Button>["size"]
  level?: React.ComponentProps<typeof Button>["level"]
  fullWidth?: React.ComponentProps<typeof Button>["fullWidth"]
}) {
  const { isSmallDevice } = useDeviceType()
  const { data } = useRemoteConfigNumber(
    config.firebaseConfig.supportPhoneNumberKey
  )
  const supportPhoneNumber = phoneNumber || data || config.supportPhoneNumber
  const childProps = {
    href: `${getWhatsAppSharingLink({
      text:
        text ||
        `Hi, I need some help regarding the ${config.appTitle}${
          isSmallDevice ? " mobile" : ""
        } web app`,
      phoneNumber: supportPhoneNumber,
    })}`,
    onClick: onClick,
    target: "_blank",
    rel: "noopener noreferrer",
    title: "Send Message",
    className: getButtonClassName({
      level: level || "secondary",
      status: "success",
      size,
      fullWidth,
    }),
    children: (
      <>
        <WhatsAppIcon /> Contact Us
      </>
    ),
  }
  if (typeof children === "function") {
    // remove the style and className
    const { className, ...otherProps } = childProps
    return <>{children(otherProps)}</>
  }
  return <a {...childProps}>{children || childProps.children}</a>
}
