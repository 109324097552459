import { useParams } from "react-router-dom"
import Page from "./Page"
import BookFieldSettingPage from "./../BookFieldSettingsPage"

export default function SmBookFieldSettingsPage() {
  const { bookId, businessId } = useParams()
  return (
    <Page
      title="Book Fields"
      backTo={`/m/businesses/${businessId}/cashbooks/${bookId}/settings`}
    >
      <BookFieldSettingPage />
    </Page>
  )
}
