import {
  DataLoadingFallback,
  PageMeta,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
  SearchIcon,
  CancelIcon,
  PlusIcon,
  Circle,
  UsersFilledIcon,
  BookIcon,
  FormatDistanceTime,
  PencilOutlinedIcon,
  UserAddIcon,
  getButtonClassName,
  ArrowLeftIcon,
  FiltersIcon,
  DotsVerticalIcon,
  Menu,
  MenuButton,
  MenuList,
  MenuItemHeader,
  MenuItem,
  CheckIcon,
  DuplicateIcon,
  GearIcon,
  Button,
} from "@cashbook/web-components"
import React, { SyntheticEvent, useEffect, useState } from "react"
import {
  BUSINESS_PERMISSIONS,
  useBusiness,
} from "@cashbook/data-store/businesses"
import { useParams, useNavigate, Link } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../../ErrorBoundary"
import {
  BOOK_PERMISSIONS,
  checkIfMemberCan,
  TBook,
  useBusinessBooksSearchForMember,
} from "@cashbook/data-store/books"
import { useMemo } from "react"
import { useProfile } from "@cashbook/data-store/users"
import { timeStampToDate } from "@cashbook/util-dates"
import { Amount } from "../../support/Intl"
import {
  AddBookInDialog,
  DuplicateBookInDialog,
  EditBookInDialog,
} from "../../Books"
import {
  BookListFooter,
  CannotFindBooks,
  HandleNoBooksForMembers,
} from "../BusinessCashbooksPage"
import { useSyncedStorageState } from "@cashbook/data-store/storage"

export default function SmBusinessCashbooksPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading business details..." />}
        traceId="loading_business_details"
      >
        <BusinessCashbooksLayout key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BusinessCashbooksLayout({ businessId }: { businessId: string }) {
  const { business, authTeamMemberDetails, checkIfAuthenticatedTeamMemberCan } =
    useBusiness(businessId)
  const { user } = useProfile()
  const navigate = useNavigate()
  const {
    books: filteredBooks,
    handleParamsChange,
    setParamValue,
    params,
  } = useBusinessBooksSearchForMember(businessId, user.uid)
  const books = useMemo(() => {
    return filteredBooks
  }, [filteredBooks])
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const canAddBookToBusiness = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.ADD_NEW_BOOK
  )

  const [isNewUser] = useSyncedStorageState<boolean>("isNewUser", false)

  useEffect(() => {
    if (canAddBookToBusiness) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 100) {
          setIsScrolled(true)
        } else {
          setIsScrolled(false)
        }
      })
    }
    return () =>
      window.removeEventListener("scroll", () => {
        return true
      })
  }, [canAddBookToBusiness])

  return (
    <>
      <PageMeta>
        <title>{business.name}</title>
      </PageMeta>
      <Box
        key={businessId}
        bgColor="white"
        overflowY="hidden"
        className="min-h-screen sm:min-h-0"
      >
        <Stack>
          <Box
            as="header"
            paddingY="6"
            paddingX="4"
            borderBottomWidth="1"
            borderColor="gray100"
          >
            <Inline alignItems="center">
              <Inline flexGrow="1" gap="4" alignItems="center">
                <Link
                  to="/m/businesses"
                  className={getButtonClassName({ inline: true })}
                >
                  <ArrowLeftIcon color="black" />
                </Link>
                <Heading as="h2" fontSize="lg" fontWeight="semibold">
                  {business.name}
                </Heading>
              </Inline>
              <Link
                to={`/businesses/${businessId}/business-settings`}
                className={getButtonClassName({ inline: true })}
              >
                <GearIcon />
              </Link>
            </Inline>
          </Box>
          <Inline paddingX="4" width="full" gap="8" justifyContent="between">
            <Box className="w-full md:w-3/4">
              <Stack>
                <Inline
                  gap="6"
                  paddingY="4"
                  justifyContent="between"
                  alignItems="center"
                >
                  <Box className="flex relative bg-opacity-20 rounded focus-within:border-blue-900 focus-within:ring-1 ring-blue-900 h-10 pr-2 items-stretch gap-2 max-w-lg w-full border">
                    <input
                      type="search"
                      name="q"
                      placeholder="Search by book name..."
                      value={params.q}
                      onChange={handleParamsChange}
                      className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
                    />
                    <button
                      type="button"
                      className="flex items-center justify-center text-gray-500"
                      onClick={() =>
                        setParamValue("q", params.q ? "" : params.q)
                      }
                    >
                      {params.q ? <CancelIcon /> : <SearchIcon />}
                    </button>
                  </Box>
                  <Menu>
                    <MenuButton inline>
                      <Inline
                        borderWidth="1"
                        height="10"
                        width="10"
                        rounded="md"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <FiltersIcon color="gray500" />
                      </Inline>
                    </MenuButton>
                    <MenuList align="bottom-right">
                      <MenuItemHeader className=" w-60">
                        <Inline>
                          <Text fontWeight="semibold">Sort Books by</Text>
                        </Inline>
                      </MenuItemHeader>
                      <MenuItem
                        action="update_books_by_last_updated"
                        onClick={() => setParamValue("sortBy", "byLastUpdated")}
                      >
                        <Inline alignItems="center" gap="2">
                          <CheckIcon
                            color="green500"
                            visibility={
                              params.sortBy === "byLastUpdated"
                                ? "visible"
                                : "hidden"
                            }
                          />
                          Last Updated
                        </Inline>
                      </MenuItem>
                      <MenuItem
                        action="update_books_by_name"
                        onClick={() => setParamValue("sortBy", "byBookName")}
                      >
                        <Inline alignItems="center" gap="2">
                          <CheckIcon
                            color="green500"
                            visibility={
                              params.sortBy === "byBookName"
                                ? "visible"
                                : "hidden"
                            }
                          />
                          Name (A to Z)
                        </Inline>
                      </MenuItem>
                      <MenuItem
                        action="update_books_by_created"
                        onClick={() => setParamValue("sortBy", "byLastCreated")}
                      >
                        <Inline alignItems="center" gap="2">
                          <CheckIcon
                            color="green500"
                            visibility={
                              params.sortBy === "byLastCreated"
                                ? "visible"
                                : "hidden"
                            }
                          />
                          Last Created
                        </Inline>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Inline>
                <Box as="ul">
                  {books.length ? (
                    books.map((book) => {
                      return (
                        <Box borderBottomWidth="1" key={book.id}>
                          <DashboardBookView
                            book={book}
                            memberId={user.uid}
                            onBookClick={() =>
                              navigate(`${book.id}/transactions`)
                            }
                          />
                        </Box>
                      )
                    })
                  ) : params.q?.length ? (
                    <Stack
                      width="full"
                      gap="8"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Stack
                        width="full"
                        maxWidth="sm"
                        gap="6"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Circle size="16">
                          <BookIcon />
                        </Circle>
                        <Stack gap="2" textAlign="center">
                          <Heading as="h2" fontSize="lg">
                            No books found with this name!
                          </Heading>
                          <Text color="gray500">
                            Check spelling or try some other search term
                          </Text>
                        </Stack>
                        <Box
                          paddingTop="2"
                          onClick={() => setParamValue("q", "")}
                        >
                          <Button level="primary" size="lg">
                            <Box>
                              <CancelIcon />
                            </Box>
                            Clear Search
                          </Button>
                        </Box>
                      </Stack>
                      {business.ownerId === user.uid ? (
                        <CannotFindBooks />
                      ) : null}
                    </Stack>
                  ) : (
                    <HandleNoBooksForMembers
                      isNewUser={isNewUser}
                      ownerId={business.ownerId}
                      businessId={businessId}
                      role={authTeamMemberDetails.role.id}
                    />
                  )}
                  <BookListFooter
                    isNewUser={isNewUser}
                    business={business}
                    books={books}
                  />
                </Box>
              </Stack>
            </Box>
          </Inline>
          {canAddBookToBusiness ? (
            <Box
              width="full"
              paddingX="4"
              position="fixed"
              className="bottom-8"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <AddBookInDialog
                ownerId={business.ownerId}
                businessId={businessId}
                userRole={authTeamMemberDetails.role.id}
              >
                {({ add }) => (
                  <Button
                    minWidth="auto"
                    level="primary"
                    size="lg"
                    style={{ borderRadius: 100 }}
                    onClick={add}
                  >
                    <Box>
                      <PlusIcon />
                    </Box>
                    {isScrolled ? null : "Add New Book"}
                  </Button>
                )}
              </AddBookInDialog>
            </Box>
          ) : null}
        </Stack>
      </Box>
    </>
  )
}

function DashboardBookView({
  book,
  memberId,
  onBookClick,
}: {
  book: TBook
  memberId: string
  onBookClick: () => void
}) {
  const closingBalance = (book?.totalCashIn || 0) - (book?.totalCashOut || 0)
  const canViewNetBalance = checkIfMemberCan(
    book,
    BOOK_PERMISSIONS.VIEW_NET_BALANCE
  )
  const canEditBook = checkIfMemberCan(
    book,
    memberId,
    BOOK_PERMISSIONS.EDIT_BOOK
  )
  const canDuplicateBook = checkIfMemberCan(
    book,
    memberId,
    BOOK_PERMISSIONS.DUPLICATE_BOOK
  )
  const canAddMembersInBook = checkIfMemberCan(
    book,
    memberId,
    BOOK_PERMISSIONS.ADD_MEMBER
  )
  return (
    <Box
      cursor="pointer"
      paddingY="4"
      width="full"
      tabIndex={-1}
      onClick={onBookClick}
    >
      <Inline width="full" alignItems="center" justifyContent="between">
        <Inline gap="3" alignItems="center">
          <Circle size="8">
            {book.sharedWith.length > 1 ? (
              <UsersFilledIcon size="5" />
            ) : (
              <BookIcon size="5" />
            )}
          </Circle>
          <Stack gap="1" className="break-words">
            <Text fontWeight="semibold">{book.name}</Text>
            <Inline gap="1" className="text-[10px] break-words" color="gray500">
              {book.sharedWith.length > 1 ? (
                <Text>{book.sharedWith.length} members</Text>
              ) : null}
              {book.sharedWith.length > 1 ? <Text>•</Text> : null}
              {book.updatedAt ? (
                <Inline gap="1" className="break-words">
                  Updated
                  <FormatDistanceTime
                    removePrefix
                    date={timeStampToDate(book.updatedAt)}
                  />
                </Inline>
              ) : book.createdAt ? (
                <Inline gap="1">
                  Created
                  <FormatDistanceTime
                    removePrefix
                    date={timeStampToDate(book.createdAt)}
                  />
                </Inline>
              ) : null}
            </Inline>
          </Stack>
        </Inline>
        <Inline gap="2" alignItems="center">
          {canViewNetBalance ? (
            <Box className="break-all" width="18" textAlign="right">
              <Amount
                amount={closingBalance}
                color={closingBalance < 0 ? "red900" : "green900"}
                fontWeight="semibold"
                className="break-words"
              />
            </Box>
          ) : null}
          {canEditBook || canDuplicateBook || canAddMembersInBook ? (
            <Box
              textAlign="right"
              position="relative"
              className="align-middle"
              onClick={(e: SyntheticEvent) => e.stopPropagation()}
            >
              <DuplicateBookInDialog
                book={book}
                bookName={book.name}
                onSuccess={() => undefined}
              >
                {({ duplicate }) => (
                  <EditBookInDialog bookId={book.id}>
                    {({ edit }) => (
                      <Menu>
                        <MenuButton inline>
                          <DotsVerticalIcon color="gray500" size="5" />
                        </MenuButton>
                        <MenuList align="bottom-right">
                          {canEditBook && (
                            <MenuItem
                              action="rename_book"
                              onClick={() => edit()}
                            >
                              <Inline
                                alignItems="center"
                                gap="2"
                                className="w-40"
                              >
                                <Box>
                                  <PencilOutlinedIcon size="5" />
                                </Box>
                                Rename
                              </Inline>
                            </MenuItem>
                          )}
                          {canDuplicateBook && (
                            <MenuItem
                              action="duplicate_book"
                              onClick={duplicate}
                            >
                              <Inline
                                alignItems="center"
                                gap="2"
                                className="w-40"
                              >
                                <Box>
                                  <DuplicateIcon size="5" />
                                </Box>
                                Duplicate Book
                              </Inline>
                            </MenuItem>
                          )}
                          {canAddMembersInBook && (
                            <MenuItem
                              action="add_members_to_book"
                              href={`${book.id}/settings/members`}
                            >
                              <Inline
                                alignItems="center"
                                gap="2"
                                className="w-40"
                              >
                                <Box>
                                  <UserAddIcon size="5" />
                                </Box>
                                Add Members
                              </Inline>
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    )}
                  </EditBookInDialog>
                )}
              </DuplicateBookInDialog>
            </Box>
          ) : null}
        </Inline>
      </Inline>
    </Box>
  )
}
