import { getDeviceOS } from "@cashbook/device-info"
import {
  Alert,
  AppDownloadIcon,
  Box,
  Button,
  CheckIcon,
  getButtonClassName,
  Heading,
  Inline,
  MobileIcon,
  Modal,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import config from "../config"
import AndroidQr from "./android.webp"

export function NotInWeb({
  heading,
  children,
}: {
  heading?: string
  children: (props: { onDisplay: () => void }) => React.ReactNode
}) {
  const os = getDeviceOS()
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ onDisplay: state.open })}
      <Modal
        title="Not Enabled on Web App"
        isOpen={state.isOpen}
        onClose={state.close}
        isDismissable
        size="sm"
      >
        <ModalBody>
          <Stack gap="6" alignItems="center" justifyContent="center">
            <Box>
              <MobileIcon color="iconMedium" size="18" />
            </Box>
            <Stack gap="3" textAlign="center">
              <Heading fontSize="xl">
                {heading ? heading : "Use mobile app to make these changes."}
              </Heading>
              <Text color="textMedium">
                This feature is not available on web app yet. Please use the
                mobile app.
              </Text>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button level="primary" size="lg" onClick={state.close}>
            <Box>
              <CheckIcon />
            </Box>
            Ok, Got it
          </Button>
          <a
            href={
              os === "ios"
                ? config.appStoreDownloadLink
                : config.appDownloadLink
            }
            target="_blank"
            className={getButtonClassName({ size: "lg" })}
            rel="noreferrer"
          >
            <AppDownloadIcon /> Download mobile app
          </a>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function ViewPassbookInformationInDialog({
  children,
}: {
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ open: state.open })}
      <Modal
        isOpen={state.isOpen}
        isDismissable
        onClose={state.close}
        title="Use mobile app to access Passbook"
      >
        <Alert status="info" paddingX="8">
          <Box>
            <Text color="textAlt2" fontSize="c2">
              Passbook automatically makes entries from your transactional SMSs.
            </Text>
          </Box>
        </Alert>
        <ModalBody>
          <Stack
            gap="8"
            marginBottom="10"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Text fontSize="s1">Scan QR to download or open CashBook App</Text>
            <Inline gap="16">
              <Stack
                gap="3"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                as="a"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.cashbooknew&hl=en_US"
              >
                <Box
                  size="24"
                  padding="2"
                  rounded="md"
                  borderWidth="1"
                  borderColor="textHigh"
                >
                  <img src={AndroidQr} alt="Android App" />
                </Box>
                <Inline gap="2" alignItems="center">
                  <Box>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.6666 7.9L16.2 5.25C16.3333 4.99167 16.2333 4.675 15.9833 4.54167C15.7416 4.41667 15.4416 4.49167 15.2916 4.725L13.725 7.425C11.3416 6.41667 8.65831 6.41667 6.27498 7.425L4.70831 4.725C4.54998 4.48333 4.22498 4.40833 3.98331 4.55833C3.74998 4.70833 3.67498 5.00833 3.79998 5.25L5.33331 7.9C2.74998 9.375 1.06665 12.0333 0.833313 15H19.1666C18.9333 12.0333 17.25 9.375 14.6666 7.9ZM5.83331 12.7083C5.25831 12.7083 4.79165 12.2417 4.79165 11.6667C4.79165 11.0917 5.25831 10.625 5.83331 10.625C6.40831 10.625 6.87498 11.0917 6.87498 11.6667C6.87498 12.2417 6.40831 12.7083 5.83331 12.7083ZM14.1666 12.7083C13.5916 12.7083 13.125 12.2417 13.125 11.6667C13.125 11.0917 13.5916 10.625 14.1666 10.625C14.7416 10.625 15.2083 11.0917 15.2083 11.6667C15.2083 12.2417 14.7416 12.7083 14.1666 12.7083Z"
                        fill="#71D98C"
                      />
                    </svg>
                  </Box>
                  <Text fontSize="b3">Android</Text>
                </Inline>
              </Stack>
            </Inline>
          </Stack>
        </ModalBody>
      </Modal>
    </>
  )
}
