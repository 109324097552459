import {
  dateToTimestamp,
  formatDate,
  getServerTimestamp,
  parseDate,
} from "@cashbook/util-dates"
import {
  arePhoneNumbersSame,
  isPhoneNumberIndian,
  isVisitorIndian,
  normalizeNumber,
  pluralize,
  RECORD_ENTRY_MIN_DATE,
} from "@cashbook/util-general"
import { logError } from "@cashbook/util-logging"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Alert,
  Button,
  ChevronDownIcon,
  ErrorMessage,
  FormField,
  formikOnSubmitWithErrorHandling,
  Modal,
  PencilIcon,
  Select,
  SpinnerIcon,
  Time,
  useOverlayTriggerState,
  Box,
  Stack,
  Heading,
  Inline,
  Text,
  ExcelVectorIcon,
  CBButton,
  ModalFooter,
  ArrowRightIcon,
  DocumentDownloadIcon,
  ModalBody,
  InformationWarningIcon,
  isPossiblePhoneNumber,
  parsePhoneNumber,
  InformationCircleFilledIcon,
  CheckCircleSolidIcon,
} from "@cashbook/web-components"
import { writeBatch, doc } from "firebase/firestore"
import { FieldProps, Form, Formik } from "formik"
import raf from "raf"
import { useEffect, useMemo, useRef, useState } from "react"
import toast from "react-hot-toast"
import { useFirestore, useUser } from "reactfire"
import { $PropertyType } from "utility-types"
import * as Validator from "yup"
import { Amount } from "../support/Intl"
import { LABEL_FOR_HEADERS } from "./Export"
import {
  TBook,
  useTransactionsCollection,
  LogTransactionDataSchema,
  useAddCategory,
  useAddPaymentMode,
  TBookEntryFields,
  T_AVAILABLE_PARTY_TYPES,
  useAddParty,
  usePartyOrContact,
} from "@cashbook/data-store/books"
import { MemberAvatar } from "../Books"
import { getCountries } from "react-phone-number-input"
import { User } from "firebase/auth"

export function ImportTransactionsInDialog({
  children,
  book,
}: {
  children: (props: { importTransactions: () => void }) => React.ReactNode
  book: TBook
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ importTransactions: state.open })}
      {state.isOpen ? (
        <Modal isOpen title="Import Data" onClose={state.close} size="lg">
          <ImportTransactions
            book={book}
            onSuccess={() => state.close()}
            onCancel={() => state.close()}
          />
        </Modal>
      ) : null}
    </>
  )
}

const ACCEPTABLE_HEADERS = {
  remark: LABEL_FOR_HEADERS.remark,
  date: LABEL_FOR_HEADERS.date,
  time: LABEL_FOR_HEADERS.date,
  debit: LABEL_FOR_HEADERS.debit,
  credit: LABEL_FOR_HEADERS.credit,
  category: LABEL_FOR_HEADERS.category,
  paymentMode: LABEL_FOR_HEADERS.paymentMode,
}

const ACCEPTABLE_HEADERS_FOR_PARTY = {
  partyName: "partyName",
  phoneNumber: "phoneNumber",
  partyType: "partyType",
  countryCode: "countryCode",
}

type T_CSV_ARRAY = Array<Array<string>>

type T_Transaction_Data = {
  remark: string | null | undefined
  type: "cash-in" | "cash-out"
  amount: number
  date: Date | undefined
  category: string | undefined | null
  paymentMode: string | undefined | null
}

type T_Valid_Transaction_Data = {
  remark: string | null
  type: "cash-in" | "cash-out"
  amount: number
  date: Date
  category: string | undefined | null
  paymentMode: string | undefined | null
}

type T_Invalid_Transaction_Data = {
  remark: string | null | undefined
  type: "cash-in" | "cash-out"
  amount: number | undefined
  date: Date | undefined
  category: string | undefined | null
  paymentMode: string | undefined | null
  rawData: {
    remark: string | undefined
    credit: string | undefined
    debit: string | undefined
    date: string | undefined
    time: string | undefined
    category: string | undefined
    paymentMode: string | undefined
  }
}

export function ImportTransactions({
  book,
  onSuccess,
  onCancel,
}: {
  book: TBook
  onSuccess: (totalTransactions: number) => void
  onCancel: () => void
}) {
  const [parsedCSVContent, setCsvContent] = useState<{
    header: Array<string>
    rows: T_CSV_ARRAY
  }>({ header: [], rows: [] })
  return !parsedCSVContent.rows.length ? (
    <SelectFile
      onCancel={(file?: File | null) => {
        trackEvent(TrackingEvents.IMPORT_ENTRIES_CANCELED, {
          from: "fileSelection",
          hasSelectedFile: Boolean(file),
        })
        onCancel()
      }}
      onChange={(header, rows) => {
        setCsvContent({ header, rows })
      }}
    />
  ) : (
    <Stack gap="4">
      <MapHeadingsAndImport
        header={parsedCSVContent.header}
        rows={parsedCSVContent.rows}
        book={book}
        onSuccess={(validTransactionsCount: number) => {
          toast.success(
            `${validTransactionsCount} ${
              validTransactionsCount > 1 ? "entries" : "entry"
            } imported`
          )
          onSuccess(validTransactionsCount)
        }}
        onCancel={() => {
          trackEvent(TrackingEvents.IMPORT_PARTIES_CANCELED, {
            from: "headersMapping",
            hasSelectedFile: true,
          })
          onCancel()
        }}
      />
      <hr />
      <Box>
        <Button onClick={() => setCsvContent({ header: [], rows: [] })}>
          <ChevronDownIcon rotate="90" /> Select Different File
        </Button>
      </Box>
    </Stack>
  )
}

function getSampleFilePath(
  type: "parties" | "transactions",
  isUserNonIndian?: boolean
): string {
  switch (type) {
    case "parties":
      return isUserNonIndian
        ? "/sample_import_parties_foreign.csv"
        : "/sample_import_parties_csv.csv"
    default:
      return "/sample_import_csv.csv"
  }
}

const initialState = { header: [], rows: [] }
export function ImportParties({
  book,
  onSuccess,
  onCancel,
}: {
  book: TBook
  onSuccess: (totalParties: number) => void
  onCancel: () => void
}) {
  const [parsedCSVContent, setCsvContent] = useState<{
    header: Array<string>
    rows: T_CSV_ARRAY
  }>(initialState)

  function selectDifferentFileHandler() {
    setCsvContent(initialState)
  }

  const { data: user } = useUser()

  const isNonIndianUser = user?.phoneNumber
    ? !isPhoneNumberIndian(user?.phoneNumber)
    : !isVisitorIndian()

  return !parsedCSVContent.rows.length ? (
    <SelectFile
      onCancel={(file?: File | null) => {
        trackEvent(TrackingEvents.IMPORT_PARTIES_CANCELED, {
          from: "fileSelection",
          hasSelectedFile: Boolean(file),
        })
        onCancel()
      }}
      type="parties"
      onChange={(header, rows) => {
        setCsvContent({ header, rows })
      }}
      isNonIndianUser={isNonIndianUser}
    />
  ) : (
    <Stack gap="4">
      <MapPartyHeadingAndImport
        book={book}
        user={user}
        rows={parsedCSVContent.rows}
        header={parsedCSVContent.header}
        onSuccess={(importedPartiesCount: number) => {
          onSuccess(importedPartiesCount)
        }}
        selectDifferentFileHandler={selectDifferentFileHandler}
      />
    </Stack>
  )
}

function SelectFile({
  type = "transactions",
  isNonIndianUser,
  onChange,
  onCancel,
}: {
  type?: "transactions" | "parties"
  isNonIndianUser?: boolean
  onChange: (header: T_CSV_ARRAY[number], rows: T_CSV_ARRAY) => void
  onCancel: (file?: File | null) => void
}) {
  const initialValues = useMemo(() => {
    return {
      fileName: "",
      file: null as File | null,
      isParsing: false,
      parsedContent: [] as T_CSV_ARRAY,
      headingRowNumber: 1,
      transactionsStartRowNumber: 2,
      transactionsEndRowNumber: 2,
    }
  }, [])
  const headerSettingChangeTriggerState = useOverlayTriggerState({})
  const VALID_MIME_TYPE = [
    "application/vnd.ms-excel",
    "text/plain",
    "text/csv",
    "text/tsv",
    "application/octet-stream",
  ]

  const sampleFilePaths = useMemo(() => {
    return getSampleFilePath(type, isNonIndianUser)
  }, [type, isNonIndianUser])

  const { partiesOrContacts } = usePartyOrContact()

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={Validator.object().shape({
        fileName: Validator.string().required("Please select a CSV file"),
        file: Validator.mixed()
          .nullable()
          .test(
            "has-valid-type",
            "Please select a valid CSV file",
            (value: File | null | undefined) => {
              return !value ? true : VALID_MIME_TYPE.indexOf(value.type) !== -1
            }
          ),
        headingRowNumber: Validator.number()
          .positive("Please provide a valid positive integer")
          .integer("Please provide a valid positive integer")
          .required("Please provide the heading row number.")
          .when(
            "parsedContent",
            (
              parsedContent: T_CSV_ARRAY | undefined,
              schema: Validator.NumberSchema
            ) => {
              if (parsedContent)
                return schema.max(
                  parsedContent.length,
                  "Heading should NOT be greater than total number of rows"
                )
              return schema
            }
          ),
        transactionsStartRowNumber: Validator.number()
          .positive("Please provide a valid positive integer")
          .integer("Please provide a valid positive integer")
          .required("Please provide the entry start row number.")
          .when(
            "headingRowNumber",
            (headingRowNumber: number, schema: Validator.NumberSchema) => {
              if (headingRowNumber) {
                return schema.min(
                  headingRowNumber + 1,
                  "Entry start row should NOT be lesser or equals to Heading row number"
                )
              }
              return schema
            }
          ),
        transactionsEndRowNumber: Validator.number()
          .positive("Please provide a valid positive integer")
          .integer("Please provide a valid positive integer")
          .required("Please provide the entry end row number.")
          .when(
            "parsedContent",
            (
              parsedContent: T_CSV_ARRAY | undefined,
              schema: Validator.NumberSchema
            ) => {
              if (parsedContent)
                return schema.max(
                  parsedContent.length,
                  "Entry end row should NOT be greater than total number of rows"
                )
              return schema
            }
          )
          .when(
            "transactionsStartRowNumber",
            (
              transactionsStartRowNumber: number,
              schema: Validator.NumberSchema
            ) => {
              if (transactionsStartRowNumber)
                return schema.min(
                  transactionsStartRowNumber,
                  "Entry end row should NOT be lesser than start row"
                )
              return schema
            }
          ),
      })}
      onSubmit={(values) => {
        const headers = values.parsedContent[values.headingRowNumber - 1]
        const rows = values.parsedContent.slice(
          values.transactionsStartRowNumber - 1,
          values.transactionsEndRowNumber
        )
        if (
          values.headingRowNumber >= values.transactionsStartRowNumber &&
          values.headingRowNumber <= values.transactionsEndRowNumber
        ) {
          // heading row is between the entries rows
          // remove it from the entries
          rows.splice(
            values.headingRowNumber - values.transactionsStartRowNumber,
            1
          )
        }
        trackEvent(
          type === "parties"
            ? TrackingEvents.IMPORT_PARTIES_FILE_SUBMITTED
            : TrackingEvents.IMPORT_ENTRIES_FILE_SUBMITTED,
          {
            headingRowNumber: values.headingRowNumber,
            entriesStartRowNumber: values.transactionsStartRowNumber,
            entriesEndRowNumber: values.transactionsEndRowNumber,
            totalRows: rows.length,
            headers: headers,
          }
        )
        onChange(headers, rows)
      }}
    >
      {({
        setFieldValue,
        values,
        setFieldError,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Form noValidate>
          <Stack gap="8">
            <Stack gap="4">
              <Box maxWidth="xs">
                <FormField
                  label="Select a CSV file"
                  name="fileName"
                  type="file"
                  accept=".csv, .xlsx"
                  required
                  autoFocus
                  onChange={({ currentTarget }) => {
                    const file = currentTarget?.files?.[0]
                    setFieldValue(currentTarget.name, currentTarget.value)
                    // NOTE: Somthing weird is going on with the fileName errors
                    // https://github.com/formium/formik/issues/1309
                    // - Even after setting the field value, it was showing required here
                    // This setTimeout is hack for now to re-run the validations
                    setTimeout(() => {
                      setFieldValue("parsedContent", [])
                      if (file) {
                        const isValidFileType =
                          VALID_MIME_TYPE.indexOf(file.type) !== -1
                        trackEvent(
                          type === "parties"
                            ? TrackingEvents.IMPORT_PARTIES_FILE_SELECTED
                            : TrackingEvents.IMPORT_ENTRIES_FILE_SELECTED,
                          {
                            fileType: file.type,
                            isValidFile: isValidFileType,
                          }
                        )
                        setFieldValue("file", file, true)
                        setFieldTouched("file", true)
                        if (!isValidFileType) {
                          return
                        }
                        setFieldValue("isParsing", true)
                        setTimeout(() => {
                          try {
                            const reader = new FileReader()
                            reader.onload = function (e) {
                              const contents = String(e?.target?.result)
                              csvToArray(contents, (array) => {
                                setFieldValue("parsedContent", array)
                                setFieldValue(
                                  "transactionsEndRowNumber",
                                  array.length
                                )
                              })
                                .then((array) => {
                                  setFieldValue("isParsing", false)
                                  if (array.length < 2) {
                                    setFieldError(
                                      currentTarget.name,
                                      "Unable to parse the file or the file has no valid rows."
                                    )
                                    return
                                  }
                                  setFieldValue("headingRowNumber", 1)
                                  setFieldValue("transactionsStartRowNumber", 2)
                                  setFieldValue(
                                    "transactionsEndRowNumber",
                                    array.length
                                  )
                                  setFieldValue("parsedContent", array)
                                })
                                .catch((e) => {
                                  setFieldError(
                                    currentTarget.name,
                                    String(
                                      e?.message ||
                                        "Unable to read the file. Please select a valid CSV file or contact our support."
                                    )
                                  )
                                  setFieldValue("isParsing", false)
                                })
                            }
                            reader.onerror = function () {
                              const error = reader.error
                              logError(
                                error ||
                                  new Error(
                                    "Unable to read the import csv file content"
                                  )
                              )
                              setFieldError(
                                currentTarget.name,
                                String(
                                  error?.message ||
                                    error ||
                                    "Unable to read the file. Please select a valid CSV file or contact our support."
                                )
                              )
                              setFieldValue("isParsing", false)
                              reader.abort()
                            }
                            reader.readAsText(file)
                          } catch (e) {
                            const error = e as Error
                            logError(
                              error || new Error("Import CSV parsing failed.")
                            )
                            setFieldError(
                              currentTarget.name,
                              error?.message ||
                                "Unable to read the file. Please select a valid CSV file or contact our support."
                            )
                            setFieldValue("isParsing", false)
                          }
                        }, 100)
                      } else {
                        setFieldError(
                          currentTarget.name,
                          "Please select a valid CSV file."
                        )
                      }
                    }, 100)
                  }}
                />
                <ErrorMessage name="file" />
              </Box>
              <Inline
                gap="8"
                rounded="md"
                paddingY="4"
                paddingX="6"
                alignItems="center"
                maxWidth="3xl"
                backgroundColor="surfaceNeutralLowest"
              >
                <Inline gap="4" alignItems="center" flex="1">
                  <Box>
                    <ExcelVectorIcon size="10" />
                  </Box>
                  <Stack gap="2">
                    <Text fontSize="s3">Download Sample File</Text>
                    <Text>
                      Your CSV file should have same columns as this sample file
                    </Text>
                  </Stack>
                </Inline>
                <Inline
                  alignItems="center"
                  paddingY="2"
                  gap="2"
                  paddingX="6"
                  as="a"
                  href={sampleFilePaths}
                  download
                  onClick={() => {
                    trackEvent(
                      type === "parties"
                        ? TrackingEvents.IMPORT_PARTIES_SAMPLE_DOWNLOAD_CLICKED
                        : TrackingEvents.IMPORT_ENTRIES_SAMPLE_DOWNLOAD_CLICKED
                    )
                  }}
                  color="textPrimary"
                >
                  <DocumentDownloadIcon />
                  <Text
                    fontSize="bt"
                    onClick={() => {
                      trackEvent(
                        type === "parties"
                          ? TrackingEvents.IMPORT_PARTIES_SAMPLE_DOWNLOAD_CLICKED
                          : TrackingEvents.IMPORT_ENTRIES_SAMPLE_DOWNLOAD_CLICKED
                      )
                    }}
                  >
                    Download CSV
                  </Text>
                </Inline>
              </Inline>
            </Stack>
            {values.parsedContent.length ? (
              <Stack as="section" gap="4">
                <Stack as="header">
                  <Heading as="h4" fontSize="2xl">
                    Parsed Data
                  </Heading>
                  <Text fontSize="sm" color="gray500">
                    Here is parsed data from your uploaded file. Please verify
                    and click on &ldquo;Next&rdquo; to proceed
                  </Text>
                </Stack>
                {headerSettingChangeTriggerState.isOpen && !values.isParsing ? (
                  <Inline gap="8">
                    <Box width="1/3">
                      <FormField
                        name="headingRowNumber"
                        label="Heading Row"
                        min={1}
                        max={values.parsedContent.length}
                        type="number"
                        placeholder="e.g. 1"
                        help="This row will be considered as the heading row"
                      />
                    </Box>
                    <Box width="1/3">
                      <FormField
                        name="transactionsStartRowNumber"
                        label="Entries Start Row"
                        min={1}
                        max={values.transactionsEndRowNumber}
                        type="number"
                        placeholder="e.g. 2"
                        help="This row will be considered as starting row for entries"
                      />
                    </Box>
                    <Box width="1/3">
                      <FormField
                        name="transactionsEndRowNumber"
                        label="Entries End Row"
                        min={values.transactionsStartRowNumber}
                        max={values.parsedContent.length}
                        type="number"
                        placeholder={`e.g. ${values.parsedContent.length.toString()}`}
                        help="This row will be considered as last row of entries"
                      />
                    </Box>
                  </Inline>
                ) : (
                  <Inline gap="8" alignItems="center">
                    <Text>Heading Row: {values.headingRowNumber}</Text>
                    <Text>
                      Entry Start Row: {values.transactionsStartRowNumber}
                    </Text>
                    <Text>
                      Entry End Row: {values.transactionsEndRowNumber}
                    </Text>
                    {values.isParsing ? (
                      <SpinnerIcon />
                    ) : (
                      <Box>
                        <Button
                          sm
                          inline
                          onClick={() => headerSettingChangeTriggerState.open()}
                        >
                          <PencilIcon size="3" /> Edit
                        </Button>
                      </Box>
                    )}
                  </Inline>
                )}
                <Box
                  maxWidth="full"
                  overflow="auto"
                  borderWidth="1"
                  paddingBottom="4"
                  style={{ maxHeight: "300px" }}
                >
                  <Box as="table" width="full" className="whitespace-pre">
                    <Box as="tbody">
                      {values.parsedContent.map((row, i) => {
                        const rowNo = i + 1
                        return (
                          <Box as="tr" key={i} borderTopWidth="1">
                            <Box
                              as="td"
                              paddingX="2"
                              paddingY="px"
                              bgColor="gray100"
                              position="sticky"
                              left="0"
                              top={
                                rowNo === values.headingRowNumber
                                  ? "0"
                                  : undefined
                              }
                              zIndex={
                                rowNo === values.headingRowNumber
                                  ? "10"
                                  : undefined
                              }
                            >
                              {rowNo}
                            </Box>
                            {row.map((c, i) => (
                              <Box
                                as="td"
                                key={i}
                                paddingX="2"
                                paddingY="px"
                                bgColor={
                                  rowNo === values.headingRowNumber
                                    ? "gray100"
                                    : undefined
                                }
                                position={
                                  rowNo === values.headingRowNumber
                                    ? "sticky"
                                    : undefined
                                }
                                top={
                                  rowNo === values.headingRowNumber
                                    ? "0"
                                    : undefined
                                }
                              >
                                <Text
                                  fontWeight={
                                    rowNo === values.headingRowNumber
                                      ? "medium"
                                      : "normal"
                                  }
                                  color={
                                    rowNo !== values.headingRowNumber &&
                                    (rowNo <
                                      values.transactionsStartRowNumber ||
                                      rowNo > values.transactionsEndRowNumber)
                                      ? "gray100"
                                      : undefined
                                  }
                                >
                                  {c}
                                </Text>
                              </Box>
                            ))}
                          </Box>
                        )
                      })}
                    </Box>
                  </Box>
                </Box>
              </Stack>
            ) : values.isParsing ? (
              <Box textAlign="center">
                <SpinnerIcon /> Reading File. Please wait...
              </Box>
            ) : null}
            <ModalFooter>
              <Inline gap="6" flexDirection="rowReverse">
                {type === "parties" ? (
                  <CBButton
                    size="lg"
                    type="submit"
                    iconPlacement="right"
                    loading={isSubmitting || values.isParsing}
                  >
                    {values.isParsing ? (
                      <Text>Reading File. Please wait...</Text>
                    ) : (
                      <>
                        Next: Review {partiesOrContacts}
                        <ArrowRightIcon />
                      </>
                    )}
                  </CBButton>
                ) : (
                  <CBButton
                    type="submit"
                    loading={isSubmitting || values.isParsing}
                    size="lg"
                    level="primary"
                    iconPlacement="right"
                  >
                    {values.isParsing ? (
                      <Text>Reading File. Please wait...</Text>
                    ) : (
                      <>
                        Next: Select Header and Preview Entries
                        <ArrowRightIcon />
                      </>
                    )}
                  </CBButton>
                )}
                <CBButton
                  size="lg"
                  loading={values.isParsing}
                  onClick={() => {
                    onCancel(values.file)
                  }}
                >
                  Cancel
                </CBButton>
              </Inline>
            </ModalFooter>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

const VALID_DATE_FORMATS = [
  "dd MMM yyyy",
  "dd-MMM-yyyy",
  "dd/MM/yyyy",
  "dd-MM-yyyy",
  "MM/dd/yyyy",
  "MM-dd-yyyy",
  "yyyy-MM-dd",
]

const VALID_TIME_FORMATS = ["hh:mm a", "HH:mm:ss"]

function MapHeadingsAndImport({
  header,
  rows,
  book,
  onSuccess,
  onCancel,
}: {
  header: T_CSV_ARRAY[number]
  rows: T_CSV_ARRAY
  book: TBook
  onSuccess: (transactionsCount: number) => void
  onCancel: () => void
}) {
  const initialValues = useMemo(() => {
    return {
      mappings: [
        {
          header: "date",
          mappedTo: findBestMatchFromHeadersForKey(header, "date") || "",
          label: "Date",
        },
        {
          header: "remark",
          mappedTo: findBestMatchFromHeadersForKey(header, "remark") || "",
          label: "Remark",
        },
        {
          header: "credit",
          mappedTo: findBestMatchFromHeadersForKey(header, "credit") || "",
          label: "Cash In",
        },
        {
          header: "debit",
          mappedTo: findBestMatchFromHeadersForKey(header, "debit") || "",
          label: "Cash Out",
        },
        {
          header: "time",
          mappedTo: findBestMatchFromHeadersForKey(header, "time") || "",
          label: "Time",
        },
        {
          header: "category",
          mappedTo: findBestMatchFromHeadersForKey(header, "category") || "",
          label: "Category",
        },
        {
          header: "paymentMode",
          mappedTo: findBestMatchFromHeadersForKey(header, "paymentMode") || "",
          label: "Payment Mode",
        },
      ] as Array<{
        header: keyof typeof ACCEPTABLE_HEADERS
        mappedTo: typeof header[number]
        label: string
      }>,
      dateFormat: "dd MMM yyyy",
      timeFormat: "hh:mm a",
    }
  }, [header])
  const store = useFirestore()
  const { data: user } = useUser()
  const bookTransactionCollection = useTransactionsCollection(book.id)
  const addCategory = useAddCategory(book, "importEntries")
  const addPaymentMode = useAddPaymentMode(book, "importEntries")
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
        const headersMapping = values.mappings.reduce((headerMapping, map) => {
          if (map.mappedTo) {
            headerMapping[map.mappedTo] = map.header
          }
          return headerMapping
        }, {} as { [key: string]: keyof typeof ACCEPTABLE_HEADERS })

        const {
          categories: existingCategories,
          paymentModes: existingPaymentModes,
          preferences,
        } = book

        const existingPaymentModesByName = (existingPaymentModes || []).reduce<{
          [key: string]: TBookEntryFields[number]
        }>((byName, m) => {
          byName[m.name] = m
          return byName
        }, {})
        const existingCategoriesByName = (existingCategories || []).reduce<{
          [key: string]: TBookEntryFields[number]
        }>((byName, m) => {
          byName[m.name] = m
          return byName
        }, {})

        // add the new payment modes and categories
        const newPaymentModeNamesIndexed: { [key: string]: string } = {}
        const newCategoryNamesIndexed: { [key: string]: string } = {}

        const [validTransactions, invalidTransactions] =
          await mapCsvArrayToTransactions(
            header,
            rows,
            headersMapping,
            {
              dateFormat: values.dateFormat,
              timeFormat: values.timeFormat,
            },
            (_, { validTransactions }) => {
              // get the new categories and payment modes
              validTransactions.forEach((t) => {
                if (t.category && !preferences?.categoriesDisabled) {
                  if (!existingCategoriesByName[t.category]) {
                    newCategoryNamesIndexed[t.category] = t.category
                  }
                }
                if (t.paymentMode && !preferences?.paymentModesDisabled) {
                  if (!existingPaymentModesByName[t.paymentMode]) {
                    newPaymentModeNamesIndexed[t.paymentMode] = t.paymentMode
                  }
                }
              })
            }
          )

        if (!validTransactions.length) {
          throw new Error(
            "There are no valid entries to import!. Please click `Invalid Entries` tab to view invalid entries"
          )
        }
        if (invalidTransactions.length) {
          if (
            !window.confirm(
              `There ${
                invalidTransactions.length > 1
                  ? `are ${invalidTransactions.length} invalid entries.`
                  : `is ${invalidTransactions.length} invalid entry`
              } which will NOT be imported. Are you sure you want to continue ?`
            )
          ) {
            return
          }
        }

        const isCategoryRequired =
          Boolean(!preferences?.categoriesDisabled) &&
          Boolean(preferences?.categoriesRequired)
        const isPaymentModeRequired =
          Boolean(!preferences?.paymentModesDisabled) &&
          Boolean(preferences?.paymentModesRequired)
        let isCategoryMissing = false
        let isPaymentModesMissing = false

        validTransactions.forEach((transaction) => {
          if (isCategoryRequired && !transaction.category) {
            isCategoryMissing = true
          }
          if (isPaymentModeRequired && !transaction.paymentMode) {
            isPaymentModesMissing = true
          }
        })

        const isMandatoryFieldMissing =
          isCategoryMissing || isPaymentModesMissing

        let mandatoryFieldsError = ""
        if (isCategoryRequired && isPaymentModeRequired) {
          mandatoryFieldsError = isMandatoryFieldMissing
            ? "Payment mode and category are mandatory fields. Please complete your entries by adding them to your csv file"
            : ""
        } else if (isCategoryRequired && !isPaymentModeRequired) {
          mandatoryFieldsError = isMandatoryFieldMissing
            ? "Category is a mandatory field. Please complete your entries by adding it to your csv file"
            : ""
        } else if (!isCategoryRequired && isPaymentModeRequired) {
          mandatoryFieldsError = isMandatoryFieldMissing
            ? "Payment mode is a mandatory field. Please complete your entries by adding it to your csv file"
            : ""
        }

        if (mandatoryFieldsError) {
          throw new Error(mandatoryFieldsError)
        }

        const newCategoryNames = Object.keys(newCategoryNamesIndexed)
        const newPaymentModeNames = Object.keys(newPaymentModeNamesIndexed)

        if (newCategoryNames.length || newPaymentModeNames.length) {
          if (
            !window.confirm(`Following new ${[
              newCategoryNames.length
                ? pluralize("Category", newCategoryNames.length)
                : "",
              newPaymentModeNames.length
                ? pluralize("Payment Mode", newPaymentModeNames.length)
                : "",
            ]
              .filter(Boolean)
              .join(" and ")} will be added to the book:

${[
  newCategoryNames.length
    ? `${pluralize(
        "Category",
        newCategoryNames.length
      )}: ${newCategoryNames.join(", ")}`
    : "",
  newPaymentModeNames.length
    ? `Payment ${pluralize(
        "Mode",
        newPaymentModeNames.length
      )}: ${newPaymentModeNames.join(", ")}`
    : "",
]
  .filter(Boolean)
  .join("\n\n")}

Please confirm to continue
`)
          ) {
            return
          }
        }

        const [categories, paymentModes] = await Promise.all([
          preferences?.categoriesDisabled || !newCategoryNames.length
            ? Promise.resolve([])
            : toast.promise(
                Promise.all(
                  newCategoryNames.map((mode) => addCategory({ name: mode }))
                ),
                {
                  loading: `Adding ${newCategoryNames.length} ${pluralize(
                    "category",
                    newCategoryNames.length
                  )}`,
                  success: "Categories added",
                  error: "Could not add categories.",
                }
              ),
          preferences?.paymentModesDisabled || !newPaymentModeNames.length
            ? Promise.resolve([])
            : toast.promise(
                Promise.all(
                  newPaymentModeNames.map((mode) =>
                    addPaymentMode({ name: mode })
                  )
                ),
                {
                  loading: `Adding ${
                    newPaymentModeNames.length
                  } payment ${pluralize("mode", newPaymentModeNames.length)}`,
                  success: "Payment modes added",
                  error: "Cound not add payment modes.",
                }
              ),
        ])

        const paymentModesByName = paymentModes
          .concat(existingPaymentModes || [])
          .reduce<{
            [key: string]: TBookEntryFields[number]
          }>((byName, m) => {
            byName[m.name] = m
            return byName
          }, {})
        const categoriesByName = categories
          .concat(existingCategories || [])
          .reduce<{
            [key: string]: TBookEntryFields[number]
          }>((byName, m) => {
            byName[m.name] = m
            return byName
          }, {})

        // update the entries with these fields

        // create multiple batches for import
        // Maximum 500 writes allowed per commit
        // https://firebase.google.com/docs/firestore/quotas#writes_and_transactions
        const batches = [writeBatch(store)]
        let documentOperationsCounts = 0
        validTransactions.forEach(function (t) {
          const data: Omit<LogTransactionDataSchema, "usingCalculator"> = {
            date: t.date,
            remark: t.remark || null,
            amount: t.amount,
            type: t.type,
            imageUrl: null,
            thumbUrl: null,
          }
          if (t.category) {
            data.categoryId = categoriesByName[t.category]?.uuid || null
          }
          if (t.paymentMode) {
            data.paymentModeId = paymentModesByName[t.paymentMode]?.uuid || null
          }
          const operationsInThisTransaction = 1
          if (documentOperationsCounts + operationsInThisTransaction > 500) {
            batches.push(writeBatch(store))
            documentOperationsCounts = 0
          }
          documentOperationsCounts += operationsInThisTransaction
          batches[batches.length - 1].set(doc(bookTransactionCollection), {
            ...data,
            date: dateToTimestamp(data.date),
            createdAt: getServerTimestamp(),
            cashBookId: book.id,
            createdBy: user?.uid,
          } as never)
        })
        const trackingHeadersMapping = values.mappings.reduce(
          (headerMapping, map) => {
            if (map.mappedTo) {
              headerMapping[map.header] = map.mappedTo
            }
            return headerMapping
          },
          {} as { [key in keyof typeof ACCEPTABLE_HEADERS]: string }
        )
        const dataToTrack = {
          totalEntries: rows.length,
          totalValidEntries: validTransactions.length,
          totalInvalidEntries: invalidTransactions.length,
          dateFormat: values.dateFormat,
          timeFormat: values.timeFormat,
          remarkMappedTo: trackingHeadersMapping.remark,
          dateMappedTo: trackingHeadersMapping.date,
          timeMappedTo: trackingHeadersMapping.time,
          cashinMappedTo: trackingHeadersMapping.credit,
          cashoutMappedTo: trackingHeadersMapping.debit,
          totalBatches: batches.length,
        }
        trackEvent(TrackingEvents.IMPORT_ENTRIES_BATCH_STARTED, dataToTrack)
        await Promise.all(batches.map((batch) => batch.commit()))
        trackEvent(TrackingEvents.IMPORT_ENTRIES_BATCH_FINISHED, dataToTrack)
        onSuccess(validTransactions.length)
      })}
    >
      {({ values, status, isSubmitting }) => (
        <Form noValidate>
          <Stack gap="8">
            <Stack gap="12">
              <Stack gap="4">
                <Stack as="header" gap="1">
                  <Heading as="h4" fontSize="2xl">
                    Match heading
                  </Heading>
                  <Text color="gray500">
                    Please match headings from your CSV file to entries data and
                    select date formats.
                  </Text>
                </Stack>
                <Box maxWidth="full" overflow="auto" position="relative">
                  <Box as="table" width="full">
                    <Box as="tbody">
                      <Box as="tr" bgColor="blue100">
                        <Box
                          as="th"
                          bgColor="blue100"
                          padding="2"
                          borderWidth="1"
                          borderColor="gray500"
                          position="sticky"
                          left="0"
                          zIndex="10"
                          className="whitespace-pre"
                        >
                          Our Heading
                        </Box>
                        {values.mappings.map((map) => (
                          <Box
                            as="td"
                            key={map.header}
                            padding="2"
                            borderTopWidth="1"
                            borderRightWidth="1"
                          >
                            {map.label}
                          </Box>
                        ))}
                      </Box>
                      <Box as="tr">
                        <Box
                          as="th"
                          bgColor="blue100"
                          padding="2"
                          borderWidth="1"
                          borderColor="gray500"
                          position="sticky"
                          left="0"
                          zIndex="10"
                          className="align-top whitespace-pre"
                        >
                          Your Heading
                        </Box>
                        {values.mappings.map((map, index) => (
                          <Box
                            as="td"
                            key={map.header}
                            padding="2"
                            borderTopWidth="1"
                            borderRightWidth="1"
                            borderBottomWidth="1"
                          >
                            <Stack gap="2">
                              <FormField
                                name={`mappings.${index}.mappedTo`}
                                renderInput={({
                                  field,
                                }: FieldProps<string>) => (
                                  <Select {...field}>
                                    <option value="">Select...</option>
                                    {header.map((h) => (
                                      <option key={h} value={h}>
                                        {h}
                                      </option>
                                    ))}
                                  </Select>
                                )}
                              />
                              {map.header === "date" ? (
                                map.mappedTo ? (
                                  <FormField
                                    label="Format"
                                    noMargin
                                    name={`dateFormat`}
                                    renderInput={({
                                      field,
                                    }: FieldProps<string>) => (
                                      <Select {...field}>
                                        <option value="">Select...</option>
                                        {VALID_DATE_FORMATS.map((format) => (
                                          <option
                                            value={format}
                                            key={format}
                                            title={format}
                                          >
                                            {formatDate(new Date(), format)}
                                          </option>
                                        ))}
                                      </Select>
                                    )}
                                  />
                                ) : null
                              ) : map.header === "time" ? (
                                map.mappedTo ? (
                                  <FormField
                                    label="Format"
                                    noMargin
                                    name={`timeFormat`}
                                    renderInput={({
                                      field,
                                    }: FieldProps<string>) => (
                                      <Select {...field}>
                                        <option value="">Current Time</option>
                                        {VALID_TIME_FORMATS.map((format) => (
                                          <option
                                            value={format}
                                            key={format}
                                            title={format}
                                          >
                                            {formatDate(new Date(), format)}
                                          </option>
                                        ))}
                                      </Select>
                                    )}
                                  />
                                ) : null
                              ) : null}
                            </Stack>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Stack>
              <Stack gap="4">
                <Stack as="header" gap="1">
                  <Heading as="h4" fontSize="xl">
                    Preview Entries
                  </Heading>
                  <Text color="gray500" fontSize="sm">
                    Please review entries before importing
                  </Text>
                </Stack>
                <PreviewTransactions
                  headers={header}
                  rows={rows}
                  headerMapping={values.mappings}
                  dateFormat={values.dateFormat}
                  timeFormat={values.timeFormat}
                />
              </Stack>
            </Stack>
            {status ? <Alert status="error">{status}</Alert> : null}
            <Inline gap="8">
              <Button type="submit" disabled={isSubmitting} size="lg">
                {isSubmitting ? "Importing..." : "Import Valid Entries"}
              </Button>
              <Button
                onClick={onCancel}
                disabled={isSubmitting}
                size="lg"
                level="tertiary"
              >
                Cancel
              </Button>
            </Inline>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

function MapPartyHeadingAndImport({
  book,
  header,
  rows,
  user,
  onSuccess,
  selectDifferentFileHandler,
}: {
  book: TBook
  header: T_CSV_ARRAY[number]
  rows: T_CSV_ARRAY
  user: User | null
  onSuccess: (validTransactions: number) => void
  selectDifferentFileHandler: () => void
}) {
  const { partyOrContact, partiesOrContacts } = usePartyOrContact()

  const parsedDefaultCountryCode = useMemo(() => {
    if (user?.phoneNumber) {
      return parsePhoneNumber(user?.phoneNumber)?.country
    }
  }, [user?.phoneNumber])

  const [apiCalled, setApiCalled] = useState<boolean>(false)
  const [partiesImportedFailed, setPartiesImportedFailed] = useState<
    Array<T_Valid_Parties_Data & { reason?: string }>
  >([])

  const initialValues = useMemo(() => {
    return {
      countryCode: parsedDefaultCountryCode,
      mappings: [
        {
          header: "partyName",
          mappedTo:
            findBestMatchFromPartyHeadersForKey(header, "partyName") || "",
          label: `${partyOrContact} Name`,
        },
        {
          header: "phoneNumber",
          mappedTo:
            findBestMatchFromPartyHeadersForKey(header, "phoneNumber") || "",
          label: "Phone Number",
        },
        {
          header: "partyType",
          mappedTo:
            findBestMatchFromPartyHeadersForKey(header, "partyType") || "",
          label: `${partyOrContact} Type`,
        },
      ] as Array<{
        header: keyof typeof ACCEPTABLE_HEADERS_FOR_PARTY
        mappedTo: typeof header[number]
        label: string
      }>,
    }
  }, [header, parsedDefaultCountryCode, partyOrContact])
  const addParty = useAddParty(book, "importParties")
  const { preferences, sharedWith } = book

  return (
    <Formik
      initialValues={{
        ...initialValues,
        validParties: undefined as T_Valid_Parties_Data[] | undefined,
      }}
      onSubmit={formikOnSubmitWithErrorHandling(
        async ({ validParties }, { setStatus }) => {
          if (preferences?.partyDisabled || !validParties?.length) return
          setApiCalled(true)
          trackEvent(TrackingEvents.IMPORT_PARTIES_STARTED)
          await Promise.allSettled(validParties.map((party) => addParty(party)))
            .then((responses) => {
              const partiesStats = responses
                .map((party, i) => {
                  return {
                    name: validParties[i].name,
                    phoneNumber: validParties[i].phoneNumber,
                    type: validParties[i].type,
                    reason:
                      party.status === "rejected"
                        ? new Error(party.reason).message.replace(
                            "FirebaseError:",
                            ""
                          )
                        : undefined,
                  }
                })
                .filter((party) => party.reason?.length)
              setPartiesImportedFailed(partiesStats)
              trackEvent(TrackingEvents.IMPORT_PARTIES_SUCCEEDED, {
                sharedBook: sharedWith.length > 1,
                totalParties: responses.length,
                failedParties: partiesStats.length,
              })
            })
            .catch((e) => {
              const err = e as Error
              setStatus(err.message)
            })
        }
      )}
    >
      {({ values, isSubmitting, status, setFieldValue, submitForm }) => (
        <Form noValidate>
          <Stack gap="8">
            <Stack gap="12">
              <Stack gap="4">
                <Stack as="header" gap="1">
                  <Heading as="h4" fontSize="2xl">
                    Match Column Heading
                  </Heading>
                  <Text color="gray500">
                    Please match headings from your CSV file to{" "}
                    {partiesOrContacts} data
                  </Text>
                </Stack>
                <Box maxWidth="full" overflow="auto" position="relative">
                  <Box as="table" width="full">
                    <Box as="tbody">
                      <Box as="tr" bgColor="blue100">
                        <Box
                          as="th"
                          bgColor="blue100"
                          padding="2"
                          borderWidth="1"
                          borderColor="gray500"
                          position="sticky"
                          left="0"
                          zIndex="10"
                          className="whitespace-pre"
                        >
                          Our Heading
                        </Box>
                        {values.mappings.map((map) => (
                          <Box
                            as="td"
                            key={map.header}
                            padding="2"
                            borderTopWidth="1"
                            borderRightWidth="1"
                          >
                            {map.label}
                          </Box>
                        ))}
                      </Box>
                      <Box as="tr">
                        <Box
                          as="th"
                          bgColor="blue100"
                          padding="2"
                          borderWidth="1"
                          borderColor="gray500"
                          position="sticky"
                          left="0"
                          zIndex="10"
                          className="align-top whitespace-pre"
                        >
                          Your Heading
                        </Box>
                        {values.mappings.map((map, index) => (
                          <Box
                            as="td"
                            key={map.header}
                            padding="2"
                            borderTopWidth="1"
                            borderRightWidth="1"
                            borderBottomWidth="1"
                          >
                            <Stack gap="2">
                              <FormField
                                name={`mappings.${index}.mappedTo`}
                                renderInput={({
                                  field,
                                }: FieldProps<string>) => (
                                  <Select {...field}>
                                    <option value="">Select...</option>
                                    {header.map((h) => (
                                      <option key={h} value={h}>
                                        {h}
                                      </option>
                                    ))}
                                  </Select>
                                )}
                              />
                              {map.header === "phoneNumber" ? (
                                <FormField
                                  label="County Code"
                                  noMargin
                                  name={`countryCode`}
                                  renderInput={({
                                    field,
                                  }: FieldProps<string>) => (
                                    <Select
                                      {...field}
                                      value={values.countryCode}
                                    >
                                      {getCountries().map((format) => (
                                        <option
                                          value={format}
                                          key={format}
                                          title={format}
                                        >
                                          {format}
                                        </option>
                                      ))}
                                    </Select>
                                  )}
                                />
                              ) : null}
                            </Stack>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Stack>
              <Stack gap="4">
                <Stack as="header" gap="1">
                  <Heading as="h4" fontSize="xl">
                    Preview {partiesOrContacts}
                  </Heading>
                  <Text color="gray500" fontSize="sm">
                    Please review {partiesOrContacts} before importing
                  </Text>
                </Stack>
                <PreviewParties
                  book={book}
                  headers={header}
                  rows={rows}
                  headerMapping={values.mappings}
                  isSubmittingParties={isSubmitting}
                  error={status}
                  country={values.countryCode}
                  onSubmit={(values) => {
                    setFieldValue("validParties", values)
                    submitForm()
                  }}
                  apiCalled={apiCalled}
                  partiesImportedFailed={partiesImportedFailed}
                  selectDifferentFile={selectDifferentFileHandler}
                  onSuccess={() => {
                    onSuccess(values.validParties?.length || 0)
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

function PreviewTransactions({
  headers,
  rows,
  headerMapping,
  dateFormat,
  timeFormat,
}: {
  headers: T_CSV_ARRAY[number]
  rows: T_CSV_ARRAY
  headerMapping: Array<{
    header: keyof typeof ACCEPTABLE_HEADERS
    mappedTo: string
  }>
  dateFormat: string
  timeFormat?: string
}) {
  const headerForColumnName = useMemo(
    () =>
      headerMapping.reduce<{
        [key: string]: keyof typeof ACCEPTABLE_HEADERS
      }>((headerMapping, map) => {
        if (map.mappedTo) {
          headerMapping[map.mappedTo] = map.header
        }
        return headerMapping
      }, {}),
    [headerMapping]
  )
  const [
    { validTransactions, invalidTransactions, validated },
    setTransactions,
  ] = useState<{
    validTransactions: Array<T_Valid_Transaction_Data>
    invalidTransactions: Array<T_Invalid_Transaction_Data>
    validated: boolean
  }>({ validTransactions: [], invalidTransactions: [], validated: false })
  useEffect(() => {
    mapCsvArrayToTransactions(
      headers,
      rows,
      headerForColumnName,
      { dateFormat, timeFormat },
      ({ validTransactions, invalidTransactions }, data) => {
        setTransactions({
          validTransactions,
          invalidTransactions,
          validated: false,
        })
      }
    ).then(() => {
      setTransactions((state) => ({
        ...state,
        validated: true,
      }))
    })
  }, [headerForColumnName, headers, rows, dateFormat, timeFormat])
  const invalidTransactionsVisibilityToggler = useOverlayTriggerState({})
  return (
    <Stack>
      <Stack>
        <Inline
          gap="8"
          fontWeight="medium"
          fontSize="sm"
          borderBottomWidth="1"
          position="relative"
        >
          <Box
            as="a"
            href="#view-valid-entries"
            onClick={invalidTransactionsVisibilityToggler.close}
            position="relative"
            zIndex="10"
            paddingX="4"
            paddingY="3"
            borderLeftWidth="1"
            borderRightWidth="1"
            borderTopWidth="1"
            display="inlineBlock"
            className="-mb-px"
            bgColor={
              !invalidTransactionsVisibilityToggler.isOpen
                ? "backgroundLight3"
                : "transparent"
            }
            color={
              !invalidTransactionsVisibilityToggler.isOpen
                ? "textPrimary"
                : "textHigh"
            }
            borderColor={
              invalidTransactionsVisibilityToggler.isOpen ? "white" : undefined
            }
          >
            <Text>Valid Entries: {validTransactions.length}</Text>
          </Box>
          <Box
            as="a"
            href="#view-invalid-entries"
            onClick={invalidTransactionsVisibilityToggler.open}
            position="relative"
            zIndex="10"
            paddingX="4"
            paddingY="3"
            borderLeftWidth="1"
            borderRightWidth="1"
            borderTopWidth="1"
            display="inlineBlock"
            className="-mb-px"
            color={
              invalidTransactionsVisibilityToggler.isOpen
                ? "textPrimary"
                : "textHigh"
            }
            bgColor={
              invalidTransactionsVisibilityToggler.isOpen
                ? "backgroundLight3"
                : undefined
            }
            borderColor={
              !invalidTransactionsVisibilityToggler.isOpen ? "white" : undefined
            }
          >
            <Text>Invalid Entries: {invalidTransactions.length}</Text>
          </Box>
          {!validated ? (
            <Box>
              <SpinnerIcon />
            </Box>
          ) : null}
        </Inline>
      </Stack>
      <Stack
        maxWidth="full"
        overflow="auto"
        position="relative"
        borderLeftWidth="1"
        borderRightWidth="1"
        borderBottomWidth="1"
        paddingBottom="8"
        paddingTop="4"
        style={{ maxHeight: "300px" }}
      >
        <Box as="table" width="full">
          <Box as="thead">
            <Box as="tr">
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
              >
                #
              </Box>
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
              >
                Date
              </Box>
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
              >
                Time
              </Box>
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
              >
                Remark
              </Box>
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
              >
                Category
              </Box>
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
              >
                Mode
              </Box>
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
                textAlign="right"
              >
                Cash In
              </Box>
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
                textAlign="right"
              >
                Cash Out
              </Box>
            </Box>
          </Box>
          <Box
            as="tbody"
            display={
              invalidTransactionsVisibilityToggler.isOpen ? "none" : undefined
            }
          >
            {validTransactions.length === 0 ? (
              <Box as="tr">
                <Box as="td" colSpan={5} paddingY="8" textAlign="center">
                  <Stack gap="4">
                    <Text>No valid entries.</Text>
                    {invalidTransactions.length ? (
                      <Box
                        as="a"
                        display="block"
                        href="#view-invalid-entries"
                        onClick={(e: React.SyntheticEvent) => {
                          e.preventDefault()
                          invalidTransactionsVisibilityToggler.open()
                        }}
                      >
                        <Text color="red900">
                          There{" "}
                          {invalidTransactions.length > 1
                            ? `are
                ${invalidTransactions.length} invalid entries`
                            : `is ${invalidTransactions.length} invalid entry.`}
                          . Click to view them.
                        </Text>
                      </Box>
                    ) : null}
                  </Stack>
                </Box>
              </Box>
            ) : (
              validTransactions.map((t, i) => (
                <Box as="tr" key={i} borderTopWidth="1">
                  <Box as="td" padding="2">
                    {i + 1}
                  </Box>
                  <Box as="td" padding="2">
                    <Time date={t.date} format="dd MMM yyyy" />
                  </Box>
                  <Box as="td" padding="2">
                    <Time date={t.date} format="hh:mm a" />
                  </Box>
                  <Box as="td" padding="2">
                    {t.remark || null}
                  </Box>
                  <Box as="td" padding="2">
                    {t.category || null}
                  </Box>
                  <Box as="td" padding="2">
                    {t.paymentMode || null}
                  </Box>
                  <Box as="td" textAlign="right" padding="2">
                    {t.type === "cash-in" ? (
                      <Amount color="green900" amount={t.amount} />
                    ) : null}
                  </Box>
                  <Box as="td" textAlign="right" padding="2">
                    {t.type === "cash-out" ? (
                      <Amount color="red900" amount={t.amount} />
                    ) : null}
                  </Box>
                </Box>
              ))
            )}
          </Box>
          <Box
            as="tbody"
            display={
              !invalidTransactionsVisibilityToggler.isOpen ? "none" : undefined
            }
          >
            {invalidTransactions.length === 0 ? (
              <Box as="tr">
                <Box as="td" colSpan={5} textAlign="center" paddingY="8">
                  <Text>There are no invalid entries.</Text>
                </Box>
              </Box>
            ) : (
              invalidTransactions.map((t, i) => (
                <Box as="tr" borderTopWidth="1" key={i}>
                  <Box as="td" padding="2">
                    {i + 1}
                  </Box>
                  <Box as="td" padding="2">
                    {t.date && isEntryDateAfterMinDate(t.date) ? (
                      <Time date={t.date} format="dd MMM yyyy" />
                    ) : (
                      <Stack>
                        <Text fontSize="sm">{t.rawData.date}</Text>
                        <Text color="red900" fontSize="xs">
                          {t.date && !isEntryDateAfterMinDate(t.date)
                            ? `Entry date must be on or after ${formatDate(
                                RECORD_ENTRY_MIN_DATE,
                                "dd MMM yyyy"
                              )}`
                            : `Invalid Format for ${dateFormat}`}
                        </Text>
                      </Stack>
                    )}
                  </Box>
                  <Box as="td" padding="2">
                    {t.date ? (
                      <Time date={t.date} format="hh:mm a" />
                    ) : (
                      <Text fontSize="sm">{t.rawData.time}</Text>
                    )}
                  </Box>
                  <Box as="td" padding="2">
                    {t.remark || null}
                  </Box>
                  <Box as="td" padding="2">
                    {t.category || null}
                  </Box>
                  <Box as="td" padding="2">
                    {t.paymentMode || null}
                  </Box>
                  <Box as="td" textAlign="right" padding="2">
                    <Text>
                      {t.rawData.credit}
                      {!t.amount && t.rawData.credit ? (
                        <Text color="red900" fontSize="sm">
                          Invalid Amount
                        </Text>
                      ) : null}
                    </Text>
                  </Box>
                  <Box as="td" textAlign="right" padding="2">
                    <Text>{t.rawData.debit}</Text>
                    {!t.amount && t.rawData.debit ? (
                      <Text color="red900" fontSize="sm">
                        Invalid Amount
                      </Text>
                    ) : null}
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Stack>
    </Stack>
  )
}

type T_Party_Data = {
  name: string | null | undefined
  phoneNumber?: string
  type?: T_AVAILABLE_PARTY_TYPES
}

type T_Valid_Parties_Data = {
  name: string
  phoneNumber?: string
  type?: T_AVAILABLE_PARTY_TYPES
}

type T_Invalid_Parties_Data = {
  name: string | null | undefined
  phoneNumber: string | null | undefined
  type: T_AVAILABLE_PARTY_TYPES | null | undefined
  rawData: {
    name: string | undefined
    phoneNumber: string | undefined
    type: string | null | undefined
  }
}

function PreviewParties({
  book,
  headers,
  rows,
  headerMapping,
  error,
  country,
  apiCalled,
  isSubmittingParties,
  partiesImportedFailed,
  onSubmit,
  onSuccess,
  selectDifferentFile,
}: {
  book: TBook
  headers: T_CSV_ARRAY[number]
  rows: T_CSV_ARRAY
  headerMapping: Array<{
    header: keyof typeof ACCEPTABLE_HEADERS_FOR_PARTY
    mappedTo: string
  }>
  apiCalled?: boolean
  country?: string
  error?: string
  partiesImportedFailed?: Array<T_Valid_Parties_Data & { reason?: string }>
  isSubmittingParties?: boolean
  selectDifferentFile?: () => void
  onSubmit: (parties: T_Valid_Parties_Data[]) => void
  onSuccess: () => void
}) {
  const importPartiesModal = useOverlayTriggerState({})
  const { partyOrContact, partiesOrContacts } = usePartyOrContact()

  const headerForColumnName = useMemo(
    () =>
      headerMapping.reduce<{
        [key: string]: keyof typeof ACCEPTABLE_HEADERS_FOR_PARTY
      }>((headerMapping, map) => {
        if (map.mappedTo) {
          headerMapping[map.mappedTo] = map.header
        }
        return headerMapping
      }, {}),
    [headerMapping]
  )
  const [{ validParties, invalidParties, validated }, setParties] = useState<{
    validParties: Array<T_Valid_Parties_Data>
    invalidParties: Array<T_Invalid_Parties_Data>
    validated: boolean
  }>({ validParties: [], invalidParties: [], validated: false })

  useEffect(() => {
    mapCsvArrayToParties(
      headers,
      rows,
      headerForColumnName,
      country,
      ({ validParties, invalidParties }) => {
        setParties({
          validParties,
          invalidParties,
          validated: false,
        })
      },
      partiesOrContacts
    ).then(() => {
      setParties((state) => ({
        ...state,
        validated: true,
      }))
    })
  }, [headerForColumnName, headers, rows, country, partiesOrContacts])

  const invalidPartiesVisibilityToggler = useOverlayTriggerState({})

  const validBookPartiesRef = useRef<T_Party_Data[]>(book?.parties || [])

  const importPartiesStats = useMemo(() => {
    const parties: { [key: string]: T_Valid_Parties_Data } = {}

    validBookPartiesRef.current?.forEach((party) => {
      if (!parties[`${party.name}/${party.phoneNumber}`]) {
        parties[
          `${party.name}${party.phoneNumber ? `/${party.phoneNumber}` : ""}`
        ] = party as T_Valid_Parties_Data
      }
    })

    const partyKeys = Object.keys(parties)

    const readyToImport: T_Valid_Parties_Data[] = []

    const duplicate = validParties.filter((validParty) => {
      const findDuplicate = partyKeys.find(
        (key) =>
          key.includes(validParty.name) ||
          arePhoneNumbersSame(key.split("/")[1], validParty.phoneNumber)
      )
      if (findDuplicate) return true
      readyToImport.push(validParty)
      return false
    })

    return {
      total: validParties,
      duplicate: duplicate,
      ready: readyToImport,
    }
  }, [validParties])

  const isAllDuplicated =
    importPartiesStats.total.length === importPartiesStats.duplicate.length

  const partiesImportedSuccessfully = useMemo(() => {
    if (
      !book.parties ||
      book.parties?.length === 0 ||
      !importPartiesStats.ready ||
      importPartiesStats.ready.length === 0
    ) {
      return []
    }

    const bookPartiesMap = new Map(
      book.parties?.map((party) => [party.name, party])
    )

    const commonParties = importPartiesStats.ready.filter((party) =>
      bookPartiesMap.has(party.name)
    )

    return commonParties as T_Valid_Parties_Data[]
  }, [book?.parties, importPartiesStats.ready])

  return (
    <Stack>
      <Stack>
        <Inline
          gap="8"
          fontWeight="medium"
          fontSize="sm"
          borderBottomWidth="1"
          position="relative"
        >
          <Box
            as="button"
            onClick={invalidPartiesVisibilityToggler.close}
            position="relative"
            zIndex="10"
            paddingX="4"
            paddingY="3"
            borderLeftWidth="1"
            borderRightWidth="1"
            borderTopWidth="1"
            display="inlineBlock"
            className="-mb-px"
            roundedTop="md"
            bgColor={
              !invalidPartiesVisibilityToggler.isOpen
                ? "backgroundLight3"
                : undefined
            }
            color={
              !invalidPartiesVisibilityToggler.isOpen
                ? "textPrimary"
                : "textHigh"
            }
            borderColor={
              invalidPartiesVisibilityToggler.isOpen ? "white" : undefined
            }
          >
            <Text>
              Valid {partiesOrContacts}: {validParties.length}
            </Text>
          </Box>
          <Box
            as="button"
            onClick={invalidPartiesVisibilityToggler.open}
            position="relative"
            zIndex="10"
            paddingX="4"
            paddingY="3"
            borderLeftWidth="1"
            borderRightWidth="1"
            borderTopWidth="1"
            display="inlineBlock"
            className="-mb-px"
            roundedTop="md"
            color={
              invalidPartiesVisibilityToggler.isOpen
                ? "textPrimary"
                : "textHigh"
            }
            bgColor={
              invalidPartiesVisibilityToggler.isOpen
                ? "backgroundLight3"
                : undefined
            }
            borderColor={
              !invalidPartiesVisibilityToggler.isOpen ? "white" : undefined
            }
          >
            <Text>
              Invalid {partiesOrContacts}: {invalidParties.length}
            </Text>
          </Box>
          {!validated ? (
            <Box>
              <SpinnerIcon />
            </Box>
          ) : null}
        </Inline>
      </Stack>
      <Stack
        maxWidth="full"
        overflow="auto"
        position="relative"
        borderLeftWidth="1"
        borderRightWidth="1"
        borderBottomWidth="1"
        paddingBottom="8"
        style={{ maxHeight: "300px" }}
      >
        <Box as="table" width="full">
          <Box as="thead">
            <Box as="tr">
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
              >
                #
              </Box>
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
              >
                {partyOrContact} Name
              </Box>
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
              >
                Phone Number
              </Box>
              <Box
                as="th"
                padding="2"
                bgColor="gray100"
                position="sticky"
                top="0"
                zIndex="10"
              >
                {partyOrContact} Type
              </Box>
            </Box>
          </Box>
          <Box
            as="tbody"
            display={
              invalidPartiesVisibilityToggler.isOpen ? "none" : undefined
            }
          >
            {validParties.length === 0 ? (
              <Box as="tr">
                <Box as="td" colSpan={5} paddingY="8" textAlign="center">
                  <Stack gap="4">
                    <Text>No valid {partiesOrContacts}.</Text>
                    {invalidParties.length ? (
                      <Box
                        as="a"
                        display="block"
                        href="#view-invalid-entries"
                        onClick={(e: React.SyntheticEvent) => {
                          e.preventDefault()
                          invalidPartiesVisibilityToggler.open()
                        }}
                      >
                        <Text color="red900">
                          There{" "}
                          {invalidParties.length > 1
                            ? `are
                ${invalidParties.length} invalid ${partiesOrContacts}`
                            : `is ${invalidParties.length} invalid ${partyOrContact}.`}
                          . Click to view them.
                        </Text>
                      </Box>
                    ) : null}
                  </Stack>
                </Box>
              </Box>
            ) : (
              validParties.map((p, i) => (
                <Box as="tr" key={i} borderTopWidth="1">
                  <Box as="td" padding="2">
                    {i + 1}
                  </Box>
                  <Box as="td" padding="2" textTransform="capitalize">
                    {p.name || null}
                  </Box>
                  <Box as="td" padding="2">
                    {p.phoneNumber || null}
                  </Box>
                  <Box as="td" padding="2" textTransform="capitalize">
                    {p.type || null}
                  </Box>
                </Box>
              ))
            )}
          </Box>
          <Box
            as="tbody"
            display={
              !invalidPartiesVisibilityToggler.isOpen ? "none" : undefined
            }
          >
            {invalidParties.length === 0 ? (
              <Box as="tr">
                <Box as="td" colSpan={5} textAlign="center" paddingY="8">
                  <Text>There are no invalid {partiesOrContacts}.</Text>
                </Box>
              </Box>
            ) : (
              invalidParties.map((p, i) => (
                <Box as="tr" borderTopWidth="1" key={i}>
                  <Box as="td" padding="2">
                    {i + 1}
                  </Box>
                  <Box as="td" padding="2" textTransform="capitalize">
                    {p.name || null}
                    {p.rawData.name ? (
                      <Text color="textCashOut">{p.rawData.name}</Text>
                    ) : null}
                  </Box>
                  <Box as="td" padding="2">
                    {p.phoneNumber || null}
                    {p.rawData.phoneNumber ? (
                      <Text color="textCashOut">{p.rawData.phoneNumber}</Text>
                    ) : null}
                  </Box>
                  <Box as="td" padding="2" textTransform="capitalize">
                    {p.type || null}
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Stack>
      <Inline
        width="full"
        alignItems="center"
        justifyContent="between"
        paddingTop="6"
      >
        <CBButton size="lg" onClick={() => selectDifferentFile?.()}>
          Cancel
        </CBButton>
        <Inline gap="6">
          <CBButton size="lg" onClick={() => selectDifferentFile?.()}>
            Select Different File
          </CBButton>
          <CBButton
            size="lg"
            level="primary"
            loading={isSubmittingParties || !validated}
            onClick={() => {
              importPartiesModal.open()
              if (importPartiesStats.duplicate.length === 0 && !apiCalled) {
                onSubmit(importPartiesStats.ready)
              }
            }}
          >
            Import Valid {partiesOrContacts}
          </CBButton>
        </Inline>
      </Inline>
      <Modal
        isOpen={importPartiesModal.isOpen}
        onClose={importPartiesModal.close}
        title={`Import ${partiesOrContacts}`}
        placement="right"
      >
        <ModalBody>
          {isSubmittingParties || apiCalled ? (
            <ImportingParties
              isProcessCompleted={!isSubmittingParties}
              partiesToBeImported={importPartiesStats.ready}
              partiesImportedFailed={partiesImportedFailed}
              partiesImportedSuccessfully={partiesImportedSuccessfully}
            />
          ) : (
            <Stack gap="8">
              {isAllDuplicated ? (
                <Stack
                  backgroundColor="surfaceErrorLowest"
                  rounded="md"
                  justifyContent="center"
                  alignItems="center"
                  paddingY="8"
                  paddingX="6"
                  gap="4"
                >
                  <InformationWarningIcon color="iconError" size="12" />
                  <Text fontSize="s1">
                    All selected {partiesOrContacts} exists in this book!
                  </Text>
                </Stack>
              ) : null}
              <Inline
                rounded="md"
                paddingY="6"
                backgroundColor="surfaceNeutralLowest"
              >
                <Stack
                  gap="3"
                  width="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="c2">Total {partiesOrContacts}</Text>
                  <Text fontSize="s3">{importPartiesStats.total.length}</Text>
                </Stack>
                <Stack
                  gap="3"
                  width="full"
                  alignItems="center"
                  borderLeftWidth="1"
                  borderRightWidth="1"
                  borderColor="borderOutline"
                  justifyContent="center"
                >
                  <Text fontSize="c2">Duplicate</Text>
                  <Text fontSize="s3">
                    {importPartiesStats.duplicate?.length}
                  </Text>
                </Stack>
                <Stack
                  gap="3"
                  width="full"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize="c2">Ready to import</Text>
                  <Text fontSize="s3">{importPartiesStats.ready?.length}</Text>
                </Stack>
              </Inline>
              {error ? <Alert status="error">{`${error}`}</Alert> : null}
              <Stack gap="6">
                <Stack gap="2">
                  <Text fontSize="s3">
                    Duplicate{" "}
                    {pluralize(
                      partyOrContact,
                      importPartiesStats.duplicate?.length
                    )}
                    ({importPartiesStats.duplicate?.length})
                  </Text>
                  <Text fontSize="b4">
                    {importPartiesStats.duplicate.length
                      ? `${pluralize(
                          partyOrContact,
                          importPartiesStats.duplicate.length
                        )} with
                  same name or number already exist in your book`
                      : `There are no duplicated ${partiesOrContacts.toLowerCase()} among your valid ${partiesOrContacts.toLowerCase()} from the file!`}
                  </Text>
                </Stack>
                {importPartiesStats.duplicate?.length ? (
                  <Stack as="ol" gap="4">
                    {importPartiesStats.duplicate?.map((party, i) => {
                      const { name, type, phoneNumber } = party
                      return (
                        <Inline
                          key={i}
                          as="li"
                          alignItems="center"
                          gap="4"
                          borderWidth="1"
                          rounded="md"
                          paddingX="4"
                          paddingY="2"
                        >
                          <MemberAvatar id={name} name={name} />
                          <Stack flex="1" gap="1">
                            <Text className="break-all">{name}</Text>
                            {type || phoneNumber ? (
                              <Inline
                                color="gray500"
                                gap={type && phoneNumber ? "1" : "0"}
                              >
                                <Text>{phoneNumber}</Text>
                                {type && phoneNumber && <Text>.</Text>}
                                <Text textTransform="capitalize">{type}</Text>
                              </Inline>
                            ) : null}
                          </Stack>
                        </Inline>
                      )
                    })}
                  </Stack>
                ) : null}
              </Stack>
            </Stack>
          )}
        </ModalBody>
        {isSubmittingParties || apiCalled ? (
          <ModalFooter>
            <CBButton
              size="lg"
              level="primary"
              disabled={isSubmittingParties}
              onClick={onSuccess}
            >
              Ok, Got It.
            </CBButton>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <CBButton
              type="submit"
              size="lg"
              loading={isSubmittingParties}
              onClick={() =>
                isAllDuplicated
                  ? selectDifferentFile?.()
                  : onSubmit(importPartiesStats.ready)
              }
            >
              {isAllDuplicated
                ? "Select Different File"
                : "Skip Duplicates & Import Others"}
            </CBButton>
            <CBButton
              size="lg"
              loading={isSubmittingParties}
              onClick={importPartiesModal.close}
            >
              {isAllDuplicated ? "Cancel" : "Go Back"}
            </CBButton>
          </ModalFooter>
        )}
      </Modal>
    </Stack>
  )
}

function ImportingParties({
  isProcessCompleted,
  partiesToBeImported,
  partiesImportedFailed,
  partiesImportedSuccessfully,
}: {
  partiesToBeImported: T_Valid_Parties_Data[]
  partiesImportedSuccessfully?: Array<
    T_Valid_Parties_Data & { reason?: string }
  >
  partiesImportedFailed?: Array<T_Valid_Parties_Data & { reason?: string }>
  isProcessCompleted: boolean
}) {
  const { partyOrContact, partiesOrContacts } = usePartyOrContact()
  const [state, setState] = useState<"imported" | "not_imported">("imported")

  const importedSuccessFullyInPercentage = useMemo(() => {
    const successPercentage =
      ((partiesImportedSuccessfully?.length || 0) /
        partiesToBeImported.length) *
      100
    return successPercentage.toFixed(2)
  }, [partiesImportedSuccessfully?.length, partiesToBeImported.length])

  const parties = useMemo(() => {
    return state === "imported" && partiesImportedSuccessfully?.length
      ? partiesImportedSuccessfully
      : state === "not_imported" && partiesImportedFailed?.length
      ? partiesImportedFailed
      : []
  }, [partiesImportedFailed, partiesImportedSuccessfully, state])

  return (
    <Stack gap="8">
      <Stack
        paddingY="8"
        paddingX="12"
        borderColor="borderOutline"
        borderWidth="1"
        rounded="md"
        gap="4"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
      >
        {isProcessCompleted ? (
          <CheckCircleSolidIcon color="iconSuccess" size="8" />
        ) : (
          <Text fontSize="b1">
            Importing {partiesOrContacts} ({partiesImportedSuccessfully?.length}{" "}
            of {partiesToBeImported.length})
          </Text>
        )}
        {isProcessCompleted ? (
          <Text fontSize="s3">
            {partiesImportedSuccessfully?.length}{" "}
            {pluralize(partyOrContact, partiesImportedSuccessfully?.length)}{" "}
            Imported Successfully!
          </Text>
        ) : (
          <Box
            height="2"
            width="full"
            backgroundColor="borderOutline"
            rounded="full"
          >
            <Box
              backgroundColor="surfacePrimary"
              rounded="full"
              height="full"
              style={{ width: `${importedSuccessFullyInPercentage}%` }}
            ></Box>
          </Box>
        )}
      </Stack>
      <Stack gap="8">
        <Inline
          gap="4"
          fontSize="s4"
          borderBottomWidth="1"
          borderColor="borderDividers"
        >
          <Box
            roundedTop="md"
            as="button"
            disabled={state === "imported" || !isProcessCompleted}
            borderBottomWidth={state === "imported" ? "2" : "0"}
            borderColor="borderPrimary"
            backgroundColor={
              state === "imported" ? "surfacePrimaryLowest" : "transparent"
            }
            padding="3"
            onClick={() => setState("imported")}
          >
            <Text color={state === "imported" ? "textPrimary" : "textMedium"}>
              Imported Successfully ({partiesImportedSuccessfully?.length})
            </Text>
          </Box>
          <Box
            roundedTop="md"
            as="button"
            padding="3"
            disabled={state === "not_imported" || !isProcessCompleted}
            borderBottomWidth={state === "not_imported" ? "2" : "0"}
            borderColor="borderPrimary"
            backgroundColor={
              state === "not_imported" ? "surfacePrimaryLowest" : "transparent"
            }
            onClick={() => setState("not_imported")}
          >
            <Text
              color={state === "not_imported" ? "textPrimary" : "textMedium"}
            >
              Failed to Import ({partiesImportedFailed?.length})
            </Text>
          </Box>
        </Inline>
        {parties.length ? (
          <Stack as="ul" gap="4">
            {parties.map((party, i) => {
              const { name, phoneNumber, type, reason } = party
              return (
                <Inline
                  key={i}
                  as="li"
                  alignItems="center"
                  gap="4"
                  borderWidth="1"
                  rounded="md"
                  paddingX="4"
                  paddingY="2"
                >
                  <MemberAvatar id={phoneNumber || name} name={name} />
                  <Stack flex="1" gap="1">
                    <Text className="break-all">{name}</Text>
                    {type || phoneNumber ? (
                      <Inline
                        color="gray500"
                        gap={type && phoneNumber ? "1" : "0"}
                      >
                        <Text>{phoneNumber}</Text>
                        {type && phoneNumber && <Text>.</Text>}
                        <Text textTransform="capitalize">{type}</Text>
                      </Inline>
                    ) : null}
                    {reason?.length ? (
                      <Inline
                        fontSize="c2"
                        color="textCashOut"
                        alignItems="center"
                        gap="1"
                      >
                        <InformationCircleFilledIcon size="4" />
                        {reason}
                      </Inline>
                    ) : null}
                  </Stack>
                </Inline>
              )
            })}
          </Stack>
        ) : !isProcessCompleted ? null : (
          <Stack
            className="h-[120px]"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="b3" color="textMedium">
              {state === "imported"
                ? `No ${partiesOrContacts} imported successfully.`
                : `No ${partiesOrContacts} failed in this import.`}{" "}
            </Text>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

/**
 * Converts a CSV to an Array without blocking the UI, using requestAnimationFrames
 */
function csvToArray(
  csv: string,
  onProgress?: (array: T_CSV_ARRAY) => void
): Promise<T_CSV_ARRAY> {
  let notifyAt = 1
  function _getNextRowFromChars(
    {
      c,
      chars,
      table,
    }: { c: number; chars: Array<string>; table: T_CSV_ARRAY },
    cb: (table: T_CSV_ARRAY) => void,
    onProgress?: (array: T_CSV_ARRAY) => void
  ) {
    const cc = chars.length
    if (c >= cc) {
      cb(table)
      return
    }
    const row: T_CSV_ARRAY[number] = []
    table.push(row)
    while (c < cc && "\r" !== chars[c] && "\n" !== chars[c]) {
      let start = c
      let end = c
      if ('"' === chars[c]) {
        start = end = ++c
        while (c < cc) {
          if ('"' === chars[c]) {
            if ('"' !== chars[c + 1]) {
              break
            } else {
              chars[++c] = "" // unescape ""
            }
          }
          end = ++c
        }
        if ('"' === chars[c]) {
          ++c
        }
        while (
          c < cc &&
          "\r" !== chars[c] &&
          "\n" !== chars[c] &&
          "," !== chars[c]
        ) {
          ++c
        }
      } else {
        while (
          c < cc &&
          "\r" !== chars[c] &&
          "\n" !== chars[c] &&
          "," !== chars[c]
        ) {
          end = ++c
        }
      }
      // if there is need to transform values, one can do here
      row.push(chars.slice(start, end).join(""))
      if ("," === chars[c]) {
        ++c
      }
    }
    if ("\r" === chars[c]) {
      ++c
    }
    if ("\n" === chars[c]) {
      ++c
    }
    raf(function () {
      // randomize the counts
      if (table.length >= notifyAt) {
        notifyAt += 10 * Math.ceil(Math.random() * 10)
        onProgress?.(table.concat())
      }
      _getNextRowFromChars({ c, chars, table }, cb, onProgress)
    })
  }
  return new Promise((resolve) => {
    const chars = csv.split("")
    const c = 0
    const table: T_CSV_ARRAY = []
    _getNextRowFromChars({ c, chars, table }, resolve, onProgress)
  })
}

function isEntryDateAfterMinDate(inputDate: Date) {
  const compareDate = RECORD_ENTRY_MIN_DATE
  return inputDate > compareDate
}

function mapCsvArrayToTransactions(
  headers: T_CSV_ARRAY[number],
  rows: T_CSV_ARRAY,
  headerMapping: { [key: string]: keyof typeof ACCEPTABLE_HEADERS },
  config: { dateFormat: string; timeFormat?: string },
  onProgress?: (
    data: {
      validTransactions: Array<T_Valid_Transaction_Data>
      invalidTransactions: Array<T_Invalid_Transaction_Data>
    },
    thisBatchData: {
      validTransactions: Array<T_Valid_Transaction_Data>
      invalidTransactions: Array<T_Invalid_Transaction_Data>
    }
  ) => void
): Promise<
  [
    validTransactions: Array<T_Valid_Transaction_Data>,
    invalidTransactions: Array<T_Invalid_Transaction_Data>
  ]
> {
  let notifyAt = 1
  function mapRow(
    {
      currentRowIndex,
      validTransactions,
      invalidTransactions,
      lastNotifiedValidTransactionsLength,
      lastNotifiedInvalidTransactionsLength,
    }: {
      currentRowIndex: number
      validTransactions: Array<T_Valid_Transaction_Data>
      invalidTransactions: Array<T_Invalid_Transaction_Data>
      lastNotifiedValidTransactionsLength: number
      lastNotifiedInvalidTransactionsLength: number
    },
    cb: (
      data: [
        validTransactions: Array<T_Valid_Transaction_Data>,
        invalidTransactions: Array<T_Invalid_Transaction_Data>
      ]
    ) => void
  ) {
    if (currentRowIndex >= rows.length) {
      onProgress?.(
        {
          validTransactions: validTransactions.concat(),
          invalidTransactions: invalidTransactions.concat(),
        },
        {
          validTransactions: validTransactions.slice(
            lastNotifiedValidTransactionsLength
          ),
          invalidTransactions: invalidTransactions.slice(
            lastNotifiedInvalidTransactionsLength
          ),
        }
      )
      return cb([validTransactions, invalidTransactions])
    }
    const row = rows[currentRowIndex]
    const transaction: T_Transaction_Data = {
      remark: undefined,
      type: "cash-in",
      amount: 0,
      date: undefined,
      category: undefined,
      paymentMode: undefined,
    }
    const rawData: $PropertyType<T_Invalid_Transaction_Data, "rawData"> = {
      remark: undefined,
      credit: undefined,
      debit: undefined,
      date: undefined,
      time: undefined,
      category: undefined,
      paymentMode: undefined,
    }
    let time: Date | undefined = new Date()
    for (const index in row) {
      const header = headerMapping[headers[index]]
      if (header) {
        const value = row[index]
        switch (header) {
          case "remark":
            rawData.remark = value
            if (value) {
              transaction.remark = String(value).trim()
            } else {
              transaction.remark = null
            }
            break
          case "credit":
            rawData.credit = value
            if (value) {
              transaction.type = "cash-in"
              transaction.amount = normalizeNumber(
                Number(String(value).replace(/[^\d.]/gi, ""))
              )
              if (transaction.amount < 0) {
                transaction.amount = -1 * transaction.amount
              }
            }
            break
          case "debit":
            rawData.debit = value
            if (value) {
              transaction.type = "cash-out"
              transaction.amount = normalizeNumber(
                Number(String(value).replace(/[^\d.]/gi, ""))
              )
              if (transaction.amount < 0) {
                transaction.amount = -1 * transaction.amount
              }
            }
            break
          case "date":
            rawData.date = value
            if (value) {
              try {
                const date = parseDate(value, config.dateFormat, new Date())
                if (!isNaN(date.getTime())) {
                  transaction.date = date
                }
              } catch (e) {
                transaction.date = undefined
              }
            }
            break
          case "time":
            rawData.time = value
            if (value && config.timeFormat) {
              try {
                const date = parseDate(value, config.timeFormat, new Date())
                if (!isNaN(date.getTime())) {
                  time = date
                }
              } catch (e) {
                time = undefined
              }
            }
            break
          case "category":
            rawData.category = value
            if (value) {
              transaction.category = String(value).trim()
            } else {
              transaction.category = null
            }
            break
          case "paymentMode":
            rawData.paymentMode = value
            if (value) {
              transaction.paymentMode = String(value).trim()
            } else {
              transaction.paymentMode = null
            }
            break
        }
      }
    }
    if (transaction.date && time) {
      // assign the time to the date
      transaction.date = parseDate(
        `${formatDate(transaction.date, "yyyy-MM-dd")} ${formatDate(
          time,
          "HH:mm:ss"
        )}`,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      )
    } else {
      transaction.date = undefined
    }
    if (
      transaction.date &&
      isEntryDateAfterMinDate(transaction.date) &&
      transaction.amount &&
      transaction.type
    ) {
      validTransactions.push(transaction as T_Valid_Transaction_Data)
    } else {
      invalidTransactions.push({ ...transaction, rawData })
    }
    raf(() => {
      // randomize the counts
      if (validTransactions.length + invalidTransactions.length >= notifyAt) {
        notifyAt += 10 * Math.ceil(Math.random() * 10)
        onProgress?.(
          {
            validTransactions: validTransactions.concat([]),
            invalidTransactions: invalidTransactions.concat([]),
          },
          {
            validTransactions: validTransactions.slice(
              lastNotifiedValidTransactionsLength
            ),
            invalidTransactions: invalidTransactions.slice(
              lastNotifiedInvalidTransactionsLength
            ),
          }
        )
        lastNotifiedValidTransactionsLength = validTransactions.length
        lastNotifiedInvalidTransactionsLength = invalidTransactions.length
      }
      mapRow(
        {
          currentRowIndex: currentRowIndex + 1,
          validTransactions,
          invalidTransactions,
          lastNotifiedValidTransactionsLength,
          lastNotifiedInvalidTransactionsLength,
        },
        cb
      )
    })
  }
  return new Promise((resolve) => {
    mapRow(
      {
        currentRowIndex: 0,
        validTransactions: [],
        invalidTransactions: [],
        lastNotifiedValidTransactionsLength: 0,
        lastNotifiedInvalidTransactionsLength: 0,
      },
      resolve
    )
  })
}

// Too much improvement scope
function mapCsvArrayToParties(
  headers: T_CSV_ARRAY[number],
  rows: T_CSV_ARRAY,
  headerMapping: { [key: string]: keyof typeof ACCEPTABLE_HEADERS_FOR_PARTY },
  country?: string,
  onProgress?: (
    data: {
      validParties: Array<T_Valid_Parties_Data>
      invalidParties: Array<T_Invalid_Parties_Data>
    },
    thisBatchData: {
      validParties: Array<T_Valid_Parties_Data>
      invalidParties: Array<T_Invalid_Parties_Data>
    }
  ) => void,
  partyLabel?: string
): Promise<
  [
    validParties: Array<T_Valid_Parties_Data>,
    invalidParties: Array<T_Invalid_Parties_Data>
  ]
> {
  let notifyAt = 1
  function mapRow(
    {
      currentRowIndex,
      validParties,
      invalidParties,
      lastNotifiedValidPartiesLength,
      lastNotifiedInvalidPartiesLength,
    }: {
      currentRowIndex: number
      validParties: Array<T_Valid_Parties_Data>
      invalidParties: Array<T_Invalid_Parties_Data>
      lastNotifiedValidPartiesLength: number
      lastNotifiedInvalidPartiesLength: number
    },
    cb: (
      data: [
        validParties: Array<T_Valid_Parties_Data>,
        invalidParties: Array<T_Invalid_Parties_Data>
      ]
    ) => void
  ) {
    if (currentRowIndex >= rows.length) {
      onProgress?.(
        {
          validParties: validParties.concat(),
          invalidParties: invalidParties.concat(),
        },
        {
          validParties: validParties.slice(lastNotifiedValidPartiesLength),
          invalidParties: invalidParties.slice(
            lastNotifiedInvalidPartiesLength
          ),
        }
      )
      return cb([validParties, invalidParties])
    }

    const row = rows[currentRowIndex]
    const party: T_Party_Data = {
      name: undefined,
      phoneNumber: undefined,
      type: "customer",
    }
    const rawData: $PropertyType<T_Invalid_Parties_Data, "rawData"> = {
      name: undefined,
      phoneNumber: undefined,
      type: undefined,
    }
    for (const index in row) {
      const header = headerMapping[headers[index]]
      if (header) {
        const value = row[index]
        const phone =
          header === "phoneNumber"
            ? parsePhoneNumber(value, (country || "IN").toUpperCase() as never)
            : undefined
        switch (header) {
          case "partyName":
            if (value) {
              party.name = String(value).trim()
            }
            break
          case "phoneNumber":
            if (phone && isPossiblePhoneNumber(phone.number)) {
              party.phoneNumber = phone.number
            } else {
              party.phoneNumber = undefined
            }
            break
          case "partyType":
            if (value) {
              party.type = value.toLowerCase().includes("sup")
                ? "supplier"
                : "customer"
            }
            break
        }
      }
    }

    if (!party.name) {
      rawData.name = "A valid name does not exist"
      invalidParties.push({ ...party, rawData } as T_Invalid_Parties_Data)
    }
    const foundFromInvalid = invalidParties.find((item) => {
      if (
        party.name === item.name ||
        arePhoneNumbersSame(item.phoneNumber, party.phoneNumber)
      )
        return true
      return false
    })

    if (foundFromInvalid) {
      invalidParties.push({
        ...foundFromInvalid,
        rawData: {
          name:
            foundFromInvalid.name === party.name
              ? `2 ${
                  partyLabel || "parties"
                } with same name but different number!`
              : null,
          phoneNumber: arePhoneNumbersSame(
            foundFromInvalid.phoneNumber,
            party.phoneNumber
          )
            ? `2 ${
                partyLabel || "parties"
              } with different name but same number!`
            : null,
        },
      } as T_Invalid_Parties_Data)
    } else {
      const foundWithName = validParties.find((item) => {
        if (party.name === item.name) return true
        return false
      })

      const foundWithNumber = validParties.find((item) => {
        if (arePhoneNumbersSame(item.phoneNumber, party.phoneNumber))
          return true
        return false
      })
      if (!foundWithName || !foundWithName) {
        validParties.push(party as T_Valid_Parties_Data)
      } else {
        let updatedParties = validParties
        if (foundWithName && (foundWithName.phoneNumber || party.phoneNumber)) {
          updatedParties = updatedParties.filter(
            (validParty) => validParty.name !== foundWithName.name
          )
          invalidParties.push({
            ...foundWithName,
            rawData: {
              name: `2 ${
                partyLabel || "parties"
              } with same name but different number!`,
            },
          } as T_Invalid_Parties_Data)
        }
        if (foundWithNumber) {
          updatedParties = updatedParties.filter(
            (validParty) =>
              !arePhoneNumbersSame(
                validParty.phoneNumber,
                foundWithNumber.phoneNumber
              )
          )
          invalidParties.push({
            ...foundWithNumber,
            rawData: {
              phoneNumber: `2 ${
                partyLabel || "parties"
              } different name but same number!`,
            },
          } as T_Invalid_Parties_Data)
        }
        validParties = updatedParties
        if (party.phoneNumber) {
          if (party.phoneNumber === foundWithNumber?.phoneNumber) {
            invalidParties.push({
              ...party,
              rawData: {
                phoneNumber: `2 ${
                  partyLabel || "parties"
                } different name but same number!`,
              },
            } as T_Invalid_Parties_Data)
          } else {
            invalidParties.push({
              ...party,
              rawData: {
                name: `2 ${
                  partyLabel || "parties"
                } same name but different number!`,
              },
            } as T_Invalid_Parties_Data)
          }
        }
      }
    }

    raf(() => {
      // randomize the counts
      if (validParties.length + invalidParties.length >= notifyAt) {
        notifyAt += 10 * Math.ceil(Math.random() * 10)
        onProgress?.(
          {
            validParties: validParties.concat([]),
            invalidParties: invalidParties.concat([]),
          },
          {
            validParties: validParties.slice(lastNotifiedValidPartiesLength),
            invalidParties: invalidParties.slice(
              lastNotifiedInvalidPartiesLength
            ),
          }
        )
        lastNotifiedValidPartiesLength = validParties.length
        lastNotifiedInvalidPartiesLength = invalidParties.length
      }
      mapRow(
        {
          currentRowIndex: currentRowIndex + 1,
          validParties,
          invalidParties,
          lastNotifiedValidPartiesLength,
          lastNotifiedInvalidPartiesLength,
        },
        cb
      )
    })
  }
  return new Promise((resolve) => {
    mapRow(
      {
        currentRowIndex: 0,
        validParties: [],
        invalidParties: [],
        lastNotifiedValidPartiesLength: 0,
        lastNotifiedInvalidPartiesLength: 0,
      },
      resolve
    )
  })
}

function findBestMatchFromHeadersForKey(
  headers: T_CSV_ARRAY[number],
  key: keyof typeof ACCEPTABLE_HEADERS
): string | undefined {
  for (const head of headers) {
    switch (key) {
      case "remark":
        if (/(remark)|(narration)|(description)|(title)/gi.test(head))
          return head
        break
      case "date":
        if (/(date)/gi.test(head)) return head
        break
      case "credit":
        if (/(credit)|(cash in)/gi.test(head)) return head
        break
      case "debit":
        if (/(debit)|(cash out)/gi.test(head)) return head
        break
      case "time":
        if (/(time)/gi.test(head)) return head
        break
      case "category":
        if (/(category)/gi.test(head)) return head
        break
      case "paymentMode":
        if (/(paymentMode|mode|method)/gi.test(head)) return head
        break
    }
  }
}

function findBestMatchFromPartyHeadersForKey(
  headers: T_CSV_ARRAY[number],
  key: keyof typeof ACCEPTABLE_HEADERS_FOR_PARTY
): string | undefined {
  for (const head of headers) {
    switch (key) {
      case "partyName":
        if (/(partyName)|(contact name)|(name)|(contact)|(party)/gi.test(head))
          return head
        break
      case "phoneNumber":
        if (
          /(phone)|(phoneNumber)|(tel)|(contact number)|(number)|(telephone)|(mobile)|(mobile no.)/gi.test(
            head
          )
        )
          return head
        break
      case "partyType":
        if (/(type)|(partyType)|(mode)/gi.test(head)) return head
        break
    }
  }
}
