import {
  DataLoadingFallback,
  PageMeta,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
  NavLink,
} from "@cashbook/web-components"
import { useBusiness } from "@cashbook/data-store/businesses"
import { Navigate, useParams, Outlet } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { useEffect, useMemo } from "react"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"

export default function BusinessSettingsPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading business details..." />}
        traceId="loading_business_details"
      >
        <BusinessSettingsLayout key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

export function RedirectToTeamFromIndexPage({
  routePrefix = "",
}: {
  routePrefix?: string
}) {
  const { businessId } = useParams()
  if (!businessId) return <Navigate to={`${routePrefix}/`} />
  return (
    <Navigate
      to={`${routePrefix}/businesses/${businessId}/business-settings/team`}
      replace
    />
  )
}

function BusinessSettingsLayout({ businessId }: { businessId: string }) {
  const { business, authTeamMemberDetails } = useBusiness(businessId)
  const authUserRole = authTeamMemberDetails.role.id
  const businessSettingsOptions = useMemo(() => {
    return [
      {
        label: "Business Team",
        description:
          authUserRole === "staff"
            ? "List of business team members"
            : "Add, remove or change role",
        to: "team",
      },
      {
        label: "Business Profile",
        description:
          authUserRole === "staff"
            ? "View business details"
            : "Edit business details",
        to: "profile",
      },
      {
        label: "Settings",
        description:
          authUserRole === "owner"
            ? "Change owner or delete business"
            : "Leave Business",
        to: "settings",
      },
    ]
  }, [authUserRole])

  useEffect(() => {
    trackEvent(TrackingEvents.BUSINESS_SETTINGS_VIEWED, { role: authUserRole })
  }, [authUserRole])
  return (
    <>
      <PageMeta>
        <title>{business.name}</title>
      </PageMeta>
      <Box key={businessId} bgColor="white" className="min-h-screen sm:min-h-0">
        <Box
          as="header"
          paddingY="6"
          paddingX="8"
          borderBottomWidth="1"
          borderColor="gray100"
        >
          <Inline flexGrow="1" gap="4" alignItems="center">
            <Heading as="h2" fontSize="lg" fontWeight="semibold">
              Business Settings
            </Heading>
            <Text color="gray500">({business.name})</Text>
          </Inline>
        </Box>
        <Box display="flex">
          <Box
            as="aside"
            className="w-[264px]"
            borderRightWidth="1"
            borderColor="gray100"
          >
            <Box as="ol" paddingLeft="4" paddingBottom="12">
              {businessSettingsOptions.map(({ label, description, to }) => {
                return (
                  <Box
                    as="li"
                    paddingY="3"
                    paddingRight="4"
                    key={label}
                    borderBottomWidth="1"
                  >
                    <NavLink
                      to={to}
                      paddingX="4"
                      paddingY="3"
                      display="block"
                      rounded="md"
                      bgColor={{ default: "transparent", hover: "blue50" }}
                      activeProps={{
                        bgColor: "blue100",
                      }}
                    >
                      <Stack gap="2">
                        <Heading as="h4" fontWeight="semibold" fontSize="base">
                          {label}
                        </Heading>
                        <Text color="gray500" fontWeight="medium" fontSize="sm">
                          {description}
                        </Text>
                      </Stack>
                    </NavLink>
                  </Box>
                )
              })}
            </Box>
          </Box>
          <Box flexGrow="1">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  )
}
