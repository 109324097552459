import {
  PageMeta,
  Box,
  Stack,
  Heading,
  DataLoadingFallback,
  Inline,
  ArrowLeftIcon,
  FormField,
  CBButton,
  Text,
  formikOnSubmitWithErrorHandling,
  useOverlayTriggerState,
  Modal,
  ModalBody,
  Alert,
  LockedDualIcon,
  ModalFooter,
} from "@cashbook/web-components"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { Form, Formik } from "formik"
import { useProfile, useVerifyEmail } from "@cashbook/data-store/users"
import { toast } from "react-hot-toast"
import { EmailValidator, OTPValidator } from "@cashbook/util-general"
import * as Validator from "yup"
import { resolveCloudFunctionAuthErrors } from "@cashbook/data-store/auth"
import { useNavigate } from "react-router-dom"

export default function ChangePhoneNumberContainer() {
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading profile details..." />}
        traceId="loading_member_account_details"
      >
        <Box width="full">
          <ChangeEmailAddress />
        </Box>
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

export function ChangeEmailAddress() {
  const { user } = useProfile()
  const navigate = useNavigate()
  const {
    sendingVerificationCode,
    secondsRemainingToResend,
    sendVerificationOtp,
    verifyEmailWithOtp,
    resendVerificationOtp,
  } = useVerifyEmail()

  const state = useOverlayTriggerState({})

  return (
    <>
      <PageMeta title="Update Email Address" />
      <Stack width="full" alignItems="center" paddingY={{ xs: "0", md: "8" }}>
        <Formik
          initialValues={{
            step: "add_email" as
              | "add_email"
              | "confirm_change"
              | "otp_validation",
            email: "" as string | undefined,
            otp: "" as string | undefined,
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Validator.object().shape({
            email: EmailValidator.required(
              "Please enter a valid email address."
            ).test(
              `valid-email-address`,
              "You cannot add your own verified email here.",
              (value: string | undefined | null) => {
                if (value === user.email) return false
                return true
              }
            ),
            otp: OTPValidator.nullable().when(["step"], (step) => {
              if (step === "otp_validation") {
                return Validator.string().required("Please enter a valid OTP.")
              }
              return Validator.string().nullable()
            }),
          })}
          onSubmit={formikOnSubmitWithErrorHandling(
            async (values, { setFieldValue, setFieldError }) => {
              if (!values.email) return
              if (values.step === "add_email" && user.email?.length)
                return setFieldValue("step", "confirm_change")
              if (values.step === "confirm_change" || !user.email?.length) {
                try {
                  await sendVerificationOtp(values.email)
                  setFieldValue("step", "otp_validation")
                  state.open()
                  return
                } catch (e) {
                  setFieldError(
                    "email",
                    resolveCloudFunctionAuthErrors(e).message
                  )
                  return
                }
              }
              if (values.step === "otp_validation" && values.otp) {
                try {
                  await verifyEmailWithOtp(values.email, values.otp)
                  toast.success("Email changed & verified successfully")
                  navigate(-1)
                } catch (e) {
                  const error = e as Error
                  setFieldError("otp", error.message)
                }
              }
            }
          )}
        >
          {({
            errors,
            status,
            values,
            isSubmitting,
            submitForm,
            setFieldValue,
          }) => (
            <Stack
              width="full"
              maxWidth="lg"
              bgColor="surfaceDefault"
              rounded="md"
              paddingY="6"
              paddingX="8"
            >
              {values.step === "confirm_change" ? (
                <Stack gap="8">
                  <Inline gap="4" alignItems="center">
                    <Box
                      as="button"
                      onClick={() => {
                        setFieldValue("step", "add_email")
                      }}
                    >
                      <ArrowLeftIcon />
                    </Box>
                    <Heading as="h3" fontSize="s1">
                      Change Email ID
                    </Heading>
                  </Inline>
                  <Stack gap="4">
                    <LockedDualIcon size="10" />
                    <Text fontSize="b3">
                      Changing email id will transfer all your data from old
                      email to new email id. OTP will be sent to new Email id.
                    </Text>
                  </Stack>
                  <Stack
                    gap="6"
                    padding="4"
                    rounded="md"
                    backgroundColor="surfaceNeutralLowest"
                  >
                    <Stack gap="2">
                      <Text color="textMedium" fontSize="c2">
                        Old Email
                      </Text>
                      <Text fontSize="b3">{user.email}</Text>
                    </Stack>
                    <Stack gap="2">
                      <Text color="textMedium" fontSize="c2">
                        New Email
                      </Text>
                      <Text fontSize="b3">{values.email}</Text>
                    </Stack>
                  </Stack>
                  {errors.email ? (
                    <Alert marginBottom="0" status="error">
                      {errors.email}
                    </Alert>
                  ) : null}
                  <ModalFooter>
                    <Form noValidate>
                      <CBButton loading={isSubmitting} type="submit" size="lg">
                        {isSubmitting ? "Verifying..." : "Yes"}
                      </CBButton>
                    </Form>
                    <CBButton
                      size="lg"
                      disabled={isSubmitting}
                      onClick={() => {
                        setFieldValue("step", "add_email")
                      }}
                    >
                      Cancel
                    </CBButton>
                  </ModalFooter>
                </Stack>
              ) : (
                <Stack gap="8">
                  <Inline gap="4" alignItems="center">
                    <Box as="button" onClick={() => navigate(-1)}>
                      <ArrowLeftIcon />
                    </Box>
                    <Heading as="h3" fontSize="s1">
                      Change Email
                    </Heading>
                  </Inline>
                  <Form noValidate>
                    <Stack gap="6">
                      <FormField
                        name="email"
                        label="Email"
                        noMargin
                        placeholder="e.g. username@example.com"
                        type="email"
                      />
                      <Inline width="full" justifyContent="end">
                        <CBButton
                          type="submit"
                          size="lg"
                          disabled={!values.email?.length}
                        >
                          Change
                        </CBButton>
                      </Inline>
                    </Stack>
                  </Form>
                </Stack>
              )}

              <Modal
                isOpen={state.isOpen}
                title="Enter OTP"
                onClose={state.close}
              >
                <ModalBody>
                  <Stack gap="6">
                    <Stack gap="3">
                      <Text fontSize="s1">
                        Please enter the 6-digit OTP sent to {values.email}
                      </Text>
                      <Text fontSize="b3">
                        Use only the latest OTP sent on email
                      </Text>
                    </Stack>
                    <FormField
                      type="text"
                      name="otp"
                      inputMode="numeric"
                      autoFocus
                      placeholder="e.g. 123456"
                      autoComplete="off"
                      maxLength={6}
                    />
                  </Stack>
                  {status ? <Alert status="error">{status}</Alert> : null}
                </ModalBody>
                <ModalFooter>
                  <CBButton
                    type="submit"
                    size="lg"
                    onClick={submitForm}
                    disabled={!values.otp?.length}
                    loading={isSubmitting}
                  >
                    Verify
                  </CBButton>
                  <CBButton
                    type="button"
                    key="resending_otp"
                    disabled={Boolean(
                      !sendingVerificationCode && secondsRemainingToResend
                    )}
                    loading={sendingVerificationCode || isSubmitting}
                    size="lg"
                    onClick={async () => {
                      if (
                        !sendingVerificationCode &&
                        !secondsRemainingToResend &&
                        values.email
                      ) {
                        resendVerificationOtp(values.email)
                      }
                    }}
                  >
                    {sendingVerificationCode ? (
                      "Resending OTP"
                    ) : secondsRemainingToResend ? (
                      <>
                        Resend OTP in{" "}
                        <span
                          style={{
                            fontVariantNumeric: "tabular-nums",
                          }}
                        >
                          {secondsRemainingToResend}
                        </span>
                      </>
                    ) : (
                      "Resend OTP"
                    )}
                  </CBButton>
                </ModalFooter>
              </Modal>
            </Stack>
          )}
        </Formik>
      </Stack>
    </>
  )
}
