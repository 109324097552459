import classNames from "classnames"
import React, { ForwardedRef, useMemo } from "react"
import { Box, BoxOwnProps } from "./Box"

export type ButtonProps = {
  /**
   * @deprecated use "size" instead
   */
  sm?: boolean
  size?: "sm" | "lg" | "base"
  status?: "warning" | "primary" | "error" | "success"
  level?: "primary" | "secondary" | "tertiary"
  inline?: boolean
  fullWidth?: boolean
  type?: "button" | "submit" | "reset"
  children: React.ReactNode
  disabled?: boolean
  align?: "center" | "left"
  verticalAlign?: "middle"
  minWidth?: "default" | "auto"
}

export function getButtonClassName({
  size,
  status,
  level,
  fullWidth,
  inline,
  type,
  disabled,
  align,
  verticalAlign,
  minWidth,
}: Pick<
  ButtonProps,
  | "size"
  | "status"
  | "level"
  | "fullWidth"
  | "inline"
  | "type"
  | "disabled"
  | "align"
  | "verticalAlign"
  | "minWidth"
>) {
  level = level || (type === "submit" ? "primary" : "secondary")
  size = size || "base"
  return classNames(
    "rounded text-center focus:ring-4 focus:outline-none focus:ring-opacity-50 disabled:opacity-80 disabled:cursor-not-allowed font-semibold gap-2 items-center",
    {
      "justify-center": !align || align === "center",
      "justify-start": align === "left",
      "inline-flex": !fullWidth,
      "flex w-full": fullWidth,
      "align-middle": verticalAlign === "middle",
      "text-sm": size === "sm",
      "opacity-80 cursor-not-allowed": disabled,
    },
    !(inline || minWidth === "auto") ? "min-w-[120px]" : undefined,
    !inline
      ? [
          "border",
          {
            "px-4 h-[32px]": size === "sm",
            "px-6 h-[40px]": size === "base",
            "px-6 h-[48px]": size === "lg",
          },
        ]
      : [],
    level === "primary"
      ? {
          "text-white": true,
          "border-blue-900 bg-blue-900": !status,
          "border-red-900 bg-red-900": status === "error",
          "border-green-900 bg-green-900": status === "success",
          "border-yellow-800 bg-yellow-800": status === "warning",
        }
      : null,
    level === "secondary"
      ? {
          "bg-transparent border-gray-100": true,
          "hover:border-blue-900 text-blue-900": !status,
          "hover:border-red-900 text-red-900": status === "error",
          "hover:border-green-900 text-green-900": status === "success",
          "hover:border-yellow-800 text-yellow-800": status === "warning",
        }
      : null,
    level === "tertiary"
      ? {
          "bg-transparent border-transparent": true,
          "text-blue-900": !status,
          "text-red-900": status === "error",
          "text-green-900": status === "success",
          "text-yellow-800": status === "warning",
        }
      : null
  )
}

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    ButtonProps
>(function Button(
  {
    className,
    sm,
    size,
    status,
    inline,
    fullWidth,
    level,
    type,
    disabled,
    align,
    verticalAlign,
    minWidth,
    ...props
  },
  ref
) {
  const styledClassName = useMemo(
    () =>
      getButtonClassName({
        size,
        status,
        level,
        fullWidth,
        inline,
        type,
        disabled,
        align,
        verticalAlign,
        minWidth,
      }),
    [
      size,
      status,
      level,
      fullWidth,
      inline,
      type,
      disabled,
      align,
      verticalAlign,
      minWidth,
    ]
  )
  return (
    <button
      type={type || "button"}
      className={styledClassName}
      ref={ref}
      disabled={disabled}
      {...props}
    />
  )
})

export const ButtonLink = React.forwardRef<
  HTMLAnchorElement,
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > &
    ButtonProps
>(function Button(
  {
    className,
    sm,
    size = "base",
    status,
    inline,
    fullWidth,
    level,
    children,
    type,
    disabled,
    href,
    download,
    align,
    minWidth,
    ...props
  },
  ref
) {
  const styledClassName = useMemo(
    () =>
      getButtonClassName({
        size,
        status,
        level,
        fullWidth,
        inline,
        disabled,
        align,
        minWidth,
      }),
    [size, status, level, fullWidth, inline, disabled, align, minWidth]
  )
  return (
    <a
      className={styledClassName}
      ref={ref}
      {...props}
      href={disabled ? "#disabled" : href}
      download={disabled ? false : download}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault()
        } else {
          props.onClick?.(e)
        }
      }}
    >
      {children}
    </a>
  )
})

export type CBButtonStateTypes = "disabled" | "loading"
export type CBButtonStatusTypes =
  | "warning"
  | "danger"
  | "success"
  | "cashIn"
  | "cashOut"
export type CBButtonLevel = "primary" | "secondary" | "tertiary"
export type CBButtonProps = {
  size?: "sm" | "lg"
  status?: CBButtonStatusTypes
  level?: CBButtonLevel
  fullWidth?: boolean
  children: React.ReactNode
  inline?: boolean
  type?: "submit" | "reset" | "button"
  iconPlacement?: "left" | "right"
  loading?: boolean
  rounded?: React.ComponentProps<typeof Box>["rounded"]
  contentAlign?: "left" | "center"
}
export const CBButton = React.forwardRef<
  HTMLButtonElement,
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    CBButtonProps
>(function CBButton(
  {
    size = "sm",
    status,
    disabled,
    fullWidth,
    level,
    children,
    type,
    inline,
    loading,
    rounded,
    contentAlign = "center",
    iconPlacement,
    onClick,
  },
  ref: ForwardedRef<HTMLButtonElement>
) {
  const state: CBButtonStateTypes | undefined = disabled
    ? "disabled"
    : loading
    ? "loading"
    : undefined
  level = level || (type === "submit" ? "primary" : "secondary")
  const buttonProps = useMemo(
    () =>
      getCBButtonProps({
        size,
        status,
        level,
        fullWidth,
        inline,
        iconPlacement,
        state,
        rounded,
        contentAlign,
      }),
    [
      size,
      status,
      level,
      inline,
      fullWidth,
      state,
      iconPlacement,
      rounded,
      contentAlign,
    ]
  )

  const buttonClasses = useMemo(() => {
    return getBgColorClassesForCBButton({ state, level, inline, status })
  }, [inline, level, state, status])

  return (
    <Box
      ref={ref}
      as={"button"}
      type={type || "button"}
      disabled={disabled || loading}
      className={buttonClasses}
      {...buttonProps}
      onClick={onClick}
    >
      {children}
    </Box>
  )
})

export function getBgColorClassesForCBButton({
  state,
  level,
  status,
  inline,
}: {
  level?: CBButtonLevel
  inline?: boolean
  state?: CBButtonStateTypes
  status?: CBButtonStatusTypes
}) {
  return classNames(
    level === "primary" && !inline && !state
      ? {
          "bg-[#4863D4]": true,
          "bg-[#179F51]": status === "success",
          "bg-[#C93B3B]": status === "danger",
          "bg-[#BD610D]": status === "warning",
        }
      : null,
    level === "primary" && !inline && state
      ? {
          "bg-[#E0E0E0]": state === "disabled",
          "bg-[#91A1E5]": state === "loading" && !status,
          "bg-[#74C597]": state === "loading" && status === "success",
          "bg-[#DF8989]": state === "loading" && status === "danger",
          "bg-[#D7A06E]": state === "loading" && status === "warning",
        }
      : null,
    {
      "cursor-not-allowed": state,
      "hover:bg-[#4159BF]": !inline && !state && level === "primary" && !status,
      "hover:bg-[#158F49]":
        !inline && !state && level === "primary" && status === "success",
      "hover:bg-[#AA570C]":
        !inline && !state && level === "primary" && status === "warning",
      "hover:bg-[#B53535]":
        !inline && !state && level === "primary" && status === "danger",
      "hover:bg-[#F5F5F5]":
        !inline && !state && (level === "secondary" || level === "tertiary"),
    }
  )
}

function getColorsForButtons({
  level,
  state,
  status,
  inline,
}: {
  level?: CBButtonLevel
  status?: CBButtonStatusTypes
  state?: CBButtonStateTypes
  inline?: boolean
}): Pick<BoxOwnProps, "color"> {
  switch (level) {
    case "primary":
      return {
        color:
          state === "disabled"
            ? "textLow"
            : inline
            ? status === "success"
              ? "textSuccess"
              : status === "danger"
              ? "textError"
              : status === "warning"
              ? "textWarning"
              : status === "cashIn"
              ? "textCashIn"
              : status === "cashOut"
              ? "textCashOut"
              : "textPrimary"
            : "textOnSurface",
      }
    default:
      return {
        color:
          state === "disabled"
            ? "textLow"
            : state === "loading"
            ? status === "success"
              ? "interactionsSuccessInProgress"
              : status === "danger"
              ? "interactionsDangerInProgress"
              : status === "warning"
              ? "interactionsWarningInProgress"
              : "interactionsPrimaryInProgress"
            : status === "success"
            ? "textSuccess"
            : status === "danger"
            ? "textError"
            : status === "warning"
            ? "textWarning"
            : status === "cashIn"
            ? "textCashIn"
            : "textPrimary",
      }
  }
}

export function getCBButtonProps({
  size,
  status,
  level,
  fullWidth,
  state,
  inline,
  rounded,
  contentAlign,
  iconPlacement,
}: { state?: CBButtonStateTypes } & Pick<
  CBButtonProps,
  | "size"
  | "status"
  | "level"
  | "fullWidth"
  | "iconPlacement"
  | "inline"
  | "rounded"
  | "contentAlign"
>): BoxOwnProps {
  const colorStyles = getColorsForButtons({ level, status, state, inline })
  const props: BoxOwnProps = {
    rounded: rounded || "md",
    fontSize: "bt",
    display: "flex",
    alignItems: "center",
    justifyContent: contentAlign === "left" ? "start" : "center",
    textTransform: "capitalize",
    height: inline ? "auto" : size === "sm" ? "10" : "12",
    width: fullWidth ? "full" : "auto",
    gap: "2",
    paddingLeft: inline ? "0" : iconPlacement === "left" ? "7" : "8",
    paddingRight: inline ? "0" : iconPlacement === "right" ? "7" : "8",
    borderColor: "borderOutline",
    borderWidth: level === "secondary" && !inline ? "1" : "0",
    ...colorStyles,
  }

  return props
}
