import Page from "./Page"
import ChangePhoneNumber from "./../ChangePhoneNumber"

export default function SmChangePhoneNumberPage() {
  return (
    <Page title="Change Mobile Number" backTo={`/m/profile`} bgColor="white">
      <ChangePhoneNumber />
    </Page>
  )
}
