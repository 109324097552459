import { useCallback, useMemo } from "react"
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire"
import {
  collection,
  doc,
  query,
  where,
  orderBy,
  increment,
  updateDoc,
} from "firebase/firestore"
import type { CollectionReference } from "firebase/firestore"

export type THelpDoc = {
  id: string
  category?: string
  title: string
  answerMarkdown: string
  platforms: Array<"mobile" | "web">
  sortId: number
  downvoteCount?: number
  upvoteCount?: number
}

function useHelpDocsCollection() {
  const store = useFirestore()
  return collection(store, "HelpDocs") as CollectionReference<THelpDoc>
}

export function useHelpDocDocument(docId: string) {
  const collection = useHelpDocsCollection()
  return doc(collection, docId)
}

export function useHelpDocs() {
  const collection = useHelpDocsCollection()
  const collectionQuery = query(
    collection,
    where("platforms", "array-contains", "web"),
    orderBy("sortId", "asc")
  )
  const { data: helpDocs } = useFirestoreCollectionData(collectionQuery, {
    idField: "id",
  })
  // get the categories from help docs
  const categories = useMemo(
    () =>
      helpDocs.reduce<{
        categoriesIndex: { [key: string]: number }
        categories: Array<{
          id: string
          name?: string
          docs: Array<THelpDoc>
        }>
      }>(
        ({ categoriesIndex, categories }, doc) => {
          const id = doc.category || "default"
          if (!categoriesIndex[id]) {
            categoriesIndex[id] = categories.length
            categories.push({
              id,
              name: doc.category,
              docs: [],
            })
          }
          categories[categoriesIndex[id]].docs.push(doc)
          return { categoriesIndex, categories }
        },
        { categoriesIndex: {}, categories: [] }
      ).categories,
    [helpDocs]
  )
  return {
    helpDocs,
    categories,
  }
}

export function useUpvoteHelpDoc(docId: string) {
  const doc = useHelpDocDocument(docId)
  return useCallback(
    async function upvote() {
      await updateDoc(doc, {
        upvoteCount: increment(1),
      })
    },
    [doc]
  )
}

export function useDownvoteHelpDoc(docId: string) {
  const doc = useHelpDocDocument(docId)
  return useCallback(
    async function downvote() {
      await updateDoc(doc, {
        downvoteCount: increment(1),
      })
    },
    [doc]
  )
}

export function useHelpDoc(docId: string) {
  const doc = useHelpDocDocument(docId)
  const { data: helpDoc } = useFirestoreDocData(doc, {
    idField: "id",
  })
  return {
    helpDoc,
  }
}
