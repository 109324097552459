import { PageMeta, Box } from "@cashbook/web-components"
import ProfilePage from "./../ProfilePage"
import Page from "./Page"

export default function SmProfilePage() {
  return (
    <>
      <PageMeta>
        <title>Your Profile</title>
      </PageMeta>
      <Page title="Your Profile" backTo={`/m/businesses`} bgColor="white">
        <Box padding="4">
          <ProfilePage />
        </Box>
      </Page>
    </>
  )
}
