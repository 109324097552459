import { useBook, useBooksForBusinessId } from "@cashbook/data-store/books"
import { Navigate, useParams, Outlet, useNavigate } from "react-router-dom"
import { Alert, SpinnerIcon, Text } from "@cashbook/web-components"

import ErrorBoundary from "../ErrorBoundary"
import { SuspenseWithPerf } from "reactfire"
import { useEffect } from "react"
import { useProfile } from "@cashbook/data-store/users"
import { useBusinesses } from "@cashbook/data-store/businesses"

export function RedirectToTransactionsFromIndexPage({
  routePrefix = "",
}: {
  routePrefix?: string
}) {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return <Navigate to={`${routePrefix}/`} replace />
  return (
    <Navigate
      to={`${routePrefix}/businesses/${businessId}/cashbooks/${bookId}/transactions`}
      replace
    />
  )
}

export function BookDetailsPageLayout() {
  const params: { bookId?: string; businessId?: string } = useParams()
  const { bookId, businessId } = params
  const { user } = useProfile()
  const { businesses } = useBusinesses()
  const navigate = useNavigate()
  const { getBooksForTeamMember } = useBooksForBusinessId(businessId || "")
  const books = getBooksForTeamMember(user.uid)

  useEffect(() => {
    if (!books.length) {
      navigate(`/businesses/${businessId}/cashbooks`, { replace: true })
    } else if (!books.find((b) => b.id === bookId)) {
      navigate(`/businesses/${businesses[0].id}/cashbooks`, { replace: true })
    }
  }, [businesses, businessId, bookId, navigate, books])
  return (
    <ErrorBoundary key={bookId}>
      <SuspenseWithPerf
        fallback={
          <div className="text-center py-8">
            <SpinnerIcon />
          </div>
        }
        traceId="ensuringBookExistence"
      >
        <EnsuredBookExistence />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function EnsuredBookExistence() {
  const params: { bookId?: string } = useParams()
  const { isDeleted } = useBook(params.bookId || "")
  if (isDeleted)
    return (
      <Alert status="error">
        <Text>
          You have been removed from the book/Or the book has been deleted.
        </Text>
      </Alert>
    )
  return (
    <>
      <Outlet />
    </>
  )
}
