import { queryToSearch } from "@cashbook/util-general"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Alert,
  ArrowLeftIcon,
  ChevronDownIcon,
  LogoIcon,
  PageMeta,
  SpinnerIcon,
  Box,
  Heading,
  Text,
  Stack,
  Inline,
  getButtonClassName,
} from "@cashbook/web-components"
import { useEffect } from "react"
import { Link, Navigate, useSearchParams } from "react-router-dom"
import { useAuth } from "reactfire"
import { useShareBookInvitation } from "@cashbook/data-store/books"
import config from "../config"
import sharedBookIllustrationUrl from "./../SharedBookInvitations/shared_cashbook_illustartion.png"

export default function SharedBookInvitationDetailsPage() {
  const [searchParams] = useSearchParams()
  // const invitationId = searchParams.get("inviteId")
  const invitationToken =
    searchParams.get("token") || searchParams.get("inviteId")
  const { invitation, status, error } = useShareBookInvitation(invitationToken)
  const { currentUser } = useAuth()
  const authUserId = currentUser?.uid
  // track how is viewing the invitation
  useEffect(() => {
    if (invitation) {
      if (!authUserId) {
        trackEvent(TrackingEvents.INVITE_SHOWN_NEW_USER)
      } else {
        trackEvent(TrackingEvents.INVITE_SHOWN_ALREADY_USER)
      }
    }
  }, [authUserId, invitation])
  if (currentUser) {
    return (
      <Navigate
        to={`/accept-invite${queryToSearch({
          token: invitationToken,
        })}`}
      />
    )
  }
  return (
    <>
      <PageMeta>
        <title>{invitation?.hostName || "Friend"}'s Invitation</title>
      </PageMeta>
      <Stack
        alignItems="center"
        justifyContent="center"
        paddingY={{ sm: "0", md: "8" }}
      >
        <Stack gap="4" maxWidth="4xl" bgColor="white" rounded="lg">
          <Inline
            gap="2"
            justifyContent="center"
            alignItems="center"
            padding="4"
          >
            <LogoIcon size="12" />
            <Heading as="h2" fontSize="2xl" fontWeight="medium">
              {config.appTitle} Invitation
            </Heading>
          </Inline>
          <Box height="px" bgColor="gray100"></Box>
          <Inline
            collapseBelow="sm"
            gap="4"
            alignItems="center"
            justifyContent="center"
            padding="4"
          >
            <Box width={{ sm: "1/2" }}>
              <Box maxWidth={{ xs: "xs", sm: "full" }}>
                <Box
                  as="img"
                  src={sharedBookIllustrationUrl}
                  alt="Shared Book Illustration"
                  size="full"
                />
              </Box>
            </Box>
            <Box flex="1" width="full" minWidth="0">
              {!invitationToken ? (
                <Stack gap="8">
                  <Stack gap="4">
                    <Heading as="h1" fontSize="3xl">
                      Missing Invitation
                    </Heading>
                    <Text>
                      You are accessing a shared-book invitation page but there
                      is no invitation with the shared link.
                    </Text>
                    <Text>
                      Please re-check the invitation link and try again.
                    </Text>
                  </Stack>
                  <Link
                    to="/"
                    className={getButtonClassName({ level: "primary" })}
                  >
                    Ok, Got it. <ChevronDownIcon rotate="270" />
                  </Link>
                </Stack>
              ) : status === "failed" ? (
                <Stack gap="8">
                  <Stack gap="4">
                    <Heading as="h1" fontSize="xl">
                      Oops!
                    </Heading>
                    <Text color="gray500">
                      There seems to be an issue with this invitation.
                    </Text>
                  </Stack>
                  <Alert status="error">{error?.message}</Alert>
                  <Text color="gray500">
                    If you need more assistance, please contact our support.
                  </Text>
                  <Link
                    to="/"
                    className={getButtonClassName({ level: "primary" })}
                  >
                    Ok, Got it. <ChevronDownIcon className="rotate-270" />
                  </Link>
                </Stack>
              ) : status === "in_progress" ? (
                <Box textAlign="center">
                  <SpinnerIcon /> Loading invitation details...
                </Box>
              ) : status === "success" && invitation ? (
                <Stack gap="8">
                  <Stack gap="4">
                    <Heading as="h1" fontSize="h1">
                      {invitation.hostName} added you to a{" "}
                      {invitation.businessId || invitation.businessName
                        ? "business"
                        : "shared cashbook"}
                      .
                    </Heading>
                    <Text fontSize="b1">
                      You will join &ldquo;
                      {invitation.businessName || invitation.bookName}&rdquo;
                      {invitation.businessName ? " business" : " book"} once you
                      login/register on {config.appTitle} using &ldquo;
                      {invitation?.guestEmail ||
                        invitation?.guestPhone}&rdquo;{" "}
                      {invitation?.guestEmail?.length
                        ? "email address"
                        : "mobile number"}
                      .
                    </Text>
                  </Stack>
                  <Box>
                    <Link
                      to={
                        `/login` +
                        queryToSearch({
                          next: `/accept-invite?token=${invitation.inviteId}&after-signup=1`,
                          email: invitation?.guestEmail || "",
                          phoneNumber: invitation?.guestPhone || "",
                          warn_on_login_change: 1,
                        })
                      }
                      state={invitation}
                      className={getButtonClassName({ level: "primary" })}
                    >
                      Login/Register Now <ArrowLeftIcon rotate="180" />
                    </Link>
                  </Box>
                </Stack>
              ) : null}
            </Box>
          </Inline>
        </Stack>
      </Stack>
    </>
  )
}
