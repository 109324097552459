import { useParams, Outlet, Link } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import { useBook } from "@cashbook/data-store/books"
import {
  DataLoadingFallback,
  PageMeta,
  Box,
  Text,
  Heading,
  Stack,
  InformationCircleIcon,
} from "@cashbook/web-components"
import Page from "./Page"
import { TransactionDetails } from "../../Transactions"
import ErrorBoundary from "../../ErrorBoundary"

export default function SmBookTransactionDetailsPage() {
  const { bookId, businessId, transactionId } = useParams()
  if (!bookId || !businessId || !transactionId) return null
  return (
    <Page
      title="Entry Details"
      backTo={
        bookId
          ? `/m/businesses/${businessId}/cashbooks/${bookId}/transactions`
          : `/m/businesses/${businessId}/cashbooks`
      }
      bgColor="white"
    >
      {bookId && transactionId ? (
        <SuspenseWithPerf
          fallback={<DataLoadingFallback label="Loading book details..." />}
          traceId="loading_book_details"
        >
          <BookTransactionDetailsLayout
            bookId={bookId}
            businessId={businessId}
            transactionId={transactionId}
            key={businessId + "-" + bookId + "-" + transactionId}
          />
        </SuspenseWithPerf>
      ) : (
        <Box padding="4">
          The entry you are looking for doen't exists or might have been
          removed.
        </Box>
      )}
    </Page>
  )
}

function BookTransactionDetailsLayout({
  bookId,
  businessId,
  transactionId,
}: {
  bookId: string
  businessId: string
  transactionId: string
}) {
  const { book } = useBook(bookId)
  return (
    <>
      <PageMeta>
        <title>{book.name} Details</title>
      </PageMeta>
      <Box padding="4">
        <SuspenseWithPerf
          fallback={<DataLoadingFallback label="Loading entry details..." />}
          traceId="loading_entry_details"
        >
          <TransactionDetails
            book={book}
            businessId={businessId}
            transactionId={transactionId}
          />
        </SuspenseWithPerf>
      </Box>
    </>
  )
}
export function SmEnsureTransactionExistsPage() {
  const { bookId, businessId, transactionId } = useParams()
  if (!bookId || !businessId || !transactionId) return null
  return (
    <ErrorBoundary
      errorRenderer={({
        isMissingPermissionsError,
        isNotFoundError,
        defaultRenderedMessage,
      }) => (
        <Page
          title="Book Details"
          backTo={`/m/businesses/${businessId}/cashbooks`}
        >
          {isMissingPermissionsError || isNotFoundError ? (
            <Stack
              paddingY={{ xs: "8", sm: "16" }}
              paddingX="4"
              marginX="auto"
              maxWidth="2xl"
              gap="4"
            >
              <InformationCircleIcon size="12" />
              <Heading as="h2" fontSize="2xl" fontWeight="medium">
                Entry Not Found
              </Heading>
              <Text>
                The entry has been either deleted or you don't have access to it
                anymore. Please{" "}
                <Text
                  as={Link}
                  to={`/m/businesses/${businessId}/cashbooks/${bookId}/transactions`}
                  color="blue900"
                  fontWeight="medium"
                >
                  select any other entry
                </Text>{" "}
                to view details
              </Text>
              <hr />
              <Text>
                If you think this is an issue with the application, please
                contact our{" "}
                <Text as={Link} to="/docs" color="blue900" fontWeight="medium">
                  support
                </Text>
                .
              </Text>
            </Stack>
          ) : (
            defaultRenderedMessage
          )}
        </Page>
      )}
    >
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading Entry details..." />}
        traceId="loading_transaction_details"
      >
        <Outlet />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}
