import {
  Alert,
  ArrowLeftIcon,
  Box,
  CBButton,
  DataLoadingFallback,
  DotsVerticalIcon,
  Heading,
  Inline,
  LabelIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PageMeta,
  PlusIcon,
  Stack,
  Text,
} from "@cashbook/web-components"
import { useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import {
  useBook,
  BOOK_PERMISSIONS,
  TBookCustomField,
} from "@cashbook/data-store/books"
import {
  AddNewCustomFieldInDialog,
  DeleteCustomFieldInDialog,
  EditCustomFieldInDialog,
} from "../Books/EntryFields"
import { pluralize } from "@cashbook/util-general"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"

export default function BookCustomFieldSettingsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <BookCustomFieldSettings
          key={bookId}
          bookId={bookId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

const fieldLabel = "Custom field"

function BookCustomFieldSettings({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book, checkIfAuthenticatedMemberCan } = useBook(bookId)
  const { customFields } = book
  const canUpdate = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.CONFIGURE_CUSTOM_FIELDS
  )

  const fields: TBookCustomField[] = useMemo(() => {
    return (
      Object.keys(customFields || {})
        .map((key) => {
          return {
            name: customFields?.[key].name,
            order: customFields?.[key].order,
            required: customFields?.[key].required,
            type: customFields?.[key].type,
            fieldName: key,
          } as TBookCustomField
        })
        .sort((a, b) => (a.order || 0) - (b.order || 0)) || []
    )
  }, [customFields])

  return (
    <>
      <PageMeta>
        <title>
          {fieldLabel} - {book.name}
        </title>
      </PageMeta>
      <Box
        key={book.id}
        bgColor="white"
        paddingTop="6"
        paddingBottom="18"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        <Box maxWidth="xl">
          <Stack gap="8">
            <Stack gap="6">
              {!canUpdate ? (
                <Alert status="warning">
                  You don't have permissions to update fields in this book.
                </Alert>
              ) : null}
              <Box display={{ xs: "none", md: "block" }}>
                <Stack gap="6">
                  <Heading fontSize="sm" fontWeight="medium" color="gray500">
                    Entry Field | {fieldLabel}
                  </Heading>
                  <Inline gap="6" alignItems="center">
                    <Link
                      to={`/businesses/${businessId}/cashbooks/${book.id}/settings/fields`}
                    >
                      <ArrowLeftIcon />
                    </Link>
                    <Box flex="1">
                      <Heading fontSize="md" fontWeight="semibold">
                        {fieldLabel}
                      </Heading>
                    </Box>
                  </Inline>
                </Stack>
              </Box>
            </Stack>
            {!fields?.length ? (
              <Box padding="4" maxWidth="md" width="full" marginX="auto">
                <Stack gap="6">
                  <Stack gap="6" alignItems="center">
                    <Stack
                      size="18"
                      rounded="full"
                      borderWidth="4"
                      borderColor="blue50"
                      bgColor="blue100"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <LabelIcon color="blue900" size="8" />
                    </Stack>
                    <Stack gap="3" alignItems="center">
                      <Heading fontWeight="semibold" fontSize="lg">
                        No Custom Fields Found
                      </Heading>
                    </Stack>
                  </Stack>
                  <Stack gap="4">
                    <AddNewCustomFieldInDialog book={book}>
                      {({ add }) => (
                        <CBButton
                          size="lg"
                          level="primary"
                          fullWidth
                          onClick={() => {
                            add()
                            trackEvent(
                              TrackingEvents.ADD_NEW_CUSTOM_FIELD_CLICKED
                            )
                          }}
                          disabled={!canUpdate || fields.length >= 5}
                        >
                          <PlusIcon />
                          Add New {fieldLabel}
                        </CBButton>
                      )}
                    </AddNewCustomFieldInDialog>
                  </Stack>
                </Stack>
              </Box>
            ) : (
              <Box>
                <Stack gap="8">
                  {canUpdate ? (
                    <Stack gap="3">
                      <AddNewCustomFieldInDialog book={book}>
                        {({ add }) => (
                          <CBButton
                            size="lg"
                            iconPlacement="left"
                            contentAlign="left"
                            onClick={() => {
                              add()
                              trackEvent(
                                TrackingEvents.ADD_NEW_CUSTOM_FIELD_CLICKED
                              )
                            }}
                            disabled={!canUpdate || fields.length >= 5}
                          >
                            <PlusIcon />
                            Add New {fieldLabel}
                          </CBButton>
                        )}
                      </AddNewCustomFieldInDialog>
                      <Text color="textMedium">
                        You can add upto 5 custom fields for this book!
                      </Text>
                    </Stack>
                  ) : null}
                  <Stack gap="6">
                    <Heading
                      as="h4"
                      fontSize="base"
                      fontWeight="semibold"
                      color="gray500"
                    >
                      {pluralize(fieldLabel, fields)} in this book (
                      {fields?.length})
                    </Heading>
                    <Stack as="ol" gap="4">
                      {fields.map((field) => {
                        const { fieldName, name, required } = field
                        return (
                          <Inline
                            key={fieldName}
                            as="li"
                            alignItems="center"
                            gap="4"
                            borderWidth="1"
                            rounded="md"
                            paddingX="4"
                            paddingY="2"
                          >
                            <Stack flex="1" gap="1">
                              <Text className="break-all">{name}</Text>
                              <Text
                                fontSize="c2"
                                color="textMedium"
                                textTransform="capitalize"
                              >
                                {required ? "Required" : "Optional"}
                              </Text>
                            </Stack>
                            <Box>
                              {canUpdate ? (
                                <DeleteCustomFieldInDialog
                                  book={book}
                                  field={field}
                                >
                                  {({ onDelete }) => (
                                    <EditCustomFieldInDialog
                                      book={book}
                                      field={field}
                                    >
                                      {({ onEdit }) => (
                                        <Menu>
                                          <MenuButton inline>
                                            <DotsVerticalIcon className="text-gray-500" />
                                          </MenuButton>
                                          <MenuList align="bottom-right">
                                            <MenuItem
                                              action="update"
                                              onClick={onEdit}
                                            >
                                              Edit
                                            </MenuItem>
                                            <MenuItem
                                              action="delete"
                                              onClick={onDelete}
                                            >
                                              Delete
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      )}
                                    </EditCustomFieldInDialog>
                                  )}
                                </DeleteCustomFieldInDialog>
                              ) : null}
                            </Box>
                          </Inline>
                        )
                      })}
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </>
  )
}
