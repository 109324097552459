import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  ArrowLeftIcon,
  DataLoadingFallback,
  DotsVerticalIcon,
  InformationCircleIcon,
  UsersIcon,
  UserAddIcon,
  Time,
  Menu,
  MenuButton,
  MenuItem,
  MenuLink,
  MenuList,
  PageMeta,
  Text,
  Heading,
  Box,
  TrashIcon,
  Stack,
  Inline,
  getButtonClassName,
} from "@cashbook/web-components"
import { Link, useNavigate, useParams, NavLink, Outlet } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import { DeleteBook } from "../../Books"
import {
  BOOK_PERMISSIONS,
  useBook,
  getRoleDetails,
} from "@cashbook/data-store/books"
import ErrorBoundary from "../../ErrorBoundary"
import Page from "./Page"
import { usePartyOrContact } from "@cashbook/data-store/books"

export default function SmBookSettingsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <BookSettingsLayout
          key={bookId}
          bookId={bookId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BookSettingsLayout({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book, checkIfAuthenticatedMemberCan, involvedUsers } = useBook(bookId)
  const navigate = useNavigate()
  const editorRoleDetails = getRoleDetails("editor")
  // The book has been deleted (but sub-collections are not!!)
  // https://firebase.google.com/docs/firestore/manage-data/delete-data#delete_documents
  const { partyOrContact } = usePartyOrContact()
  return (
    <>
      <PageMeta>
        <title>{book.name} Details</title>
      </PageMeta>
      <Page
        title="Book Details"
        backTo={`/m/businesses/${businessId}/cashbooks/${book.id}/transactions`}
        bgColor="white"
        actions={
          <DeleteBook
            book={book}
            onSuccess={() => {
              navigate(`/businesses/${businessId}/cashbooks`)
            }}
          >
            {({ onDelete }) => (
              <Menu>
                <MenuButton inline>
                  <DotsVerticalIcon />
                </MenuButton>
                <MenuList align="bottom-right" className="whitespace-pre">
                  {checkIfAuthenticatedMemberCan(
                    BOOK_PERMISSIONS.ADD_MEMBER
                  ) ? (
                    <MenuLink
                      to={`members`}
                      onClick={() => {
                        trackEvent(TrackingEvents.ADD_MEMBERS_CLICKED, {
                          countExistingMembers: book.sharedWith?.length,
                        })
                      }}
                    >
                      <Inline gap="2" alignItems="center">
                        <UserAddIcon color="gray500" />
                        Add Member
                      </Inline>
                    </MenuLink>
                  ) : null}
                  <hr />
                  {checkIfAuthenticatedMemberCan(
                    BOOK_PERMISSIONS.DELETE_BOOK
                  ) ? (
                    <MenuItem
                      action="delete-book"
                      className="whitespace-pre"
                      onClick={() => onDelete()}
                    >
                      <Inline gap="2" alignItems="center" color="red900">
                        <TrashIcon color="red900" />
                        Delete Book
                      </Inline>
                    </MenuItem>
                  ) : null}
                </MenuList>
              </Menu>
            )}
          </DeleteBook>
        }
      >
        <Box paddingX="4" paddingY="8">
          <Stack gap="8">
            <Stack gap="3">
              <Heading
                as="h2"
                color="gray500"
                fontWeight="medium"
                fontSize="sm"
              >
                Book Details
              </Heading>
              <Box
                bgColor="white"
                borderWidth="1"
                borderColor="gray100"
                rounded="md"
                padding="4"
              >
                <Stack gap="2">
                  <Text fontSize="lg" fontWeight="medium">
                    {book.name}
                  </Text>
                  {book.createdAt ? (
                    <Inline gap="2">
                      <Text fontSize="sm">
                        Created on{" "}
                        <Time timeStamp={book.createdAt} format="dd/MM/yyyy" />
                      </Text>
                      {book.sharedWith?.length > 1 ? (
                        <>
                          <Text as="span" fontSize="sm">
                            •
                          </Text>
                          <Inline gap="1" alignItems="center">
                            <UsersIcon size="3" />
                            <Text fontSize="sm">{book.sharedWith.length}</Text>
                          </Inline>
                        </>
                      ) : null}
                    </Inline>
                  ) : null}
                </Stack>
              </Box>
            </Stack>
            <Stack gap="3">
              <Heading
                as="h2"
                color="gray500"
                fontWeight="medium"
                fontSize="sm"
              >
                Settings
              </Heading>
              <Box
                as="ol"
                className="divide-y"
                borderWidth="1"
                bgColor="white"
                borderColor="gray100"
                rounded="md"
                marginBottom="24"
              >
                {[
                  {
                    label: "Members",
                    description: "Add, Change role, Remove",
                    to: "members",
                  },
                  {
                    label: "Entry Field",
                    description: `${partyOrContact}, Category & Payment mode`,
                    to: "fields",
                  },
                ]
                  .concat(
                    involvedUsers.length > 1 &&
                      checkIfAuthenticatedMemberCan(
                        BOOK_PERMISSIONS.UPDATE_MEMBER_ROLES
                      )
                      ? [
                          {
                            label: `Edit ${editorRoleDetails.title} Role`,
                            description:
                              "Make changes in role as per your need",
                            to: `roles/editor`,
                          },
                        ]
                      : []
                  )
                  .map(({ label, description, to }) => (
                    <Box as="li" padding="2" key={label}>
                      <Inline
                        as={NavLink}
                        to={to}
                        padding="3"
                        gap="4"
                        alignItems="center"
                        justifyContent="between"
                      >
                        <Stack gap="2">
                          <Heading
                            as="h4"
                            fontWeight="semibold"
                            fontSize="base"
                          >
                            {label}
                          </Heading>
                          <Text
                            color="gray500"
                            fontWeight="medium"
                            fontSize="sm"
                          >
                            {description}
                          </Text>
                        </Stack>
                        <Box>
                          <ArrowLeftIcon color="blue900" rotate="180" />
                        </Box>
                      </Inline>
                    </Box>
                  ))}
              </Box>
            </Stack>
            {checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.ADD_MEMBER) ? (
              <Box
                width="full"
                bgColor="white"
                paddingX="4"
                paddingY="4"
                borderTopWidth="1"
                className="fixed left-0 bottom-0"
              >
                <Link
                  className={getButtonClassName({
                    level: "primary",
                    size: "lg",
                    fullWidth: true,
                  })}
                  to={`members`}
                  onClick={() => {
                    trackEvent(TrackingEvents.ADD_MEMBERS_CLICKED, {
                      countExistingMembers: book.sharedWith?.length,
                    })
                  }}
                >
                  <Inline gap="2" alignItems="center">
                    <UserAddIcon />
                    Add Member
                  </Inline>
                </Link>
              </Box>
            ) : null}
          </Stack>
        </Box>
      </Page>
    </>
  )
}

export function SmEnsureBookExistsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary
      errorRenderer={({
        isMissingPermissionsError,
        defaultRenderedMessage,
      }) => (
        <Page title="Book Details" backTo={`/m/books`}>
          {isMissingPermissionsError ? (
            <Stack
              paddingY={{ xs: "8", sm: "16" }}
              paddingX="4"
              maxWidth="2xl"
              gap="4"
            >
              <InformationCircleIcon size="12" />
              <Heading as="h2" fontSize="2xl" fontWeight="medium">
                Book Not Found
              </Heading>
              <Text>
                The book has been either deleted or you don't have access to it
                anymore. Please{" "}
                <Text
                  as={Link}
                  color="blue900"
                  fontWeight="medium"
                  to="/m/businesses"
                >
                  select any other book
                </Text>{" "}
                to view details and add entries
              </Text>
              <hr />
              <Box>
                <Text>
                  If you think this is an issue with the application, please
                  contact our{" "}
                  <Text
                    as={Link}
                    to="/docs"
                    color="blue900"
                    fontWeight="medium"
                  >
                    support
                  </Text>
                  .
                </Text>
              </Box>
            </Stack>
          ) : (
            defaultRenderedMessage
          )}
        </Page>
      )}
    >
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <Outlet />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}
