import { logError, logInfo } from "@cashbook/util-logging"
import {
  Alert,
  Button,
  FormField,
  formikOnSubmitWithErrorHandling,
  Modal,
  ModalBody,
  ModalFooter,
} from "@cashbook/web-components"
import { Form, Formik } from "formik"
import { useCallback, useEffect, useRef, useState } from "react"
import { useAuth } from "reactfire"
import * as Validator from "yup"
import type {
  PhoneAuthCredential,
  RecaptchaVerifier as TRecaptchaVerifier,
  AuthError,
} from "firebase/auth"
import { AuthErrorCodes, RecaptchaVerifier } from "firebase/auth"
import { $Values } from "utility-types"
import { useSendVerificationCode } from "@cashbook/data-store/auth"

export function VerifyPhoneNumber({
  children,
}: {
  children: (props: {
    verifyPhoneNumber: (phoneNumber: string) => Promise<PhoneAuthCredential>
    attempts: number
  }) => React.ReactNode
}) {
  const {
    phoneVerificationId,
    sendVerificationCode,
    resetVerification,
    createAuthCredentials,
    setCaptchaVerifier,
  } = useSendVerificationCode()
  const [attempts, setAttempts] = useState(0)
  const withCredentialsSuccessCallback = useRef<
    null | ((credentials: PhoneAuthCredential) => void)
  >(null)
  const withCredentialsFailedCallback = useRef<null | ((e?: Error) => void)>(
    null
  )
  const verifyPhoneNumber = useCallback(
    async (phoneNumber: string): Promise<PhoneAuthCredential> => {
      try {
        await sendVerificationCode(phoneNumber)
        return new Promise((resolve, reject) => {
          withCredentialsSuccessCallback.current = resolve
          withCredentialsFailedCallback.current = reject
        })
      } catch (error) {
        setAttempts((count) => count + 1)
        const e = error as AuthError
        if (e.code) {
          const errorCode = e.code as $Values<typeof AuthErrorCodes>
          switch (errorCode) {
            case AuthErrorCodes.INVALID_PHONE_NUMBER:
            case AuthErrorCodes.MISSING_PHONE_NUMBER:
              throw new Error("Please provide a valid mobile number")
            case AuthErrorCodes.CAPTCHA_CHECK_FAILED:
              throw new Error(
                "Invalid captcha. Please refresh the page and try again"
              )
            case AuthErrorCodes.SECOND_FACTOR_LIMIT_EXCEEDED:
              throw new Error(
                "Verification tries exceeded. Please try after some time"
              )
            case AuthErrorCodes.NETWORK_REQUEST_FAILED:
              throw new Error("Network Error. Please try after some time")
            default:
              logError(e)
              throw new Error("Can not verify. Please try after some time")
          }
        }
        throw e
      }
    },
    [sendVerificationCode]
  )
  return (
    <>
      {children({ verifyPhoneNumber, attempts })}
      <Recaptcha onLoad={setCaptchaVerifier} />
      {phoneVerificationId ? (
        <Modal
          isOpen={Boolean(phoneVerificationId)}
          onClose={() => {
            resetVerification()
            withCredentialsFailedCallback.current?.()
          }}
          title="Verify Mobile Number"
          size="sm"
        >
          <Formik
            initialValues={{ code: "" }}
            validationSchema={Validator.object().shape({
              code: Validator.mixed().required(
                "Please enter the code sent your mobile number."
              ),
            })}
            onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
              const credentials = createAuthCredentials(values.code)
              withCredentialsSuccessCallback.current?.(credentials)
              resetVerification()
            })}
          >
            {({ isSubmitting, status }) => (
              <Form noValidate>
                <ModalBody>
                  <FormField
                    type="number"
                    name="code"
                    placeholder="e.g. 132412"
                    label="Enter Security Code"
                    required
                    className="max-w-xs"
                    help="Please enter security code/OTP sent to your mobile number"
                  />
                  {status ? <Alert status="error">{status}</Alert> : null}
                </ModalBody>
                <ModalFooter>
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Please wait..." : "Submit"}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      resetVerification()
                      withCredentialsFailedCallback.current?.()
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      ) : null}
    </>
  )
}

export function Recaptcha({
  onLoad,
}: {
  /**
   * Get the captcha instance,
   * IMPORTANT: This must be a memozied function
   */
  onLoad: (captchVerifier: TRecaptchaVerifier | null) => void
}) {
  const auth = useAuth()
  const [recaptchaContainer, setRecaptchaContainer] =
    useState<HTMLDivElement | null>(null)
  const captchaRendererTimerRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const verifierRef = useRef<TRecaptchaVerifier | null>(null)

  useEffect(() => {
    if (captchaRendererTimerRef.current) {
      clearTimeout(captchaRendererTimerRef.current)
      captchaRendererTimerRef.current = undefined
    }
    try {
      verifierRef.current?.clear()
      verifierRef.current = null
      onLoad(null)
    } catch (e) {
      const error = e as Error
      logInfo("Captcha Clearance", {
        tags: {
          message: error.message,
        },
      })
    }
    captchaRendererTimerRef.current = setTimeout(() => {
      if (recaptchaContainer) {
        const captchVerifier = new RecaptchaVerifier(
          recaptchaContainer,
          {
            size: "invisible",
          },
          auth
        )
        captchVerifier
          .render()
          .then((_widgetId) => {
            onLoad(captchVerifier)
          })
          .catch((e) => {
            onLoad(null)
            const error = e as Error
            logInfo("Captcha Rendering", {
              tags: {
                message: error.message,
              },
            })
          })
      }
    }, 2000)
    return () => {
      if (captchaRendererTimerRef.current) {
        clearTimeout(captchaRendererTimerRef.current)
      }
      try {
        onLoad(null)
        verifierRef.current?.clear()
        verifierRef.current = null
      } catch (e) {
        const error = e as Error
        logInfo("Captcha Clearance", {
          tags: {
            message: error.message,
          },
        })
      }
    }
  }, [auth, recaptchaContainer, verifierRef, onLoad])
  return <div ref={setRecaptchaContainer} id="recaptcha_container"></div>
}
