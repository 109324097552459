import { DataLoadingFallback } from "@cashbook/web-components"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import {
  AddPaymentModeFieldInDialog,
  ImportPaymentModeFieldInDialog,
  EditPaymentModeFieldInDialog,
  DeletePaymentModeFieldInDialog,
  useTogglePaymentModeField,
} from "../Books"
import { useBook, BOOK_PERMISSIONS } from "@cashbook/data-store/books"
import { BookFieldSettingsDetails } from "./BookFieldCategorySettingsPage"
import { useTogglePaymentModeFieldRequired } from "../Books/EntryFields"

export default function BookFieldPaymentModeSettingsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <BookFieldPaymentModeSettings
          key={bookId}
          bookId={bookId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BookFieldPaymentModeSettings({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book, checkIfAuthenticatedMemberCan } = useBook(bookId)
  const canUpdate = useMemo(
    () => checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS),
    [checkIfAuthenticatedMemberCan]
  )
  return (
    <BookFieldSettingsDetails
      canUpdate={canUpdate}
      book={book}
      businessId={businessId}
      fieldLabel="Payment Mode"
      fields={book.paymentModes}
      fieldDisabled={Boolean(book.preferences?.paymentModesDisabled)}
      fieldRequired={Boolean(book.preferences?.paymentModesRequired)}
      useToggleField={useTogglePaymentModeField}
      AddFieldInDialogComponent={AddPaymentModeFieldInDialog}
      ImportFieldInDialog={ImportPaymentModeFieldInDialog}
      EditFieldInDialogComponent={EditPaymentModeFieldInDialog}
      DeleteFieldInDialogComponent={DeletePaymentModeFieldInDialog}
      useToggleRequiredFieldFlag={useTogglePaymentModeFieldRequired}
    />
  )
}
