import { queryToSearch } from "@cashbook/util-general"
import { logError, logInfo } from "@cashbook/util-logging"
import {
  ArrowLeftIcon,
  Heading,
  Text,
  Inline,
  Box,
  Icon,
} from "@cashbook/web-components"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react"
import toast from "react-hot-toast"
import { Link } from "react-router-dom"
import { Optional } from "utility-types"
import {
  getMessaging,
  isSupported as isMessagingSupported,
  getToken,
  deleteToken,
  onMessage,
} from "firebase/messaging"
import {
  useNotificationSubscriptionPreference,
  IfProfileCompleted,
} from "@cashbook/data-store/users"
import { SuspenseWithPerf, useFirebaseApp } from "reactfire"
import { IfAuthenticated } from "@cashbook/data-store/auth"

// https://firebase.google.com/docs/reference/js/firebase.messaging.MessagePayload
type TMessagePayload = {
  // The collapse key of this message
  collapseKey: string
  // sender of this message
  from: string
  // Options for features provided by the FCM SDK for Web
  fcmOptions?: {
    //Label associated with the message's analytics data
    analyticsLabel?: string
    // The link to open when the user clicks on the notification.
    link?: string
  }
  // notification payload
  notification?: {
    // The body of a notification.
    body?: string
    // The URL of the image that is shown with the notification.
    image?: string
    // The title of a notification.
    title?: string
  }
  // Arbitrary key/value pairs.
  data?: {
    [key: string]: string
    notifee: string
  }
}

enum NotificationTypes {
  AddEntry = "addEntry",
  UpdateEntry = "updateEntry",
  BookUpdates = "bookUpdates",
}

type TNotificationData =
  | {
      type: NotificationTypes.AddEntry
      bookId: string
      entryBy: string
      createdByUserId: string
      bookName: string
      amount: number
      entryType: "cash-in" | "cash-out"
      remark: string
    }
  | {
      type: NotificationTypes.UpdateEntry
      bookId: string
      updatedBy: string
      updatedByUserId: string
      bookName: string
      amount: number
      entryType: "cash-in" | "cash-out"
      remark: string
    }
  | {
      type: NotificationTypes.BookUpdates | "others"
      title: string
      body: string
    }

type TNotificationContext = {
  currentToken?: string
  lastToken?: string
  isUpdatingToken: boolean
  isSupportedByBrowser: boolean
  hasPermissions: boolean
  hasBlocked: boolean
  requestToken: (onSuccess: (token: string) => Promise<void>) => Promise<string>
  revokeToken: (
    onSuccess: (token?: string) => Promise<void>
  ) => Promise<string | undefined>
  error?: Error
}

const NotificationContext = createContext<TNotificationContext>({
  currentToken: undefined,
  lastToken: undefined,
  isUpdatingToken: false,
  hasPermissions: false,
  hasBlocked: false,
  isSupportedByBrowser: false,
  requestToken: () => Promise.reject("Missing context"),
  revokeToken: () => Promise.reject("Missing context"),
  error: new Error("Missing context"),
})

export function NotificationsProvider(
  props: React.ComponentProps<typeof NotificationsProviderEnsured>
) {
  return (
    <EnsureNotificationsAreSupported>
      {({ isSupported }) =>
        isSupported === undefined ? null : isSupported ? (
          <NotificationsProviderEnsured {...props} />
        ) : (
          <NotificationsProviderNotSupported>
            {props.children}
          </NotificationsProviderNotSupported>
        )
      }
    </EnsureNotificationsAreSupported>
  )
}

function EnsureNotificationsAreSupported({
  children,
}: {
  children: (props: { isSupported: boolean | undefined }) => React.ReactNode
}) {
  const [isSupported, setState] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    isMessagingSupported().then((supported) => setState(supported))
  }, [])
  return <>{children({ isSupported })}</>
}

function NotificationsProviderNotSupported({
  children,
}: {
  children: React.ReactNode
}) {
  const context: TNotificationContext = useMemo(() => {
    return {
      currentToken: undefined,
      lastToken: undefined,
      isUpdatingToken: false,
      isSupportedByBrowser: false,
      hasPermissions: false,
      hasBlocked: false,
      requestToken: () => Promise.reject("Not supported by browser"),
      revokeToken: () => Promise.reject("Not supported by browser"),
      error: new Error("Not supported by browser"),
    }
  }, [])
  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  )
}

function transformNotification(notification: TNotificationData): {
  title: string
  body: string
  link?: string
} {
  switch (notification.type) {
    case NotificationTypes.AddEntry:
      return {
        title: `${notification.entryBy} added an entry to '${notification.bookName}'`,
        body: `${notification.amount} (${notification.entryType} - ${notification.remark})`,
        link: `/books/${notification.bookId}/transactions${queryToSearch({
          q: notification.remark,
        })}`,
      }
    case NotificationTypes.UpdateEntry:
      return {
        title: `${notification.updatedBy} updated an entry in '${notification.bookName}'`,
        body: `${notification.amount} (${notification.entryType} - ${notification.remark})`,
        link: `/books/${notification.bookId}/transactions${queryToSearch({
          q: notification.remark,
        })}`,
      }
    default:
      return {
        title: notification.title || "Updates in your cash books",
        body: notification.body || "Please check your books in CashBook app.",
        link: "/",
      }
  }
}

function useMessaging() {
  const app = useFirebaseApp()
  return getMessaging(app)
}

function NotificationsProviderEnsured({
  children,
  vapidKey,
}: {
  children: React.ReactNode
  vapidKey: string
}) {
  const messaging = useMessaging()
  type TState = {
    currentToken?: string
    lastToken?: string
    isUpdatingToken: boolean
    hasPermissions: boolean
    hasBlocked: boolean
    error?: Error
  }

  const [
    {
      currentToken,
      lastToken,
      error,
      isUpdatingToken,
      hasPermissions,
      hasBlocked,
    },
    setState,
  ] = useReducer<React.Reducer<TState, Optional<TState>>, TState>(
    (state, action) => ({ ...state, ...action }),
    {
      currentToken: undefined,
      lastToken: undefined,
      error: undefined,
      isUpdatingToken: false,
      hasPermissions: false,
      hasBlocked: false,
    },
    (init) => {
      let lastToken: string | undefined = undefined
      try {
        lastToken = window.localStorage.getItem("notifyme_token") || undefined
      } catch (e: unknown) {
        const error = e as Error
        logInfo(error.message || "Unable to access the localStorage")
      }
      return {
        ...init,
        hasPermissions: getNotificationPermissionStatus() === "granted",
        hasBlocked: getNotificationPermissionStatus() === "denied",
        lastToken,
      }
    }
  )
  const requestToken = useCallback(
    async (onSuccess: (token: string) => Promise<void>) => {
      setState({
        currentToken: undefined,
        isUpdatingToken: true,
        error: undefined,
      })
      try {
        const currentToken = await getToken(messaging, {
          vapidKey: vapidKey,
        })
        setState({
          hasPermissions: true,
        })
        if (currentToken) {
          await onSuccess(currentToken)
          setState({
            currentToken: currentToken,
            lastToken: currentToken,
            isUpdatingToken: false,
            hasPermissions: true,
          })
          try {
            window.localStorage.setItem("notifyme_token", currentToken)
          } catch (e) {
            const error = e as Error
            logInfo(error.message || "Unable to access the localStorage")
          }
          return currentToken
        } else {
          const e = new Error(
            "Sorry! We are unable to access our notification service. Please try again later."
          )
          setState({
            currentToken: undefined,
            isUpdatingToken: false,
            hasPermissions: true,
            hasBlocked: false,
            error: e,
          })
          throw e
        }
      } catch (e) {
        const error = e as Error
        setState({ currentToken: undefined, isUpdatingToken: false, error })
        throw e
      }
    },
    [messaging, vapidKey]
  )

  const revokeToken = useCallback(
    async (onSuccess: (token?: string) => Promise<void>) => {
      const token = currentToken || lastToken
      setState({ isUpdatingToken: true, error: undefined })
      if (currentToken) {
        try {
          await deleteToken(messaging)
        } catch (e) {
          const error = e as Error
          logInfo(error.message)
        }
      }
      await onSuccess(token)
      setState({ currentToken: undefined, isUpdatingToken: false })
      return token
    },
    [messaging, currentToken, lastToken]
  )
  useEffect(() => {
    function handleChange(e: React.ChangeEvent<PermissionStatus>) {
      const hasGrantedPermissions = e.currentTarget.state === "granted"
      const hasBlockedPermissions = e.currentTarget.state === "denied"
      if (
        hasPermissions !== hasGrantedPermissions ||
        hasBlocked !== hasBlockedPermissions
      ) {
        setState({
          hasPermissions: hasGrantedPermissions,
          hasBlocked: hasBlockedPermissions,
        })
      }
    }
    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          notificationPerm.addEventListener("change", handleChange as any)
        })
      return () => {
        navigator.permissions
          .query({ name: "notifications" })
          .then(function (notificationPerm) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            notificationPerm.removeEventListener("change", handleChange as any)
          })
      }
    }
    return () => undefined
  }, [hasPermissions, hasBlocked])

  const context: TNotificationContext = useMemo(() => {
    return {
      currentToken,
      lastToken,
      isSupportedByBrowser: true,
      isUpdatingToken,
      hasPermissions,
      requestToken,
      revokeToken,
      error,
      hasBlocked,
    }
  }, [
    currentToken,
    requestToken,
    revokeToken,
    isUpdatingToken,
    hasPermissions,
    lastToken,
    error,
    hasBlocked,
  ])
  return (
    <NotificationContext.Provider value={context}>
      {children}
      <SuspenseWithPerf fallback={null} traceId="auto_subscribing">
        <IfAuthenticated>
          <IfProfileCompleted>
            <ConnectIfAlreadySubscribed />
          </IfProfileCompleted>
        </IfAuthenticated>
      </SuspenseWithPerf>
    </NotificationContext.Provider>
  )
}

export function useNotifications() {
  return useContext(NotificationContext)
}

/**
 * Automatically subscribe to the notifications channel if user is already subscribed
 */
function ConnectIfAlreadySubscribed() {
  const {
    hasPermissions,
    requestToken,
    revokeToken,
    currentToken,
    lastToken,
    isUpdatingToken,
    error,
  } = useNotifications()
  const { hasSubscribed, subscribe, unsubscribe } =
    useNotificationSubscriptionPreference()
  const messaging = useMessaging()

  // subscribe if needed
  useEffect(() => {
    if (
      hasPermissions &&
      hasSubscribed &&
      !currentToken &&
      !isUpdatingToken &&
      !error
    ) {
      requestToken((token) => {
        return subscribe(token)
      }).catch((e) => {
        logError(e)
      })
    }
  }, [
    hasPermissions,
    hasSubscribed,
    currentToken,
    requestToken,
    isUpdatingToken,
    subscribe,
    error,
  ])

  // auto un-subscribe if needed
  useEffect(() => {
    if (hasSubscribed && !hasPermissions) {
      if (currentToken && !isUpdatingToken && !error) {
        console.log("permissions changed, revoke and unsubscribe")
        revokeToken((token) => unsubscribe(token)).catch((e) => logError(e))
      }
      if (!currentToken) {
        console.log("permissions changed, no last token unsubscribe")
        unsubscribe(lastToken).catch((e) => logError(e))
      }
    }
  }, [
    hasPermissions,
    hasSubscribed,
    currentToken,
    isUpdatingToken,
    error,
    lastToken,
    unsubscribe,
    revokeToken,
  ])

  // subscribe to notification messages
  useEffect(() => {
    // called when app is in foreground (visible in the current browser tab)
    // https://firebase.google.com/docs/cloud-messaging/js/receive
    if (hasSubscribed && hasPermissions) {
      console.log("subscribe to notification messages")
      const unsubscribe = onMessage(messaging, (message) => {
        const payload = message as TMessagePayload
        if (!payload.data) return
        const notificationDataRaw = payload.data.notifee // added via our backend notification library
        try {
          const notificationData: TNotificationData =
            JSON.parse(notificationDataRaw).data
          const n = transformNotification(notificationData)
          showNotification(n)
        } catch (e) {
          logError(e)
        }
      })
      return () => {
        console.log("unsubscribe from notification messages")
        unsubscribe()
      }
    }
    return () => undefined
  }, [messaging, hasSubscribed, hasPermissions])
  return null
}

export function useToggleNotificationSubscription() {
  const {
    requestToken,
    revokeToken,
    isUpdatingToken,
    isSupportedByBrowser,
    hasPermissions,
    hasBlocked,
  } = useNotifications()
  const { hasSubscribed, subscribe, unsubscribe } =
    useNotificationSubscriptionPreference()
  const toggleSubscription = useCallback(async () => {
    if (hasSubscribed) {
      if (
        window.confirm("Are you sure you want to disable app notifications ?")
      ) {
        await revokeToken((token) => unsubscribe(token))
      }
    } else {
      await requestToken((token) => subscribe(token))
    }
  }, [hasSubscribed, requestToken, revokeToken, subscribe, unsubscribe])
  return {
    hasPermissions,
    hasSubscribed,
    toggleSubscription,
    isUpdating: isUpdatingToken,
    isSupportedByBrowser,
    hasBlocked,
  }
}

////////////////////////////////////
///////// helper utils /////////////
///////////////////////////////////
function getNotificationPermissionStatus(): NotificationPermission {
  if ("Notification" in window) {
    return Notification.permission
  }
  return "default"
}

function showNotification(n: { title: string; body: string; link?: string }) {
  toast(
    (t) => (
      <Inline alignItems="center" gap="2">
        <Box flex="1">
          <Heading as="h6" fontSize="sm">
            {n.title}
          </Heading>
          <Text fontSize="xs">{n.body}</Text>
        </Box>
        {n.link ? (
          <Box>
            <Text
              as={Link}
              fontSize="xs"
              to={n.link}
              onClick={() => {
                toast.dismiss(t.id)
              }}
            >
              <ArrowLeftIcon rotate="180" />
            </Text>
          </Box>
        ) : null}
      </Inline>
    ),
    {
      duration: 7000,
      icon: (
        <Icon size="5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
        </Icon>
      ),
      style: {
        background: "#333",
        color: "#fff",
      },
    }
  )
}
