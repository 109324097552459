export function NoBooksIcon() {
  return (
    <svg
      width="182"
      height="128"
      viewBox="0 0 182 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M178 27.356H4C1.79086 27.356 0 29.1592 0 31.3836V116.972C0 119.197 1.79086 121 4 121H178C180.209 121 182 119.197 182 116.972V31.3836C182 29.1592 180.209 27.356 178 27.356Z"
        fill="#E2E6F7"
      />
      <path d="M57 38.4321H8V109.924H57V38.4321Z" fill="white" />
      <path d="M115 38.4321H67V109.924H115V38.4321Z" fill="white" />
      <path d="M173 38.4321H125V109.924H173V38.4321Z" fill="white" />
      <path
        d="M123.062 123.976L104.562 91.4938C109.031 88.2914 112.114 83.4898 113.181 78.0726C114.247 72.6554 113.216 67.0326 110.299 62.3558C107.382 57.679 102.8 54.3023 97.4905 52.9172C92.1812 51.5322 86.5468 52.2436 81.7414 54.9058C76.9361 57.5681 73.3235 61.9796 71.6437 67.2368C69.9638 72.494 70.3438 78.199 72.7058 83.1833C75.0678 88.1676 79.2331 92.0538 84.3484 94.0461C89.4638 96.0384 95.1421 95.9858 100.22 93.8993L118.432 126.624C118.604 126.932 118.834 127.203 119.109 127.422C119.385 127.64 119.701 127.801 120.039 127.896C120.377 127.991 120.73 128.017 121.078 127.974C121.426 127.931 121.762 127.82 122.067 127.645V127.645C122.373 127.471 122.642 127.237 122.857 126.958C123.073 126.678 123.232 126.359 123.324 126.017C123.416 125.676 123.441 125.319 123.396 124.968C123.351 124.617 123.237 124.279 123.061 123.972L123.062 123.976ZM86.7722 93.0051C82.9937 91.9555 79.6091 89.8013 77.0464 86.8148C74.4838 83.8284 72.8581 80.1438 72.375 76.2271C71.892 72.3104 72.5731 68.3374 74.3324 64.8105C76.0917 61.2836 78.8502 58.3611 82.2589 56.4127C85.6676 54.4643 89.5736 53.5774 93.4829 53.8641C97.3922 54.1509 101.129 55.5985 104.222 58.0237C107.314 60.449 109.623 63.7432 110.856 67.4896C112.089 71.236 112.191 75.2665 111.149 79.0713C110.457 81.5981 109.278 83.9628 107.679 86.0306C106.079 88.0983 104.091 89.8286 101.827 91.1225C99.5633 92.4164 97.0687 93.2486 94.4856 93.5717C91.9025 93.8947 89.2814 93.7022 86.7722 93.0051Z"
        fill="#9AA5D0"
      />
      <path
        d="M80.126 87.2756C80.1053 87.2601 80.0856 87.2433 80.067 87.2253L79.905 87.0662C79.767 86.9232 79.544 86.7269 79.294 86.4419C78.5652 85.6523 77.9091 84.7976 77.334 83.8883C76.9324 83.2625 76.5668 82.614 76.239 81.946C75.8695 81.1773 75.5491 80.3856 75.28 79.5757C74.021 75.8334 73.9919 71.7825 75.197 68.0223C75.6725 66.4944 76.3399 65.034 77.183 63.6764C77.7464 62.7605 78.3899 61.8972 79.106 61.0967C79.353 60.8077 79.572 60.6073 79.706 60.4613L79.866 60.3002C79.903 60.2649 79.923 60.2468 79.925 60.2498C79.9096 60.2707 79.8929 60.2906 79.875 60.3093L79.722 60.4794C79.593 60.6294 79.378 60.8318 79.137 61.1279C78.4363 61.9371 77.8048 62.8045 77.249 63.7207C76.8651 64.3524 76.5166 65.0053 76.205 65.6761C75.8528 66.4489 75.5486 67.2428 75.294 68.0535C74.1093 71.7945 74.1383 75.8182 75.377 79.5415C75.6428 80.3484 75.9588 81.1377 76.323 81.9047C76.6446 82.5711 77.0028 83.2188 77.396 83.845C77.9646 84.7537 78.6086 85.6124 79.321 86.4117C79.566 86.7007 79.783 86.9011 79.914 87.0511C79.98 87.1216 80.03 87.177 80.069 87.2162C80.0888 87.2346 80.1072 87.2545 80.124 87.2756H80.126Z"
        fill="#7D8297"
      />
      <path
        d="M86.494 56.749C86.3069 56.8417 86.113 56.9198 85.914 56.9826C85.553 57.1196 85.06 57.3179 84.532 57.5737C84.004 57.8295 83.542 58.0943 83.209 58.2926C83.0367 58.411 82.8551 58.515 82.666 58.6038C82.8186 58.4594 82.9862 58.3322 83.166 58.2242C83.5869 57.9458 84.0259 57.6962 84.48 57.477C84.9352 57.2562 85.4049 57.0674 85.886 56.9121C86.0816 56.835 86.2852 56.7802 86.493 56.749H86.494Z"
        fill="#7D8297"
      />
      <path
        d="M22.2232 110.084L30.1032 108.682C31.1912 108.488 31.9174 107.443 31.7252 106.348L20.0672 39.896C19.875 38.8005 18.8372 38.0693 17.7492 38.2628L9.86924 39.6645C8.78124 39.858 8.05505 40.903 8.24724 41.9985L19.9052 108.45C20.0974 109.546 21.1352 110.277 22.2232 110.084Z"
        fill="#C6CDEB"
      />
      <path
        d="M47.8642 109.868L40.9712 108.645C40.4488 108.552 39.9845 108.254 39.6803 107.817C39.3761 107.379 39.257 106.838 39.3492 106.312L46.4682 65.6553C46.5603 65.1294 46.8562 64.6618 47.2908 64.3556C47.7253 64.0493 48.2628 63.9293 48.7852 64.0221L55.6782 65.2455C55.9369 65.2914 56.1841 65.3881 56.4057 65.5301C56.6273 65.6722 56.8189 65.8568 56.9696 66.0735C57.1204 66.2902 57.2272 66.5346 57.2841 66.7928C57.341 67.0511 57.3468 67.3181 57.3012 67.5786L50.1812 108.235C50.089 108.761 49.7931 109.229 49.3586 109.535C48.9241 109.841 48.3865 109.961 47.8642 109.868V109.868Z"
        fill="#C6CDEB"
      />
      <path
        d="M171.105 39.6778L163.225 38.2762C162.137 38.0827 161.099 38.8139 160.907 39.9094L149.249 106.361C149.057 107.457 149.783 108.502 150.871 108.695L158.751 110.097C159.839 110.29 160.877 109.559 161.069 108.464L172.727 42.0119C172.919 40.9163 172.193 39.8714 171.105 39.6778Z"
        fill="#C6CDEB"
      />
      <path d="M126 15.7002H154L150 27.3563H130L126 15.7002Z" fill="#AAB4DD" />
      <path
        d="M146.036 4.76357C146.673 6.9126 146.578 9.21456 145.766 11.3029C144.699 13.2718 143.009 14.8248 140.964 15.7145C140.327 13.5655 140.422 11.2636 141.234 9.1752C142.301 7.20629 143.991 5.65333 146.036 4.76357V4.76357Z"
        fill="#AAB4DD"
      />
      <path
        d="M136.817 0.790582C136.817 0.790582 140.804 3.40304 141.843 7.60029C142.882 11.7975 140.58 15.989 140.58 15.989C140.58 15.989 136.593 13.3765 135.554 9.17928C134.514 4.98204 136.817 0.790582 136.817 0.790582Z"
        fill="#C4CCEA"
      />
      <path
        d="M9.20484 54.9327L24.9648 52.1294L24.6168 50.1458L8.85684 52.949L9.20484 54.9327Z"
        fill="white"
      />
      <path
        d="M171.107 54.0801L155.347 51.2769L154.999 53.2605L170.759 56.0638L171.107 54.0801Z"
        fill="white"
      />
      <path
        d="M56.968 75.1026L41.208 72.2993L40.86 74.283L56.62 77.0862L56.968 75.1026Z"
        fill="white"
      />
      <path
        d="M9.20484 59.7134L24.9648 56.9102L24.6168 54.9265L8.85684 57.7298L9.20484 59.7134Z"
        fill="white"
      />
      <path
        d="M171.107 58.8599L155.347 56.0566L154.999 58.0403L170.759 60.8436L171.107 58.8599Z"
        fill="white"
      />
      <path
        d="M165.107 100.144L149.347 97.3403L148.999 99.324L164.759 102.127L165.107 100.144Z"
        fill="white"
      />
      <path
        d="M56.968 79.8834L41.208 77.0801L40.86 79.0637L56.62 81.867L56.968 79.8834Z"
        fill="white"
      />
    </svg>
  )
}
