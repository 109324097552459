import { useParams } from "react-router-dom"
import Page from "./Page"
import BookFieldPaymentModeSettingPage from "./../BookFieldPaymentModeSettingsPage"

export default function SmBookFieldPaymentModeSettingsPage() {
  const { bookId, businessId } = useParams()
  return (
    <Page
      title="Payment Mode Field"
      backTo={`/m/businesses/${businessId}/cashbooks/${bookId}/settings/fields`}
    >
      <BookFieldPaymentModeSettingPage />
    </Page>
  )
}
