import { logInfo } from "@cashbook/util-logging"
import { useCallback, useEffect, useState } from "react"
import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
} from "reactfire"
import { collection, query, where } from "firebase/firestore"
import type { CollectionReference, Timestamp } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"

export type TInvitation = {
  // ID of the the invitation document
  id: string
  // Name of the book for which the user is invited
  bookName: string
  // Name of the host (who has invited / sent the invitation)
  hostName: string
  // Token associated with the invitation
  inviteId: string
  // Invitation link
  inviteLink: string
  // Name of the guest (who is invited / received the invitation)
  guestName: string
  // Phone number of the guest (who is invited)
  guestPhone?: string
  guestEmail?: string
  // Role for which the guest is invited
  role: "editor" | "admin" | "viewer"
  // Date of creation for invitation
  createdAt: Timestamp
  // Date of invitation acceptance
  acceptedAt?: Timestamp
  // Who (user id) accepted the invitation
  acceptedBy?: string
  // Was this invitation accepted via link ?
  // Invitations can be automatically accepted on signup (without visiting the link)
  acceptedViaLink?: boolean
  // When was this invitation deleted/canceled ?
  deletedAt?: Timestamp
  // Who (user id) deleted/canceled this invitation
  deletedBy?: string
  // ID of the business for which the user is invited
  businessId?: string
  // Name of the business for which the user is invited
  businessName: string
}

function useShareBookInvitationCollection() {
  const store = useFirestore()
  return collection(store, "Invitations") as CollectionReference<TInvitation>
}

export function useShareBookInvitations(bookId: string) {
  const collection = useShareBookInvitationCollection()
  const collectionQuery = query(collection, where("bookId", "==", bookId))
  const { data: invitations } = useFirestoreCollectionData(collectionQuery, {
    idField: "id",
  })
  return {
    invitations: invitations.filter(
      (invitation) => !invitation.deletedBy && !invitation.acceptedBy
    ),
  }
}

export function useShareBookInvitation(tokenId?: string | null) {
  const fns = useFunctions()
  const [{ data: invitation, error, status }, setState] = useState<
    | {
        data: undefined
        error: null
        status: "init"
      }
    | {
        data: TInvitation
        error: null
        status: "success"
      }
    | {
        data: undefined
        error: null
        status: "in_progress"
      }
    | {
        data: undefined
        error: Error
        status: "failed"
      }
  >({ data: undefined, error: null, status: "init" })
  const getInvitation = useCallback(async () => {
    setState({
      data: undefined,
      error: null,
      status: "in_progress",
    })
    try {
      if (!tokenId) {
        throw new Error("Missing invitation")
      }
      const { data } = await httpsCallable<{ inviteId: string }, TInvitation>(
        fns,
        "getInvitationInfo"
      )({
        inviteId: tokenId,
      })
      setState({
        data,
        error: null,
        status: "success",
      })
    } catch (e) {
      const err = e as Error
      logInfo(err.message || "Error info for get invitation info")
      setState({
        data: undefined,
        error: err,
        status: "failed",
      })
    }
  }, [fns, tokenId])
  useEffect(() => {
    getInvitation()
  }, [getInvitation])
  return {
    invitation,
    error,
    status,
  }
}

export function useAcceptInvitation(tokenId?: string | null) {
  const fns = useFunctions()
  const [{ data, error, status }, setState] = useState<
    | {
        data: undefined
        error: null
        status: "init"
      }
    | {
        data: string
        error: null
        status: "success"
      }
    | {
        data: undefined
        error: null
        status: "in_progress"
      }
    | {
        data: undefined
        error: Error
        status: "failed"
      }
  >({ data: undefined, error: null, status: "init" })
  const acceptInvitation = useCallback(async () => {
    setState({
      data: undefined,
      error: null,
      status: "in_progress",
    })
    try {
      if (!tokenId) {
        throw new Error("Missing invitation")
      }
      const { data } = await httpsCallable<{ tokenId: string }, string>(
        fns,
        "syncAddToShareBook"
      )({
        tokenId,
      })
      setState({
        data,
        error: null,
        status: "success",
      })
    } catch (e) {
      const err = e as Error
      setState({
        data: undefined,
        error: err,
        status: "failed",
      })
    }
  }, [fns, tokenId])
  useEffect(() => {
    acceptInvitation()
  }, [acceptInvitation])
  return {
    bookId: data,
    error,
    status,
  }
}
