import { useParams } from "react-router-dom"
import Page from "./Page"
import BookFieldCategorySettingPage from "./../BookFieldCategorySettingsPage"

export default function SmBookFieldCategorySettingsPage() {
  const { bookId, businessId } = useParams()
  return (
    <Page
      title="Category Field"
      backTo={`/m/businesses/${businessId}/cashbooks/${bookId}/settings/fields`}
    >
      <BookFieldCategorySettingPage />
    </Page>
  )
}
