import {
  BusinessCategoriesOrSubcategories,
  BusinessTypes,
  CreateBusinessPayload,
  TBusiness,
  useCreateBusiness,
} from "@cashbook/data-store/businesses"
import {
  removeSyncStoredItem,
  useSyncedStorageState,
} from "@cashbook/data-store/storage"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  AgricultureVectorIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  Box,
  Button,
  CheckCircleSolidIcon,
  CheckIcon,
  ClothesVectorIcon,
  ConstructionVectorIcon,
  DistributorVectorIcon,
  EducationVectorIcon,
  ElectronicsVectorIcon,
  FinancialVectorIcon,
  FoodRestaurantVectorIcon,
  FormField,
  formikOnSubmitWithErrorHandling,
  GroceryVectorIcon,
  HardwareVectorIcon,
  Heading,
  HealthMedicineVectorIcon,
  Inline,
  JewelryVectorIcon,
  LogoutIcon,
  ManufacturerVectorIcon,
  Modal,
  ModalBody,
  ModalFooter,
  OtherBusinessTypeVectorIcon,
  RetailerVectorIcon,
  ServiceProviderVectorIcon,
  Stack,
  Text,
  TraderVectorIcon,
  TransportVectorIcon,
  TrashIcon,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import { Form, Formik, useField } from "formik"
import React, { SyntheticEvent, useMemo, useState } from "react"
import * as Validator from "yup"
import config from "../config"

const addBusinessDataValidationSchema = Validator.object().shape({
  name: Validator.string()
    .required(
      "Please provide a business name for the business e.g. RK Garments, MK Electronics etc."
    )
    .max(191, "Please use 191 or fewer characters"),
})

export enum BUSINESS_PROFILE_FIELDS {
  BUSINESS_LOGO = "BUSINESS_LOGO",
  BUSINESS_NAME = "BUSINESS_NAME",
  BUSINESS_ADDRESS = "BUSINESS_ADDRESS",
  BUSINESS_STAFF_SIZE = "BUSINESS_STAFF_SIZE",
  BUSINESS_CATEGORY = "BUSINESS_CATEGORY",
  BUSINESS_SUBCATEGORY = "BUSINESS_SUBCATEGORY",
  BUSINESS_TYPE = "BUSINESS_TYPE",
  BUSINESS_REGISTRATION_TYPE = "BUSINESS_REGISTRATION_TYPE",
  BUSINESS_GST_INFO = "BUSINESS_GST_INFO",
  BUSINESS_PHONE_NUMBER = "BUSINESS_PHONE_NUMBER",
  BUSINESS_EMAIL = "BUSINESS_EMAIL",
}

export const businessProfileMarksForFieldIndiaUser = {
  [BUSINESS_PROFILE_FIELDS.BUSINESS_LOGO]: 5,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_NAME]: 10,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_ADDRESS]: 10,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_STAFF_SIZE]: 10,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_CATEGORY]: 15,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_SUBCATEGORY]: 15,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_TYPE]: 15,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_REGISTRATION_TYPE]: 5,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_GST_INFO]: 10,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_PHONE_NUMBER]: 2.5,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_EMAIL]: 2.5,
}

export const businessProfileMarksForFieldNonIndiaUser = {
  [BUSINESS_PROFILE_FIELDS.BUSINESS_LOGO]: 5,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_NAME]: 10,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_ADDRESS]: 10,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_STAFF_SIZE]: 10,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_CATEGORY]: 20,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_SUBCATEGORY]: 15,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_TYPE]: 20,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_REGISTRATION_TYPE]: 5,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_PHONE_NUMBER]: 2.5,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_EMAIL]: 2.5,
  [BUSINESS_PROFILE_FIELDS.BUSINESS_GST_INFO]: 0,
}

export type BusinessCategory = {
  id: BusinessCategoriesOrSubcategories
  name: string
  description?: string
}

export function getBusinessCategories(): BusinessCategory[] {
  return [
    {
      id: "agriculture",
      name: "Agriculture",
    },
    {
      id: "construction",
      name: "Construction",
    },
    {
      id: "education",
      name: "Education",
    },
    {
      id: "electronics",
      name: "Electronics",
    },
    {
      id: "financialServices",
      name: "Financial Services",
    },
    {
      id: "foodRestaurant",
      name: "Food/Restaurant",
    },
    {
      id: "garmentsFashion",
      name: "Clothes/Fashion",
    },
    {
      id: "hardware",
      name: "Hardware",
    },
    {
      id: "jewellery",
      name: "Jewellery",
    },
    {
      id: "healthcareFitness",
      name: "Healthcare & Fitness",
    },
    {
      id: "kiranaGrocery",
      name: "Kirana/Grocery",
    },
    {
      id: "transport",
      name: "Transport",
    },
    {
      id: "others",
      name: "Other",
    },
  ]
}

export function getBusinessProfileStrength(
  business: TBusiness,
  isIndianUser: boolean
) {
  const { name, logo, details } = business
  const businessProfileMarksForField = isIndianUser
    ? businessProfileMarksForFieldIndiaUser
    : businessProfileMarksForFieldNonIndiaUser
  let totalMarksScored = 0
  let totalFieldsCompleted = 0
  const totalFieldsToComplete = isIndianUser
    ? Object.keys(businessProfileMarksForField).length
    : Object.keys(businessProfileMarksForField).length - 1

  //Sectionizing Data
  const sectionedBusinessProfile: BusinessSectionedDataPropsExt = [
    { id: "basics", title: "Basics" },
    { id: "businessInfo", title: "Business Info" },
    { id: "communication", title: "Communication" },
  ]

  if (isIndianUser) {
    sectionedBusinessProfile.splice(2, 0, {
      id: "gstInfo",
      title: "GST Info",
    })
  }

  function updateSectionedData() {
    const basicsInfoFields: Array<BusinessFieldsProps> = [
      { id: "businessName", title: "Business Name", data: name },
      {
        id: "businessAddress",
        title: "Business Address",
        data: details.address
          ? `${details.address?.buildingName} ${
              details.address?.area ? `, ${details.address.area}` : ""
            } ${`${details.address?.city}`} ${
              details.address?.state ? `, ${details.address.state}` : ""
            } ${details.address?.pinCode ? `, ${details.address.pinCode}` : ""}`
          : undefined,
      },
      {
        id: "staffSize",
        title: "Staff Size",
        data: details?.staff_size,
      },
    ]
    const businessCategoryName = details.category?.name
      ? details.category.name
      : details.category?.id
      ? getBusinessCategoryDetailsForId(details.category.id)?.name
      : undefined
    const businessInfoFields: Array<BusinessFieldsProps> = [
      {
        id: "businessCategory",
        title: "Business Category",
        data: businessCategoryName,
      },
      {
        id: "businessSubCategory",
        title: "Business Subcategory",
        data: details.category?.subCategoryId,
      },
      {
        id: "type",
        title: "Business Type",
        data: details?.type
          ? getBusinessTypeDetailsForId(details.type)?.name
          : undefined,
      },
      {
        id: "registrationType",
        title: "Business Registration Type",
        data: details?.registrationType,
      },
    ]
    let gstInfoFields: Array<BusinessFieldsProps> | null = null
    if (isIndianUser) {
      gstInfoFields = [
        {
          id: "gstNumber",
          title: "GST number",
          data: details.gst?.number,
        },
      ]
    }
    const communicationFields: Array<BusinessFieldsProps> = [
      {
        id: "businessMobileNumber",
        title: "Business Mobile Number",
        data: details?.phoneNumber,
      },
      {
        id: "businessEmail",
        title: "Business Email",
        data: details?.email,
      },
    ]
    sectionedBusinessProfile[0].fields = [...basicsInfoFields]
    sectionedBusinessProfile[1].fields = [...businessInfoFields]
    if (isIndianUser && gstInfoFields?.length) {
      sectionedBusinessProfile[2].fields = [...gstInfoFields]
    }
    if (!isIndianUser) {
      sectionedBusinessProfile[2].fields = [...communicationFields]
    } else {
      sectionedBusinessProfile[3].fields = [...communicationFields]
    }
  }
  //Sectionizing ends here

  if (logo?.image_url || logo?.thumb_url) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[BUSINESS_PROFILE_FIELDS.BUSINESS_LOGO]
    totalFieldsCompleted += 1
  }
  if (name.length) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[BUSINESS_PROFILE_FIELDS.BUSINESS_NAME]
    totalFieldsCompleted += 1
  }
  if (details.address) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[BUSINESS_PROFILE_FIELDS.BUSINESS_ADDRESS]
    totalFieldsCompleted += 1
  }
  if (details.staff_size) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[BUSINESS_PROFILE_FIELDS.BUSINESS_STAFF_SIZE]
    totalFieldsCompleted += 1
  }
  if (details.category?.id) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[BUSINESS_PROFILE_FIELDS.BUSINESS_CATEGORY]
    totalFieldsCompleted += 1
  }
  if (details.category?.subCategoryId) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[BUSINESS_PROFILE_FIELDS.BUSINESS_SUBCATEGORY]
    totalFieldsCompleted += 1
  }
  if (details.type) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[BUSINESS_PROFILE_FIELDS.BUSINESS_TYPE]
    totalFieldsCompleted += 1
  }
  if (details.registrationType) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[
        BUSINESS_PROFILE_FIELDS.BUSINESS_REGISTRATION_TYPE
      ]
    totalFieldsCompleted += 1
  }
  if (details.phoneNumber) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[
        BUSINESS_PROFILE_FIELDS.BUSINESS_PHONE_NUMBER
      ]
    totalFieldsCompleted += 1
  }
  if (details.email) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[BUSINESS_PROFILE_FIELDS.BUSINESS_EMAIL]
    totalFieldsCompleted += 1
  }
  if (
    isIndianUser &&
    (details.gst?.registered === false || details.gst?.number)
  ) {
    totalMarksScored =
      totalMarksScored +
      businessProfileMarksForField[BUSINESS_PROFILE_FIELDS.BUSINESS_GST_INFO]
    totalFieldsCompleted += 1
  }

  updateSectionedData()

  const scored = totalMarksScored / 100
  return {
    percentage: Number(Number(scored * 100).toFixed(2)),
    totalFieldsToComplete,
    totalFieldsCompleted,
    sectionedBusinessProfile,
  }
}

export type BusinessInfoKeys =
  | "basics"
  | "businessInfo"
  | "gstInfo"
  | "communication"
export type BusinessInfoDataKeys =
  | "businessName"
  | "businessAddress"
  | "staffSize"
  | "businessCategory"
  | "businessSubCategory"
  | "type"
  | "registrationType"
  | "businessMobileNumber"
  | "businessEmail"
  | "gstNumber"

type BusinessFieldsProps = {
  id?: BusinessInfoDataKeys
  title?: string
  data?: string
}

type BusinessInfoProps = {
  id: BusinessInfoKeys
  title: string
  fields?: Array<BusinessFieldsProps>
}
type BusinessSectionedDataPropsExt = Array<BusinessInfoProps>

export type BusinessType = {
  id: BusinessTypes
  name: string
  description?: string
}

export function getBusinessTypes(): BusinessType[] {
  return [
    {
      id: "retailer",
      name: "Retailer",
    },
    {
      id: "distributor",
      name: "Distributor",
    },
    {
      id: "manufacturer",
      name: "Manufacturer",
    },
    {
      id: "serviceProvider",
      name: "Service Provider",
    },
    {
      id: "trader",
      name: "Trader",
    },
    {
      id: "others",
      name: "Other",
    },
  ]
}

export function AddNewBusinessInModal({
  onSuccess,
  children,
  ...props
}: Omit<AddBusinessProps, "onCancel"> & {
  children: (props: { add: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({ add: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        placement="right"
        size="lg"
        isDismissable
        title="Add New Business"
      >
        <AddNewBusiness
          {...props}
          onSuccess={(...args) => {
            if (args.length) {
              state.close()
              onSuccess?.(...args)
            }
          }}
          onCancel={state.close}
        />
      </Modal>
    </>
  )
}

type AddBusinessProps = Omit<AddBusinessFormProps, "onSubmit"> & {
  onSuccess?: (businessId: string) => void
  onCancel?: () => void
}
export function AddNewBusiness({ onSuccess }: AddBusinessProps) {
  const createBusiness = useCreateBusiness()
  return (
    <AddNewBusinessForm
      onSubmit={async (data) => {
        const payload: CreateBusinessPayload = {
          name: data.name,
        }
        if (data.category) {
          const category = { id: data.category.id, name: data.category.name }
          payload["details"] = {
            ...payload["details"],
            category: { ...category },
          }
        }
        if (data.type) {
          const type = data.type.id
          payload["details"] = { ...payload["details"], type: type }
        }
        const { businessId } = await createBusiness(payload)
        onSuccess?.(businessId)
      }}
    />
  )
}

type AddBusinessDataSchema = {
  name: string
  category?: { id: BusinessCategoriesOrSubcategories; name?: string }
  type?: BusinessType
}
type AddBusinessFormProps = {
  onSubmit: (data: AddBusinessDataSchema) => Promise<void>
  onCancel?: () => void
}

function AddNewBusinessForm({ onSubmit }: AddBusinessFormProps) {
  const initialValues: AddBusinessDataSchema = useMemo(
    () => ({
      name: "",
      type: {} as BusinessType,
      category: {} as BusinessCategory,
    }),
    []
  )
  const [categoryCollapsed, setCategoryCollapsed] = useState<boolean>(false)
  const [businessTypeCollapsed, setBusinessTypeCollapsed] =
    useState<boolean>(false)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addBusinessDataValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={formikOnSubmitWithErrorHandling(async (values, actions) => {
        if (!values.name.trim()) {
          return actions.setFieldError(
            "name",
            "Please enter valid business name."
          )
        }
        trackEvent(TrackingEvents.BUSINESS_CATEGORY_SUBMITTED, {
          categoryId: values.category?.id || "",
          from: "addNewBusiness",
          isSkipped: false,
        })
        trackEvent(TrackingEvents.BUSINESS_TYPE_SUBMITTED, {
          typeId: values.type?.id || "",
          from: "addNewBusiness",
          isSkipped: false,
        })
        await onSubmit(values)
      })}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form noValidate>
          <ModalBody>
            <Stack gap="10">
              <Box maxWidth="lg">
                <FormField
                  type="text"
                  name="name"
                  label="Business Name"
                  placeholder="Add Business Name"
                  autoFocus
                  required
                  noMargin
                  autoComplete="off"
                  onChange={(e) => {
                    setFieldValue("name", e.target.value)
                    if (e.target.value.length === 1) {
                      setCategoryCollapsed(true)
                    }
                  }}
                />
              </Box>
              <BusinessCategorySelect
                name="category"
                collapsed={categoryCollapsed}
                onCollapse={(shouldCollapse) => {
                  setCategoryCollapsed(shouldCollapse)
                  setBusinessTypeCollapsed(!shouldCollapse)
                }}
              />
              <BusinessTypeSelect
                name="type"
                collapsed={businessTypeCollapsed}
                onCollapse={(shouldCollapse) => {
                  if (categoryCollapsed) {
                    setCategoryCollapsed(false)
                  }
                  setBusinessTypeCollapsed(shouldCollapse)
                }}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              size="lg"
              disabled={!values.name.length || isSubmitting}
            >
              {isSubmitting ? "Creating..." : "Create Business"}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

function BusinessCategorySelect({
  name,
  collapsed,
  onCollapse,
}: {
  name: string
  collapsed: boolean
  onCollapse: (shouldCollapse: boolean) => void
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ value }, _, { setValue }] = useField<BusinessCategory>(name)
  return (
    <Box
      borderWidth="1"
      rounded="md"
      padding="8"
      cursor="pointer"
      onClick={() => onCollapse(!collapsed)}
    >
      <Stack gap="8">
        <Inline alignItems="center" justifyContent="between">
          <Stack gap="1">
            <Text fontSize="md">Select Business Category</Text>
            <Text color="gray500">
              This will help us personalize your business
            </Text>
          </Stack>
          <Box bgColor={value && value.id ? "gray100" : undefined} rounded="md">
            <Inline
              alignItems="center"
              paddingX="4"
              paddingY="1"
              gap="4"
              justifyContent="between"
            >
              {value ? getIconForBusinessInfo("8")[value.id] : null}
              {value && value.name ? (
                <Text fontWeight="semibold">{value.name}</Text>
              ) : null}
              <Box>{collapsed ? <ArrowUpIcon /> : <ArrowDownIcon />}</Box>
            </Inline>
          </Box>
        </Inline>
        {collapsed ? (
          <Inline
            as="ul"
            gap="4"
            flexWrap="wrap"
            onClick={(e: SyntheticEvent) => e.stopPropagation()}
          >
            {getBusinessCategories().map((category) => {
              const isChecked = value && value.id === category.id
              return (
                <Box
                  key={category.id}
                  as="li"
                  rounded="md"
                  padding="4"
                  borderWidth={isChecked ? "2" : "1"}
                  borderColor={isChecked ? "blue900" : undefined}
                  backgroundColor={isChecked ? "blue100" : undefined}
                  position="relative"
                  className="w-[23.5%] max-w-[25%]"
                  onClick={() => {
                    onCollapse(false)
                    setValue(category)
                  }}
                >
                  {isChecked && (
                    <Box position="absolute" top="0" right="0" padding="1">
                      <CheckCircleSolidIcon size="4" color="blue900" />
                    </Box>
                  )}
                  <FormField
                    label={
                      <Inline alignItems="center" gap="4" className="break-all">
                        <Box>{getIconForBusinessInfo()[category.id]}</Box>
                        <Text fontWeight="semibold">{category.name}</Text>
                      </Inline>
                    }
                    type="radio"
                    name={name}
                    value={category.id}
                    id={`${name}.${category.id}`}
                    noMargin
                    invisibleInput
                    hideError
                    onChange={() => {
                      onCollapse(false)
                      setValue(category)
                    }}
                  />
                </Box>
              )
            })}
          </Inline>
        ) : null}
      </Stack>
    </Box>
  )
}

function BusinessTypeSelect({
  name,
  collapsed,
  onCollapse,
}: {
  name: string
  collapsed: boolean
  onCollapse: (shouldCollapse: boolean) => void
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ value }, _, { setValue }] = useField<BusinessType>(name)
  return (
    <Box
      borderWidth="1"
      rounded="md"
      padding="8"
      cursor="pointer"
      onClick={() => onCollapse(!collapsed)}
    >
      <Stack gap="8">
        <Inline alignItems="center" justifyContent="between">
          <Stack gap="1">
            <Text fontSize="md">Select Business Type</Text>
            <Text color="gray500">
              This will help us personalize your business
            </Text>
          </Stack>
          <Box bgColor={value && value.id ? "gray100" : undefined} rounded="md">
            <Inline alignItems="center" paddingX="4" paddingY="1" gap="4">
              {value.id ? getIconForBusinessInfo("8")[value.id] : null}
              {value && value.name ? (
                <Text fontWeight="semibold">{value.name}</Text>
              ) : null}
              <Box>
                <Box>{collapsed ? <ArrowUpIcon /> : <ArrowDownIcon />}</Box>
              </Box>
            </Inline>
          </Box>
        </Inline>
        {collapsed ? (
          <Inline
            as="ul"
            gap="4"
            flexWrap="wrap"
            onClick={(e: SyntheticEvent) => e.stopPropagation()}
          >
            {getBusinessTypes().map((type) => {
              const isChecked = value && value.id === type.id
              return (
                <Box
                  key={type.id}
                  as="li"
                  rounded="md"
                  padding="4"
                  borderWidth={isChecked ? "2" : "1"}
                  borderColor={isChecked ? "blue900" : undefined}
                  backgroundColor={isChecked ? "blue100" : undefined}
                  position="relative"
                  className="w-[23.5%] max-w-[25%]"
                >
                  {isChecked && (
                    <Box position="absolute" top="0" right="0" padding="1">
                      <CheckCircleSolidIcon size="4" color="blue900" />
                    </Box>
                  )}
                  <FormField
                    label={
                      <Inline alignItems="center" gap="4" className="break-all">
                        <Box>{getIconForBusinessInfo()[type.id]}</Box>
                        <Text fontWeight="semibold">{type.name}</Text>
                      </Inline>
                    }
                    type="radio"
                    name={name}
                    value={type.id}
                    id={`${name}.${type.id}`}
                    noMargin
                    invisibleInput
                    hideError
                    onChange={() => {
                      setValue(type)
                      onCollapse(false)
                    }}
                  />
                </Box>
              )
            })}
          </Inline>
        ) : null}
      </Stack>
    </Box>
  )
}

export function getIconForBusinessInfo(
  size?: React.ComponentProps<typeof Box>["size"]
): {
  [key in BusinessCategoriesOrSubcategories | BusinessTypes]: React.ReactNode
} {
  const defaultSize = size || "10"
  return {
    agriculture: <AgricultureVectorIcon size={defaultSize} />,
    construction: <ConstructionVectorIcon size={defaultSize} />,
    distributor: <DistributorVectorIcon size={defaultSize} />,
    education: <EducationVectorIcon size={defaultSize} />,
    electronics: <ElectronicsVectorIcon size={defaultSize} />,
    financialServices: <FinancialVectorIcon size={defaultSize} />,
    foodRestaurant: <FoodRestaurantVectorIcon size={defaultSize} />,
    garmentsFashion: <ClothesVectorIcon size={defaultSize} />,
    hardware: <HardwareVectorIcon size={defaultSize} />,
    healthcareFitness: <HealthMedicineVectorIcon size={defaultSize} />,
    jewellery: <JewelryVectorIcon size={defaultSize} />,
    kiranaGrocery: <GroceryVectorIcon size={defaultSize} />,
    manufacturer: <ManufacturerVectorIcon size={defaultSize} />,
    others: <OtherBusinessTypeVectorIcon size={defaultSize} />,
    retailer: <RetailerVectorIcon size={defaultSize} />,
    serviceProvider: <ServiceProviderVectorIcon size={defaultSize} />,
    trader: <TraderVectorIcon size={defaultSize} />,
    transport: <TransportVectorIcon size={defaultSize} />,
  }
}

export function getBusinessCategoryDetailsForId(
  id: BusinessCategoriesOrSubcategories
) {
  const foundBusinessCategory = getBusinessCategories().find(
    (type) => type.id === id
  )
  if (!foundBusinessCategory) {
    return null
  }
  return foundBusinessCategory as BusinessCategory
}

export function getBusinessTypeDetailsForId(id: BusinessTypes) {
  const foundBusinessType = getBusinessTypes().find((type) => type.id === id)
  if (!foundBusinessType) {
    return null
  }
  return foundBusinessType as BusinessType
}

export function LeftBusinessInformingInDialog() {
  const [isLeftBusinessPending, setIsLeftBusinessPending] =
    useSyncedStorageState<string>("leftBusiness", "")
  const state = useOverlayTriggerState({
    isOpen: isLeftBusinessPending.length ? true : false,
  })
  function onCloseModal() {
    setIsLeftBusinessPending("")
    removeSyncStoredItem("leftBusiness")
    state.close()
  }
  return (
    <Modal
      isDismissable
      size="sm"
      title="Left Business"
      isOpen={state.isOpen}
      onClose={onCloseModal}
    >
      <ModalBody>
        <Stack
          gap="6"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <LogoutIcon color="red900" size="18" />
          </Box>
          <Stack gap="3">
            <Heading as="h2" fontSize="lg">
              You have left{" "}
              {isLeftBusinessPending.length
                ? `'${isLeftBusinessPending}'`
                : "one"}{" "}
              business
            </Heading>
            <Text>
              We have removed this business from your account. If this is a
              mistake then please ask Owner/Partners of ‘
              {isLeftBusinessPending.length ? isLeftBusinessPending : "one"}’ to
              add you again
            </Text>
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button size="lg" level="primary" onClick={onCloseModal}>
          <Box>
            <CheckIcon />
          </Box>
          Ok, Got it
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export function DeletedBusinessInformingInDialog() {
  const [isDeletedBusinessPending, setIsDeletedBusinessPending] =
    useSyncedStorageState<string>("deletedBusiness", "")
  const state = useOverlayTriggerState({
    isOpen: isDeletedBusinessPending.length ? true : false,
  })
  function onCloseModal() {
    setIsDeletedBusinessPending("")
    removeSyncStoredItem("deletedBusiness")
    state.close()
    trackEvent(TrackingEvents.BUSINESS_DELETED)
  }
  return (
    <Modal
      isDismissable
      size="sm"
      title="Deleted Business"
      isOpen={state.isOpen}
      onClose={onCloseModal}
    >
      <ModalBody>
        <Stack
          gap="6"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <TrashIcon color="red900" size="18" />
          </Box>
          <Stack gap="3">
            <Heading as="h2" fontSize="lg">
              You have deleted{" "}
              {isDeletedBusinessPending.length
                ? `'${isDeletedBusinessPending}'`
                : "one"}{" "}
              business account
            </Heading>
            <Text>
              You can keep using {config.appTitle} for other businesses
            </Text>
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button size="lg" level="primary" onClick={onCloseModal}>
          <Box>
            <CheckIcon />
          </Box>
          Ok, Got it
        </Button>
      </ModalFooter>
    </Modal>
  )
}
