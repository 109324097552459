import toast from "react-hot-toast"
import { Optional } from "utility-types"
import { useCallback, useEffect, useRef, useReducer } from "react"

export function CopyToClipboard({
  children,
  ...config
}: TConfig & {
  children: (props: ReturnType<typeof useCopyToClipboard>) => React.ReactNode
}) {
  const props = useCopyToClipboard(config)
  return <>{children(props)}</>
}

type TConfig = {
  resetAfterMs?: number
}

type TState = {
  copying: boolean
  copied: boolean
}

function useCopyToClipboard(config?: TConfig) {
  const resetAfterMs = config?.resetAfterMs || 5000
  const [state, dispatch] = useReducer(
    (state: TState, action: Optional<TState>) => ({ ...state, ...action }),
    { copying: false, copied: false }
  )
  const resetTimerRef = useRef<NodeJS.Timeout | undefined>()
  useEffect(() => {
    return () => {
      if (resetTimerRef.current) {
        clearTimeout(resetTimerRef.current)
      }
    }
  }, [])
  const copy = useCallback(
    async (text: string) => {
      if (resetTimerRef.current) {
        clearTimeout(resetTimerRef.current)
      }
      dispatch({ copied: false, copying: true })
      try {
        await copyTextToClipboard(text)
        dispatch({ copying: false, copied: true })
        resetTimerRef.current = setTimeout(
          () => dispatch({ copied: false }),
          resetAfterMs
        )
      } catch (e) {
        const error = e as Error
        toast.error(error.message || "Unable to copy to clipboard")
        dispatch({ copied: false, copying: false })
      }
    },
    [resetAfterMs]
  )
  return { copy, copied: state.copied, copying: state.copying }
}

async function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  return navigator.clipboard.writeText(text)
}
function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea")
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand("copy")
    const msg = successful ? "successful" : "unsuccessful"
    console.log("Fallback: Copying text command was " + msg)
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err)
  }

  document.body.removeChild(textArea)
}
