import { normalizeNumber } from "@cashbook/util-general"
import { logInfo } from "@cashbook/util-logging"
import {
  Text,
  TextProps,
  Box,
  parsePhoneNumber,
} from "@cashbook/web-components"
import React, { useMemo } from "react"
import { SuspenseWithPerf, useAuth } from "reactfire"

export function detectCountry(): string | null {
  const timezoneToCountry: { [key: string]: string } = {
    // North America
    "America/New_York": "US",
    "America/Chicago": "US",
    "America/Denver": "US",
    "America/Los_Angeles": "US",
    "America/Anchorage": "US",
    "America/Phoenix": "US",
    "America/Honolulu": "US",
    "America/Toronto": "CA",
    "America/Vancouver": "CA",
    "America/Edmonton": "CA",
    "America/Winnipeg": "CA",
    "America/Halifax": "CA",
    "America/St_Johns": "CA",
    "America/Mexico_City": "MX",
    "America/Tijuana": "MX",
    "America/Monterrey": "MX",
    "America/Guatemala": "GT",
    "America/El_Salvador": "SV",
    "America/Managua": "NI",
    "America/Costa_Rica": "CR",
    "America/Panama": "PA",
    "America/Havana": "CU",
    "America/Santo_Domingo": "DO",
    "America/Port-au-Prince": "HT",
    "America/Puerto_Rico": "PR",

    // South America
    "America/Sao_Paulo": "BR",
    "America/Fortaleza": "BR",
    "America/Manaus": "BR",
    "America/Argentina/Buenos_Aires": "AR",
    "America/Santiago": "CL",
    "America/Lima": "PE",
    "America/Bogota": "CO",
    "America/Caracas": "VE",
    "America/La_Paz": "BO",
    "America/Asuncion": "PY",
    "America/Montevideo": "UY",
    "America/Guayaquil": "EC",
    "America/Guyana": "GY",
    "America/Paramaribo": "SR",

    // Europe
    "Europe/London": "GB",
    "Europe/Dublin": "IE",
    "Europe/Paris": "FR",
    "Europe/Berlin": "DE",
    "Europe/Rome": "IT",
    "Europe/Madrid": "ES",
    "Europe/Moscow": "RU",
    "Europe/Kiev": "UA",
    "Europe/Warsaw": "PL",
    "Europe/Amsterdam": "NL",
    "Europe/Brussels": "BE",
    "Europe/Vienna": "AT",
    "Europe/Stockholm": "SE",
    "Europe/Oslo": "NO",
    "Europe/Copenhagen": "DK",
    "Europe/Helsinki": "FI",
    "Europe/Athens": "GR",
    "Europe/Bucharest": "RO",
    "Europe/Sofia": "BG",
    "Europe/Istanbul": "TR",
    "Europe/Zurich": "CH",
    "Europe/Lisbon": "PT",
    "Europe/Prague": "CZ",
    "Europe/Budapest": "HU",
    "Europe/Minsk": "BY",
    "Europe/Zagreb": "HR",
    "Europe/Ljubljana": "SI",
    "Europe/Tallinn": "EE",
    "Europe/Riga": "LV",
    "Europe/Vilnius": "LT",
    "Europe/Belgrade": "RS",
    "Europe/Bratislava": "SK",
    "Europe/Skopje": "MK",

    // Africa
    "Africa/Cairo": "EG",
    "Africa/Johannesburg": "ZA",
    "Africa/Lagos": "NG",
    "Africa/Nairobi": "KE",
    "Africa/Casablanca": "MA",
    "Africa/Tunis": "TN",
    "Africa/Algiers": "DZ",
    "Africa/Accra": "GH",
    "Africa/Addis_Ababa": "ET",
    "Africa/Dakar": "SN",
    "Africa/Dar_es_Salaam": "TZ",
    "Africa/Abidjan": "CI",
    "Africa/Harare": "ZW",
    "Africa/Luanda": "AO",
    "Africa/Windhoek": "NA",
    "Africa/Maputo": "MZ",
    "Africa/Kampala": "UG",

    // Middle East
    "Asia/Dubai": "AE",
    "Asia/Riyadh": "SA",
    "Asia/Tehran": "IR",
    "Asia/Jerusalem": "IL",
    "Asia/Amman": "JO",
    "Asia/Beirut": "LB",
    "Asia/Baghdad": "IQ",
    "Asia/Kuwait": "KW",
    "Asia/Bahrain": "BH",
    "Asia/Doha": "QA",
    "Asia/Muscat": "OM",

    // Asia
    "Asia/Tokyo": "JP",
    "Asia/Shanghai": "CN",
    "Asia/Hong_Kong": "HK",
    "Asia/Singapore": "SG",
    "Asia/Seoul": "KR",
    "Asia/Kolkata": "IN",
    "Asia/Calcutta": "IN",
    "Asia/Bangkok": "TH",
    "Asia/Jakarta": "ID",
    "Asia/Manila": "PH",
    "Asia/Kuala_Lumpur": "MY",
    "Asia/Taipei": "TW",
    "Asia/Karachi": "PK",
    "Asia/Dhaka": "BD",
    "Asia/Colombo": "LK",
    "Asia/Kathmandu": "NP",
    "Asia/Almaty": "KZ",
    "Asia/Tashkent": "UZ",
    "Asia/Baku": "AZ",
    "Asia/Yerevan": "AM",
    "Asia/Tbilisi": "GE",
    "Asia/Vientiane": "LA",
    "Asia/Phnom_Penh": "KH",
    "Asia/Brunei": "BN",
    "Asia/Ho_Chi_Minh": "VN",
    "Asia/Ulaanbaatar": "MN",
    "Asia/Dili": "TL",
    "Asia/Thimphu": "BT",
    "Asia/Macau": "MO",

    // Oceania
    "Australia/Sydney": "AU",
    "Australia/Melbourne": "AU",
    "Australia/Perth": "AU",
    "Australia/Brisbane": "AU",
    "Australia/Adelaide": "AU",
    "Australia/Darwin": "AU",
    "Australia/Hobart": "AU",
    "Pacific/Auckland": "NZ",
    "Pacific/Fiji": "FJ",
    "Pacific/Guam": "GU",
    "Pacific/Honolulu": "US",
    "Pacific/Port_Moresby": "PG",
    "Pacific/Samoa": "WS",
    "Pacific/Tahiti": "PF",
    "Pacific/Noumea": "NC",
    "Pacific/Palau": "PW",

    // Antarctica
    "Antarctica/Casey": "AQ",
    "Antarctica/Davis": "AQ",
    "Antarctica/Mawson": "AQ",
    "Antarctica/McMurdo": "AQ",
    "Antarctica/Rothera": "AQ",
    "Antarctica/Palmer": "AQ",
    "Antarctica/Vostok": "AQ",
    "Antarctica/DumontDUrville": "AQ",

    // UTC
    UTC: "GB", // Default to GB for UTC
  }

  // Method 1: User's timezone
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (timezoneToCountry[timezone]) {
    return timezoneToCountry[timezone]
  }

  // Method 2: Browser language
  const browserLanguage =
    navigator.language || (navigator.languages && navigator.languages[0])
  if (browserLanguage) {
    const countryCode = browserLanguage.split("-")[1]
    if (countryCode) {
      return countryCode.toUpperCase()
    }
  }

  // If we can't detect the country, return null
  return null
}

export function useUserPhoneNumber() {
  const { currentUser } = useAuth()
  const phoneNumber = currentUser?.phoneNumber
  return useMemo(() => {
    if (!phoneNumber) return null
    const data = parsePhoneNumber(phoneNumber)
    if (!data) return null
    return data
  }, [phoneNumber])
}

export function useUserCountry(fallbackToCountry = "IN") {
  const data = useUserPhoneNumber()
  let country: string | undefined = data?.country
  if (!country) {
    if (data?.countryCallingCode) {
      const countryCallingCode = data.countryCallingCode
      country = COUNTRY_CALLING_CODES[countryCallingCode as string][0] as never
    }
  }
  if (!country) country = detectCountry() || fallbackToCountry
  return country
}

export function useNumberFormatter(options?: Intl.NumberFormatOptions) {
  const country = useUserCountry()
  const formatter = useMemo(
    (newOptions?: Intl.NumberFormatOptions) => {
      newOptions = { ...options, ...newOptions }
      const maximumFractionDigits =
        newOptions?.maximumFractionDigits === undefined
          ? 2
          : newOptions?.maximumFractionDigits
      try {
        if (typeof Intl !== "undefined") {
          return new Intl.NumberFormat(`en-${country.toUpperCase()}`, {
            ...newOptions,
            maximumFractionDigits,
          }).format
        }
        throw new Error("Intl not supported")
      } catch (e) {
        const error = e as Error
        logInfo(error?.message || "Unable to create numer formatter")
        return (n: number) =>
          fallbackNumberToLocalString(n, maximumFractionDigits)
      }
    },
    [country, options]
  )
  return formatter
}

export const LocalizedNumber = React.forwardRef<
  HTMLSpanElement,
  TextProps<"span"> & {
    number: number
  }
>(function LocalizedNumber({ number, ...props }, ref) {
  const format = useNumberFormatter()
  return (
    <Text as="span" {...props} ref={ref}>
      {format(number)}
    </Text>
  )
})

type CURRENCY_TYPES = "inr"

export const currencies_supported = {
  inr: "₹",
}

export const Amount = React.forwardRef<
  HTMLSpanElement,
  TextProps<"span"> & {
    currency?: CURRENCY_TYPES
    amount: number
    type?: "cash-in" | "cash-out"
    currencySpacing?: React.ComponentProps<typeof Box>["paddingRight"]
  }
>(function Amount({ currency, type, amount, currencySpacing, ...props }, ref) {
  return (
    <Text
      as="span"
      ref={ref}
      itemScope
      itemType="http://schema.org/UnitPriceSpecification"
      color={
        type === "cash-in"
          ? "textCashIn"
          : type === "cash-out"
          ? "textCashOut"
          : props.color
      }
      {...props}
    >
      {type ? (
        <Box as="span" paddingRight="1" itemProp="priceCurrency">
          {type === "cash-in" ? "+" : "-"}
        </Box>
      ) : null}
      {currency ? (
        <Box
          as="span"
          paddingRight={currencySpacing || "1"}
          itemProp="priceCurrency"
        >
          {currencies_supported[currency]}
        </Box>
      ) : null}
      <Box itemProp="price" display="inline">
        <SuspenseWithPerf
          fallback={normalizeNumber(amount)}
          traceId="intl_format"
        >
          <LocalizedNumber number={amount} />
        </SuspenseWithPerf>
      </Box>
    </Text>
  )
})

// This is list of countries where calling code is same
const COUNTRY_CALLING_CODES: { [key: string]: Array<string> } = {
  1: [
    "US",
    "AG",
    "AI",
    "AS",
    "BB",
    "BM",
    "BS",
    "CA",
    "DM",
    "DO",
    "GD",
    "GU",
    "JM",
    "KN",
    "KY",
    "LC",
    "MP",
    "MS",
    "PR",
    "SX",
    "TC",
    "TT",
    "VC",
    "VG",
    "VI",
  ],
  7: ["RU", "KZ"],
  39: ["IT", "VA"],
  44: ["GB", "GG", "IM", "JE"],
  47: ["NO", "SJ"],
  61: ["AU", "CC", "CX"],
  212: ["MA", "EH"],
  262: ["RE", "YT"],
  290: ["SH", "TA"],
  358: ["FI", "AX"],
  590: ["GP", "BL", "MF"],
  599: ["CW", "BQ"],
}

function fallbackNumberToLocalString(
  n: number | null | undefined,
  digitsAfterDecimal: number | undefined = 2
) {
  if (n === null || n === undefined) return ""
  const str = normalizeNumber(n, digitsAfterDecimal).toString()
  // we dont want to add commas in the after the decimal point
  const parts = str.split(".")
  // reading the regex
  // ?= means something MUST follow (called sometimes lookahead matches)
  //      e.g. "a?=b" => a follows b matches ab but not ac
  // ?! means something MUST NOT follow
  //      e.g  "a?!b" => a must not follow b matches ac but not ab
  // So bellow regex reads as follows
  // Other than boundries (\B), something (empty space) should be follow (?=)
  //  (\d{2}+\d{3})|(\d{3}) i.e. group of 2 digits with a group of 3 digit at end
  //  and MUST NOT (?!) follow a single digit (\d)
  // How this works ?
  // e.g. Suppose we a the input as 1234567 which should ouput 12,34,567
  // so we will start to find a match, looking at the first not boundry whitespce
  // e.g. space between 1,2, we will see that it is does not follows \d{2}+\d{3} pattern,
  // it follows \d{2}+\d{2} pattern, and so there will be no match
  // Next we move to the space between 2,3, we see that it has a match i.e. \d{2}+ => "34" and
  // \d{3} => '567', and so we put a "," at the place of this space.
  // Same goes for the space between 3,4 which does not pass the criteria.
  // Now we move to the space between 4,5. This does not passes the pattern of \d{2}+\d{3} but passes
  // \d{3} pattern (we have an OR statement for this case) and so we put a "," here.
  // Afterwards we find no match
  parts[0] = parts[0].replace(/\B(?=(((\d{2})+\d{3})|\d{3})(?!\d))/g, ",")
  return parts.join(".")
}
