import {
  PageMeta,
  SupportIcon,
  Heading,
  DataLoadingFallback,
  Inline,
  Stack,
  Box,
  getButtonClassName,
} from "@cashbook/web-components"
import { NavLink } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../../ErrorBoundary"
import { WhatsAppContactLink } from "../../HelpDocs"
import { useHelpDocs } from "@cashbook/data-store/help-docs"
import Page from "./Page"

export default function SmHelpDocsListPage() {
  return (
    <>
      <PageMeta title="Help Docs" />
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading docs..." />}
        traceId="loading_docs"
      >
        <ErrorBoundary>
          <HelpDocsLayout />
        </ErrorBoundary>
      </SuspenseWithPerf>
    </>
  )
}

export function HelpDocsLayout() {
  return (
    <Page
      backTo={`/m/books`}
      title="Help Docs"
      bgColor="white"
      actions={
        <Inline>
          <WhatsAppContactLink>
            {(props) => (
              <a
                {...props}
                className={getButtonClassName({
                  size: "sm",
                  status: "success",
                })}
              >
                <SupportIcon /> Contact Us
              </a>
            )}
          </WhatsAppContactLink>
        </Inline>
      }
    >
      <Box paddingX="4" paddingTop="8" paddingBottom="16">
        <SuspenseWithPerf
          fallback={<DataLoadingFallback label="Loading docs..." />}
          traceId="loading_docs"
        >
          <HelpDocsList />
        </SuspenseWithPerf>
      </Box>
    </Page>
  )
}

function HelpDocsList() {
  const { categories } = useHelpDocs()
  return (
    <Stack as="ol" gap="6">
      {categories.map((category) => (
        <Box as="li" key={category.id}>
          <Stack gap="3">
            {category.name ? (
              <Heading
                as="h4"
                textTransform="uppercase"
                fontSize="sm"
                fontWeight="normal"
              >
                {category.name}
              </Heading>
            ) : null}
            <Stack
              as="ol"
              borderWidth="1"
              rounded="md"
              bgColor="blue50"
              paddingY="2"
              className="divide-y"
            >
              {category.docs.map((doc) => (
                <Box as="li" key={doc.id}>
                  <Box
                    as={NavLink}
                    to={`/m/docs/${doc.id}`}
                    width="full"
                    display="block"
                    padding="3"
                  >
                    <Heading as="h5" fontSize="sm" fontWeight="medium">
                      {doc.title}
                    </Heading>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Box>
      ))}
    </Stack>
  )
}
