import { useMount } from "@cashbook/util-general"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  ArrowLeftIcon,
  PageMeta,
  Heading,
  Text,
  DataLoadingFallback,
  Box,
  Stack,
  getButtonClassName,
} from "@cashbook/web-components"
import ReactMarkdown from "react-markdown"
import { Link, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { useHelpDoc } from "@cashbook/data-store/help-docs"
import { CaptureFeedback } from "../HelpDocs"

export default function HelpDocDetailsPage() {
  const { docId } = useParams()
  if (!docId) return null
  return (
    <Box>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading docs..." />}
        traceId="loading_docs"
      >
        <ErrorBoundary>
          <HelpDoc docId={docId} />
        </ErrorBoundary>
      </SuspenseWithPerf>
    </Box>
  )
}

function HelpDoc({ docId }: { docId: string }) {
  const { helpDoc } = useHelpDoc(docId)
  useMount(() => {
    if (!helpDoc) {
      return
    }
    const { id, category, title } = helpDoc
    trackEvent(TrackingEvents.HELP_VIEWED, {
      id,
      category,
      title,
    })
  })
  if (!helpDoc) {
    return (
      <Stack
        role="alert"
        aria-label="Page not found"
        aria-live="assertive"
        gap="8"
      >
        <Stack gap="4">
          <Stack gap="2">
            <Heading
              as="h2"
              fontSize="xl"
              fontWeight="semibold"
              color="gray500"
            >
              404
            </Heading>
            <Heading as="h1" fontSize="3xl" fontWeight="medium" color="red900">
              Help Document Not Found
            </Heading>
          </Stack>
          <Text>
            Sorry! The page you are looking for doesn&apos;t exists. It might
            have been renamed or removed.
          </Text>
          <Text>Please contact us if you need any support.</Text>
        </Stack>
        <Box>
          <Link to="/docs" className={getButtonClassName({ level: "primary" })}>
            Visit Other Docs <ArrowLeftIcon className="rotate-180" />
          </Link>
        </Box>
      </Stack>
    )
  }
  const answer = helpDoc.answerMarkdown.replace(/\\n/g, "\n")
  return (
    <>
      <PageMeta>
        <title>{helpDoc.title}</title>
      </PageMeta>
      <Box>
        <Box>
          <Box marginBottom="8">
            <Heading as="h2" fontSize={{ xs: "xl", sm: "3xl" }}>
              {helpDoc.title}
            </Heading>
          </Box>
          <Box className="markdown max-w-4xl">
            <ReactMarkdown children={answer} />
          </Box>
        </Box>
        <Box marginTop={{ xs: "8", sm: "16" }}>
          <CaptureFeedback helpDoc={helpDoc} />
        </Box>
      </Box>
    </>
  )
}
