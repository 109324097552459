import {
  DataLoadingFallback,
  PageMeta,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
  CalendarIcon,
  Switch,
  Input,
  Button,
  Alert,
  HideReportsIcon,
  HideEntriesIcon,
} from "@cashbook/web-components"
import { Formik, Form } from "formik"
import { Link, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import {
  useBook,
  getRoleDetails,
  useUpdateBookPreferences,
} from "@cashbook/data-store/books"
import ErrorBoundary from "../ErrorBoundary"
import { useState } from "react"
import toast from "react-hot-toast"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"

export default function BookEditorRoleSettingsPage(
  props: Omit<
    React.ComponentProps<typeof BookEditorRoleSettings>,
    "bookId" | "businessId"
  >
) {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <BookEditorRoleSettings
          key={bookId}
          businessId={businessId}
          bookId={bookId}
          {...props}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BookEditorRoleSettings({
  bookId,
  hideTitle,
  businessId,
}: {
  bookId: string
  businessId: string
  hideTitle?: boolean
}) {
  const {
    book,
    allowedBackdatedEntriesForEditor: initialAllowedBackdatedEntriesForEditor,
    hideBalancesAndReportsForEditor: initialHideBalancesAndReportsForEditor,
    hideEntriesByOthers: initialHideEntriesByOthers,
  } = useBook(bookId)
  const [resetCounter, setResetCounter] = useState(0)
  const updateBookPreferences = useUpdateBookPreferences(book)
  // The book has been deleted (but sub-collections are not!!)
  // https://firebase.google.com/docs/firestore/manage-data/delete-data#delete_documents
  if (!book.name || !businessId) return null
  const editorRoleDetails = getRoleDetails("editor")
  return (
    <>
      <PageMeta>
        <title>Editor Role - {book.name}</title>
      </PageMeta>
      <Box
        key={bookId}
        bgColor="white"
        paddingY="6"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        <Box maxWidth="xl">
          <Formik
            key={resetCounter}
            initialValues={{
              allowedBackdatedEntriesForEditor:
                initialAllowedBackdatedEntriesForEditor,
              hideBalancesAndReportsForEditor:
                initialHideBalancesAndReportsForEditor,
              hideEntriesByOthers: initialHideEntriesByOthers,
            }}
            onSubmit={async (values) => {
              let allowBackdatedEntryParam: "always" | "never" | "oneday"
              if (values.allowedBackdatedEntriesForEditor === -1) {
                allowBackdatedEntryParam = "always"
              } else if (values.allowedBackdatedEntriesForEditor === 0) {
                allowBackdatedEntryParam = "never"
              } else {
                allowBackdatedEntryParam = "oneday"
              }
              trackEvent(TrackingEvents.DATA_OPERATOR_PERMISSION_ENABLED, {
                allowedBackdatedEntriesEnabled: allowBackdatedEntryParam,
                hideBalanceAndReportsEnabled:
                  values.hideBalancesAndReportsForEditor,
                hideEntriesByOthers: values.hideEntriesByOthers,
              })
              await updateBookPreferences({
                allowedBackdatedEntries:
                  values.allowedBackdatedEntriesForEditor,
                hideBalancesAndReports: values.hideBalancesAndReportsForEditor,
                hideEntriesByOthers: values.hideEntriesByOthers,
              })
              toast.success(`${editorRoleDetails.title} role settings updated.`)
            }}
          >
            {({ values, isSubmitting, setFieldValue, status }) => (
              <Form>
                <Stack gap="12">
                  <Stack gap="8">
                    <Stack gap="2">
                      {!hideTitle ? (
                        <Heading fontWeight="medium" fontSize="lg">
                          {editorRoleDetails.title} Role
                        </Heading>
                      ) : null}
                      <Text color="gray500" fontSize="sm">
                        Here are the customizations available for the{" "}
                        {editorRoleDetails.title} role.
                      </Text>
                      <Text
                        as="span"
                        color="yellow800"
                        fontWeight="medium"
                        fontSize="sm"
                      >
                        (Premium Trial Feature .{" "}
                        <Link
                          to="/premium"
                          target="_blank"
                          className="text-blue-900 font-medium"
                        >
                          Learn More
                        </Link>
                        )
                      </Text>
                    </Stack>
                    <Inline gap="4">
                      <Box>
                        <CalendarIcon color="blue900" />
                      </Box>
                      <Stack flex="1" gap="4">
                        <Text fontSize="md">Allow backdated entries</Text>
                        <Stack gap="3">
                          {(
                            [] as Array<{
                              title: string
                              description: string
                              value: typeof initialAllowedBackdatedEntriesForEditor
                            }>
                          )
                            .concat([
                              {
                                title: "Always",
                                description: `${editorRoleDetails.title} can add entry on any past date`,
                                value: -1,
                              },
                              {
                                title: "Never",
                                description: `${editorRoleDetails.title} cannot add entries on past date`,
                                value: 0,
                              },
                              {
                                title: "One day before",
                                description: `${editorRoleDetails.title} can entry on today and day before`,
                                value: 1,
                              },
                            ])
                            .map(({ title, description, value }) => {
                              const isSelected =
                                values.allowedBackdatedEntriesForEditor ===
                                value
                              return (
                                <Inline
                                  as="label"
                                  key={title}
                                  paddingY="3"
                                  paddingX="4"
                                  borderWidth="1"
                                  rounded="md"
                                  gap="4"
                                  bgColor={isSelected ? "blue100" : undefined}
                                  cursor="pointer"
                                >
                                  <Box>
                                    <Input
                                      type="radio"
                                      name="allowedBackdatedEntriesForEditor"
                                      checked={isSelected}
                                      onChange={(e) => {
                                        setFieldValue(
                                          e.currentTarget.name,
                                          value
                                        )
                                      }}
                                    />
                                  </Box>
                                  <Stack flex="1" minWidth="0" gap="2">
                                    <Text fontWeight="semibold">{title}</Text>
                                    <Text color="gray500" fontSize="sm">
                                      {description}
                                    </Text>
                                  </Stack>
                                </Inline>
                              )
                            })}
                        </Stack>
                      </Stack>
                    </Inline>
                    <Inline gap="4">
                      <Box>
                        <HideReportsIcon color="blue900" />
                      </Box>
                      <Stack
                        flex="1"
                        gap="3"
                        as="label"
                        htmlFor="toggle"
                        cursor="pointer"
                      >
                        <Text fontSize="md">Hide net balance & reports</Text>
                        <Text fontSize="sm" color="gray500">
                          Data operators will not be able to see net balance and
                          download reports
                        </Text>
                      </Stack>
                      <Box>
                        <Switch
                          id="toggle"
                          label={`Switch to toggle visibility of Net balance and reports for Date Operator role`}
                          on={values.hideBalancesAndReportsForEditor}
                          onToggle={() =>
                            setFieldValue(
                              "hideBalancesAndReportsForEditor",
                              !values.hideBalancesAndReportsForEditor
                            )
                          }
                        />
                      </Box>
                    </Inline>
                    <Inline gap="4">
                      <Box>
                        <HideEntriesIcon color="blue900" />
                      </Box>
                      <Stack
                        flex="1"
                        gap="3"
                        as="label"
                        htmlFor="toggleHideEntryByOthers"
                        cursor="pointer"
                      >
                        <Text fontSize="md">Hide entries by other members</Text>
                        <Text fontSize="sm" color="gray500">
                          Data operators will not be able to see entries done by
                          other members
                        </Text>
                      </Stack>
                      <Box>
                        <Switch
                          id="toggleHideEntryByOthers"
                          label={`Switch to toggle visibility of entries done by other members for Date Operator role`}
                          on={values.hideEntriesByOthers}
                          onToggle={() =>
                            setFieldValue(
                              "hideEntriesByOthers",
                              !values.hideEntriesByOthers
                            )
                          }
                        />
                      </Box>
                    </Inline>
                  </Stack>
                  <Stack gap="6">
                    {status ? <Alert status="error">{status}</Alert> : null}
                    {values.allowedBackdatedEntriesForEditor !==
                      initialAllowedBackdatedEntriesForEditor ||
                    values.hideBalancesAndReportsForEditor !==
                      initialHideBalancesAndReportsForEditor ||
                    values.hideEntriesByOthers !==
                      initialHideEntriesByOthers ? (
                      <>
                        <Alert status="warning">
                          Changes will be applicable to all{" "}
                          {editorRoleDetails.title.toLowerCase()} of this book
                        </Alert>
                        <Inline justifyContent="end" gap="4">
                          <Button
                            disabled={isSubmitting}
                            onClick={() => {
                              setResetCounter(resetCounter + 1)
                            }}
                          >
                            Cancel
                          </Button>
                          <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Saving..." : "Save Changes"}
                          </Button>
                        </Inline>
                      </>
                    ) : null}
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  )
}
