import { Channel } from "./contract"

const createConsoleChannel: Channel = function createConsoleChannel() {
  return {
    page(...args) {
      console.log("page", ...args)
    },
    track(...args) {
      console.log("track", ...args)
    },
    reset() {
      console.log("reset")
    },
    identify(...args) {
      console.log("identify", ...args)
    },
  }
}

export default createConsoleChannel
