import { DataLoadingFallback } from "@cashbook/web-components"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import {
  useTogglePartyField,
  AddPartyFieldInDialog,
  EditPartyFieldInDialog,
  DeletePartyFieldInDialog,
  ImportPartyFieldInDialog,
} from "../Books"
import {
  useBook,
  BOOK_PERMISSIONS,
  usePartyOrContact,
} from "@cashbook/data-store/books"
import { BookFieldSettingsDetails } from "./BookFieldCategorySettingsPage"
import {
  AddNewPartyFieldOptions,
  EmptyStatePartyField,
} from "../Books/EntryFields"

export default function BookFieldPartySettingsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <BookFieldPartySettings
          key={bookId}
          bookId={bookId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BookFieldPartySettings({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book, checkIfAuthenticatedMemberCan } = useBook(bookId)
  const canUpdate = useMemo(
    () => checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS),
    [checkIfAuthenticatedMemberCan]
  )
  const { partyOrContact } = usePartyOrContact()
  return (
    <BookFieldSettingsDetails
      canUpdate={canUpdate}
      book={book}
      fieldLabel={partyOrContact}
      fields={book.parties}
      businessId={businessId}
      fieldKey="parties"
      fieldDisabled={Boolean(book.preferences?.partyDisabled)}
      useToggleField={useTogglePartyField}
      AddFieldInDialogComponent={AddPartyFieldInDialog}
      ImportFieldInDialog={ImportPartyFieldInDialog}
      EditFieldInDialogComponent={EditPartyFieldInDialog}
      DeleteFieldInDialogComponent={DeletePartyFieldInDialog}
      AddFieldOptions={AddNewPartyFieldOptions}
      EmptyState={EmptyStatePartyField}
      useToggleRequiredFieldFlag={useTogglePartyField}
    />
  )
}
