import { isTodayDate } from "@cashbook/util-dates"
import { Timestamp } from "firebase/firestore"
import { useEffect, useMemo, useReducer } from "react"

export function transformDate(
  value: Timestamp | Date | null
): Timestamp | undefined {
  if (value === null) {
    return undefined
  } else if (value instanceof Date) {
    return Timestamp.fromDate(value)
  } else {
    return value
  }
}

type PaginationState = {
  skip: number
  take: number
  current: number
  lastPage: number
}
type TYPE_AND_PAYLOAD =
  | { type: "SET_UP_PAGINATION"; payload: { skip: number; take: number } }
  | { type: "UPDATE_LAST_PAGE"; payload: number }
  | { type: "RESET_PAGINATION"; payload: PaginationState }
  | { type: "NEXT_PAGE"; payload: number }
  | { type: "PREVIOUS_PAGE" }

const reducer = (
  state: PaginationState,
  action: TYPE_AND_PAYLOAD
): PaginationState => {
  switch (action.type) {
    case "SET_UP_PAGINATION":
      return {
        ...state,
        skip: action.payload.skip,
        take: action.payload.take,
      }
    case "UPDATE_LAST_PAGE":
      return {
        ...state,
        lastPage: action.payload,
      }
    case "NEXT_PAGE":
      return {
        ...state,
        skip: action.payload,
        current: state.current + 1,
      }
    case "RESET_PAGINATION":
      return {
        ...action.payload,
      }
    default:
      return state
  }
}

export function usePagination({
  skip,
  take,
  totalItems,
}: {
  skip: number
  take: number
  totalItems: number
}) {
  const initialPagination: PaginationState = useMemo(() => {
    return {
      skip,
      take,
      current: 1,
      lastPage: 0,
    }
  }, [skip, take])

  const [state, dispatch] = useReducer(reducer, initialPagination)

  const lastPageCalculated: number = useMemo(() => {
    if (totalItems) {
      return Math.ceil(totalItems / take)
    }
    return 0
  }, [totalItems, take])

  function next() {
    if (state.current === state.lastPage) return
    dispatch({ type: "NEXT_PAGE", payload: take * state.current })
  }

  function reset() {
    dispatch({ type: "RESET_PAGINATION", payload: initialPagination })
  }

  useEffect(() => {
    if (!state.lastPage) {
      dispatch({ type: "UPDATE_LAST_PAGE", payload: lastPageCalculated })
    }
  }, [lastPageCalculated, state.lastPage])

  return {
    pagination: {
      skip: state.skip,
      take: state.take,
    },
    current: state.current,
    lastPage: state.lastPage,
    next,
    reset,
  }
}

type DATE_LABELS =
  | "all"
  | "today"
  | "yesterday"
  | "this_month"
  | "last_month"
  | "single_day"
  | "date_range"
  | "this_week"
  | "last_week"
export function getDateLabel(from?: Date, to?: Date): DATE_LABELS {
  if (from && to) {
    if (isTodayDate(from) && isTodayDate(to)) return "today"
  }
  return "all"
}
