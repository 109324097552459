import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Box,
  Button,
  CheckIcon,
  Circle,
  CopyIcon,
  CopyToClipboard,
  Inline,
  Modal,
  ModalBody,
  Stack,
  Text,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import React, { useEffect } from "react"
import { toast } from "react-hot-toast"
import config from "../config"

type HowToAddMoneyKeys = "Net Banking"
const howToAddMoney: {
  [key in HowToAddMoneyKeys]: Array<string>
} = {
  "Net Banking": [
    `Go to your banking’s App or website.`,
    "Click on the Fund Transfer.",
    "Copy the Account Number & IFSC code and add as beneficiary.",
    "Transfer the amount through NEFT/IMPS/RTGS.",
    "Money will be credited to Master Wallet.",
  ],
}

const masterWalletOtherDetails = {
  bankName: "IDFC",
  branch:
    config.appEnv === "production" ? "Chennai (108, R.K.Salai)" : "Mumbai",
  accountType: "Current Account",
}

export function ViewMasterWalletDetailsInModal({
  from,
  ifscCode,
  businessName,
  accountNumber,
  corporateName,
  children,
}: {
  ifscCode?: string
  accountNumber?: string
  businessName: string
  corporateName?: string
  from: "details" | "addMoney"
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})

  useEffect(() => {
    if (state.isOpen) {
      trackEvent(TrackingEvents.MASTER_WALLET_BANK_ACCOUNT_DETAILS_VIEWED)
    }
  }, [state.isOpen])

  return (
    <>
      {children({
        open: state.open,
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        placement="right"
        title={
          from === "details"
            ? "Master Wallet Details"
            : "Add Money to Master Wallet"
        }
        isDismissable
      >
        <ModalBody autoMaxHeight>
          <Stack gap="10" marginBottom="12">
            <Stack gap="4">
              <Text fontSize="b1">Use these details to add money</Text>
              <Box
                rounded="md"
                borderLeftWidth="1"
                borderRightWidth="1"
                borderBottomWidth="1"
                borderColor="borderOutline"
                borderTopWidth="4"
                style={{
                  borderTopColor: "#4863D4",
                }}
              >
                <Box gap="6">
                  <Stack gap="6" padding="6">
                    <Stack gap="2">
                      <Text fontSize="b3" color="textMedium">
                        Account Number
                      </Text>
                      <Inline justifyContent="between" alignItems="center">
                        <Text fontSize="s3">{accountNumber}</Text>
                        <CopyToClipboard>
                          {({ copied, copy }) => (
                            <Button
                              inline
                              onClick={() => {
                                toast.success(
                                  "Account number copied to clipboard"
                                )
                                copy(`${accountNumber}`)
                              }}
                            >
                              {copied ? (
                                <CheckIcon color="green500" />
                              ) : (
                                <CopyIcon />
                              )}
                            </Button>
                          )}
                        </CopyToClipboard>
                      </Inline>
                    </Stack>
                    <Stack gap="2">
                      <Text fontSize="b3" color="textMedium">
                        IFSC Code
                      </Text>
                      <Inline justifyContent="between" alignItems="center">
                        <Text fontSize="s3">{ifscCode}</Text>
                        <CopyToClipboard>
                          {({ copied, copy }) => (
                            <Button
                              inline
                              onClick={() => {
                                toast.success("IFSC code copied to clipboard")
                                copy(`${ifscCode}`)
                              }}
                            >
                              {copied ? (
                                <CheckIcon color="green500" />
                              ) : (
                                <CopyIcon />
                              )}
                            </Button>
                          )}
                        </CopyToClipboard>
                      </Inline>
                    </Stack>
                    <Stack gap="2">
                      <Text fontSize="b3" color="textMedium">
                        Beneficiary Name
                      </Text>
                      <Inline justifyContent="between" alignItems="center">
                        <Text fontSize="s3">{corporateName}</Text>
                        <CopyToClipboard>
                          {({ copied, copy }) => (
                            <Button
                              inline
                              onClick={() => {
                                toast.success(
                                  "Beneficiary Name copied to clipboard"
                                )
                                copy(`${corporateName}`)
                              }}
                            >
                              {copied ? (
                                <CheckIcon color="green500" />
                              ) : (
                                <CopyIcon />
                              )}
                            </Button>
                          )}
                        </CopyToClipboard>
                      </Inline>
                    </Stack>
                    <Stack gap="2">
                      <Text fontSize="b3" color="textMedium">
                        Bank Name
                      </Text>
                      <Text fontSize="s3">
                        {masterWalletOtherDetails.bankName}
                      </Text>
                    </Stack>
                    <Stack gap="2">
                      <Text fontSize="b3" color="textMedium">
                        Branch
                      </Text>
                      <Text fontSize="s3">
                        {masterWalletOtherDetails.branch}
                      </Text>
                    </Stack>
                    <Stack gap="2">
                      <Text fontSize="b3" color="textMedium">
                        Account Type
                      </Text>
                      <Text fontSize="s3">
                        {masterWalletOtherDetails.accountType}
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Stack>
            <Stack gap="4">
              <Text fontSize="s3">How to add money via Net Banking?</Text>
              <Box>
                <Box
                  paddingY="6"
                  paddingX="4"
                  borderWidth="1"
                  roundedBottom="md"
                  borderColor="borderOutline"
                >
                  <Stack as="ul" gap="6">
                    {howToAddMoney["Net Banking"].map((step, i) => (
                      <Inline key={step} alignItems="center" gap="4">
                        <Circle
                          size="6"
                          color="textSuccess"
                          backgroundColor="surfaceSuccessLowest"
                        >
                          <Text fontSize="b3">{i + 1}</Text>
                        </Circle>
                        <Text fontSize="b3" color="textMedium">
                          {step}
                        </Text>
                      </Inline>
                    ))}
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
      </Modal>
    </>
  )
}
