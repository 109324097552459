import {
  getRoleDetails,
  TBook,
  T_AVAILABLE_ROLES,
  useBook,
  useBooksForBusinessId,
} from "@cashbook/data-store/books"
import {
  BUSINESS_PERMISSIONS,
  getBusinessRoleDetails,
  OperationalBookRoles,
  TBusinessInvitation,
  TBusinessMember,
  T_AVAILABLE_BUSINESS_ROLES,
  useBusiness,
  useShareBusinessInvitations,
} from "@cashbook/data-store/businesses"
import { useProfile } from "@cashbook/data-store/users"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Alert,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowUpIcon,
  BookIcon,
  Box,
  Button,
  CancelFilledIcon,
  CheckCircleSolidIcon,
  Circle,
  DataLoadingFallback,
  DotsVerticalIcon,
  Heading,
  InformationWarningIcon,
  Inline,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PageMeta,
  parsePhoneNumber,
  PlusIcon,
  RemoveUserIcon,
  ShareIcon,
  Stack,
  Text,
  Time,
  UserInABoxIcon,
  UsersFilledIcon,
} from "@cashbook/web-components"
import { Timestamp } from "firebase/firestore"
import { useEffect, useMemo } from "react"
import { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import { MemberAvatar } from "../Books"
import {
  AddAndAssignRoleInBooksInModal,
  BusinessRoleDetails,
  ChangeRoleToPartnerOrStaffInModal,
  EditTeamMemberRoleInBookInModal,
  RemoveTeamMemberFromBookInModal,
  RemoveTeamMemberFromBusinessInModal,
  ResendInvitationInModal,
} from "../Business"
import ErrorBoundary from "../ErrorBoundary"
import config from "../config"

export default function BusinessTeamMemberPage() {
  const { businessId, teamMemberId } = useParams()
  if (!teamMemberId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading member details..." />}
        traceId="loading_member_details"
      >
        <TeamMemberInfo
          key={teamMemberId}
          businessId={businessId}
          teamMemberId={teamMemberId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function TeamMemberInfo({
  businessId,
  teamMemberId,
}: {
  businessId: string
  teamMemberId: string
}) {
  const {
    business,
    authTeamMemberDetails,
    getTeamMemberInfoForId,
    checkIfAuthenticatedTeamMemberCan,
  } = useBusiness(businessId)
  const { getInvitationForInvitationId } =
    useShareBusinessInvitations(businessId)
  const teamMember = getTeamMemberInfoForId(teamMemberId)
  const invitedTeamMember = getInvitationForInvitationId(teamMemberId)
  const invitedMemberRole = getBusinessRoleDetails(
    invitedTeamMember?.role || "staff"
  )

  const canAddOrAssignBooks = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.ADD_TO_BOOKS_AND_ASSIGN_ROLE
  )

  const currentMemberRoleId =
    (teamMember && teamMember.role && teamMember.role.id) ||
    (invitedMemberRole && invitedMemberRole.id)

  const canRemoveMemberFromBusiness =
    currentMemberRoleId === "partner"
      ? checkIfAuthenticatedTeamMemberCan(
          BUSINESS_PERMISSIONS.REMOVE_BUSINESS_PARTNER
        )
      : checkIfAuthenticatedTeamMemberCan(
          BUSINESS_PERMISSIONS.REMOVE_TEAM_MEMBER
        )

  const canChangeRoleInBusiness =
    currentMemberRoleId === "partner"
      ? checkIfAuthenticatedTeamMemberCan(
          BUSINESS_PERMISSIONS.DEMOTE_PARTNER_TO_STAFF
        )
      : checkIfAuthenticatedTeamMemberCan(
          BUSINESS_PERMISSIONS.PROMOTE_STAFF_TO_PARTNER
        )

  const canUpdateRoleInBook = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.UPDATE_ROLE_IN_BOOK
  )

  const canRemoveMemberFromBook = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.REMOVE_MEMBER_FROM_BOOK
  )

  function getNumberParsed(number: string): string {
    if (parsePhoneNumber(number)?.nationalNumber) {
      return `${parsePhoneNumber(number)?.nationalNumber}`
    } else {
      return "CB"
    }
  }

  const businessMember = {
    userId: teamMember?.id || "",
    name:
      teamMember?.name ||
      invitedTeamMember?.guestName ||
      invitedTeamMember?.guestEmail ||
      getNumberParsed(invitedTeamMember?.guestPhone || "") ||
      "",
    isInvited: Boolean(invitedTeamMember?.inviteId),
    email: teamMember?.email || invitedTeamMember?.guestEmail || "",
    phoneNumber: teamMember?.phoneNumber || invitedTeamMember?.guestPhone || "",
    sharedBooks: invitedTeamMember?.sharedBooks?.map((book) => book.id) || [],
    sharedBooksWithRole: invitedTeamMember?.sharedBooks,
    role: teamMember?.role.id || invitedMemberRole.id,
  }

  useEffect(() => {
    trackEvent(TrackingEvents.BUSINESS_TEAM_MEMBER_PROFILE_VIEWED, {
      isInvited: businessMember.isInvited,
      memberRole: businessMember.role,
    })
  }, [businessMember.isInvited, businessMember.role])

  const walletIssued: boolean = useMemo(() => {
    return Boolean(teamMember?.wallet_issued_by) || false
  }, [teamMember?.wallet_issued_by])

  const enabledPayments: boolean = useMemo(() => {
    return business.payments?.enabled || false
  }, [business.payments?.enabled])

  if (!teamMember && !invitedTeamMember) return null
  return (
    <>
      <PageMeta>
        <title>Business Team - {business.name}</title>
      </PageMeta>
      <Stack
        key={businessId}
        bgColor="white"
        paddingTop="6"
        paddingBottom="18"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
        gap="6"
        maxWidth="xl"
      >
        <Box display={{ xs: "none", md: "block" }}>
          <RoleInfoHeading
            roleTitle={teamMember?.role.title || invitedMemberRole.title}
            businessId={businessId}
          />
        </Box>
        <MemberInfoBox
          id={teamMemberId}
          name={teamMember?.name || invitedTeamMember?.guestName}
          joinedAt={teamMember?.joinedAt || invitedTeamMember?.createdAt}
          role={teamMember?.role.id || invitedMemberRole.id}
          phoneNumber={
            teamMember?.phoneNumber || invitedTeamMember?.guestPhone || ""
          }
          email={teamMember?.email || invitedTeamMember?.guestEmail || ""}
          isInvitedUser={Boolean(invitedTeamMember?.inviteLink)}
        />
        {invitedTeamMember && invitedTeamMember?.inviteLink && (
          <PendingInvitationBox inviteDetails={invitedTeamMember} />
        )}
        <ViewRolesAndPermissionsBox
          role={teamMember?.role.id || invitedTeamMember?.role || "staff"}
        />
        <BooksForBusinessMember
          businessId={businessId}
          businessMember={businessMember}
          canAddOrAssignBooks={canAddOrAssignBooks}
          canUpdateRoleInBook={canUpdateRoleInBook}
          authTeamMemberDetails={authTeamMemberDetails}
          canRemoveMemberFromBook={canRemoveMemberFromBook}
          roleOfMember={teamMember?.role.id || invitedMemberRole.id}
        />
        <BusinessMemberCriticalActions
          businessId={businessId}
          teamMember={businessMember}
          walletIssued={walletIssued}
          enabledPayments={enabledPayments}
          isAuthenticatedUser={authTeamMemberDetails.uid === teamMember?.uid}
          businessRole={teamMember?.role.id || invitedMemberRole.id || "staff"}
          canChangeRoleInBusiness={canChangeRoleInBusiness}
          canRemoveMemberFromBusiness={canRemoveMemberFromBusiness}
        />
      </Stack>
    </>
  )
}

function RoleInfoHeading({
  roleTitle,
  businessId,
}: {
  roleTitle: string
  businessId: string
}) {
  return (
    <Stack gap="6">
      <Heading fontSize="sm" fontWeight="medium" color="gray500">
        Business Team | {roleTitle} Info
      </Heading>
      <Inline gap="6" alignItems="center">
        <Link to={`/businesses/${businessId}/business-settings/team`}>
          <ArrowLeftIcon />
        </Link>
        <Box flex="1">
          <Heading fontSize="md" fontWeight="semibold">
            {roleTitle} Info
          </Heading>
        </Box>
      </Inline>
    </Stack>
  )
}

function MemberInfoBox({
  id,
  name,
  role,
  email,
  phoneNumber,
  joinedAt,
  isInvitedUser,
}: {
  id: string
  name?: string
  role: T_AVAILABLE_BUSINESS_ROLES
  email?: string
  phoneNumber?: string
  joinedAt?: Timestamp
  isInvitedUser?: boolean
}) {
  const { user } = useProfile()
  const roleDetails = getBusinessRoleDetails(role)
  name =
    user.uid === id
      ? "You"
      : name ||
        email ||
        parsePhoneNumber(phoneNumber || "")?.nationalNumber ||
        "CB User"
  return (
    <Inline gap="4" alignItems="center">
      <MemberAvatar id={id} name={name} />
      <Stack flex="1" minWidth="0" gap="2">
        <Heading as="h5" fontSize="md" fontWeight="semibold">
          {name}
        </Heading>
        {email ? (
          <Text fontWeight="medium" color="gray500">
            {email}
          </Text>
        ) : null}
        {phoneNumber ? (
          <Text fontWeight="medium" color="gray500">
            {phoneNumber}
          </Text>
        ) : null}
        <Text fontSize="sm" color="gray500">
          {isInvitedUser ? "Invitation sent on" : "Member since"}{" "}
          <Time timeStamp={joinedAt} format="dd MMM yyyy" />
        </Text>
      </Stack>
      <Box alignSelf="stretch">
        <BusinessRoleDetails role={roleDetails} />
      </Box>
    </Inline>
  )
}

function PendingInvitationBox({
  inviteDetails,
}: {
  inviteDetails: TBusinessInvitation
}) {
  return (
    <Stack rounded="md" borderWidth="1">
      <Inline gap="4" padding="6">
        <Circle size="12" backgroundColor="orange100">
          <InformationWarningIcon color="orange900" size="6" />
        </Circle>
        <Stack gap="2">
          <Text fontSize="md">Pending Invitation</Text>
          <Text color="gray500" fontSize="sm">
            {inviteDetails?.guestPhone || "This user"} hasn’t registered on{" "}
            {config.appTitle} yet. Send them invite link & ask to register
          </Text>
        </Stack>
      </Inline>
      <Box borderTopWidth="1" paddingY="2">
        <ResendInvitationInModal invitation={inviteDetails}>
          {({ onResendClick }) => (
            <Button fullWidth inline onClick={onResendClick}>
              <ShareIcon />
              Resend Invitation
            </Button>
          )}
        </ResendInvitationInModal>
      </Box>
    </Stack>
  )
}

function ViewRolesAndPermissionsBox({
  role,
}: {
  role: T_AVAILABLE_BUSINESS_ROLES
}) {
  const [visible, setVisible] = useState<boolean>(false)
  const roleDetails = getBusinessRoleDetails(role)
  const { id, title, permissionsDescription, restrictionsDescription } =
    roleDetails
  return (
    <>
      <Stack
        borderWidth="1"
        rounded="md"
        alignItems="center"
        justifyContent="center"
        paddingY="2"
        cursor="pointer"
        onClick={() => setVisible((prevState) => !prevState)}
      >
        <Inline alignItems="center" gap="2">
          <Text fontWeight="semibold" color="gray500">
            {title} Permissions
          </Text>
          <Box>
            {visible ? (
              <ArrowUpIcon color="gray500" size="6" />
            ) : (
              <ArrowDownIcon color="gray500" size="6" />
            )}
          </Box>
        </Inline>
      </Stack>
      {visible && (
        <Stack rounded="md" gap="6" padding="6" borderWidth="1">
          {id === "owner" && (
            <Alert status="info">
              <Text fontSize="sm" fontWeight="medium">
                Each business can have only one owner
              </Text>
            </Alert>
          )}
          {permissionsDescription.length ? (
            <Stack gap="4">
              <Text fontWeight="semibold" color="gray500">
                Permissions
              </Text>
              <Stack as="ol" gap="4">
                {permissionsDescription.map((permission) => (
                  <Inline as="li" alignItems="center" gap="3" key={permission}>
                    <Box>
                      <CheckCircleSolidIcon color="green500" />
                    </Box>
                    <Text>{permission}</Text>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          ) : null}
          {restrictionsDescription.length ? (
            <Stack gap="4">
              <Text fontWeight="semibold" color="gray500">
                Restrictions
              </Text>
              <Stack as="ol" gap="4">
                {restrictionsDescription.map((restriction) => (
                  <Inline as="li" alignItems="center" gap="3" key={restriction}>
                    <Box>
                      <CancelFilledIcon color="red500" />
                    </Box>
                    <Text>{restriction}</Text>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      )}
    </>
  )
}

type BusinessMemberToOperate = {
  name: string
  userId: string
  email?: string
  phoneNumber?: string
  isInvited?: boolean
  sharedBooks?: Array<string>
  sharedBooksWithRole?: Array<{ id: string; role: OperationalBookRoles }>
}
function BooksForBusinessMember({
  businessId,
  roleOfMember,
  businessMember,
  canAddOrAssignBooks,
  canUpdateRoleInBook,
  authTeamMemberDetails,
  canRemoveMemberFromBook,
}: {
  businessId: string
  canAddOrAssignBooks: boolean
  canUpdateRoleInBook: boolean
  canRemoveMemberFromBook: boolean
  roleOfMember: T_AVAILABLE_BUSINESS_ROLES
  authTeamMemberDetails: TBusinessMember
  businessMember: BusinessMemberToOperate
}) {
  const role = getBusinessRoleDetails(roleOfMember)
  const { books, getBooksForTeamMember, getBooksForInvitedMember } =
    useBooksForBusinessId(businessId)
  const booksForMember: TBook[] = businessMember.sharedBooks?.length
    ? getBooksForInvitedMember(businessMember.sharedBooks)
    : roleOfMember === "staff"
    ? getBooksForTeamMember(businessMember.userId)
    : books

  return (
    <>
      {authTeamMemberDetails.role.id === "owner" ? (
        <Text color="gray500" fontWeight="semibold">
          Books ({booksForMember.length})
        </Text>
      ) : null}
      <Stack as="ol">
        {canAddOrAssignBooks && roleOfMember === "staff" ? (
          <AddAndAssignRoleInBooksInModal
            businessId={businessId}
            teamMember={businessMember}
          >
            {({ onAddClick }) => (
              <Box
                as="li"
                paddingY="4"
                cursor="pointer"
                key="AddToBooksAndAssignRole"
                borderBottomWidth="1"
                onClick={() => {
                  onAddClick()
                  trackEvent(TrackingEvents.ADD_MEMBER_TO_BOOKS_CLICKED, {
                    isInvited: businessMember.isInvited || false,
                  })
                }}
              >
                <Inline gap="6" alignItems="center">
                  <Box
                    height="10"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="10"
                    rounded="full"
                    backgroundColor="blue100"
                  >
                    <PlusIcon color="blue900" />
                  </Box>
                  <Stack gap="2">
                    <Text fontSize="md" fontWeight="semibold" color="blue900">
                      {booksForMember.length > 0
                        ? "Add to more books"
                        : "Add to books"}
                    </Text>
                    <Text color="gray500">Add & Assign Role</Text>
                  </Stack>
                </Inline>
              </Box>
            )}
          </AddAndAssignRoleInBooksInModal>
        ) : null}
        {booksForMember.length
          ? booksForMember.map((book, index) => (
              <Box
                as="li"
                paddingY="4"
                key={book.id}
                borderTopWidth={index === 0 ? "0" : "1"}
              >
                <BookView
                  book={book}
                  teamMember={businessMember}
                  businessRole={role.id}
                  canUpdateRoleInBook={canUpdateRoleInBook}
                  canRemoveMemberFromBook={canRemoveMemberFromBook}
                />
              </Box>
            ))
          : null}
      </Stack>
    </>
  )
}

function BookView({
  book,
  teamMember,
  businessRole,
  canUpdateRoleInBook,
  canRemoveMemberFromBook,
}: {
  book: TBook
  canUpdateRoleInBook: boolean
  canRemoveMemberFromBook: boolean
  teamMember: BusinessMemberToOperate
  businessRole: T_AVAILABLE_BUSINESS_ROLES
}) {
  const { isShared, getMemberInfoWithRoleForId } = useBook(book.id)
  const bookLevelRole: T_AVAILABLE_ROLES = useMemo(() => {
    if (teamMember.sharedBooksWithRole?.length) {
      return (
        teamMember.sharedBooksWithRole.find(
          (bookWithRole) => bookWithRole.id === book.id
        )?.role || "viewer"
      )
    }
    const role =
      getMemberInfoWithRoleForId(teamMember.userId)?.role.id || "editor"
    return role
  }, [
    book.id,
    getMemberInfoWithRoleForId,
    teamMember.sharedBooksWithRole,
    teamMember.userId,
  ])
  const bookLevelRoleDetails = getRoleDetails(bookLevelRole)
  return (
    <Inline gap="6" alignItems="center">
      <Box
        height="10"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="10"
        rounded="full"
        backgroundColor="blue100"
      >
        {isShared ||
        (book.pendingInvitationsCount && book.pendingInvitationsCount > 0) ? (
          <UsersFilledIcon color="blue900" />
        ) : (
          <BookIcon color="blue900" />
        )}
      </Box>
      <Stack gap="2" flex="1">
        <Text fontSize="md" fontWeight="semibold">
          {book.name}
        </Text>
        {businessRole && (
          <Text color="gray500">
            {businessRole === "staff" ? "Role: " : ""}{" "}
            {businessRole === "owner" || businessRole === "partner"
              ? "Full Access"
              : bookLevelRoleDetails.title}
          </Text>
        )}
      </Stack>
      {(canUpdateRoleInBook || canRemoveMemberFromBook) &&
      bookLevelRoleDetails &&
      businessRole !== "partner" &&
      businessRole !== "owner" ? (
        <EditTeamMemberRoleInBookInModal
          book={book}
          bookRole={bookLevelRoleDetails.id}
          teamMember={teamMember}
        >
          {({ onEditClick }) => (
            <RemoveTeamMemberFromBookInModal
              book={book}
              teamMember={teamMember}
            >
              {({ onRemoveClick }) => (
                <Menu>
                  <MenuButton inline>
                    <DotsVerticalIcon cursor="pointer" size="4" />
                  </MenuButton>
                  <MenuList align="bottom-right" className="whitespace-pre">
                    {canUpdateRoleInBook ? (
                      <MenuItem action="promote-admin" onClick={onEditClick}>
                        Change Role
                      </MenuItem>
                    ) : null}
                    {canRemoveMemberFromBook ? (
                      <MenuItem action="remove-member" onClick={onRemoveClick}>
                        Remove from Book
                      </MenuItem>
                    ) : null}
                  </MenuList>
                </Menu>
              )}
            </RemoveTeamMemberFromBookInModal>
          )}
        </EditTeamMemberRoleInBookInModal>
      ) : null}
    </Inline>
  )
}

function BusinessMemberCriticalActions({
  businessId,
  teamMember,
  businessRole,
  walletIssued,
  enabledPayments,
  isAuthenticatedUser,
  canChangeRoleInBusiness,
  canRemoveMemberFromBusiness,
}: {
  businessId: string
  walletIssued?: boolean
  enabledPayments?: boolean
  isAuthenticatedUser: boolean
  teamMember: BusinessMemberToOperate
  canChangeRoleInBusiness: boolean
  canRemoveMemberFromBusiness: boolean
  businessRole: T_AVAILABLE_BUSINESS_ROLES
}) {
  const navigate = useNavigate()
  if (
    (!canChangeRoleInBusiness && !canRemoveMemberFromBusiness) ||
    businessRole === "owner" ||
    isAuthenticatedUser
  )
    return null
  return (
    <Stack>
      <Text color="gray500" fontWeight="semibold">
        Actions
      </Text>
      {canChangeRoleInBusiness ? (
        <ChangeRoleToPartnerOrStaffInModal
          businessId={businessId}
          teamMember={teamMember}
          businessRole={businessRole}
          enabledPayments={enabledPayments}
        >
          {({ onChangeRoleClick }) => (
            <Inline
              gap="6"
              cursor="pointer"
              alignItems="center"
              borderBottomWidth="1"
              paddingY="4"
              onClick={onChangeRoleClick}
            >
              <Box
                height="10"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="10"
                rounded="full"
                backgroundColor="blue100"
              >
                <UserInABoxIcon color="blue900" />
              </Box>
              <Heading as="h3" fontSize="md" color="blue900">
                Change role to {businessRole === "staff" ? "partner" : "staff"}
              </Heading>
            </Inline>
          )}
        </ChangeRoleToPartnerOrStaffInModal>
      ) : null}
      {canRemoveMemberFromBusiness ? (
        <RemoveTeamMemberFromBusinessInModal
          businessId={businessId}
          walletIssued={walletIssued}
          teamMember={{ ...teamMember, role: businessRole }}
          onSuccess={() =>
            navigate(`/businesses/${businessId}/business-settings/team`)
          }
        >
          {({ onRemoveClick }) => (
            <Inline
              gap="6"
              cursor="pointer"
              alignItems="center"
              borderBottomWidth="1"
              paddingY="4"
              onClick={onRemoveClick}
            >
              <Box
                height="10"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="10"
                rounded="full"
                backgroundColor="red100"
              >
                <RemoveUserIcon color="red900" />
              </Box>
              <Heading as="h3" fontSize="md" color="red900">
                {teamMember.isInvited
                  ? "Cancel Invitation"
                  : "Remove from business"}
              </Heading>
            </Inline>
          )}
        </RemoveTeamMemberFromBusinessInModal>
      ) : null}
    </Stack>
  )
}
