import { Box, PageMeta } from "@cashbook/web-components"
import { useNavigate, useParams } from "react-router-dom"
import { useBook } from "@cashbook/data-store/books"
import { EditTransaction } from "../../Transactions"
import Page from "./Page"

export default function SmEditTransactionPage() {
  const { bookId, businessId, transactionId } = useParams()
  if (!bookId || !businessId || !transactionId) return null
  return (
    <EditTransactionLayout
      bookId={bookId}
      businessId={businessId}
      transactionId={transactionId}
    />
  )
}

function EditTransactionLayout({
  bookId,
  businessId,
  transactionId,
}: {
  bookId: string
  businessId: string
  transactionId: string
}) {
  const { book, involvedUsers } = useBook(bookId)
  const navigate = useNavigate()
  return (
    <>
      <PageMeta>
        <title>Edit Entry in {book.name}</title>
      </PageMeta>
      <Page
        title={`Edit Entry`}
        backTo={`/m/businesses/${businessId}/cashbooks/${bookId}/transactions/${transactionId}`}
        bgColor="white"
      >
        <Box padding="4">
          <EditTransaction
            book={book}
            involvedUsers={involvedUsers}
            transactionId={transactionId}
            onSuccess={() =>
              navigate(
                `/m/businesses/${businessId}/cashbooks/${bookId}/transactions/${transactionId}`
              )
            }
          />
        </Box>
      </Page>
    </>
  )
}
