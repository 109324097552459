import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  AppDownloadIcon,
  ChevronDownIcon,
  LogoIcon,
  LogoutIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemHeader,
  MenuLink,
  MenuList,
  PageMeta,
  Button,
  SupportIcon,
  Box,
  DataLoadingFallback,
  Text,
  Inline,
  Stack,
  Heading,
  InformationCircleIcon,
} from "@cashbook/web-components"
import { Link, Navigate, Outlet, useParams } from "react-router-dom"
import { SuspenseWithPerf, useUser } from "reactfire"
import config from "../../config"
import ErrorBoundary from "../../ErrorBoundary"
import { useLogout } from "../../Auth"
import { useAppUpdateStatus } from "../../support/AppUpdatesProvider"
import Page from "./Page"
import { BusinessSidebar } from "../../DashboardLayout"
import { useBusinesses } from "@cashbook/data-store/businesses"

export default function SmBusinessesListPage() {
  return (
    <div>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading businesses..." />}
        traceId="loading_businesses"
      >
        <ErrorBoundary>
          <BusinessesList />
        </ErrorBoundary>
      </SuspenseWithPerf>
    </div>
  )
}

function BusinessesList() {
  const { businesses } = useBusinesses()
  if (!businesses.length) {
    return <Navigate to="/m/add-first-business" replace />
  }
  return (
    <>
      <PageMeta title="Dashboard" />
      <BusinessesLayout />
    </>
  )
}

function BusinessesLayout() {
  const { data: user } = useUser()
  const logout = useLogout()
  const [isUpdateAvailable, updateApp] = useAppUpdateStatus()
  if (!user) return null
  return (
    <Page
      title={
        <Inline gap="2" alignItems="center">
          <LogoIcon size="8" />
          <Text as="span" color="blue900">
            {config.appTitle}
          </Text>
        </Inline>
      }
      actions={
        <Inline gap="8" alignItems="center">
          {isUpdateAvailable ? (
            <Box>
              <Button
                onClick={updateApp}
                title="Update App"
                level="primary"
                size="sm"
                minWidth="auto"
              >
                Update App
              </Button>
            </Box>
          ) : null}
          <Menu>
            <MenuButton inline>
              {user.displayName ? user.displayName[0] : "CB"}
              <ChevronDownIcon />
            </MenuButton>
            <MenuList align="bottom-right">
              <MenuLink
                to={user.displayName ? "/m/profile" : "/m/onboarding"}
                className="border-b py-4 mb-2"
              >
                <Inline alignItems="center" gap="4" className="w-60">
                  <Stack
                    size="12"
                    justifyContent="center"
                    alignItems="center"
                    rounded="full"
                    bgColor="blue100"
                  >
                    <Text
                      textTransform="uppercase"
                      fontSize="lg"
                      color="blue900"
                    >
                      {user.displayName ? user.displayName[0] : "CB"}
                    </Text>
                  </Stack>
                  <Stack gap="1">
                    <Heading as="h4" fontWeight="medium">
                      {user.displayName || `${config.appTitle} User`}
                    </Heading>
                    <Text
                      fontSize="sm"
                      color="gray500"
                      className="tracking-wider"
                    >
                      {user.email}
                    </Text>
                    <Text
                      fontSize="sm"
                      color="gray500"
                      className="tracking-wider"
                    >
                      {user.phoneNumber}
                    </Text>
                    <Text color="blue900" fontSize="xs" fontWeight="medium">
                      View Profile <ChevronDownIcon rotate="270" />
                    </Text>
                  </Stack>
                </Inline>
              </MenuLink>
              <MenuLink
                to={"/m/docs"}
                onClick={() => {
                  trackEvent(TrackingEvents.HELP_CLICKED, {
                    from: "ThreeDotsIcon",
                  })
                }}
              >
                <SupportIcon /> Help &amp; Support
              </MenuLink>
              <MenuItemHeader className="border-t mt-2">
                Settings
              </MenuItemHeader>
              <MenuItem
                action="logout"
                onClick={() => logout("fromThisDevice")}
              >
                <LogoutIcon /> Logout
              </MenuItem>
              <MenuItemHeader className="border-t mt-2">
                Mobile App
              </MenuItemHeader>
              <MenuItem action="download_app" href={config.appDownloadLink}>
                <AppDownloadIcon /> Download App
              </MenuItem>
              <MenuItemHeader className="py-2 px-2 text-gray-500">
                &copy; {config.appTitle} • Version {config.appVersion}
              </MenuItemHeader>
            </MenuList>
          </Menu>
        </Inline>
      }
    >
      <BusinessSidebar routePrefix="/m" maxHeight="calc(100vh - 56px)" />
    </Page>
  )
}

export function SmEnsureBusinessExistsPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary
      errorRenderer={({
        isMissingPermissionsError,
        defaultRenderedMessage,
      }) => (
        <Page title="Business Details" backTo={`/m/businesses`}>
          {isMissingPermissionsError ? (
            <Stack
              paddingY={{ xs: "8", sm: "16" }}
              paddingX="4"
              maxWidth="2xl"
              gap="4"
            >
              <InformationCircleIcon size="12" />
              <Heading as="h2" fontSize="2xl" fontWeight="medium">
                Business Not Found
              </Heading>
              <Text>
                The book has been either deleted or you don't have access to it
                anymore. Please{" "}
                <Text
                  as={Link}
                  color="blue900"
                  fontWeight="medium"
                  to="/m/businesses"
                >
                  select any other book
                </Text>{" "}
                to view details and add entries
              </Text>
              <hr />
              <Box>
                <Text>
                  If you think this is an issue with the application, please
                  contact our{" "}
                  <Text
                    as={Link}
                    to="/docs"
                    color="blue900"
                    fontWeight="medium"
                  >
                    support
                  </Text>
                  .
                </Text>
              </Box>
            </Stack>
          ) : (
            defaultRenderedMessage
          )}
        </Page>
      )}
    >
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading business details..." />}
        traceId="loading_business_details"
      >
        <Outlet />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}
