import {
  Alert,
  Button,
  FormField,
  PageMeta,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
  ModalBody,
  ArrowLeftIcon,
  ModalFooter,
  CheckCircleSolidIcon,
  SpinnerIcon,
  BusinessBuildingIcon,
  getButtonClassName,
} from "@cashbook/web-components"
import { Form, Formik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import * as Validator from "yup"
import { Header } from "../DashboardLayout"
import config from "./../config"
import {
  CreateBusinessPayload,
  useBusinesses,
} from "@cashbook/data-store/businesses"
import {
  CreateUserWithBusinessPayload,
  reloadUserAuth,
  useHasCompletedProfile,
  useRegisterUserWithBusiness,
} from "@cashbook/data-store/users"
import {
  BusinessCategory,
  BusinessType,
  getBusinessCategories,
  getBusinessTypes,
  getIconForBusinessInfo,
} from "../Business"
import {
  setSyncStoredItem,
  useSyncedStorageState,
} from "@cashbook/data-store/storage"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { useEffect, useState } from "react"
import { useUser } from "reactfire"

const onboardingDataValidationSchema = Validator.object().shape({
  displayName: Validator.string()
    .required(`Please provide a name for your account on ${config.appTitle}`)
    .max(100, "Name should be lesser than 191 characters"),
  businessName: Validator.string()
    .max(100, "Name should be lesser than 191 characters")
    .when(["doNotHaveBusiness"], {
      is: (doNotHaveBusiness: boolean) => !doNotHaveBusiness,
      then: Validator.string().required(
        "Please enter a valid business name to proceed."
      ),
    }),
})

type UserTransitionedStorage = {
  [key: string]: boolean
}
export default function OnboardingPage() {
  const navigate = useNavigate()
  const { data: authUser } = useUser()
  const profile = useHasCompletedProfile()
  const createUserWithBusiness = useRegisterUserWithBusiness()
  const { businesses } = useBusinesses()
  const [isReloading, setIsReloading] = useState<boolean>(true)

  const reloadUserAuthentication = async () => {
    await reloadUserAuth()
    setIsReloading(false)
  }

  useEffect(() => {
    reloadUserAuthentication()
  }, [])

  const profileCompleted =
    profile !== false && profile !== "loading" && businesses.length
  const [isUserTransitioned, setIsUserTransitioned] =
    useSyncedStorageState<UserTransitionedStorage>("userTransitioned", {})

  return (
    <>
      <PageMeta>
        <title>Onboarding</title>
      </PageMeta>
      <Stack gap="6" width="full" paddingBottom="8">
        <Header />
        {isReloading ? (
          <Box maxWidth="lg" marginX="auto">
            <Stack
              padding="8"
              bgColor="white"
              rounded="md"
              gap="6"
              borderWidth="1"
              borderColor="gray100"
            >
              <Inline alignItems="center" gap="4">
                <SpinnerIcon />
                Fetching details
              </Inline>
            </Stack>
          </Box>
        ) : profileCompleted ? (
          <Box maxWidth="lg" marginX="auto">
            <Stack
              padding="8"
              bgColor="white"
              rounded="md"
              gap="6"
              borderWidth="1"
              borderColor="gray100"
            >
              <Inline alignItems="center" gap="4">
                <Box>
                  <CheckCircleSolidIcon size="8" color="green500" />
                </Box>
                <Heading fontSize="lg">
                  You cashbook profile is already completed
                </Heading>
              </Inline>
              <Link
                className={getButtonClassName({
                  level: "primary",
                  fullWidth: true,
                })}
                to="/"
              >
                Visit Dashboard
              </Link>
            </Stack>
          </Box>
        ) : (
          <Formik
            initialValues={{
              displayName: authUser?.displayName || ("" as string),
              businessName: "" as string,
              step: "basicProfile" as
                | "basicProfile"
                | "chooseCategory"
                | "chooseType",
              type: {} as BusinessType,
              category: {} as BusinessCategory,
              doNotHaveBusiness: false as boolean,
              emailVerificationComplete: false as boolean,
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={onboardingDataValidationSchema}
            onSubmit={async (values, actions) => {
              if (!values.displayName.trim()) {
                return actions.setFieldError(
                  "displayName",
                  `Please provide a valid name for your account on ${config.appTitle}`
                )
              }
              if (!values.doNotHaveBusiness && !values.businessName.length) {
                actions.setFieldError(
                  "businessName",
                  `Please enter business name. If you do not have a business please select the checkbox "I don’t own a business".`
                )
                return
              }
              let businessInfo = {} as CreateBusinessPayload
              if (values.businessName.length) {
                businessInfo = {
                  name: values.businessName,
                }
              }
              if (values.category.id) {
                businessInfo = {
                  ...businessInfo,
                  details: {
                    ...businessInfo.details,
                    category: {
                      id: values.category.id,
                      name: values.category.name,
                    },
                  },
                }
              }
              if (values.type) {
                businessInfo = {
                  ...businessInfo,
                  details: {
                    ...businessInfo.details,
                    type: values.type.id,
                  },
                }
              }
              let payload: CreateUserWithBusinessPayload = {
                displayName: values.displayName,
              }
              if (businessInfo.name) {
                payload = {
                  ...payload,
                  business: { ...businessInfo },
                }
              }
              setSyncStoredItem("isNewUser", true)
              trackEvent(TrackingEvents.NAME_SUBMITTED)
              const { user, business } = await createUserWithBusiness(payload)
              if (!business || !user) {
                actions.setStatus(
                  `Something went wrong. Please try again after some time!`
                )
                return
              }
              setIsUserTransitioned({ ...isUserTransitioned, [user.uid]: true })
              await reloadUserAuth()
              navigate(`/businesses`)
            }}
          >
            {({
              status,
              values,
              errors,
              isSubmitting,
              submitForm,
              setFieldValue,
              setErrors,
            }) => (
              <Form noValidate>
                {isSubmitting ? (
                  <Stack
                    maxWidth="full"
                    rounded="md"
                    justifyContent="center"
                    alignItems="center"
                    gap="6"
                    paddingY="24"
                  >
                    <Inline alignItems="center" gap="4">
                      <Box
                        padding="2"
                        rounded="md"
                        className="bg-gradient-to-r from-[#3739D0] to-[#4586E8]"
                      >
                        <BusinessBuildingIcon color="white" />
                      </Box>
                      <Inline alignItems="center" gap="1" fontSize="md">
                        <Text>Setting up</Text>
                        <Text fontWeight="semibold">“Business”</Text>
                      </Inline>
                    </Inline>
                    <Box>
                      <SpinnerIcon color="blue900" />
                    </Box>
                  </Stack>
                ) : values.step === "basicProfile" ? (
                  <Box maxWidth="lg" marginX="auto">
                    <Box
                      padding="8"
                      bgColor="white"
                      rounded="md"
                      borderWidth="1"
                      borderColor="gray100"
                    >
                      <Stack gap="8">
                        <Stack gap="2">
                          <Heading as="h1" fontSize="xl" fontWeight="semibold">
                            Welcome to {config.appTitle}
                          </Heading>
                          <Text fontSize="md">Add your details</Text>
                        </Stack>
                        <Stack gap="8" minWidth="screenMd" overflow="hidden">
                          <Box maxWidth="md" padding="2">
                            <Stack gap="6">
                              <Box position="relative">
                                <FormField
                                  type="text"
                                  name="displayName"
                                  label="Your Full Name"
                                  placeholder="Your Full Name"
                                  autoFocus
                                  noMargin
                                  required
                                  disabled={isSubmitting}
                                />
                              </Box>
                              <Box position="relative">
                                <FormField
                                  type="text"
                                  noMargin
                                  name="businessName"
                                  label="Business Name"
                                  placeholder="Business Name"
                                  autoComplete="off"
                                  className={`${
                                    values.doNotHaveBusiness &&
                                    !values.businessName.length
                                      ? "bg-gray-100"
                                      : ""
                                  }`}
                                />
                                <Box paddingY="3">
                                  <Box
                                    position="relative"
                                    className={`opacity-${
                                      values.businessName.length ? "30" : "100"
                                    }`}
                                  >
                                    <FormField
                                      type="checkbox"
                                      noMargin
                                      name="doNotHaveBusiness"
                                      checked={
                                        values.doNotHaveBusiness &&
                                        !values.businessName.length
                                      }
                                      label={
                                        <Text
                                          fontSize="c2"
                                          className="px-1"
                                          color="gray500"
                                        >
                                          I don’t own a business. Skip this.
                                        </Text>
                                      }
                                      className={`h-4 w-4 border-[2px] border-[#757575]`}
                                      disabled={values.businessName.length > 1}
                                      onChange={() =>
                                        setFieldValue(
                                          "doNotHaveBusiness",
                                          !values.doNotHaveBusiness
                                        )
                                      }
                                    />
                                  </Box>
                                </Box>
                              </Box>
                              {status ? (
                                <Alert status="error">{status}</Alert>
                              ) : null}
                            </Stack>
                            <Inline justifyContent="end" paddingTop="7">
                              <Button
                                level="primary"
                                size="lg"
                                disabled={isSubmitting}
                                onClick={async () => {
                                  if (!values.businessName.length) {
                                    return submitForm()
                                  }

                                  if (!values.displayName.trim()) {
                                    setErrors({
                                      ...errors,
                                      displayName: `Please provide a valid name for your account on ${config.appTitle}`,
                                    })
                                    return
                                  }

                                  if (!values.businessName.trim()) {
                                    setErrors({
                                      ...errors,
                                      businessName: `Please enter business name. If you do not have a business please select the checkbox "I don’t own a business".`,
                                    })
                                    return
                                  }

                                  setFieldValue("step", "chooseCategory")
                                }}
                              >
                                Get Started
                              </Button>
                            </Inline>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    className="max-w-[84%]"
                    marginX="auto"
                    padding="8"
                    bgColor="white"
                    rounded="md"
                    borderWidth="1"
                    borderColor="gray100"
                  >
                    <ModalBody>
                      <Stack borderBottomWidth="1" paddingBottom="6">
                        <Inline alignItems="center" justifyContent="between">
                          <Inline gap="4" alignItems="center">
                            <Button
                              inline
                              onClick={() => {
                                if (values.step === "chooseCategory") {
                                  setFieldValue("step", "basicProfile")
                                  return
                                }
                                setFieldValue("step", "chooseCategory")
                              }}
                            >
                              <Box>
                                <ArrowLeftIcon color="black" />
                              </Box>
                            </Button>
                            <Inline alignItems="center" gap="3">
                              <Heading as="h1" fontSize="xl">
                                Select Business{" "}
                                {values.step === "chooseType"
                                  ? "Type"
                                  : "Category"}
                              </Heading>
                              <Text color="gray500">
                                ({values.businessName})
                              </Text>
                            </Inline>
                          </Inline>
                          <Inline fontSize="sm" alignItems="center" gap="2">
                            <Text color="gray500">Business Setup: </Text>
                            <Box
                              rounded="full"
                              paddingX="4"
                              paddingY="3"
                              backgroundColor="gray100"
                            >
                              <Text fontWeight="semibold">
                                Step {values.step === "chooseCategory" ? 1 : 2}
                                /2
                              </Text>
                            </Box>
                          </Inline>
                        </Inline>
                        <Inline>
                          <Inline gap="4" alignItems="center">
                            <Box className="invisible">
                              <ArrowLeftIcon />
                            </Box>
                            <Text fontSize="md">
                              This will help us personalize your app experience
                            </Text>
                          </Inline>
                        </Inline>
                      </Stack>
                      <Inline
                        gap="4"
                        alignItems="center"
                        flexWrap="wrap"
                        paddingY="8"
                        as="ul"
                      >
                        {values.step === "chooseType"
                          ? getBusinessTypes().map((type) => {
                              const isChecked = values.type.id === type.id
                              return (
                                <Box
                                  key={type.id}
                                  as="li"
                                  rounded="md"
                                  padding="4"
                                  borderWidth={isChecked ? "2" : "1"}
                                  borderColor={
                                    isChecked ? "blue900" : undefined
                                  }
                                  backgroundColor={
                                    isChecked ? "blue100" : undefined
                                  }
                                  cursor="pointer"
                                  onClick={() => setFieldValue("type", type)}
                                  position="relative"
                                  className="w-[23.5%] max-w-[25%]"
                                >
                                  {isChecked && (
                                    <Box
                                      position="absolute"
                                      top="0"
                                      right="0"
                                      padding="1"
                                    >
                                      <CheckCircleSolidIcon
                                        size="4"
                                        color="blue900"
                                      />
                                    </Box>
                                  )}
                                  <Inline
                                    alignItems="center"
                                    gap="4"
                                    className="break-all"
                                  >
                                    <Box>
                                      {getIconForBusinessInfo()[type.id]}
                                    </Box>
                                    <Text fontWeight="semibold">
                                      {type.name}
                                    </Text>
                                  </Inline>
                                </Box>
                              )
                            })
                          : values.step === "chooseCategory"
                          ? getBusinessCategories().map((category) => {
                              const isChecked =
                                values.category?.id === category.id
                              return (
                                <Box
                                  key={category.id}
                                  as="li"
                                  rounded="md"
                                  padding="4"
                                  borderWidth={isChecked ? "2" : "1"}
                                  borderColor={
                                    isChecked ? "blue900" : undefined
                                  }
                                  backgroundColor={
                                    isChecked ? "blue100" : undefined
                                  }
                                  cursor="pointer"
                                  onClick={() =>
                                    setFieldValue("category", category)
                                  }
                                  position="relative"
                                  className="w-[23.5%] max-w-[25%]"
                                >
                                  {isChecked && (
                                    <Box
                                      position="absolute"
                                      top="0"
                                      right="0"
                                      padding="1"
                                    >
                                      <CheckCircleSolidIcon
                                        size="4"
                                        color="blue900"
                                      />
                                    </Box>
                                  )}
                                  <Inline
                                    alignItems="center"
                                    gap="4"
                                    className="break-all"
                                  >
                                    <Box>
                                      {getIconForBusinessInfo()[category.id]}
                                    </Box>
                                    <Text fontWeight="semibold">
                                      {category.name}
                                    </Text>
                                  </Inline>
                                </Box>
                              )
                            })
                          : null}
                      </Inline>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        disabled={
                          (values.step === "chooseType" && !values.type) ||
                          (values.step === "chooseCategory" &&
                            !values.category.id)
                        }
                        size="lg"
                        level="primary"
                        onClick={() => {
                          if (values.step === "chooseCategory") {
                            trackEvent(
                              TrackingEvents.BUSINESS_CATEGORY_SUBMITTED,
                              {
                                categoryId: values.category.id,
                                from: "onboarding",
                                isSkipped: false,
                              }
                            )
                          }
                          if (values.step === "chooseType") {
                            trackEvent(TrackingEvents.BUSINESS_TYPE_SUBMITTED, {
                              typeId: values.type.id,
                              from: "onboarding",
                              isSkipped: false,
                            })
                          }
                          values.step === "chooseType"
                            ? submitForm()
                            : setFieldValue("step", "chooseType")
                        }}
                      >
                        Next
                      </Button>
                      <Button
                        size="lg"
                        onClick={() => {
                          if (values.step === "chooseCategory") {
                            trackEvent(
                              TrackingEvents.BUSINESS_CATEGORY_SUBMITTED,
                              {
                                categoryId: "",
                                from: "onboarding",
                                isSkipped: true,
                              }
                            )
                          }
                          if (values.step === "chooseType") {
                            trackEvent(TrackingEvents.BUSINESS_TYPE_SUBMITTED, {
                              typeId: "",
                              from: "onboarding",
                              isSkipped: true,
                            })
                          }
                          submitForm()
                        }}
                      >
                        Skip
                      </Button>
                    </ModalFooter>
                  </Box>
                )}
              </Form>
            )}
          </Formik>
        )}
      </Stack>
    </>
  )
}
