import { Navigate, useParams, Outlet, useNavigate } from "react-router-dom"
import { Alert, SpinnerIcon, Text } from "@cashbook/web-components"

import ErrorBoundary from "../ErrorBoundary"
import { SuspenseWithPerf } from "reactfire"
import { useBusiness, useBusinesses } from "@cashbook/data-store/businesses"
import { useEffect } from "react"

export function RedirectToCashbooksFromIndexPage({
  routePrefix = "",
}: {
  routePrefix?: string
}) {
  const { businesses } = useBusinesses()
  const { businessId } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (!businesses.length) {
      navigate("/add-first-business", { replace: true })
    } else if (!businesses.find((b) => b.id === businessId)) {
      navigate(`/books/${businesses[0].id}/transactions`, { replace: true })
    }
  }, [businesses, businessId, navigate])
  if (!businessId) return <Navigate to={`${routePrefix}/`} replace />
  return (
    <Navigate
      to={`${routePrefix}/businesses/${businessId}/cashbooks`}
      replace
    />
  )
}

export function BusinessDetailsPageLayout() {
  const navigate = useNavigate()
  const params: { businessId?: string } = useParams()
  const { businessId } = params
  const { businesses } = useBusinesses()

  useEffect(() => {
    if (!businesses.length) {
      navigate("/add-first-book", { replace: true })
    } else if (!businesses.find((b) => b.id === businessId)) {
      navigate(`/businesses/${businesses[0].id}/cashbooks`, { replace: true })
    }
  }, [businesses, businessId, navigate])

  return (
    <ErrorBoundary key={businessId}>
      <SuspenseWithPerf
        fallback={
          <div className="text-center py-8">
            <SpinnerIcon />
          </div>
        }
        traceId="ensuringBusinessExistence"
      >
        <EnsuredBusinessExistence />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function EnsuredBusinessExistence() {
  const params: { businessId?: string } = useParams()
  const { isDeleted } = useBusiness(params.businessId || "")
  if (isDeleted)
    return (
      <Alert status="error">
        <Text>
          You have been removed from the business/Or the business has been
          deleted.
        </Text>
      </Alert>
    )
  return (
    <>
      <Outlet />
    </>
  )
}
