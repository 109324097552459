import { useBooksForBusinessId } from "@cashbook/data-store/books"
import {
  T_AVAILABLE_BUSINESS_ROLES,
  useBusiness,
  useChangeBusinessOwner,
  useDeleteBusiness,
  useLeaveBusiness,
  useNewBusinesses,
} from "@cashbook/data-store/businesses"
import { setSyncStoredItem } from "@cashbook/data-store/storage"
import { pluralize } from "@cashbook/util-general"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Alert,
  ArrowRightIcon,
  BookWithCloseIcon,
  Box,
  BuildingWithCloseIcon,
  Button,
  CancelIcon,
  CannotChangeOwnerIcon,
  CheckIcon,
  Circle,
  FormField,
  formikOnSubmitWithErrorHandling,
  Heading,
  InformationWarningIcon,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  SpinnerIcon,
  Stack,
  Text,
  TrashIcon,
  WalletWithCloseIcon,
  WomanInBWIcon,
} from "@cashbook/web-components"
import { useOverlayTriggerState } from "@react-stately/overlays"
import { Form, Formik } from "formik"
import { useCallback, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import config from "../config"
import { WhatsAppContactLink } from "../HelpDocs"
import { BusinessRoleDetails } from "./BusinessTeam"

type ChangeOwnerSteps =
  | "disclaimer"
  | "chooseNewOwner"
  | "changeOwnerConfirmation"

const CANNOT_CHANGE_OWNER = [
  `Owner can’t be changed since ${config.appTitle} payments is active in this business`,
  `Contact us to know more`,
]
export function ChangeOwnerInModal({
  enabledPayments,
  children,
  ...props
}: React.ComponentProps<typeof ChangeOwnership> & {
  enabledPayments?: boolean
  children: (props: { onChangeClick: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const [step, setStep] = useState<ChangeOwnerSteps>("disclaimer")

  const onCloseModal = () => {
    setStep("disclaimer")
    state.close()
  }

  return (
    <>
      {children({ onChangeClick: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={onCloseModal}
        placement={enabledPayments ? undefined : "right"}
        isDismissable
        onBackPress={
          step !== "disclaimer"
            ? () =>
                setStep(
                  step === "chooseNewOwner" ? "disclaimer" : "chooseNewOwner"
                )
            : undefined
        }
        title={
          enabledPayments
            ? `Can’t Change Owner`
            : step === "disclaimer"
            ? "Change Owner"
            : "Choose New Owner"
        }
      >
        <SuspenseWithPerf
          traceId="fetching_business_details"
          fallback={
            <div className="text-center py-16">
              <SpinnerIcon />
            </div>
          }
        >
          {enabledPayments ? (
            <CannotChangeOwner
              businessId={props.businessId}
              onClose={state.close}
            />
          ) : step === "disclaimer" ? (
            <ChangeOwnerDisclaimer
              onNextClick={() => setStep("chooseNewOwner")}
            />
          ) : (
            <ChangeOwnership
              {...props}
              step={step}
              onClose={onCloseModal}
              onGoBackClick={() => setStep("chooseNewOwner")}
              onTransferOwnerShipClick={() =>
                setStep("changeOwnerConfirmation")
              }
            />
          )}
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

function CannotChangeOwner({
  businessId,
  onClose,
}: {
  businessId: string
  onClose: () => void
}) {
  const {
    business: { name },
  } = useBusiness(businessId)
  return (
    <>
      <ModalBody>
        <Stack gap="2" alignItems="center">
          <CannotChangeOwnerIcon size="18" />
          <Text fontSize="lg" fontWeight="semibold">
            Can’t Change Owner
          </Text>
        </Stack>
        <Stack alignItems="center">
          <Box
            height="1"
            rounded="full"
            width="4"
            marginY="4"
            backgroundColor="gray400"
          />
        </Stack>
        <Stack as="ul" gap="4">
          {CANNOT_CHANGE_OWNER.map((pointer) => (
            <Inline key={pointer} gap="4" as="li" alignItems="center">
              <Circle size="2" backgroundColor="gray400" />
              <Text>{pointer}</Text>
            </Inline>
          ))}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button size="lg" level="primary" onClick={onClose}>
          <Box>
            <CheckIcon />
          </Box>
          Ok, Got it
        </Button>
        <WhatsAppContactLink
          size="lg"
          text={`Hi, I would like to change Owner of my Business (Payments Activated) ${name}. For version Cashbook-Web (${config.appVersion})`}
        />
      </ModalFooter>
    </>
  )
}

const CHANGE_OWNER_POINTERS = [
  "Every business can have only one owner",
  "Once you transfer ownership to new owner, your role will be changed to partner",
  "New owner can remove you from business or delete the business",
  "You will not be able to reverse this",
]

function ChangeOwnerDisclaimer({ onNextClick }: { onNextClick: () => void }) {
  return (
    <>
      <ModalBody className="relative">
        <Stack gap="8">
          <Box bgColor="red100" rounded="md">
            <Stack
              padding="8"
              gap="4"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <InformationWarningIcon size="12" color="red900" />
              </Box>
              <Heading as="h1" fontSize="lg" className="text-center">
                This will transfer all your permissions to new owner
              </Heading>
            </Stack>
          </Box>
          <Stack as="ul" gap="4">
            {CHANGE_OWNER_POINTERS.map((pointer) => (
              <Inline as="li" key={pointer} alignItems="center" gap="4">
                <Circle size="2" backgroundColor="gray400" />
                <Text>{pointer}</Text>
              </Inline>
            ))}
          </Stack>
        </Stack>
        <Inline
          bgColor="gray100"
          paddingY="3"
          paddingX="8"
          gap="1"
          width="full"
          className="absolute left-0 bottom-0"
        >
          <Text color="gray500" fontSize="sm">
            Next Step:
          </Text>
          <Text fontWeight="semibold" fontSize="sm">
            Choose New Owner
          </Text>
        </Inline>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onNextClick} size="lg" level="primary">
          Next
        </Button>
      </ModalFooter>
    </>
  )
}

const CHANGE_OWNER_INFO = [
  `Add ‘Partner’ from business team page`,
  "Alternately you can change the role of existing staff member to partner",
  "Once you have partner in your team, then you can transfer ownership to them",
]

function ChangeOwnership({
  step,
  businessId,
  onClose,
  onSuccess,
  onGoBackClick,
  onTransferOwnerShipClick,
}: {
  step?: ChangeOwnerSteps
  businessId: string
  onClose?: () => void
  onSuccess?: () => void
  onGoBackClick?: () => void
  onTransferOwnerShipClick?: () => void
}) {
  const { businessRoleWiseTeam } = useBusiness(businessId)
  const { partner } = businessRoleWiseTeam
  const changeBusinessOwner = useChangeBusinessOwner(businessId)
  const changeOwnerConfirm = useCallback((name: string) => {
    return [
      `${name} will be able to remove you from business or delete the business`,
      "Once this is done, your role will be changed to partner",
    ]
  }, [])
  if (!partner.length) {
    return (
      <>
        <ModalBody>
          <Stack gap="6" alignItems="center">
            <Box>
              <WomanInBWIcon size="14" />
            </Box>
            <Stack gap="2" className="text-center">
              <Heading fontSize="md">No business partners found!</Heading>
              <Text>
                Add partner from business team page & then you can transfer
                ownership to them
              </Text>
            </Stack>
            <Box
              height="1"
              width="4"
              backgroundColor="gray400"
              rounded="full"
            />
            <Stack as="ul" gap="4">
              {CHANGE_OWNER_INFO.map((pointer) => (
                <Inline key={pointer} alignItems="center" gap="4">
                  <Circle size="2" backgroundColor="gray400" />
                  <Text>{pointer}</Text>
                </Inline>
              ))}
            </Stack>
            <Box
              as={Link}
              width="full"
              cursor="pointer"
              to={`/docs`}
              onClick={() => onClose?.()}
            >
              <Alert status="info">
                <Inline alignItems="center" justifyContent="between">
                  <Text fontSize="sm" color="black">
                    How to transfer ownership?
                  </Text>
                  <Box>
                    <ArrowRightIcon color="gray500" />
                  </Box>
                </Inline>
              </Alert>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button level="primary" size="lg" type="button" disabled>
            Transfer Ownership
          </Button>
        </ModalFooter>
      </>
    )
  }
  return (
    <Formik
      initialValues={{ id: "" as string, name: "" as string }}
      onSubmit={formikOnSubmitWithErrorHandling(async ({ id, name }) => {
        await changeBusinessOwner(id)
        trackEvent(TrackingEvents.BUSINESS_OWNER_CHANGED)
        toast.success(`${name} is the new owner of this business`)
        onSuccess?.()
      })}
    >
      {({ values, status, isSubmitting, setValues }) => (
        <>
          {step === "chooseNewOwner" && (
            <Alert status="info">
              <Text fontSize="sm">
                Ownership can be transferred only to business partners
              </Text>
            </Alert>
          )}
          <ModalBody>
            {step === "chooseNewOwner" ? (
              <Stack gap="4">
                <Form>
                  <Heading fontSize="md">
                    Choose new owner from partners
                  </Heading>
                  {
                    <Stack as="ul">
                      {partner.map(
                        ({ uid, name, email, phoneNumber, role }, index) => (
                          <Box
                            as="li"
                            key={uid}
                            borderBottomWidth={
                              index === partner.length - 1 ? "0" : "1"
                            }
                            paddingY="4"
                          >
                            <FormField
                              name="book"
                              type="radio"
                              id={`partner_${uid}`}
                              value={uid}
                              noMargin
                              onChange={() => {
                                setValues({ ...values, id: uid, name: name })
                              }}
                              checked={uid === values.id}
                              className={"border-2 border-gray-500"}
                              label={
                                <Inline
                                  alignItems="center"
                                  justifyContent="between"
                                >
                                  <Stack gap="2" marginLeft="2">
                                    <Text fontSize="md" fontWeight="semibold">
                                      {name}
                                    </Text>
                                    {email ? (
                                      <Text
                                        color="textMedium"
                                        fontSize="sm"
                                        fontWeight="medium"
                                      >
                                        {email}
                                      </Text>
                                    ) : null}
                                    {phoneNumber ? (
                                      <Text
                                        color="textMedium"
                                        fontSize="sm"
                                        fontWeight="medium"
                                      >
                                        {phoneNumber}
                                      </Text>
                                    ) : null}
                                  </Stack>
                                  <BusinessRoleDetails role={role} />
                                </Inline>
                              }
                            />
                          </Box>
                        )
                      )}
                    </Stack>
                  }
                </Form>
              </Stack>
            ) : (
              <Stack gap="6" alignItems="center">
                <Box>
                  <InformationWarningIcon color="red900" size="18" />
                </Box>
                <Heading fontSize="xl">Make {values.name} new owner?</Heading>
                <Box height="1" width="4" rounded="full" bgColor="gray400" />
                <Stack as="ul" gap="4">
                  {changeOwnerConfirm(values.name).map((pointer) => (
                    <Inline key={pointer} alignItems="center" gap="4">
                      <Circle size="2" backgroundColor="gray400" />
                      <Text>{pointer}</Text>
                    </Inline>
                  ))}
                </Stack>
                {status ? <Alert status="error">{status}</Alert> : null}
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            {step === "chooseNewOwner" ? (
              <Button
                level="primary"
                size="lg"
                type="button"
                disabled={!values.id.length}
                onClick={onTransferOwnerShipClick}
              >
                Transfer Ownership
              </Button>
            ) : (
              <>
                <Form>
                  <Button
                    status="error"
                    disabled={isSubmitting}
                    level="primary"
                    size="lg"
                    type="submit"
                  >
                    Transfer Ownership
                  </Button>
                </Form>
                <Button size="lg" onClick={onGoBackClick}>
                  No, Go back
                </Button>
              </>
            )}
          </ModalFooter>
        </>
      )}
    </Formik>
  )
}

export function LeaveBusinessInDialog({
  walletIssued,
  children,
  ...props
}: React.ComponentProps<typeof LeaveBusiness> & {
  walletIssued?: boolean
  children: (props: { onLeaveClick: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})

  return (
    <>
      {children({ onLeaveClick: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        placement={walletIssued ? "right" : undefined}
        size="sm"
        title="Leave Business?"
      >
        <SuspenseWithPerf
          traceId="fetching_business_details"
          fallback={
            <div className="text-center py-16">
              <SpinnerIcon />
            </div>
          }
        >
          <LeaveBusiness
            {...props}
            onCancel={state.close}
            walletIssued={walletIssued}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

const LEAVE_BUSINESS_POINTERS = [
  "You will lose access to this business",
  "We will also notify the business owner & other members that you have left the business.",
]

const LEAVE_BUSINESS_POINTERS_FOR_WALLET_ENABLED_USERS = [
  {
    title: "Deactivate Your Wallet",
    subTitle: "You won’t be able to make payments",
    icon: <WalletWithCloseIcon size="10" />,
  },
  {
    title: "Remove Cashbook Access",
    subTitle: "You won’t be able to access books",
    icon: <BookWithCloseIcon size="10" />,
  },
]

function LeaveBusiness({
  businessId,
  memberRole,
  redirectUrl,
  businessName,
  walletIssued,
  onCancel,
  onSuccess,
}: {
  businessId: string
  redirectUrl?: string
  businessName?: string
  walletIssued?: boolean
  memberRole?: T_AVAILABLE_BUSINESS_ROLES
  onCancel?: () => void
  onSuccess?: () => void
}) {
  const navigate = useNavigate()
  const leaveBusiness = useLeaveBusiness(businessId)
  const { removeFromBusinesses } = useNewBusinesses()
  return (
    <Formik
      initialValues={{}}
      onSubmit={formikOnSubmitWithErrorHandling(async () => {
        setSyncStoredItem("leftBusiness", businessName)
        trackEvent(TrackingEvents.BUSINESS_LEFT, {
          role: memberRole || "staff",
        })
        await leaveBusiness()
        navigate(redirectUrl || "/")
        removeFromBusinesses(businessId)
        onSuccess?.()
      })}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <ModalBody
            style={{
              background: walletIssued
                ? "linear-gradient(180deg, rgba(201, 59, 59, 0.1) 0%, rgba(201, 59, 59, 0) 100%)"
                : undefined,
            }}
          >
            {walletIssued ? (
              <Box>
                <Stack
                  paddingY="16"
                  textAlign="center"
                  alignItems="center"
                  gap="4"
                >
                  <InformationWarningIcon size="12" color="red900" />
                  <Text fontSize="lg" fontWeight="semibold">
                    If you leave, we will
                  </Text>
                </Stack>
                <Stack gap="10">
                  {LEAVE_BUSINESS_POINTERS_FOR_WALLET_ENABLED_USERS.map(
                    (pointer) => (
                      <Inline key={pointer.title} alignItems="center" gap="4">
                        {pointer.icon}
                        <Stack gap="2">
                          <Text fontSize="md" fontWeight="semibold">
                            {pointer.title}
                          </Text>
                          <Text color="gray500">{pointer.subTitle}</Text>
                        </Stack>
                      </Inline>
                    )
                  )}
                </Stack>
              </Box>
            ) : (
              <Stack gap="4">
                <Text>Are you sure?</Text>
                {LEAVE_BUSINESS_POINTERS.map((pointer) => (
                  <Inline key={pointer} alignItems="center" gap="4">
                    <Circle size="2" backgroundColor="gray400" />
                    <Text>{pointer}</Text>
                  </Inline>
                ))}
              </Stack>
            )}

            {status ? <Alert status="error">{status}</Alert> : null}
          </ModalBody>
          <ModalFooter>
            {walletIssued ? (
              <>
                <Button
                  disabled={isSubmitting}
                  size="lg"
                  level="primary"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  size="lg"
                  type="submit"
                  status="error"
                  level="secondary"
                >
                  {isSubmitting ? "Leaving..." : "Leave"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={isSubmitting}
                  size="lg"
                  status="error"
                  level="primary"
                  type="submit"
                >
                  {isSubmitting ? "Leaving..." : "Leave"}
                </Button>
                <Button size="lg" disabled={isSubmitting} onClick={onCancel}>
                  Cancel
                </Button>
              </>
            )}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

type DeleteBusinessProps = {
  businessId: string
  redirectUrl?: string
  onSuccess?: (businessId: string) => void
  onCancel?: () => void
}

export function DeleteBusinessInDialog({
  enabledPayments,
  onSuccess,
  children,
  ...props
}: DeleteBusinessProps & {
  enabledPayments?: boolean
  children: (props: { delete: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const [step, setStep] = useState<number>(1)
  const [shouldDisableActions, setShouldDisableActions] =
    useState<boolean>(false)

  function onClose() {
    setStep(1)
    state.close()
  }

  function updateStep(type: "next" | "previous") {
    setStep((prevStep) => (type === "next" ? prevStep + 1 : prevStep - 1))
  }

  return (
    <>
      {children({ delete: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={() => (!shouldDisableActions ? onClose() : undefined)}
        placement={enabledPayments ? undefined : "right"}
        isDismissable={!shouldDisableActions}
        title={
          enabledPayments ? `Delete Business Account` : "Delete Business ?"
        }
      >
        <DeleteBusiness
          {...props}
          onSuccess={(...args) => {
            if (args.length) {
              onClose()
              onSuccess?.(...args)
            }
          }}
          step={step}
          enabledPayments={enabledPayments}
          updateStep={updateStep}
          onCancel={onClose}
          disableModalActions={setShouldDisableActions}
        />
      </Modal>
    </>
  )
}

function DeleteBusiness({
  step,
  businessId,
  redirectUrl,
  enabledPayments,
  onCancel,
  updateStep,
  disableModalActions,
}: DeleteBusinessProps & {
  step: number
  enabledPayments?: boolean
  updateStep: (type: "next" | "previous") => void
  disableModalActions: (shouldDisableActions: boolean) => void
}) {
  const navigate = useNavigate()
  const deleteBusiness = useDeleteBusiness(businessId)
  const { business } = useBusiness(businessId)
  const { books } = useBooksForBusinessId(businessId)
  const numberOfEntries = useMemo(() => {
    let numOfEntriesInBusiness = 0
    books.forEach((book) => {
      numOfEntriesInBusiness += book?.numEntries || 0
    })
    return numOfEntriesInBusiness
  }, [books])

  const CANNOT_DELETE_BUSINESS_POINTERS = useMemo(() => {
    return [
      `${business.name} cannot be deleted since payments are active in this business`,
      `If you would like to delete this business then please contact us`,
    ]
  }, [business.name])

  return (
    <>
      {enabledPayments ? (
        <>
          <ModalBody>
            <Stack gap="2" textAlign="center" alignItems="center">
              <BuildingWithCloseIcon size="18" />
              <Text fontSize="lg" fontWeight="semibold">
                Can’t delete {business.name}
              </Text>
            </Stack>
            <Stack alignItems="center" justifyContent="center">
              <Box
                height="1"
                width="4"
                rounded="lg"
                marginY="4"
                backgroundColor="gray400"
              />
            </Stack>
            <Stack as="ul" gap="4">
              {CANNOT_DELETE_BUSINESS_POINTERS.map((pointer) => (
                <Inline key={pointer} gap="4" alignItems="center" as="li">
                  <Circle size="2" backgroundColor="gray400" />
                  <Text>{pointer}</Text>
                </Inline>
              ))}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <WhatsAppContactLink
              size="lg"
              text={`Hi, I would like to delete ${business.name} (Payments Active). For version Cashbook-Web (${config.appVersion}).`}
            />
          </ModalFooter>
        </>
      ) : (
        <Formik
          initialValues={{
            name: "" as string,
            businessId: businessId as string,
          }}
          onSubmit={formikOnSubmitWithErrorHandling(async (values, actions) => {
            disableModalActions(true)
            if (values.name !== business.name) {
              actions.setStatus(
                `Business name doesn’t match. Please type ${business.name} in the required field to confirm`
              )
              return
            }
            setSyncStoredItem("deletedBusiness", business.name)
            await deleteBusiness()
            disableModalActions(false)
            navigate(redirectUrl || "/")
          })}
        >
          {({ status, values, isSubmitting }) => (
            <>
              {step === 1 ? (
                <>
                  <ModalBody>
                    <Stack
                      gap="12"
                      className="bg-[#FAEBEB]"
                      padding="8"
                      rounded="md"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Stack
                        width="full"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        gap="4"
                      >
                        <Box>
                          <InformationWarningIcon color="red900" size="12" />
                        </Box>
                        <Heading as="h2" fontSize="lg">
                          Are you sure?
                        </Heading>
                        <Text>
                          This business will be deleted permanently. All the
                          team members will lose access
                        </Text>
                        <Box
                          height="1"
                          width="4"
                          rounded="lg"
                          marginTop="4"
                          className="bg-[#E9B1B1]"
                        />
                      </Stack>
                      <Stack
                        gap="4"
                        width="full"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text fontSize="md" fontWeight="semibold">
                          You are deleting
                        </Text>
                        <Box
                          rounded="md"
                          backgroundColor="white"
                          padding="4"
                          width="full"
                        >
                          <Inline alignItems="center" justifyContent="evenly">
                            <Stack
                              gap="2"
                              width="1/2"
                              alignItems="center"
                              justifyContent="center"
                              borderRightWidth="1"
                            >
                              <Text
                                color="red500"
                                fontSize="2xl"
                                fontWeight="semibold"
                              >
                                {books.length}
                              </Text>
                              <Text fontSize="md" fontWeight="semibold">
                                Books
                              </Text>
                            </Stack>
                            <Stack
                              gap="2"
                              width="1/2"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Text
                                color="red500"
                                fontSize="2xl"
                                fontWeight="semibold"
                              >
                                {numberOfEntries}
                              </Text>
                              <Text fontSize="md" fontWeight="semibold">
                                Entries
                              </Text>
                            </Stack>
                          </Inline>
                        </Box>
                      </Stack>
                    </Stack>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      level="primary"
                      size="lg"
                      type="button"
                      onClick={onCancel}
                    >
                      <Box>
                        <CancelIcon />
                      </Box>
                      Cancel
                    </Button>
                    <Button
                      size="lg"
                      status="error"
                      onClick={() => updateStep("next")}
                    >
                      <Box>
                        <CheckIcon />
                      </Box>
                      Continue
                    </Button>
                  </ModalFooter>
                </>
              ) : step === 2 ? (
                <Form noValidate>
                  <ModalBody>
                    <Stack gap="6">
                      <Text as="span">
                        Are you sure? Please Type{" "}
                        <Text as="span" fontWeight="semibold">
                          '{business.name}'
                        </Text>{" "}
                        to confirm
                      </Text>
                      <FormField
                        type="text"
                        name="name"
                        label="Enter Business Name"
                        autoComplete="off"
                        placeholder="Enter Business Name Here"
                        autoFocus
                        required
                        noMargin
                      />
                      <Box borderWidth="1" rounded="md" padding="5">
                        <Stack gap="3">
                          <Inline gap="2" alignItems="center">
                            <Box>
                              <InformationWarningIcon color="red900" />
                            </Box>
                            <Text
                              fontSize="md"
                              fontWeight="semibold"
                              color="red900"
                            >
                              {books.length} {pluralize("book", books.length)}{" "}
                              will be deleted
                            </Text>
                          </Inline>
                          {books.length ? (
                            <Inline as="ul" gap="2" flexWrap="wrap">
                              {books.map((book) => (
                                <Box
                                  key={book.id}
                                  paddingY="1"
                                  paddingX="2"
                                  rounded="md"
                                  backgroundColor="red100"
                                >
                                  <Text fontSize="sm">{book.name}</Text>
                                </Box>
                              ))}
                            </Inline>
                          ) : null}
                        </Stack>
                      </Box>
                      {status ? <Alert status="error">{status}</Alert> : null}
                    </Stack>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      level="primary"
                      disabled={isSubmitting}
                      onClick={onCancel}
                      size="lg"
                    >
                      <Box>
                        <CancelIcon />
                      </Box>
                      Cancel
                    </Button>
                    <Button
                      size="lg"
                      status="error"
                      type="submit"
                      level="secondary"
                      disabled={isSubmitting || values.name !== business.name}
                    >
                      <Box>
                        {isSubmitting ? <SpinnerIcon /> : <TrashIcon />}
                      </Box>
                      {isSubmitting ? "Deleting..." : "Delete"}
                    </Button>
                  </ModalFooter>
                </Form>
              ) : null}
            </>
          )}
        </Formik>
      )}
    </>
  )
}
