import { useParams } from "react-router-dom"
import NewBusinessTransition from "../TransitionToNewBusiness"
import { Header } from "../../DashboardLayout"

export default function SmTransitionToNewBusiness() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <>
      <Header />
      <NewBusinessTransition />
    </>
  )
}
