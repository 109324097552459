import { getFunctions } from "firebase/functions"
import { useFirebaseApp } from "reactfire"

export function useFunctionsInRegion(region: string) {
  const app = useFirebaseApp()
  return getFunctions(app, region)
}

declare global {
  interface Window {
    _reactFirePreloadedObservables: Map<unknown, unknown>
  }
}

export const clearFirestoreCache = () => {
  const map = window._reactFirePreloadedObservables
  Array.from(map.keys()).forEach((key) => {
    if (typeof key === "string" && key.includes("firestore")) {
      map.delete(key)
    }
  })
}
