import type { CollectionReference, Timestamp } from "firebase/firestore"
import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
} from "reactfire"
import { collection, doc, query, where } from "firebase/firestore"
import { useCallback, useEffect, useState } from "react"
import { httpsCallable } from "firebase/functions"
import { logInfo } from "@cashbook/util-logging"

export type TBusinessInvitation = {
  // ID of the the invitation document
  id: string
  // ID of the business for which the user is invited
  businessId: string
  // Name of the business for which the user is invited
  businessName: string
  // Name of the book for which the user is invited
  bookName?: string
  // ID of the host (who has invited / sent the invitation)
  hostId: string
  // Name of the host (who has invited / sent the invitation)
  hostName: string
  // Token associated with the invitation
  inviteId: string
  // Invitation link
  inviteLink: string
  // Name of the guest (who is invited / received the invitation)
  guestName: string
  // Phone number of the guest (who is invited)
  guestPhone: string
  // Email address of the guest (who is invited)
  guestEmail: string
  // Role for which the guest is invited
  role: "partner" | "staff"
  // Date of creation for invitation
  createdAt: Timestamp
  // Date of invitation acceptance
  acceptedAt?: Timestamp
  // Who (user id) accepted the invitation
  acceptedBy?: string
  // Was this invitation accepted via link ?
  // Invitations can be automatically accepted on signup (without visiting the link)
  acceptedViaLink?: boolean
  // When was this invitation deleted/canceled ?
  deletedAt?: Timestamp
  // Who (user id) deleted/canceled this invitation
  deletedBy?: string
  //Books invited into specifically
  sharedBooks?: Array<{ id: string; role: "admin" | "editor" | "viewer" }>
  bookId?: string
  editor: boolean
  businessLogo?: {
    image_url: string
    thumb_url: string
  }
  wallet?: {
    can_enable_wallet?: boolean
    min_kyc?: boolean
  }
  uid?: string
}

function useShareBusinessInvitationCollection() {
  const store = useFirestore()
  return collection(
    store,
    "Invitations"
  ) as CollectionReference<TBusinessInvitation>
}

function useShareBusinessInvitationDocument(invitationId: string) {
  const store = useShareBusinessInvitationCollection()
  return doc(store, invitationId)
}

export function useShareBusinessInvitations(businessId: string) {
  const collection = useShareBusinessInvitationCollection()
  const collectionQuery = query(
    collection,
    where("businessId", "==", businessId)
  )
  const { data: invitations } = useFirestoreCollectionData(collectionQuery, {
    idField: "id",
  })
  const getInvitationForInvitationId = useCallback(
    (invitationId: string) => {
      const teamMember: TBusinessInvitation | undefined = invitations.find(
        (invitation) => invitation.id === invitationId
      )
      return teamMember
    },
    [invitations]
  )

  invitations.sort((a, b) => {
    const roles = ["staff", "partner"]
    const aRoleIndex = roles.indexOf(a.role)
    const bRoleIndex = roles.indexOf(b.role)
    if (aRoleIndex !== bRoleIndex) {
      // prefer the owner role first
      return aRoleIndex > bRoleIndex ? -1 : 1
    }
    return -1
  })

  return {
    invitations: invitations.filter(
      (invitation) => !invitation.deletedBy && !invitation.acceptedBy
    ),
    getInvitationForInvitationId,
  }
}

export function useShareBusinessInvitationById(invitationId: string) {
  const doc = useShareBusinessInvitationDocument(invitationId)
  return {
    invitation: doc,
  }
}

export function useAcceptBusinessInvitation(tokenId?: string | null) {
  const fns = useFunctions()
  const [{ data, error, status }, setState] = useState<
    | {
        data: undefined
        error: null
        status: "init"
      }
    | {
        data: { businessId: string; bookId?: string }
        error: null
        status: "success"
      }
    | {
        data: undefined
        error: null
        status: "in_progress"
      }
    | {
        data: undefined
        error: Error
        status: "failed"
      }
  >({ data: undefined, error: null, status: "init" })
  const acceptInvitation = useCallback(async () => {
    setState({
      data: undefined,
      error: null,
      status: "in_progress",
    })
    try {
      if (!tokenId) {
        throw new Error("Missing invitation")
      }
      const { data } = await httpsCallable<
        { tokenId: string },
        {
          businessId: string
          bookId?: string
        }
      >(
        fns,
        "syncAddToBusiness"
      )({
        tokenId,
      })
      setState({
        data,
        error: null,
        status: "success",
      })
    } catch (e) {
      const err = e as Error
      setState({
        data: undefined,
        error: err,
        status: "failed",
      })
    }
  }, [fns, tokenId])
  useEffect(() => {
    acceptInvitation()
  }, [acceptInvitation])
  return {
    invitedTo: { ...data },
    error,
    status,
  }
}

export function useShareBusinessInvitation(tokenId?: string | null) {
  const fns = useFunctions()
  const [{ data: invitation, error, status }, setState] = useState<
    | {
        data: undefined
        error: null
        status: "init"
      }
    | {
        data: TBusinessInvitation
        error: null
        status: "success"
      }
    | {
        data: undefined
        error: null
        status: "in_progress"
      }
    | {
        data: undefined
        error: Error
        status: "failed"
      }
  >({ data: undefined, error: null, status: "init" })
  const getInvitation = useCallback(async () => {
    setState({
      data: undefined,
      error: null,
      status: "in_progress",
    })
    try {
      if (!tokenId) {
        throw new Error("Missing invitation")
      }
      const { data } = await httpsCallable<
        { inviteId: string },
        TBusinessInvitation
      >(
        fns,
        "getInvitationInfo"
      )({
        inviteId: tokenId,
      })
      setState({
        data,
        error: null,
        status: "success",
      })
    } catch (e) {
      const err = e as Error
      logInfo(err.message || "Error info for get invitation info")
      setState({
        data: undefined,
        error: err,
        status: "failed",
      })
    }
  }, [fns, tokenId])
  useEffect(() => {
    getInvitation()
  }, [getInvitation])
  return {
    invitation,
    error,
    status,
  }
}
