import { SpinnerIcon, Box } from "@cashbook/web-components"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../../ErrorBoundary"
import HelpDocDetailsPage from "./../HelpDocDetailsPage"
import Page from "./Page"

export default function SmHelpDocDetailsPage() {
  return (
    <SuspenseWithPerf
      fallback={
        <Box paddingY="16" textAlign="center">
          <SpinnerIcon />
        </Box>
      }
      traceId="loading_docs"
    >
      <ErrorBoundary>
        <Page title="Help Docs" backTo={`/m/docs`} bgColor="white">
          <Box padding="4">
            <HelpDocDetailsPage />
          </Box>
        </Page>
      </ErrorBoundary>
    </SuspenseWithPerf>
  )
}
