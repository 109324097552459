import { useState } from "react"
import { Form, Formik } from "formik"
import { useAuth } from "reactfire"
import { signInWithCustomToken } from "firebase/auth"
import type { User } from "firebase/auth"
import {
  useCheckUsersExists,
  useSendVerificationCode,
} from "@cashbook/data-store/auth"
import { Recaptcha } from "./VerifyPhoneNumber"
import * as Validator from "yup"
import {
  Box,
  FormField,
  Text,
  Stack,
  formikOnSubmitWithErrorHandling,
  CBButton,
} from "@cashbook/web-components"
import { logInfo } from "@cashbook/util-logging"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import toast from "react-hot-toast"
import { EmailValidator, OTPValidator } from "@cashbook/util-general"
import TermsAndConditionsBox from "./TermsAndConditionsBox"
import { OtpVerification } from "./OtpVerification"

type STEPS = "phoneInput" | "emailInput" | "socialAuthentication"
type LoginWithEmailProps = {
  onSuccess?: (data: User) => void
  email?: string | null
  isIndianUser: boolean
  setStep: (step: STEPS) => void
  onEmailChange?: (newEmail: string, oldEmail: string) => Promise<void>
}

type ACTION_ERRORS = {
  email?: string
  code?: string
}

export function LoginWithEmail({
  email,
  isIndianUser,
  onSuccess,
  setStep,
  onEmailChange,
}: LoginWithEmailProps) {
  const auth = useAuth()
  const {
    secondsRemainingToResend,
    secondsRemainingToResendStr,
    setCaptchaVerifier,
    emailOtpAttempts,
    sendingVerificationCode,
    verifyOtpViaEmail,
    sendVerificationCodeUsingEmail,
    resendVerificationCodeViaEmail,
  } = useSendVerificationCode()

  const [askForOTP, setAskForOTP] = useState<boolean>(false)
  const { checkEmailExists: checkIfEmailExist } = useCheckUsersExists()

  const parsedEmail = email?.replace(/\s/gi, "")

  //Login using email
  const loginWithEmailHandler = async (
    email: string,
    code: string,
    setErrors: ({ email, code }: ACTION_ERRORS) => void
  ) => {
    if (askForOTP) {
      try {
        const token = await verifyOtpViaEmail(email, code)
        const { user } = await signInWithCustomToken(auth, token)
        trackEvent(TrackingEvents.LOGIN_OTP_VERIFIED, {
          isAutoVerified: false,
          verificationMethod: "email",
        })
        if (!user.displayName) {
          trackEvent(TrackingEvents.OTP_VERIFIED_NEW_USER, {
            verificationMethod: "email",
          })
        }
        onSuccess?.(user)
      } catch (e) {
        const error = e as Error
        logInfo("Email Login Failed", {
          tags: {
            code: (e as never as { code?: string }).code || error.message,
            emailOtpAttempts,
          },
        })
        setErrors({
          code: error.message,
        })
        return
      }
      return
    }
    if (
      onEmailChange &&
      parsedEmail &&
      parsedEmail !== email.replace(/\s/gi, "")
    ) {
      try {
        await onEmailChange(parsedEmail, email)
      } catch (e) {
        const error = e as Error
        toast.error(error.message)
        return
      }
    }
    trackEvent(TrackingEvents.EMAIL_SUBMITTED_FOR_LOGIN, {
      email: email,
      autoFilled: false,
    })
    if (isIndianUser) {
      const userExist = await checkIfEmailExist(email)
      if (!userExist) {
        setErrors({
          email:
            "This email is not verified with us. Please enter a verified email and try again.",
        })
        return
      }
    }
    try {
      await sendVerificationCodeUsingEmail(email)
      toast.success(`Verification OTP sent on mail!`)
      setAskForOTP(true)
    } catch (e) {
      const error = e as Error
      logInfo("Send OTP Failed", {
        tags: {
          message: error.message,
          emailOtpAttempts,
        },
      })
      setErrors({
        email: error.message,
      })
      throw new Error(error.message)
    }
    return
  }

  return (
    <Box
      id="loginWithEmailForm"
      maxWidth="sm"
      marginX="auto"
      borderWidth="1"
      borderColor="gray100"
      rounded="md"
      padding="4"
    >
      <Recaptcha onLoad={setCaptchaVerifier} />
      <Stack gap="8">
        <Formik
          initialValues={{
            email: (email || "") as string,
            code: "" as string,
          }}
          validateOnBlur={false}
          validationSchema={Validator.object().shape({
            email: EmailValidator.required(),
            code: OTPValidator,
          })}
          onSubmit={formikOnSubmitWithErrorHandling(async (values, actions) => {
            await loginWithEmailHandler(
              values.email,
              values.code,
              ({ email, code }: ACTION_ERRORS) =>
                actions.setErrors({ email, code })
            )
          })}
        >
          {({ values, isSubmitting, status, setFieldValue, submitForm }) => (
            <Form noValidate>
              {!askForOTP ? (
                <Stack position="relative" padding="4">
                  <Box>
                    <Stack>
                      <Text
                        as="label"
                        htmlFor="email"
                        fontSize="md"
                        fontWeight="medium"
                        className="pb-4"
                      >
                        Enter your email address
                      </Text>
                      <FormField
                        autoFocus
                        name="email"
                        value={values.email}
                        placeholder="xyz123@gmail.com"
                        disabled={isSubmitting}
                        onChange={(e) => setFieldValue("email", e.target.value)}
                      />
                      <Box
                        width="full"
                        display="flex"
                        flexDirection="col"
                        alignItems="center"
                      >
                        <CBButton
                          fullWidth
                          type="submit"
                          id="submitEmail"
                          size="lg"
                          disabled={!values.email.length}
                          loading={isSubmitting}
                        >
                          {isSubmitting ? "Sending OTP..." : "Send OTP"}
                        </CBButton>
                        <Text className="py-4">OR</Text>
                        <CBButton
                          fullWidth
                          size="lg"
                          onClick={() =>
                            setStep(
                              isIndianUser
                                ? "phoneInput"
                                : "socialAuthentication"
                            )
                          }
                          loading={isSubmitting}
                        >
                          Other ways to login
                        </CBButton>
                      </Box>
                    </Stack>
                  </Box>
                  <Box className="pt-8">
                    <TermsAndConditionsBox />
                  </Box>
                </Stack>
              ) : (
                <OtpVerification
                  otp={values.code}
                  otpVerificationLoading={isSubmitting}
                  sendingVerificationCode={sendingVerificationCode}
                  secondsRemainingToResend={secondsRemainingToResend}
                  secondsRemainingToResendStr={secondsRemainingToResendStr}
                  email={values.email}
                  onBack={() => {
                    setAskForOTP(false)
                    setFieldValue("code", "")
                  }}
                  onResendOtpHandler={async () => {
                    setFieldValue("code", "")
                    await resendVerificationCodeViaEmail(values.email)
                    trackEvent(TrackingEvents.OTP_RESENT, {
                      from: "login",
                      attemptCount: emailOtpAttempts,
                      verificationMethod: "email",
                    })
                    toast.success(`OTP resent to ${values.email}`)
                  }}
                  onOtpChange={(value) => setFieldValue("code", value)}
                />
              )}
            </Form>
          )}
        </Formik>
      </Stack>
    </Box>
  )
}
