import {
  ArrowLeftIcon,
  Button,
  DataLoadingFallback,
  DotsVerticalIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PageMeta,
  Text,
  Heading,
  Box,
  TrashIcon,
  Stack,
  Inline,
  NavLink,
  PencilOutlinedIcon,
  CopyIcon,
  Circle,
} from "@cashbook/web-components"
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  Outlet,
} from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import { DeleteBook, DuplicateBookInDialog, EditBookInDialog } from "../Books"
import {
  BOOK_PERMISSIONS,
  getRoleDetails,
  useBook,
} from "@cashbook/data-store/books"
import ErrorBoundary from "../ErrorBoundary"
import { usePartyOrContact } from "@cashbook/data-store/books"
import { useSyncedStorageState } from "@cashbook/data-store/storage"
import { useMemo } from "react"
import { useProfile } from "@cashbook/data-store/users"

export default function BookSettingsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <BookSettingsLayout
          key={bookId}
          bookId={bookId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

export function RedirectToMembersFromIndexPage({
  routePrefix = "",
}: {
  routePrefix?: string
}) {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return <Navigate to={`${routePrefix}/`} />
  return (
    <Navigate
      to={`${routePrefix}/businesses/${businessId}/cashbooks/${bookId}/settings/members`}
      replace
    />
  )
}

function BookSettingsLayout({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book, checkIfAuthenticatedMemberCan, involvedUsers } = useBook(bookId)
  const navigate = useNavigate()
  const { user } = useProfile()
  const editorRoleDetails = getRoleDetails("editor")
  const { partyOrContact } = usePartyOrContact()

  const [newFeaturesIntroduced, setNewFeaturesIntroduced] =
    useSyncedStorageState<{
      [key: string]: string[]
    }>("newFeatures", {})

  const showNewBannerForEntryFields: string | undefined = useMemo(() => {
    return !newFeaturesIntroduced[user?.uid]?.includes("fields") ||
      !newFeaturesIntroduced[user?.uid]?.includes("mandatoryCategory") ||
      !newFeaturesIntroduced[user?.uid]?.includes("mandatoryPaymentMode")
      ? "fields"
      : undefined
  }, [newFeaturesIntroduced, user?.uid])
  return (
    <>
      <PageMeta>
        <title>{book.name}</title>
      </PageMeta>
      <Box key={bookId} bgColor="white" className="min-h-screen sm:min-h-0">
        <Box
          as="header"
          paddingY="6"
          paddingX="8"
          borderBottomWidth="1"
          borderColor="gray100"
        >
          <Inline gap="6" alignItems="center">
            <Inline flexGrow="1" gap="4" alignItems="center">
              <Box>
                <Link
                  to={`/businesses/${businessId}/cashbooks/${book.id}/transactions`}
                >
                  <ArrowLeftIcon />
                </Link>
              </Box>
              <Heading as="h2" fontSize="lg" fontWeight="semibold">
                Settings
              </Heading>
              <Text color="gray500">({book.name})</Text>
            </Inline>
            <Box display={{ xs: "none", sm: "flex" }} gap="12">
              {checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.EDIT_BOOK) ? (
                <EditBookInDialog bookId={book.id}>
                  {({ edit }) => (
                    <Button inline onClick={edit}>
                      <Box>
                        <PencilOutlinedIcon />
                      </Box>
                      Rename Book
                    </Button>
                  )}
                </EditBookInDialog>
              ) : null}
              {checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.EDIT_BOOK) ? (
                <DuplicateBookInDialog
                  book={book}
                  bookName={book.name}
                  onSuccess={(newBookId) => {
                    navigate(
                      `/businesses/${businessId}/cashbooks/${newBookId}/transactions`
                    )
                  }}
                >
                  {({ duplicate }) => (
                    <Button inline onClick={duplicate}>
                      <Box>
                        <CopyIcon />
                      </Box>
                      Duplicate Book
                    </Button>
                  )}
                </DuplicateBookInDialog>
              ) : null}
              {checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.DELETE_BOOK) ? (
                <DeleteBook
                  book={book}
                  onSuccess={() => {
                    navigate(`/businesses/${businessId}/cashbooks`)
                  }}
                >
                  {({ onDelete }) => (
                    <Button onClick={onDelete} inline status="error">
                      <TrashIcon /> Delete Book
                    </Button>
                  )}
                </DeleteBook>
              ) : null}
            </Box>
            <Box className="flex sm:hidden">
              <EditBookInDialog bookId={bookId}>
                {({ edit }) => (
                  <DeleteBook
                    book={book}
                    onSuccess={() => {
                      navigate(`/businesses/${businessId}/cashbooks`)
                    }}
                  >
                    {({ onDelete }) => (
                      <Menu>
                        <MenuButton inline>
                          <DotsVerticalIcon />
                        </MenuButton>
                        <MenuList
                          align="bottom-right"
                          className="whitespace-pre"
                        >
                          {checkIfAuthenticatedMemberCan(
                            BOOK_PERMISSIONS.DELETE_BOOK
                          ) ? (
                            <MenuItem
                              action="delete-book"
                              className="whitespace-pre"
                              color="red900"
                              onClick={() => onDelete()}
                            >
                              Delete Book
                            </MenuItem>
                          ) : null}
                        </MenuList>
                      </Menu>
                    )}
                  </DeleteBook>
                )}
              </EditBookInDialog>
            </Box>
          </Inline>
        </Box>
        <Box display="flex">
          <Box
            as="aside"
            className="w-[264px]"
            borderRightWidth="1"
            borderColor="gray100"
          >
            <Box as="ol" paddingLeft="4" className="divide-y">
              {[
                {
                  label: "Members",
                  description: "Add, Change role, Remove",
                  to: "members",
                },
                {
                  label: "Entry Field",
                  description: `${partyOrContact}, Category, Payment mode & Custom Fields`,
                  to: "fields",
                },
              ]
                .concat(
                  involvedUsers.length > 1 &&
                    checkIfAuthenticatedMemberCan(
                      BOOK_PERMISSIONS.UPDATE_MEMBER_ROLES
                    )
                    ? [
                        {
                          label: `Edit ${editorRoleDetails.title} Role`,
                          description: "Make changes in role as per your need",
                          to: `roles/editor`,
                        },
                      ]
                    : []
                )
                .map(({ label, description, to }) => {
                  return (
                    <Box as="li" paddingY="3" paddingRight="4" key={label}>
                      <NavLink
                        to={to}
                        paddingX="4"
                        paddingY="3"
                        display="block"
                        rounded="md"
                        bgColor={{ default: "transparent", hover: "blue50" }}
                        activeProps={{
                          bgColor: "blue100",
                        }}
                        onClick={() => {
                          if (
                            showNewBannerForEntryFields &&
                            showNewBannerForEntryFields === to &&
                            user?.uid.length
                          ) {
                            setNewFeaturesIntroduced({
                              ...newFeaturesIntroduced,
                              [`${user.uid}`]: [
                                ...(newFeaturesIntroduced[user.uid] ?? []),
                                to,
                              ],
                            })
                          }
                        }}
                      >
                        <Stack gap="2">
                          <Inline alignItems="center" gap="2">
                            <Heading
                              as="h4"
                              fontWeight="semibold"
                              fontSize="base"
                              className="relative"
                            >
                              {label}
                              {showNewBannerForEntryFields === to ? (
                                <Circle
                                  size="2"
                                  className="absolute top-0 -right-3"
                                  backgroundColor="iconError"
                                />
                              ) : null}
                            </Heading>
                          </Inline>

                          <Text
                            color="gray500"
                            fontWeight="medium"
                            fontSize="sm"
                          >
                            {description}
                          </Text>
                        </Stack>
                      </NavLink>
                    </Box>
                  )
                })}
            </Box>
          </Box>
          <Box flexGrow="1">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  )
}
