import { useParams } from "react-router-dom"
import Page from "./Page"
import BookMemberSettingPage from "./../BookMemberSettingsPage"

export default function SmBookMemberSettingsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <Page
      title="Book Members"
      backTo={`/m/businesses/${businessId}/cashbooks/${bookId}/settings`}
    >
      <BookMemberSettingPage />
    </Page>
  )
}
