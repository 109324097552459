import { init as initLogging } from "@cashbook/util-logging"
import { SpinnerIcon, OverlayProvider, Inline } from "@cashbook/web-components"
import React from "react"
import { createRoot } from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import { Toaster } from "react-hot-toast"
import { BrowserRouter as Router } from "react-router-dom"
import { FirebaseAppProvider, SuspenseWithPerf } from "reactfire"
import App from "./App"
import config from "./config"
import "./index.css"
import * as serviceWorker from "./serviceWorkerRegistration"
import { AppInstallProvider } from "./support/AppInstall"
import { setUpdateAvailable } from "./support/AppUpdatesProvider"
import { configure as configureDataStore } from "@cashbook/data-store/users"
import { configure as configurePayments } from "@cashbook/data-store/payments"
import AppNotSupported from "./AppNotSupported"

//Add strict mode as soon as it is compatible with react-aria
const root = createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <HelmetProvider>
    <OverlayProvider>
      <FirebaseAppProvider firebaseConfig={config.firebaseConfig} suspense>
        <AppInstallProvider>
          <Router>
            <SuspenseWithPerf
              traceId={"cashbook-auth-wait"}
              fallback={
                <Inline
                  minHeight="screen"
                  alignItems="center"
                  justifyContent="center"
                  gap="4"
                >
                  <SpinnerIcon size="8" /> Loading...
                </Inline>
              }
            >
              <AppNotSupported>
                <App />
              </AppNotSupported>
            </SuspenseWithPerf>
            <Toaster
              position="bottom-center"
              reverseOrder
              toastOptions={{
                style: {
                  background: "#2c324b",
                  color: "#fff",
                },
                success: {
                  duration: 5000,
                },
              }}
            />
          </Router>
        </AppInstallProvider>
      </FirebaseAppProvider>
    </OverlayProvider>
  </HelmetProvider>
)

initLogging({
  env: config.appEnv,
  dsn: config.errorReporting.sentry.dsn,
  appName: config.appName,
  appVersion: config.appVersion,
})

configureDataStore({
  cloudFunctionsUSRegion: config.firebaseConfig
    .cloudFunctionsUSRegion as string,
})

configurePayments({
  baseUrl: config.paymentsApiBaseUrl as string,
  paymentsHashKey: config.paymentsHashKey as string,
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register({
  onSuccess: (_) => {
    console.log("Application is ready to installed")
  },
  onUpdate: (registration) => {
    setUpdateAvailable(registration)
  },
})
