import { useParams } from "react-router-dom"
import Page from "./Page"
import BookEditorRoleSettingsPage from "./../BookEditorRoleSettingsPage"
import { getRoleDetails } from "@cashbook/data-store/books"

export default function SmBookEditorRoleSettingsPage() {
  const { bookId, businessId } = useParams()
  const editorRoleDetails = getRoleDetails("editor")
  return (
    <Page
      title={`Edit ${editorRoleDetails.title} Role`}
      backTo={`/m/businesses/${businessId}/cashbooks/${bookId}/settings`}
    >
      <BookEditorRoleSettingsPage hideTitle />
    </Page>
  )
}
