import {
  LogoIcon,
  PageMeta,
  SpinnerIcon,
  Box,
  Heading,
  Text,
  Stack,
  Inline,
  getButtonClassName,
  CheckCircleIcon,
  CanceledLinkIcon,
  Alert,
  ChevronDownIcon,
} from "@cashbook/web-components"
import { Link, useSearchParams } from "react-router-dom"

import { useVerifyEmailToken } from "@cashbook/data-store/users"
import config from "../config"
import { useDeviceType } from "@cashbook/device-info"

export default function EmailVerificationDetails() {
  const [searchParams] = useSearchParams()
  const emailVerificationToken = searchParams.get("token")
  const { status, error } = useVerifyEmailToken(emailVerificationToken)
  return (
    <>
      <PageMeta>
        <title>Email Verification</title>
      </PageMeta>
      <Inline gap="2" alignItems="center" padding="4">
        <LogoIcon size="12" />
        <Heading as="h2" fontSize="2xl" color="blue900" fontWeight="medium">
          {config.appTitle}
        </Heading>
      </Inline>
      <Stack
        alignItems="center"
        justifyContent="center"
        paddingY={{ sm: "0", md: "8" }}
      >
        <Stack
          gap="4"
          maxWidth="4xl"
          rounded="lg"
          padding="16"
          bgColor="white"
          alignItems="center"
          justifyContent="center"
        >
          {!emailVerificationToken ? (
            <Stack gap="8">
              <Stack gap="4">
                <Heading as="h1" fontSize="3xl">
                  Invalid Link.
                </Heading>
                <Text>
                  You are verifying a email but there is no email verification
                  token attached with the shared link.
                </Text>
                <Text>Please re-check the link and try again.</Text>
              </Stack>
              <Link to="/" className={getButtonClassName({ level: "primary" })}>
                Ok, Got it. <ChevronDownIcon rotate="270" />
              </Link>
            </Stack>
          ) : status === "failed" ? (
            <Stack gap="8">
              <Stack gap="4" justifyContent="center" alignItems="center">
                <CanceledLinkIcon color="red500" size="12" />
                <Heading as="h1" fontSize="lg">
                  Your verification link has expired
                </Heading>
                <Text color="gray500">Please resend verification email.</Text>
              </Stack>
              <Alert status="error">{error?.message}</Alert>
              <Text color="gray500">
                If you need more assistance, please contact our support.
              </Text>
              <CashbookAppLink />
            </Stack>
          ) : status === "in_progress" ? (
            <Box textAlign="center">
              <SpinnerIcon /> Verifying...
            </Box>
          ) : status === "success" ? (
            <Stack gap="8">
              <Stack gap="4" justifyContent="center" alignItems="center">
                <CheckCircleIcon color="green500" size="12" />
                <Heading as="h1" fontSize="lg">
                  Your email address was verified successfully
                </Heading>
              </Stack>
              <CashbookAppLink />
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </>
  )
}

function CashbookAppLink() {
  const { isSmallDevice } = useDeviceType()
  return isSmallDevice ? (
    <a
      className={getButtonClassName({ level: "primary" })}
      rel="noreferrer"
      href={config.appDownloadLink}
    >
      Open Cashbook
    </a>
  ) : (
    <Link
      to="/"
      className={getButtonClassName({ level: "primary" })}
      rel="noreferrer"
    >
      Open Cashbook
    </Link>
  )
}
