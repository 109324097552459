import Page from "./Page"
import { useParams } from "react-router-dom"
import BusinessProfilePage from "../BusinessProfilePage"

export default function SmBusinessProfile() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <Page
      title="Business Profile"
      backTo={`/m/businesses/${businessId}/business-settings`}
      bgColor="white"
    >
      <BusinessProfilePage />
    </Page>
  )
}
