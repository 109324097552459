import {
  Box,
  CancelIcon,
  CheckIcon,
  Circle,
  Inline,
  SearchIcon,
  Text,
} from "@cashbook/web-components"

export function SearchBar({
  value,
  label,
  maxWidth,
  onChange,
}: {
  label?: string
  value: string
  maxWidth?: React.ComponentProps<typeof Box>["maxWidth"]
  onChange: (value: string) => void
}) {
  return (
    <Box flex="1">
      <Inline
        position="relative"
        rounded="md"
        height="10"
        paddingRight="2"
        alignItems="stretch"
        gap="2"
        maxWidth={maxWidth || "lg"}
        width="full"
        borderWidth="1"
        borderColor="borderOutline"
        className="bg-opacity-20 focus-within:border-blue-900 focus-within:ring-1 ring-blue-900"
      >
        <input
          type="search"
          name="search"
          placeholder={label || `Search...`}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
        />
        <Inline
          as="button"
          type="button"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            if (value) onChange("")
          }}
        >
          {value ? (
            <CancelIcon color="gray900" />
          ) : (
            <SearchIcon color="gray500" />
          )}
        </Inline>
      </Inline>
    </Box>
  )
}

export function Checkbox({
  size,
  isSelected,
  onClick,
}: {
  size?: React.ComponentProps<typeof Box>["size"]
  isSelected?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}) {
  size = size || "5"
  return (
    <Box
      borderColor="iconMedium"
      borderWidth={isSelected ? "0" : "2"}
      rounded="md"
      size={size}
      onClick={onClick}
      cursor="pointer"
      backgroundColor={isSelected ? "surfacePrimary" : "transparent"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="transition-all duration-100 ease-in-out transform scale-20"
    >
      {isSelected ? <CheckIcon color="iconOnSurface" size={size} /> : null}
    </Box>
  )
}

export function Radio({
  size,
  isSelected,
  onClick,
}: {
  size?: React.ComponentProps<typeof Box>["size"]
  isSelected?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}) {
  size = size || "5"
  const innerCircleSize = `${Number(size) - 2}` // Calculate the size for the circle
  return (
    <Box
      borderColor={isSelected ? "borderPrimary" : "iconMedium"}
      borderWidth={"2"}
      rounded="full"
      size={size}
      onClick={onClick}
      cursor="pointer"
      backgroundColor={"transparent"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="transition-all duration-100 ease-in-out transform scale-20"
    >
      {isSelected ? (
        <Circle size={innerCircleSize} backgroundColor="surfacePrimary" />
      ) : null}
    </Box>
  )
}

export function Tag({ text }: { text?: string }) {
  return (
    <Box
      backgroundColor="surfaceSuccess"
      paddingY="px"
      paddingX="1"
      rounded="md"
    >
      <Text color="textOnSurface" fontSize="xs">
        {text || "New"}
      </Text>
    </Box>
  )
}
