import Page from "./Page"
import { useParams } from "react-router-dom"
import BusinessTeamMemberPage from "../BusinessTeamMember"

export default function SmBusinessTeamMemberSettings() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <Page
      title="Business Team Details"
      backTo={`/m/businesses/${businessId}/business-settings/team`}
      bgColor="white"
    >
      <BusinessTeamMemberPage />
    </Page>
  )
}
