import html2pdf from "html2pdf.js"
import { toBlob } from "html-to-image"
import { useCallback, useEffect, useState } from "react"

/**
 * Convert a file to base64 string
 */
export async function convertFileToBase64(file: Blob): Promise<string> {
  const imageAsBase64UrlString = await readFileAsDataURL(file)
  if (typeof imageAsBase64UrlString === "string") {
    return convertDataURLtoBase64(imageAsBase64UrlString)
  }
  throw new Error("Can not convert file to base64")
}

/**
 * Read a file a Data URl
 * e.g. "data:image/png;base64,iVBORw0KGgoAA..."
 */
export async function readFileAsDataURL(file: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener("load", function loadedFileInReader(e) {
      resolve(e.target?.result)
    })
    reader.addEventListener("error", function loadedFileInReader(e) {
      reject(e.target?.result)
    })
    reader.readAsDataURL(file)
  })
}

export function convertDataURLtoBase64(dataURL: string) {
  return dataURL.split(";base64,")[1]
}

/**
 * Convert HTML to PDF and Download it as a file
 * NOTE:
 * Make sure that the with of the container elm is 790px
 */
type TDownloadHTMLAsPDFConfig = {
  width?: number
}
export function useDownloadHTMLAsPDF(config?: TDownloadHTMLAsPDFConfig) {
  const width = config?.width || 1000
  const [downloadStatus, setDownloadStatus] = useState<
    "init" | "in_progress" | "success" | "failed"
  >("init")
  // reset the status after success
  useEffect(() => {
    if (downloadStatus === "success") {
      const handler = setTimeout(() => {
        setDownloadStatus("init")
      }, 5000)
      return () => {
        clearTimeout(handler)
      }
    }
    return () => undefined
  }, [downloadStatus])
  const download = useCallback(
    async (html: string | HTMLElement, fileName: string) => {
      setDownloadStatus("in_progress")
      setTimeout(async () => {
        const worker = html2pdf()
        try {
          await worker
            .from(html)
            .set({
              // this can be an array of four values
              margin: 0,
              enableLinks: true,
              html2canvas: { width },
            })
            .save(fileName)
          setTimeout(() => {
            setDownloadStatus("success")
          }, 1000)
        } catch (e) {
          setDownloadStatus("failed")
          throw e
        }
      }, 0)
    },
    [width]
  )
  return {
    download,
    status: downloadStatus,
  }
}

/**
 * Check for a file size (KB)
 */
export function checkFileSize(max: number) {
  return function checkFilesForSize(files?: unknown | File | [File]): boolean {
    let valid = true
    if (files) {
      if (!Array.isArray(files)) {
        files = [files]
      }
      if (Array.isArray(files)) {
        files.every((file) => {
          const size = file.size / 1024
          if (size > max) {
            valid = false
          }
          return valid
        })
      }
    }
    return valid
  }
}

export function checkFileTypes(types: Array<string>) {
  return function checkTypesForFiles(files?: unknown | File | [File]): boolean {
    let valid = true
    if (files) {
      if (!Array.isArray(files)) {
        files = [files]
      }
      if (Array.isArray(files)) {
        files.every((file) => {
          if (!types.includes(file.type)) {
            valid = false
          }
          return valid
        })
      }
    }
    return valid
  }
}

export function useDownloadHTMLAsImage() {
  const [downloadStatus, setDownloadStatus] = useState<
    "init" | "in_progress" | "success" | "failed"
  >("init")
  // reset the status after success
  useEffect(() => {
    if (downloadStatus === "success") {
      const handler = setTimeout(() => {
        setDownloadStatus("init")
      }, 5000)
      return () => {
        clearTimeout(handler)
      }
    }
    return () => undefined
  }, [downloadStatus])

  function downloadBlob(blob: Blob, fileName: string) {
    const a = document.createElement("a")
    document.body.appendChild(a)
    a.style.display = "none"
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
    setTimeout(() => {
      a.remove()
    }, 5000)
  }

  const download = useCallback(async (html: HTMLElement, fileName: string) => {
    setDownloadStatus("in_progress")
    setTimeout(async () => {
      try {
        const url = await toBlob(html)
        if (url) {
          setDownloadStatus("success")
          return downloadBlob(url, fileName)
        }
        setDownloadStatus("failed")
        throw new Error("Error downloading file. Please try again later.")
      } catch (e) {
        setDownloadStatus("failed")
        throw e
      }
    }, 0)
  }, [])
  return {
    download,
    status: downloadStatus,
  }
}
