import { useParams } from "react-router-dom"
import Page from "./Page"
import AddMemberToBook from "./../AddMemberToBook"

export default function SmAddMemberToBookPage() {
  const { bookId, businessId } = useParams()
  return (
    <Page
      title="Add Member"
      backTo={`/m/businesses/${businessId}/cashbooks/${bookId}/settings/members`}
    >
      <AddMemberToBook />
    </Page>
  )
}
