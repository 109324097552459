import React from "react"
import { Box } from "./Box"
import { COLORS, SIZES } from "./css/theme"

export function Circle({
  size,
  color,
  style,
  children,
  className,
  backgroundColor,
}: {
  size?: keyof typeof SIZES | string
  color?: keyof typeof COLORS
  backgroundColor?: keyof typeof COLORS
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
}) {
  const defaultSize = (size as keyof typeof SIZES) || "12"
  const defaultColor = color || "blue900"
  const defaultBgColor = backgroundColor || "blue100"
  return (
    <Box>
      <Box
        width={defaultSize}
        height={defaultSize}
        rounded="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={defaultColor}
        backgroundColor={defaultBgColor}
        style={style}
        className={className}
      >
        {children}
      </Box>
    </Box>
  )
}
