import { Channel } from "./contract"
import {
  Analytics,
  logEvent,
  setUserProperties,
  setUserId,
} from "firebase/analytics"

const createGAChannel: Channel = function createGAChannel(config: {
  analytics?: Analytics
}) {
  const { analytics } = config
  if (!analytics) {
    throw new Error("Analytics sdk required for google channel")
  }
  return {
    page(...args) {
      console.log("TODO: page", ...args)
    },
    track(eventName: string, eventData) {
      logEvent(analytics, eventName, eventData)
    },
    reset() {
      setUserId(analytics, null)
      setUserProperties(analytics, {
        user_id: undefined,
      })
    },
    identify(userId, ...args) {
      setUserId(analytics, userId as string)
      setUserProperties(analytics, {
        user_id: userId,
        ...(args[0] && typeof args[0] === "object" ? args[0] : {}),
      })
    },
  }
}

export default createGAChannel
