import { Link, useNavigate, useParams } from "react-router-dom"
import ErrorBoundary from "../ErrorBoundary"
import { SuspenseWithPerf } from "reactfire"
import {
  Alert,
  ArrowLeftIcon,
  Box,
  ChevronDownIcon,
  DataLoadingFallback,
  Heading,
  Inline,
  PageMeta,
  Text,
} from "@cashbook/web-components"
import {
  BOOK_PERMISSIONS,
  useBook,
  usePartyOrContact,
} from "@cashbook/data-store/books"
import { ImportParties } from "../Transactions"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { useEffect } from "react"
import { toast } from "react-hot-toast"

export default function BookPartiesImportPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <BookPartiesImport
          key={bookId}
          bookId={bookId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BookPartiesImport({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const navigate = useNavigate()
  const { book, authMemberDetails, checkIfAuthenticatedMemberCan, isShared } =
    useBook(bookId)
  const role = authMemberDetails.role.id

  const { partyOrContact, partiesOrContacts } = usePartyOrContact()

  useEffect(() => {
    trackEvent(TrackingEvents.IMPORT_PARTIES_SCREEN_VIEWED, {
      sharedBook: isShared,
      role: role,
    })
  }, [isShared, role])

  if (!checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.UPDATE_ENTRY_FIELDS)) {
    return (
      <Box paddingY="8" paddingX="4">
        <Alert status="error">
          You don't have permissions to add {partiesOrContacts.toLowerCase()}{" "}
          inside this book. Please contact with Admins of this book or contact
          our{" "}
          <Box as={Link} to="/docs" color="blue900" fontWeight="medium">
            support
          </Box>{" "}
          if you need more assistance.
        </Alert>
        <Box
          as={Link}
          to={`/businesses/${businessId}/cashbooks/${book.id}/settings/fields/parties`}
          replace
          color="blue900"
          fontWeight="medium"
        >
          <ChevronDownIcon rotate="90" /> Back to {partiesOrContacts}
        </Box>
      </Box>
    )
  }
  return (
    <>
      <PageMeta>
        <title>Import Entries in {book.name}</title>
      </PageMeta>
      <Box width="full" maxWidth="full" overflow="hidden" key={bookId}>
        <Box
          as="header"
          paddingY="6"
          paddingX="8"
          borderBottomWidth="1"
          borderColor="gray100"
        >
          <Inline alignItems="center" gap="6">
            <Inline flexGrow="1" gap="4" alignItems="center">
              <Box
                as="button"
                onClick={() => {
                  navigate(-1)
                }}
              >
                <ArrowLeftIcon color="iconHigh" />
              </Box>
              <Heading as="h2" fontSize="lg" fontWeight="semibold">
                Import {partiesOrContacts}
              </Heading>
              <Text color="gray500">({book.name})</Text>
            </Inline>
            <Text as="span" color="yellow800" fontWeight="medium" fontSize="sm">
              (Premium Trial Feature .{" "}
              <Link
                to="/premium"
                target="_blank"
                className="text-blue-900 font-medium"
              >
                Learn More
              </Link>
              )
            </Text>
          </Inline>
        </Box>
        <Box paddingX="8" paddingTop="4" paddingBottom="16">
          <ImportParties
            book={book}
            onSuccess={(totalParties) => {
              navigate(-1)
              toast.success(
                `${totalParties} ${
                  totalParties > 1 ? partiesOrContacts : partyOrContact
                } imported successfully!`
              )
            }}
            onCancel={() => {
              navigate(-1)
            }}
          />
        </Box>
      </Box>
    </>
  )
}
