import { DataLoadingFallback, Box } from "@cashbook/web-components"
import { Navigate } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../ErrorBoundary"
import { useBusinesses } from "@cashbook/data-store/businesses"

export default function BusinessesListPage() {
  return (
    <Box>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading businesses..." />}
        traceId="loading_businesses"
      >
        <ErrorBoundary>
          <RedirectToFirstBusiness />
        </ErrorBoundary>
      </SuspenseWithPerf>
    </Box>
  )
}

export function RedirectToFirstBusiness() {
  const { businesses } = useBusinesses()
  if (!businesses.length) {
    return <Navigate to="/add-first-business" replace />
  }
  return <Navigate to={`/businesses/${businesses[0].id}/cashbooks`} replace />
}

export function RedirectToCashbooksOfBusiness() {
  return <Navigate to={`cashbooks`} replace />
}
