import React from "react"
import BasePhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input/max"
import { Input } from "./Form"

export { isPossiblePhoneNumber, parsePhoneNumber }

function ArrowIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#ArrowIcon)">
        <path d="M7 10L12 15L17 10H7Z" fill="#212121" />
      </g>
      <defs>
        <clipPath id="ArrowIcon">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export function PhoneInput(
  props: Omit<
    React.ComponentProps<typeof BasePhoneInput>,
    "inputComponent" | "onChange"
  > & {
    onChange: (phoneNumber: string) => void
  }
) {
  return (
    <BasePhoneInput
      placeholder="e.g. 8772321230"
      className="space-x-4 ml-4"
      {...props}
      type="tel"
      countrySelectProps={{ arrowComponent: ArrowIcon }}
      inputComponent={Input}
    />
  )
}
