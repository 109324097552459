import { useCallback, useEffect, useMemo, useReducer } from "react"
import { Timestamp } from "firebase/firestore"
import { PaymentTransactionTypes, PaymentsTransaction } from "./types"
import {
  MasterWalletTransactionsPayload,
  getMasterWalletBalance,
  getMasterWalletTransactions,
} from "./services"
import { usePagination } from "./utils"
import { useFormik } from "formik"
import { useMount } from "@cashbook/util-general"
import { toast } from "react-hot-toast"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"

type MasterWalletResponse = {
  data: {
    balance: string
    business: {
      account_no: string
      ifsc_code: string
      created_at: Timestamp | Date
      updated_at: Timestamp | Date
      business_id: string
      corpShortName: string
      corporateName: string
      kyc_status: "APPROVED" | "PENDING"
    }
  }
}

export type MasterWalletTransactionsFilters = {
  transaction_type?: "all" | PaymentTransactionTypes
  from_datetime?: Date
  to_datetime?: Date
  dateFilterLabel?: string
}

type MasterWallet = {
  balance: string
  account_no: string
  ifsc_code: string
  created_at: Timestamp | Date
  business_id: string
  corpShortName: string
  corporateName: string
  kyc_status: "APPROVED" | "PENDING"
}
type Transactions = {
  transactionCount?: number
  transactions: PaymentsTransaction[]
  fetching?: boolean
  fetchingMore?: boolean
}
type MasterWalletState = {
  error?: string | Error | null
  status: "in_progress" | "success" | "failed"
  wallet?: MasterWallet
  transactionDetails?: Transactions
}
type TYPE_AND_PAYLOAD =
  | { type: "FETCHING_DATA" }
  | {
      type: "FETCHED_DATA"
      payload: { transactions: Transactions; wallet: MasterWallet }
    }
  | { type: "FETCHING_DATA_FAILED"; payload: Error }
  | { type: "FETCHING_TRANSACTIONS" }
  | {
      type: "FETCHED_TRANSACTIONS"
      payload: { transactions: PaymentsTransaction[]; count: number }
    }
  | { type: "FETCHING_TRANSACTIONS_FAILED"; payload: Error }
  | { type: "FETCHING_TRANSACTIONS_WITH_FILTERS_APPLIED" }
  | { type: "FETCHING_MORE_TRANSACTIONS" }
  | {
      type: "FETCHED_MORE_TRANSACTIONS"
      payload: PaymentsTransaction[]
    }

const reducer = (
  state: MasterWalletState,
  action: TYPE_AND_PAYLOAD
): MasterWalletState => {
  switch (action.type) {
    case "FETCHING_DATA":
      return {
        ...state,
        error: null,
        status: "in_progress",
        wallet: undefined,
        transactionDetails: undefined,
      }
    case "FETCHED_DATA":
      return {
        ...state,
        error: null,
        status: "success",
        wallet: action.payload.wallet,
        transactionDetails: {
          fetching: false,
          fetchingMore: false,
          transactions: action.payload.transactions.transactions,
          transactionCount: action.payload.transactions.transactionCount,
        },
      }
    case "FETCHING_DATA_FAILED":
      return {
        ...state,
        error: action.payload,
        status: "failed",
        wallet: undefined,
        transactionDetails: undefined,
      }
    case "FETCHING_TRANSACTIONS":
      return {
        ...state,
        transactionDetails: {
          fetching: true,
          fetchingMore: false,
          transactions: [],
          transactionCount: undefined,
        },
      }
    case "FETCHED_TRANSACTIONS":
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          fetching: false,
          fetchingMore: false,
          transactionCount: action.payload.count,
          transactions: action.payload.transactions,
        },
      }
    case "FETCHING_MORE_TRANSACTIONS":
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          fetching: false,
          fetchingMore: true,
          transactions: state.transactionDetails?.transactions
            ? state.transactionDetails.transactions
            : [],
        },
      }
    case "FETCHED_MORE_TRANSACTIONS":
      return {
        ...state,
        transactionDetails: {
          ...state.transactionDetails,
          fetching: false,
          fetchingMore: false,
          transactions: state.transactionDetails?.transactions
            ? [...state.transactionDetails.transactions, ...action.payload]
            : action.payload,
        },
      }
    default:
      return state
  }
}
const initialState: MasterWalletState = {
  status: "in_progress",
  error: null,
  wallet: undefined,
  transactionDetails: undefined,
}

const initialParamsForTransactions: MasterWalletTransactionsFilters = {
  transaction_type: "all",
  from_datetime: undefined,
  to_datetime: undefined,
  dateFilterLabel: "all",
}

export function useMasterWallet(
  businessId: string,
  initialSearchParamsProp: MasterWalletTransactionsFilters = initialParamsForTransactions
) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    lastPage,
    pagination,
    current: currentPage,
    next,
    reset: resetPagination,
  } = usePagination({
    skip: 0,
    take: 25,
    totalItems: state.transactionDetails?.transactionCount || 0,
  })

  const initialSearchParams = useMemo(() => {
    if (!initialSearchParamsProp) return initialParamsForTransactions
    return {
      ...initialParamsForTransactions,
      ...initialSearchParamsProp,
    }
  }, [initialSearchParamsProp])

  const { values: params, setValues } =
    useFormik<MasterWalletTransactionsFilters>({
      initialValues: initialSearchParams,
      onSubmit: () => undefined,
    })

  const callApis = useCallback(async () => {
    dispatch({ type: "FETCHING_DATA" })
    try {
      const walletApi = getMasterWalletBalance<MasterWalletResponse>(businessId)
      const payload = {
        businessId,
        skip: pagination.skip,
        take: pagination.take,
      }
      const transactionsApi = getMasterWalletTransactions<{
        count: number
        data: PaymentsTransaction[]
      }>(payload)
      if (params.dateFilterLabel !== "all") {
        trackEvent(TrackingEvents.PAYMENT_TRANSACTIONS_FILTER_APPLIED, {
          from: "masterWalletTransactions",
          dateFilter: params.dateFilterLabel,
        })
      }
      Promise.all([walletApi, transactionsApi])
        .then((responses) => {
          const [wallet, transactions] = responses
          if (!wallet || !transactions) {
            throw new Error(
              "Something went wrong while fetching master wallet data!"
            )
          }
          const walletData: MasterWallet = {
            balance: wallet.data.balance,
            ifsc_code: wallet.data.business.ifsc_code,
            account_no: wallet.data.business.account_no,
            created_at: wallet.data.business.created_at,
            kyc_status: wallet.data.business.kyc_status,
            business_id: wallet.data.business.business_id,
            corporateName: wallet.data.business.corporateName,
            corpShortName: wallet.data.business.corpShortName,
          }
          const transactionsData: Transactions = {
            transactions: transactions.data,
            transactionCount: transactions.count,
          }
          dispatch({
            type: "FETCHED_DATA",
            payload: { wallet: walletData, transactions: transactionsData },
          })
        })
        .catch((e) => {
          dispatch({ type: "FETCHING_DATA_FAILED", payload: e as Error })
        })
    } catch (e) {
      dispatch({ type: "FETCHING_DATA_FAILED", payload: e as Error })
    }
  }, [businessId, pagination.skip, pagination.take, params.dateFilterLabel])

  useMount(() => {
    callApis()
  })

  const getTransactions = useCallback(async () => {
    try {
      const constructedPayload: MasterWalletTransactionsPayload = {
        businessId,
        skip: pagination.skip,
        take: pagination.take,
      }
      if (params.transaction_type !== "all") {
        constructedPayload.transaction_type =
          params.transaction_type === "B2B"
            ? "VIRTUAL_ACCOUNT_CREDIT"
            : params?.transaction_type
      }
      if (params.from_datetime) {
        constructedPayload.from_datetime = params.from_datetime.toISOString()
      }
      if (params.to_datetime) {
        constructedPayload.to_datetime = params.to_datetime.toISOString()
      }
      if (params.dateFilterLabel !== "all") {
        trackEvent(TrackingEvents.PAYMENT_TRANSACTIONS_FILTER_APPLIED, {
          from: "masterWalletTransactions",
          dateFilter: params.dateFilterLabel,
        })
      }
      const response = await getMasterWalletTransactions<{
        count: number
        data: PaymentsTransaction[]
      }>(constructedPayload)
      if (!response) {
        throw new Error("Something went wrong while fetching transactions!")
      }
      if (currentPage === 1) {
        return dispatch({
          type: "FETCHED_TRANSACTIONS",
          payload: {
            transactions: response.data,
            count: response.count,
          },
        })
      }
      return dispatch({
        type: "FETCHED_MORE_TRANSACTIONS",
        payload: response.data,
      })
    } catch (e) {
      const err = e as Error
      toast.error(
        err.message || "Something went wrong while fetching transactions!"
      )
    }
  }, [
    businessId,
    currentPage,
    pagination.skip,
    pagination.take,
    params.from_datetime,
    params.to_datetime,
    params.transaction_type,
    params.dateFilterLabel,
  ])

  useEffect(() => {
    if (
      state.transactionDetails?.fetching ||
      state.transactionDetails?.fetchingMore
    ) {
      getTransactions()
    }
  }, [
    getTransactions,
    state.transactionDetails?.fetching,
    state.transactionDetails?.fetchingMore,
  ])

  function handleChange(key: string, value: unknown) {
    resetPagination()
    setValues({ ...params, [key]: value })
    dispatch({ type: "FETCHING_TRANSACTIONS" })
  }

  function handleDateChange(value1?: Date, value2?: Date, label?: string) {
    resetPagination()
    setValues({
      ...params,
      from_datetime: value1,
      to_datetime: value2,
      dateFilterLabel: label || "all",
    })
    dispatch({ type: "FETCHING_TRANSACTIONS" })
  }

  function resetFilters() {
    setValues({ transaction_type: "all" })
    resetPagination()
    dispatch({ type: "FETCHING_TRANSACTIONS" })
  }

  function reset() {
    setValues({ transaction_type: "all" })
    resetPagination()
    callApis()
  }

  function fetchMore() {
    next()
    dispatch({ type: "FETCHING_MORE_TRANSACTIONS" })
  }

  const areFiltersApplied: boolean = useMemo(() => {
    return Boolean(
      params.from_datetime ||
        params.to_datetime ||
        params.transaction_type !== "all"
    )
  }, [params])

  return {
    areFiltersApplied,
    error: state.error,
    status: state.status,
    masterWallet: state.wallet,
    fetching: state.transactionDetails?.fetching,
    transactions: state.transactionDetails?.transactions,
    transactionsCount: state.transactionDetails?.transactionCount,

    //pagination
    lastPage,
    currentPage,
    fetchingMore: state.transactionDetails?.fetchingMore,
    fetchMore,

    //Filters
    params,
    resetFilters,
    handleChange,
    handleDateChange,
    refreshPage: reset,
  }
}
