import {
  EmailValidator,
  PhoneNumberValidator,
  arePhoneNumbersSame,
  getWhatsAppSharingLink,
  isPhoneNumberIndian,
  isVisitorIndian,
} from "@cashbook/util-general"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Alert,
  CopyToClipboard,
  Button,
  CheckIcon,
  FormField,
  formikOnSubmitWithErrorHandling,
  InformationCircleIcon,
  Modal,
  ModalFooter,
  LinkIcon,
  WhatsAppIcon,
  SpinnerIcon,
  useOverlayTriggerState,
  UserAddIcon,
  PhoneInput,
  isPossiblePhoneNumber,
  parsePhoneNumber,
  Box,
  Text,
  Heading,
  Stack,
  Inline,
  DuplicateIcon,
  getButtonClassName,
  CheckCircleSolidIcon,
  CancelFilledIcon,
  ModalBody,
  CancelIcon,
  SearchIcon,
  PlusIcon,
  Circle,
  ArrowRightIcon,
  CopyIcon,
  SecurityCheckIcon,
} from "@cashbook/web-components"
import { FieldProps, Form, Formik, useField } from "formik"
import React, { useMemo, useReducer, useState } from "react"
import toast from "react-hot-toast"
import { SuspenseWithPerf, useUser } from "reactfire"
import * as Validator from "yup"
import config from "../config"
import {
  useShareBookInvitations,
  getAllRolesWithPermissions,
  getRoleDetails,
  T_AVAILABLE_ROLES,
  useBook,
  TBook,
  BOOK_PERMISSIONS,
} from "@cashbook/data-store/books"
import { useCheckUsersExists, useProfile } from "@cashbook/data-store/users"
import {
  BUSINESS_PERMISSIONS,
  getBusinessRoleDetails,
  OperationalBookRoles,
  TBusinessInvitation,
  TBusinessMember,
  useAddTeamMember,
  useBusiness,
  useShareBusinessInvitations,
} from "@cashbook/data-store/businesses"
import { MemberAvatar } from "."
import { BusinessRoleDetails } from "../Business"
import { WhatsAppContactLink } from "../HelpDocs"

type AddMemberProps = {
  book: TBook
  onSuccess: ({
    name,
    bookName,
    role,
    isInvited,
  }: {
    name: string
    bookName: string
    role: T_AVAILABLE_ROLES
    isInvited: boolean
  }) => void
  onCancel?: () => void
}

type StepToAddMember =
  | "ask_number"
  | "ask_email"
  | "show_member_status"
  | "show_invitation_details"

type Status =
  | "checking_user_status"
  | "check_user_status_failed"
  | "checked_user_status"

type TExtendedState = {
  email?: string
  phoneNumber?: string
  step: StepToAddMember
  guestName: string
  status?: Status
  userStatusDetails?: {
    email?: string
    phoneNumber?: string
    isAppUser: boolean
  }
  error?: string | Error | null
  invitationDetails?: TBusinessInvitation
}

type CheckUserStatusProps =
  | {
      type: "email"
      email: string
      phoneNumber?: string
      setError: (key: string, value: string) => void
    }
  | {
      type: "phone"
      email?: string
      phoneNumber: string
      setError: (key: string, value: string) => void
    }

const initialExtendedStateForAddMember: TExtendedState = {
  step: "ask_email",
  guestName: "",
  phoneNumber: "",
  email: "",
}

type TYPE_AND_PAYLOAD =
  | { type: "ASK_PHONE_NUMBER" }
  | { type: "EDIT_USER_DETAILS"; payload: "ask_number" | "ask_email" }
  | {
      type: "CHECK_USER_STATUS"
      payload: {
        name: string
        email?: string
        phoneNumber?: string
        isAppUser: boolean
      }
    }
  | { type: "CHECK_USER_STATUS_FAILED"; payload: Error }
  | { type: "CHECKING_USER_STATUS" }
  | {
      type: "CHECKED_USER_STATUS"
      payload: { email?: string; phoneNumber?: string; isAppUser: boolean }
    }
  | {
      type: "SHOW_INVITATION_DETAILS"
      payload: TBusinessInvitation
    }

const reducer = (
  state: TExtendedState,
  action: TYPE_AND_PAYLOAD
): TExtendedState => {
  switch (action.type) {
    case "ASK_PHONE_NUMBER":
      return {
        ...initialExtendedStateForAddMember,
        step: "ask_number",
        userStatusDetails: undefined,
        error: null,
      }
    case "EDIT_USER_DETAILS":
      return {
        ...state,
        step: action.payload,
        userStatusDetails: undefined,
        error: null,
      }
    case "CHECK_USER_STATUS":
      return {
        ...state,
        step: "show_member_status",
        guestName: action.payload.name,
        ...action.payload,
        userStatusDetails: {
          ...action.payload,
          isAppUser: action.payload.isAppUser,
        },
      }
    case "CHECKING_USER_STATUS":
      return {
        ...state,
        status: "checking_user_status",
        error: null,
      }
    case "CHECK_USER_STATUS_FAILED":
      return {
        ...state,
        status: "check_user_status_failed",
        error: action.payload,
      }
    case "CHECKED_USER_STATUS":
      return {
        ...state,
        status: "checked_user_status",
        userStatusDetails: action.payload,
      }
    case "SHOW_INVITATION_DETAILS":
      return {
        ...state,
        step: "show_invitation_details",
        invitationDetails: action.payload,
      }
  }
}

export function AddMember({
  book,
  businessId,
  onCancel,
  onSuccess,
}: AddMemberProps & { businessId: string }) {
  const {
    members,
    hideEntriesByOthers,
    hideBalancesAndReportsForEditor,
    checkIfAuthenticatedMemberCan,
  } = useBook(book.id)
  const { invitations } = useShareBookInvitations(book.id)
  const { invitations: businessInvitations } =
    useShareBusinessInvitations(businessId)
  const { data: user } = useUser()
  const canAddAdmin = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.ASSIGN_ADMIN_ROLE
  )
  const { phoneNumber: myPhoneNumber } = user || { phoneNumber: "" }
  const defaultCountry = useMemo(
    () =>
      (myPhoneNumber ? parsePhoneNumber(myPhoneNumber)?.country : "IN") || "IN",
    [myPhoneNumber]
  )
  const [
    {
      step,
      email,
      guestName,
      phoneNumber,
      userStatusDetails,
      invitationDetails,
    },
    dispatch,
  ] = useReducer(reducer, initialExtendedStateForAddMember)

  const { checkIfPhonesExists, checkIfEmailsExists } = useCheckUsersExists()
  const addMemberToBook = useAddTeamMember()

  async function checkUserStatus({
    type,
    phoneNumber,
    email,
    setError,
  }: CheckUserStatusProps) {
    if (type === "phone") {
      try {
        const { data: usersInfo } = await checkIfPhonesExists([phoneNumber])
        if (
          parsePhoneNumber(usersInfo[0].phone)?.country !== "IN" &&
          !usersInfo[0].isAppUser
        ) {
          setError(
            "phoneNumber",
            `Mobile number is not registered on ${config.appTitle}. Try adding via email.`
          )
        } else {
          dispatch({
            type: "CHECK_USER_STATUS",
            payload: {
              name: `${
                usersInfo[0]?.name ||
                parsePhoneNumber(usersInfo[0].phone)?.nationalNumber ||
                "CB"
              }`,
              phoneNumber: usersInfo[0].phone,
              isAppUser: usersInfo[0].isAppUser,
            },
          })
        }
      } catch (error) {
        dispatch({ type: "CHECK_USER_STATUS_FAILED", payload: error as Error })
      }
    } else if (type === "email") {
      try {
        const { data: usersInfo } = await checkIfEmailsExists([email])
        dispatch({
          type: "CHECK_USER_STATUS",
          payload: {
            name: `${usersInfo[0].name || email || "CB"}`,
            email: usersInfo[0].email,
            isAppUser: usersInfo[0].isAppUser,
          },
        })
      } catch (error) {
        dispatch({ type: "CHECK_USER_STATUS_FAILED", payload: error as Error })
      }
    }
  }

  const invitingUser: { name: string; email?: string; phone?: string } = {
    name:
      guestName ||
      userStatusDetails?.email ||
      userStatusDetails?.phoneNumber ||
      "CB",
    email: userStatusDetails?.email,
    phone: userStatusDetails?.phoneNumber,
  }

  const isUserIndian = myPhoneNumber
    ? isPhoneNumberIndian(myPhoneNumber)
    : isVisitorIndian()

  return (
    <Box>
      {step === "ask_number" || step === "ask_email" ? (
        <Formik
          initialValues={{
            name: guestName,
            phoneNumber,
            askFor: isUserIndian
              ? "phone"
              : step === "ask_email"
              ? "email"
              : ("phone" as "phone" | "email"),
            email,
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Validator.object().shape({
            email: EmailValidator.nullable().when(["askFor"], (askFor) => {
              if (askFor === "email") {
                return Validator.string()
                  .email("Please enter a valid email address")
                  .required("Email address is required!")
                  .test({
                    name: "valid-email-number",
                    test: (value: string | undefined | null) => {
                      if (!value) {
                        // required attribute should handle the empty case
                        return true
                      }
                      value = value?.toLowerCase()
                      return (
                        !members.some((m) => {
                          return m.email === value
                        }) &&
                        !invitations.some((m) => {
                          return m.guestEmail === value
                        }) &&
                        !businessInvitations.some((m) => {
                          return m.guestEmail === value
                        })
                      )
                    },
                    message: function test(params) {
                      const value = params.value?.toLowerCase()
                      const existingMember = members.find(
                        (m) => m.email === value
                      )
                      const pendingInvitation =
                        invitations.find((m) => m.guestPhone === value) ||
                        businessInvitations.find((m) => {
                          return m.guestEmail === value
                        })
                      if (existingMember?.uid === user?.uid) {
                        return `You are not allowed to add your email to the business`
                      }
                      if (existingMember) {
                        return `Member with this email address (${existingMember.email}) already added to this business.`
                      }
                      if (pendingInvitation) {
                        return `Invitation to ${pendingInvitation.guestName} is already pending. Please share the link
                          (${pendingInvitation.inviteLink}) and ask to join`
                      }
                      return `Please enter a valid email address`
                    },
                  })
              }
              return Validator.string().nullable()
            }),
            phoneNumber: PhoneNumberValidator.nullable().when(
              ["askFor"],
              (askFor) => {
                if (askFor === "phone") {
                  return Validator.string()
                    .required("Phone number is required")
                    .test({
                      name: "valid-phone-number",
                      test: (value: string | undefined | null) => {
                        if (!value) {
                          // required attribute should handle the empty case
                          return true
                        }
                        return (
                          isPossiblePhoneNumber(value) &&
                          !members.some((m) =>
                            arePhoneNumbersSame(m.phoneNumber, value)
                          ) &&
                          !invitations.some((m) =>
                            arePhoneNumbersSame(m.guestPhone, value)
                          ) &&
                          !businessInvitations.some((m) =>
                            arePhoneNumbersSame(m.guestPhone, value)
                          )
                        )
                      },
                      message: function test(params) {
                        const existingMember = members.find((m) =>
                          arePhoneNumbersSame(m.phoneNumber, params.value)
                        )
                        const pendingInvitation =
                          invitations.find((m) =>
                            arePhoneNumbersSame(m.guestPhone, params.value)
                          ) ||
                          businessInvitations.find((m) =>
                            arePhoneNumbersSame(m.guestPhone, params.value)
                          )
                        if (existingMember?.uid === user?.uid) {
                          return `You are not allowed to add your number to the business`
                        }
                        if (existingMember) {
                          return `Member with this phone (${existingMember?.phoneNumber}) already added to this business.`
                        }
                        if (pendingInvitation) {
                          return `Invitation to ${pendingInvitation.guestName} is already pending. Please share the link
                      (${pendingInvitation.inviteLink}) and ask to join`
                        }
                        return `Please enter a valid mobile number`
                      },
                    })
                }
                return Validator.string().nullable()
              }
            ),
          })}
          onSubmit={async (values, actions) => {
            await checkUserStatus(
              values.askFor === "phone"
                ? {
                    type: "phone",
                    setError: actions.setFieldError,
                    phoneNumber: values?.phoneNumber || "",
                  }
                : {
                    type: "email",
                    email: values?.email || "",
                    setError: actions.setFieldError,
                  }
            )
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form noValidate>
              <ModalBody>
                {values.askFor === "email" ? (
                  <Stack gap="3">
                    <FormField
                      type="text"
                      name="email"
                      label={`Add Email`}
                      placeholder="eg. xyz123@gmail.com"
                      noMargin
                      onChange={(e) =>
                        setFieldValue("email", e.target.value.toLowerCase())
                      }
                    />
                  </Stack>
                ) : (
                  <Stack gap="3">
                    <Box as="label" htmlFor="phoneNumber">
                      Mobile number
                    </Box>
                    <FormField
                      name="phoneNumber"
                      renderInput={({
                        field: { onChange, ...otherFieldProps },
                        form,
                      }: FieldProps<string>) => (
                        <Box className="max-w-xs">
                          <PhoneInput
                            {...otherFieldProps}
                            id="phoneNumber"
                            onChange={(phoneNumber) =>
                              form.setFieldValue(
                                otherFieldProps.name,
                                phoneNumber
                              )
                            }
                            defaultCountry={defaultCountry}
                            required
                            autoFocus
                          />
                        </Box>
                      )}
                    />
                    {!isUserIndian ? (
                      <Inline gap="2" alignItems="center">
                        <Box
                          width="full"
                          className="h-[1px]"
                          backgroundColor="borderOutline"
                        />
                        <Text fontSize="b3">Or</Text>
                        <Box
                          width="full"
                          className="h-[1px]"
                          backgroundColor="borderOutline"
                        />
                      </Inline>
                    ) : null}
                    {!isUserIndian ? (
                      <Stack
                        paddingTop="5"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          size="lg"
                          onClick={() => setFieldValue("askFor", "email")}
                        >
                          Add Email
                        </Button>
                      </Stack>
                    ) : null}
                  </Stack>
                )}
              </ModalBody>
              <ModalFooter>
                <Button type="submit" disabled={isSubmitting} size="lg">
                  {isSubmitting ? "Verifying..." : "Next"}
                </Button>
                {isUserIndian ? (
                  <Button size="lg" onClick={onCancel}>
                    Cancel
                  </Button>
                ) : values.askFor === "email" ? (
                  <Button
                    onClick={() => setFieldValue("askFor", "phone")}
                    size="lg"
                    disabled={isSubmitting}
                  >
                    Add With Mobile Number
                  </Button>
                ) : null}
              </ModalFooter>
            </Form>
          )}
        </Formik>
      ) : step === "show_member_status" ? (
        <Box>
          <Box>
            {userStatusDetails?.email || userStatusDetails?.phoneNumber ? (
              <Formik
                initialValues={{
                  guestEmail: invitingUser?.email,
                  guestPhone: invitingUser?.phone,
                  guestName: invitingUser.name,
                  cashBookId: book.id,
                  role: "editor" as OperationalBookRoles,
                }}
                onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
                  const { data } = await addMemberToBook({
                    businessId: businessId,
                    role: "staff",
                    guestEmail: values.guestEmail,
                    guestPhone: values.guestPhone,
                    guestName: values.guestName,
                    bookId: values.cashBookId,
                    bookRole: values.role,
                  })
                  if (data.message) {
                    // already our app user
                    trackEvent(TrackingEvents.MEMBER_ADDED, {
                      role: values.role,
                      via: values.guestEmail?.length ? "email" : "phone",
                      type: "addManually",
                    })
                    onSuccess({
                      name: guestName || email || phoneNumber || "CB",
                      bookName: book.name,
                      role: values.role,
                      isInvited: false,
                    })
                    toast.success(
                      `${guestName || phoneNumber} added to ${book.name}`
                    )
                    return
                  }
                  if (data.inviteLink) {
                    trackEvent(TrackingEvents.MEMBER_INVITED, {
                      role: values.role,
                      type: "addManually",
                      via: values.guestEmail?.length ? "email" : "phone",
                    })
                    dispatch({
                      type: "SHOW_INVITATION_DETAILS",
                      payload: {
                        ...data,
                        role: data.role,
                        sharedBooks: data.sharedBooks
                          ? [
                              ...data.sharedBooks,
                              { id: book.id, role: values.role },
                            ]
                          : [{ id: book.id, role: values.role }],
                      },
                    })
                  }
                })}
              >
                {({ isSubmitting, status }) => (
                  <>
                    {status ? <Alert status="error">{status}</Alert> : null}
                    {userStatusDetails.isAppUser ? (
                      <Form noValidate>
                        <ModalBody className="relative">
                          <Box
                            position="absolute"
                            top="0"
                            width="full"
                            left="0"
                          >
                            <Alert
                              status="info"
                              marginBottom="0"
                              rounded="none"
                            >
                              <Text>
                                {invitingUser.name} will be added to this
                                business too
                              </Text>
                            </Alert>
                          </Box>
                          <Stack as="section" gap="4" paddingY="12">
                            <Text>
                              {invitingUser.name} is already using{" "}
                              {config.appTitle} app. Choose their role in this
                              book to add them
                            </Text>
                            <Box
                              rounded="md"
                              borderWidth="1"
                              paddingX="4"
                              paddingY="6"
                              borderColor="borderOutline"
                            >
                              <Inline
                                gap="6"
                                alignItems="center"
                                justifyContent="between"
                              >
                                <Inline
                                  gap={{ xs: "3", md: "6" }}
                                  alignItems="center"
                                >
                                  <MemberAvatar
                                    id={invitingUser.name}
                                    name={invitingUser.name}
                                  />
                                  <Stack gap="2">
                                    <Heading
                                      fontSize="md"
                                      className="break-all line-clamp-1"
                                    >
                                      {invitingUser.name}
                                    </Heading>
                                    <Text
                                      color="gray500"
                                      fontSize="sm"
                                      className="break-all line-clamp-2"
                                    >
                                      {invitingUser?.email ||
                                        invitingUser?.phone}
                                    </Text>
                                  </Stack>
                                </Inline>
                                <Box
                                  bgColor="blue100"
                                  rounded="md"
                                  paddingX="3"
                                  paddingY="1"
                                >
                                  <Text
                                    color="blue900"
                                    fontSize="sm"
                                    fontWeight="medium"
                                    whiteSpace="preserve"
                                  >
                                    {config.appTitle} User
                                  </Text>
                                </Box>
                              </Inline>
                            </Box>
                            <Box width="full">
                              <Stack gap="8">
                                <Stack gap="4">
                                  <SelectFromBookLevelRole
                                    name="role"
                                    canAddAdmin={canAddAdmin}
                                    hideEntriesByOthers={hideEntriesByOthers}
                                    hideNetBalance={
                                      hideBalancesAndReportsForEditor
                                    }
                                  />
                                  <Text
                                    color="gray500"
                                    fontSize="sm"
                                    fontWeight="medium"
                                  >
                                    <InformationCircleIcon size="5" /> You can
                                    change this role later
                                  </Text>
                                </Stack>
                              </Stack>
                            </Box>
                          </Stack>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            type="submit"
                            size="lg"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <>
                                <SpinnerIcon /> Adding member...
                              </>
                            ) : (
                              <>
                                <CheckIcon /> Add Member
                              </>
                            )}
                          </Button>
                          {!isSubmitting ? (
                            <Button
                              size="lg"
                              onClick={() =>
                                dispatch({
                                  type: "EDIT_USER_DETAILS",
                                  payload: userStatusDetails?.email?.length
                                    ? "ask_email"
                                    : "ask_number",
                                })
                              }
                            >
                              {userStatusDetails?.email
                                ? "Change Email"
                                : "Change Mobile Number"}
                            </Button>
                          ) : null}
                        </ModalFooter>
                      </Form>
                    ) : (
                      <Form noValidate>
                        <ModalBody>
                          <Stack
                            gap="6"
                            className="relative"
                            paddingBottom="12"
                          >
                            <Text>
                              {invitingUser.name} is a new user. Send invite to{" "}
                              {invitingUser?.email || invitingUser?.phone} to
                              join this book.
                            </Text>
                            <Box
                              rounded="md"
                              borderWidth="1"
                              paddingX="4"
                              paddingY="6"
                              borderColor="borderOutline"
                            >
                              <Inline
                                gap="6"
                                alignItems="center"
                                justifyContent="between"
                              >
                                <Inline
                                  gap={{ xs: "3", md: "6" }}
                                  alignItems="center"
                                >
                                  <MemberAvatar
                                    id={invitingUser.name}
                                    name={invitingUser.name}
                                  />
                                  <Stack gap="2">
                                    <Heading
                                      fontSize="md"
                                      className="break-all line-clamp-1"
                                    >
                                      {invitingUser.name}
                                    </Heading>
                                    <Text
                                      color="textMedium"
                                      fontSize="c2"
                                      className="break-all line-clamp-2"
                                    >
                                      {invitingUser?.email ||
                                        invitingUser?.phone}
                                    </Text>
                                  </Stack>
                                </Inline>
                                <Box
                                  bgColor="surfaceNeutralLowest"
                                  rounded="md"
                                  paddingX={{ xs: "1", md: "3" }}
                                  paddingY="1"
                                  whiteSpace="preserve"
                                >
                                  <Text color="textMedium" fontSize="c2">
                                    Not a {config.appTitle} User
                                  </Text>
                                </Box>
                              </Inline>
                            </Box>
                            <Box>
                              <SelectFromBookLevelRole
                                name="role"
                                canAddAdmin={canAddAdmin}
                                hideEntriesByOthers={hideEntriesByOthers}
                                hideNetBalance={hideBalancesAndReportsForEditor}
                              />
                            </Box>
                            <Text
                              color="gray500"
                              fontSize="sm"
                              fontWeight="medium"
                            >
                              <InformationCircleIcon size="5" /> You can change
                              this role later
                            </Text>
                          </Stack>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            type="submit"
                            disabled={isSubmitting}
                            size="lg"
                          >
                            {isSubmitting ? (
                              <>
                                <SpinnerIcon /> Creating Invitation...
                              </>
                            ) : (
                              <>
                                <UserAddIcon /> Invite to {config.appTitle}
                              </>
                            )}
                          </Button>
                          {!isSubmitting ? (
                            <Button
                              size="lg"
                              onClick={() =>
                                dispatch({
                                  type: "EDIT_USER_DETAILS",
                                  payload: userStatusDetails?.email?.length
                                    ? "ask_email"
                                    : "ask_number",
                                })
                              }
                            >
                              {userStatusDetails?.email
                                ? "Change Email"
                                : "Change Mobile Number"}
                            </Button>
                          ) : null}
                        </ModalFooter>
                      </Form>
                    )}
                  </>
                )}
              </Formik>
            ) : (
              <Stack gap="4" padding="6">
                <Text>
                  Something went wrong. Please try after some time or contact
                  our support.
                </Text>
                <Box>
                  <WhatsAppContactLink
                    text={`Hi, I need help adding member to this book (${book.name}).`}
                  />
                </Box>
              </Stack>
            )}
          </Box>
        </Box>
      ) : step === "show_invitation_details" ? (
        <Box>
          {invitationDetails ? (
            <>
              <ModalBody>
                <PendingInvitationDetails
                  book={book}
                  invitation={invitationDetails}
                />
              </ModalBody>
              <ModalFooter>
                <ShareInvitationActions
                  bookName={book.name}
                  invitation={invitationDetails}
                  onSuccess={() => {
                    const sharedBook = invitationDetails.sharedBooks?.find(
                      (sharedBook: { id: string; role: T_AVAILABLE_ROLES }) =>
                        sharedBook.id === book.id
                    )
                    onSuccess({
                      name: invitingUser.name,
                      bookName: book.name,
                      role: sharedBook?.role || "editor",
                      isInvited: true,
                    })
                  }}
                />
              </ModalFooter>
            </>
          ) : (
            <Text>Something went wrong. Missing Invitation details</Text>
          )}
        </Box>
      ) : null}
    </Box>
  )
}

export function SelectRole({
  name,
  canAddAdmin,
  hideNetBalance,
  hideEntriesByOthers,
}: {
  name: string
  canAddAdmin?: boolean
  hideNetBalance?: boolean
  hideEntriesByOthers?: boolean
}) {
  const [{ value }, meta] = useField<T_AVAILABLE_ROLES>(name)
  return (
    <Stack borderWidth="1" rounded="md">
      <Heading></Heading>
      <Stack gap="8" padding="6">
        <Inline gap="2" flexWrap="wrap">
          {getAllRolesWithPermissions().map(({ id, role, title }) => {
            return id === "owner" ||
              id === "partner" ||
              (!canAddAdmin && id === "admin") ? null : (
              <Box
                as="label"
                key={role}
                display="inlineBlock"
                paddingX={{ xs: "2", sm: "4" }}
                paddingY="2"
                rounded="full"
                cursor="pointer"
                borderWidth="1"
                borderColor={value !== role ? "gray100" : "blue900"}
                bgColor={value !== role ? "gray100" : "blue100"}
                color={value === role ? "blue900" : undefined}
              >
                <FormField
                  label={
                    <Text as="span" fontWeight="medium" fontSize="base">
                      {title}
                    </Text>
                  }
                  type="radio"
                  name={name}
                  value={role}
                  id={`${name}.${role}`}
                  noMargin
                  invisibleInput
                  hideError
                />
              </Box>
            )
          })}
        </Inline>
        {meta.error && meta.touched ? (
          <Box
            paddingX="2"
            paddingY="1"
            bgColor="red100"
            borderWidth="1"
            borderColor="red100"
            color="red900"
            fontSize="sm"
          >
            {meta.error}
          </Box>
        ) : null}
        <Stack key={value} gap="4">
          <Heading as="h5" color="gray500">
            Permissions
          </Heading>
          <Stack as="ul" gap="4">
            {getRoleDetails(value).permissionsDescription.map((permission) => {
              if (value === "editor") {
                if (
                  (hideEntriesByOthers &&
                    permission
                      .toLowerCase()
                      .includes("View entries by everyone".toLowerCase())) ||
                  (hideNetBalance &&
                    permission
                      .toLowerCase()
                      .includes(
                        "View net balance & download PDF or Excel".toLowerCase()
                      ))
                )
                  return null
              }
              return (
                <Inline as="li" key={permission} alignItems="center" gap="3">
                  <CheckCircleSolidIcon color="green500" />
                  <Text fontWeight="medium">{permission}</Text>
                </Inline>
              )
            })}
          </Stack>
          {getRoleDetails(value).restrictionsDescription.length ? (
            <Stack gap={"4"} paddingTop="4">
              <Heading as="h5" color="gray500">
                Restrictions
              </Heading>
              <Stack as="ul" gap="4">
                {getRoleDetails(value).restrictionsDescription.map(
                  (restriction) => {
                    return (
                      <Inline
                        as="li"
                        key={restriction}
                        alignItems="center"
                        gap="3"
                      >
                        <CancelFilledIcon color="red500" />
                        <Text fontWeight="medium">{restriction}</Text>
                      </Inline>
                    )
                  }
                )}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  )
}

export function AddMemberInDialog({
  children,
  onSuccess,
  ...props
}: Omit<AddMemberProps, "onCancel"> & {
  businessId: string
  children: (props: { add: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({
        add: () => {
          trackEvent(TrackingEvents.ADD_MEMBERS_CLICKED, {
            countExistingMembers: props.book.sharedWith?.length || 1,
          })
          state.open()
        },
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title="Add New Member"
        autoFocus={false}
      >
        <SuspenseWithPerf
          fallback={
            <Box paddingY="4" textAlign="center">
              <SpinnerIcon />
            </Box>
          }
          traceId="loading_member_account_details"
        >
          <AddMember
            {...props}
            onSuccess={(...args) => {
              state.close()
              onSuccess(...args)
            }}
            onCancel={state.close}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

export function AddMemberInModal({
  children,
  onSuccess,
  ...props
}: Omit<AddMemberProps, "onCancel"> & {
  businessId: string
  children: (props: { add: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  return (
    <>
      {children({
        add: () => {
          trackEvent(TrackingEvents.ADD_MEMBERS_CLICKED, {
            countExistingMembers: props.book.sharedWith?.length || 1,
          })
          state.open()
        },
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title="Add New Member"
        autoFocus={false}
        placement="right"
        isDismissable
      >
        <SuspenseWithPerf
          fallback={
            <Box paddingY="4" textAlign="center">
              <SpinnerIcon />
            </Box>
          }
          traceId="loading_member_account_details"
        >
          <AddMember
            {...props}
            onSuccess={(values) => {
              state.close()
              onSuccess(values)
            }}
            onCancel={state.close}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

export function PendingInvitationDetails({
  book,
  invitation,
}: {
  book: TBook
  invitation: TBusinessInvitation
}) {
  const sharedBook = invitation.sharedBooks?.find(
    (sharedBook) => sharedBook.id === book.id
  )
  const invitationRole = invitation.role as T_AVAILABLE_ROLES
  const { title } = getRoleDetails(
    sharedBook?.role || invitationRole || "editor"
  )
  const name =
    invitation?.guestName ||
    invitation?.guestEmail ||
    parsePhoneNumber(invitation.guestPhone)?.nationalNumber
  return (
    <Stack gap="8">
      <Box
        rounded="md"
        borderWidth="1"
        borderColor="borderOutline"
        paddingX="4"
        paddingY="6"
      >
        <Inline alignItems="center" justifyContent="between">
          <Inline gap={{ xs: "3", md: "6" }} alignItems="center">
            <MemberAvatar
              size="12"
              fontSize="s1"
              id={invitation.inviteId}
              name={name}
            />
            <Stack gap="2">
              <Heading fontSize="s3">{name}</Heading>
              <Text color="textMedium" fontSize="c2">
                {invitation?.guestEmail || invitation.guestPhone}
              </Text>
            </Stack>
          </Inline>
          <Box
            bgColor="surfaceNeutralLowest"
            rounded="md"
            paddingX={{ xs: "1", md: "3" }}
            paddingY="1"
          >
            <Text color="textMedium" fontSize="c2">
              Not a {config.appTitle} User
            </Text>
          </Box>
        </Inline>
      </Box>
      <Inline
        borderWidth="1"
        rounded="md"
        alignItems="center"
        gap="2"
        paddingX="6"
        paddingY="4"
        borderColor="borderOutline"
      >
        <Text color="textMedium" fontSize="b3">
          Book role:{" "}
        </Text>
        <Text textTransform="capitalize" fontSize="s4">
          {title}
        </Text>
      </Inline>
      <Stack gap="4">
        <Text fontSize="b3">
          Unique Invite Link for{" "}
          <span className="font-semibold">
            {invitation.bookName || book.name}
          </span>
        </Text>

        <Inline
          paddingY="2"
          paddingX={{ xs: "3", md: "4" }}
          rounded="md"
          bgColor="blue100"
          alignItems="center"
          justifyContent="between"
          gap={{ xs: "2", md: "4" }}
        >
          <Circle backgroundColor="blue900" color="white" size="8">
            <LinkIcon />
          </Circle>

          <Text className="text-ellipsis overflow-hidden" fontSize="b3">
            {invitation.inviteLink}
          </Text>
          <Box flex="1" textAlign="right">
            <CopyToClipboard>
              {({ copy, copied, copying }) => (
                <Button
                  inline
                  disabled={copied || copying}
                  onClick={() => {
                    copy(invitation.inviteLink)
                  }}
                >
                  {copied ? (
                    <CheckIcon color="green500" />
                  ) : (
                    <CopyIcon color="blue900" />
                  )}
                </Button>
              )}
            </CopyToClipboard>
          </Box>
        </Inline>
        <Alert status="success">
          <Inline gap="3">
            <Box>
              <SecurityCheckIcon color="green500" size="6" />
            </Box>
            <Text color="textHigh" fontSize="b3">
              This link is 100% secure. Only{" "}
              {invitation.guestEmail || invitation.guestPhone} can access this
              business by logging in with their{" "}
              {invitation.guestEmail ? "email address" : "phone number"}.
            </Text>
          </Inline>
        </Alert>
      </Stack>
    </Stack>
  )
}

export function ShareInvitationActions({
  bookName,
  invitation,
  isReinvited,
  onSuccess,
}: {
  bookName?: string
  isReinvited?: boolean
  invitation: TBusinessInvitation
  onSuccess?: () => void
}) {
  return (
    <>
      <CopyToClipboard>
        {({ copy, copied, copying }) => (
          <Button
            level="primary"
            size="lg"
            status={copied ? "success" : undefined}
            disabled={copied || copying}
            onClick={() => {
              copy(invitation.inviteLink)
              onSuccess?.()
              trackEvent(
                isReinvited
                  ? TrackingEvents.INVITATION_RESENT
                  : TrackingEvents.INVITATION_SHARED,
                {
                  sharedVia: "other",
                }
              )
            }}
          >
            {copied ? (
              <>
                <CheckIcon /> Copied
              </>
            ) : (
              <>
                <DuplicateIcon /> Copy Link
              </>
            )}
          </Button>
        )}
      </CopyToClipboard>
      <a
        target="_blank"
        rel="noopener noreferrer"
        title="Send Message"
        href={getWhatsAppSharingLink({
          text: bookName?.length
            ? `Let's record transactions on *${config.appTitle}*! It's fast, simple, and secure for recording cash entries .
Created a '*${bookName}*' book for us, I am adding transactions here. Use the link to see my entries`
            : `Shared '${invitation.businessName}' business with you to add entries. Click on this link to join.`,
          phoneNumber: invitation.guestPhone,
          url: invitation.inviteLink,
        })}
        className={getButtonClassName({
          level: "secondary",
          status: "success",
          size: "lg",
        })}
        onClick={() => {
          trackEvent(
            isReinvited
              ? TrackingEvents.INVITATION_RESENT
              : TrackingEvents.INVITATION_SHARED,
            {
              sharedVia: "WhatsApp",
            }
          )
        }}
      >
        <WhatsAppIcon size="6" /> Share on WhatsApp
      </a>
    </>
  )
}

export function SuccessDialog({
  title,
  successTitle,
  footerButtonOne,
  successSubTitle,
  footerButtonTwo,
  onClose,
}: {
  title: string
  successTitle: string
  successSubTitle?: string
  footerButtonOne: React.ReactNode
  footerButtonTwo?: React.ReactNode
  onClose?: () => void
}) {
  const state = useOverlayTriggerState({ isOpen: true })
  return (
    <Modal
      isOpen={state.isOpen}
      onClose={() => {
        state.close()
        onClose?.()
      }}
      size="sm"
      isDismissable
      title={title}
    >
      <ModalBody>
        <Stack gap="6" justifyContent="center" alignItems="center">
          <Box>
            <CheckCircleSolidIcon color="green500" size="18" />
          </Box>
          <Stack textAlign="center" gap="3">
            <Text fontSize="lg" fontWeight="semibold">
              {successTitle}
            </Text>
            {successSubTitle && <Text color="gray500">{successSubTitle}</Text>}
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        {footerButtonOne}
        {footerButtonTwo ? footerButtonTwo : null}
      </ModalFooter>
    </Modal>
  )
}

type AddMemberInBookSteps =
  | "chooseStaff"
  | "selectRoleForStaff"
  | "addingExternalMember"
export function AddMemberToBookInDialog({
  businessName,
  children,
  ...props
}: React.ComponentProps<typeof AddMemberFromBusinessStaff> & {
  businessName: string
  children?: (props: { add: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const [step, setStep] = useState<AddMemberInBookSteps>("chooseStaff")
  function closeModal() {
    state.close()
    setStep("chooseStaff")
  }
  return (
    <>
      {children
        ? children({
            add: () => {
              state.open()
            },
          })
        : null}
      <Modal
        isOpen={state.isOpen}
        onClose={closeModal}
        title={`Add from ${businessName}`}
        autoFocus={false}
        placement="right"
        isDismissable
      >
        <SuspenseWithPerf
          fallback={
            <Box paddingY="4" textAlign="center">
              <SpinnerIcon />
            </Box>
          }
          traceId="loading_member_account_details"
        >
          <AddMemberFromBusinessStaff
            {...props}
            step={step}
            setStep={(type) => setStep(type)}
          />
        </SuspenseWithPerf>
      </Modal>
    </>
  )
}

type GuestEmailOrPhone = {
  guestName: string
  guestPhone?: string
  guestEmail?: string
}
function AddMemberFromBusinessStaff({
  step,
  bookId,
  businessId,
  setStep,
}: {
  bookId: string
  businessId: string
  step?: AddMemberInBookSteps
  setStep?: (type: AddMemberInBookSteps) => void
}) {
  const { business, businessRoleWiseTeam, checkIfAuthenticatedTeamMemberCan } =
    useBusiness(businessId)
  const { staff } = businessRoleWiseTeam
  const [userToBeAdded, setUserToBeAdded] = useState<GuestEmailOrPhone | null>(
    null
  )
  const onAddMemberClick = ({
    guestName,
    guestEmail,
    guestPhone,
  }: GuestEmailOrPhone) => {
    setUserToBeAdded({ guestName, guestEmail, guestPhone })
    setStep?.("selectRoleForStaff")
  }
  const canAddBusinessTeamMember = checkIfAuthenticatedTeamMemberCan(
    BUSINESS_PERMISSIONS.ADD_TEAM_MEMBER
  )
  return (
    <>
      {step === "chooseStaff" ? (
        <Alert status="info" marginBottom="0">
          <Text fontSize="sm">
            You can add members to this book from the staff of “{business.name}”
          </Text>
        </Alert>
      ) : null}
      {step === "chooseStaff" ? (
        <ChooseStaffForBook
          staff={staff}
          bookId={bookId}
          businessId={businessId}
          businessName={business.name}
          canAddBusinessTeamMember={canAddBusinessTeamMember}
          onAddClickHandler={onAddMemberClick}
        />
      ) : step === "selectRoleForStaff" && userToBeAdded !== null ? (
        <SelectRoleForStaffInBook
          bookId={bookId}
          businessId={businessId}
          guestName={userToBeAdded.guestName}
          guestEmail={userToBeAdded?.guestEmail}
          guestPhone={userToBeAdded?.guestPhone}
          onSuccess={() => setStep?.("chooseStaff")}
        />
      ) : null}
    </>
  )
}

function ChooseStaffForBook({
  staff,
  bookId,
  businessId,
  businessName,
  canAddBusinessTeamMember,
  onAddClickHandler,
}: {
  bookId: string
  businessId: string
  businessName: string
  staff: TBusinessMember[]
  canAddBusinessTeamMember: boolean
  onAddClickHandler: ({
    guestName,
    guestEmail,
    guestPhone,
  }: GuestEmailOrPhone) => void
}) {
  const { user } = useProfile()
  const { book, sharedWithIds } = useBook(bookId)
  const [searchValue, setSearchValue] = useState<string>("")
  const [successDialog, setSuccessDialog] = useState<boolean>(false)
  const [addedMember, setAddedMember] = useState<{
    name: string
    bookName: string
    role: T_AVAILABLE_ROLES
    isInvited: boolean
  } | null>(null)

  const filteredUsers: TBusinessMember[] = useMemo(() => {
    return staff.filter(
      (staffMember) =>
        (staffMember.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          (staffMember?.phoneNumber || staffMember?.email || "")
            .toLowerCase()
            .includes(searchValue.toLowerCase())) &&
        staffMember.uid !== user.uid
    )
  }, [searchValue, staff, user.uid])

  return (
    <>
      <ModalBody>
        <Stack gap="6">
          <div className="flex relative bg-opacity-20 rounded focus-within:border-blue-900 focus-within:ring-1 ring-blue-900 h-10 pr-2 items-stretch gap-2 max-w-lg w-full border">
            <input
              type="search"
              name="q"
              placeholder="Search by name or number..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
            />
            <button
              type="button"
              className="flex items-center justify-center text-gray-500"
            >
              {searchValue.length ? (
                <CancelIcon onClick={() => setSearchValue("")} />
              ) : (
                <SearchIcon />
              )}
            </button>
          </div>
          {canAddBusinessTeamMember && (
            <AddMemberInModal
              book={book}
              businessId={businessId}
              onSuccess={(values) => {
                setSuccessDialog(true)
                setAddedMember(values)
              }}
            >
              {({ add }) => (
                <Inline
                  cursor="pointer"
                  alignItems="center"
                  justifyContent="between"
                  onClick={() => {
                    add()
                    trackEvent(TrackingEvents.ADD_EXTERNAL_MEMBER_CLICKED)
                  }}
                >
                  <Inline alignItems="center" gap="4">
                    <Circle size="14">
                      <UserAddIcon />
                    </Circle>
                    <Stack gap="2">
                      <Heading>Add New Member</Heading>
                      <Text color="gray500" fontSize="sm">
                        Invite members who are not part of your business yet
                      </Text>
                    </Stack>
                  </Inline>
                  <Box>
                    <ArrowRightIcon color="gray500" />
                  </Box>
                </Inline>
              )}
            </AddMemberInModal>
          )}
          <Stack>
            <Heading as="h3" color="gray500">
              Members of {businessName}
            </Heading>
            {filteredUsers.length ? (
              <Stack as="ul">
                {filteredUsers.map(
                  ({ uid, name, email, phoneNumber }, index) => {
                    const isAlreadyAdded = sharedWithIds.includes(uid)
                    return (
                      <Box
                        as="li"
                        key={uid}
                        borderTopWidth={index > 0 ? "1" : "0"}
                        paddingY="4"
                      >
                        <Inline alignItems="center" justifyContent="between">
                          <Inline
                            alignItems="center"
                            gap={{ xs: "3", md: "6" }}
                          >
                            <Box>
                              <MemberAvatar id={uid} name={name} />
                            </Box>
                            <Stack gap="2" className="break-all">
                              <Heading as="h4" fontSize="md">
                                {name}
                              </Heading>
                              {email ? (
                                <Text color="gray500" fontSize="sm">
                                  {email}
                                </Text>
                              ) : null}
                              {phoneNumber ? (
                                <Text color="gray500" fontSize="sm">
                                  {phoneNumber}
                                </Text>
                              ) : null}
                            </Stack>
                          </Inline>
                          <Button
                            level="tertiary"
                            disabled={isAlreadyAdded}
                            onClick={() =>
                              onAddClickHandler({
                                guestName: name,
                                guestPhone: phoneNumber,
                                guestEmail: email,
                              })
                            }
                          >
                            {isAlreadyAdded && (
                              <Box>
                                <CheckCircleSolidIcon
                                  size="5"
                                  color="green900"
                                />
                              </Box>
                            )}
                            <Text
                              color={isAlreadyAdded ? "gray400" : "blue900"}
                            >
                              {isAlreadyAdded ? "ADDED" : "ADD"}
                            </Text>
                          </Button>
                        </Inline>
                      </Box>
                    )
                  }
                )}
              </Stack>
            ) : (
              <Stack paddingY="8" gap="4">
                <Text color="gray500">
                  There are no staff members in business!
                </Text>
              </Stack>
            )}
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter className="h-0 border-t-0" />
      {successDialog && addedMember ? (
        <SuccessDialog
          title={`${addedMember.isInvited ? "Invited" : "Added"} in ${
            book.name
          }`}
          successTitle={`${addedMember.name} ${
            addedMember.isInvited ? "invited" : "added"
          } as ${getRoleDetails(addedMember.role).title}!`}
          successSubTitle={`Your new team count is ${
            sharedWithIds.length
          }. Add more members ${
            addedMember.isInvited ? "to" : "from"
          } your team`}
          footerButtonOne={
            <Button
              size="lg"
              level="primary"
              onClick={() => {
                setSuccessDialog(false)
                setAddedMember(null)
              }}
            >
              <Box>
                <UserAddIcon />
              </Box>{" "}
              Add more members
            </Button>
          }
          onClose={() => {
            setSuccessDialog(false)
            setAddedMember(null)
          }}
        />
      ) : null}
    </>
  )
}

function SelectRoleForStaffInBook({
  bookId,
  guestName,
  guestEmail,
  guestPhone,
  businessId,
  onSuccess,
}: {
  bookId: string
  guestName: string
  businessId: string
  guestEmail?: string
  guestPhone?: string
  onSuccess: () => void
}) {
  const addMemberToBook = useAddTeamMember()
  const {
    book,
    hideEntriesByOthers,
    hideBalancesAndReportsForEditor,
    checkIfAuthenticatedMemberCan,
  } = useBook(bookId)
  const canAddAdmin = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.ASSIGN_ADMIN_ROLE
  )
  return (
    <>
      <Formik
        initialValues={{
          guestPhone: guestPhone,
          guestName: guestName,
          cashBookId: bookId,
          guestEmail: guestEmail,
          role: "editor" as OperationalBookRoles,
        }}
        onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
          const { data } = await addMemberToBook({
            businessId: businessId,
            role: "staff",
            guestEmail: values.guestEmail,
            guestPhone: values.guestPhone,
            guestName: values.guestName,
            bookId: values.cashBookId,
            bookRole: values.role,
          })
          if (data.message) {
            trackEvent(TrackingEvents.MEMBER_ADDED, {
              role: values.role,
              type: "businessUser",
              via: values.guestEmail?.length ? "email" : "phone",
            })
            toast.success(`${guestName || guestPhone} added to ${book.name}!`)
            onSuccess()
            return
          }
        })}
      >
        {({ values, isSubmitting, status }) => (
          <>
            <Form>
              <ModalBody className="relative">
                {values.role === "editor" ? (
                  <Box position="absolute" top="0" left="0">
                    <Alert status="info" marginBottom="0">
                      <Text fontSize="sm">
                        You can change operator permissions from book setting
                        page after adding this operator.
                      </Text>
                    </Alert>
                  </Box>
                ) : null}
                <Stack gap="4" paddingY={values.role === "editor" ? "14" : "0"}>
                  <Box
                    borderWidth="1"
                    borderColor="borderOutline"
                    paddingX="6"
                    paddingY="4"
                    rounded="md"
                  >
                    <Inline alignItems="center" justifyContent="between">
                      <Inline gap="6" alignItems="center">
                        <MemberAvatar
                          id={guestPhone || guestEmail || guestName}
                          name={guestName}
                        />
                        <Stack gap="2">
                          <Heading as="h3" fontSize="md">
                            {guestName}
                          </Heading>
                          <Text fontSize="sm" color="gray500">
                            {guestPhone}
                          </Text>
                        </Stack>
                      </Inline>
                      <BusinessRoleDetails
                        role={getBusinessRoleDetails("staff")}
                      />
                    </Inline>
                  </Box>
                  <SelectFromBookLevelRole
                    name="role"
                    canAddAdmin={canAddAdmin}
                    hideEntriesByOthers={hideEntriesByOthers}
                    hideNetBalance={hideBalancesAndReportsForEditor}
                  />
                  <Inline alignItems="center" gap="3">
                    <Box>
                      <InformationCircleIcon size="5" color="gray500" />
                    </Box>
                    <Text fontSize="sm" color="gray500">
                      You can change this role later
                    </Text>
                  </Inline>
                </Stack>
              </ModalBody>
              <ModalFooter>
                {status ? <Alert status="error">{status}</Alert> : null}
                <Button
                  level="primary"
                  disabled={isSubmitting}
                  size="lg"
                  type="submit"
                >
                  <Box>{isSubmitting ? <SpinnerIcon /> : <PlusIcon />}</Box>
                  {isSubmitting ? "ADDING..." : "ADD"}
                </Button>
              </ModalFooter>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export function SelectFromBookLevelRole({
  name,
  canAddAdmin,
  hideNetBalance,
  hideEntriesByOthers,
}: {
  name: string
  canAddAdmin?: boolean
  hideNetBalance?: boolean
  hideEntriesByOthers?: boolean
}) {
  const [{ value }] = useField<T_AVAILABLE_ROLES>(name)
  return (
    <Box borderWidth="1" rounded="md" borderColor="borderOutline">
      <Box borderBottomWidth="1" borderColor="borderOutline">
        <Text fontSize="md" fontWeight="semibold" className="px-6 py-5">
          Choose Role
        </Text>
      </Box>
      <Inline padding="6" gap="4" flexWrap="wrap">
        {getAllRolesWithPermissions().map(({ id, role, title }) => {
          return id === "owner" ||
            id === "partner" ||
            (!canAddAdmin && id === "admin") ? null : (
            <Box
              as="label"
              key={role}
              display="inlineBlock"
              paddingX={{ xs: "3", sm: "4" }}
              paddingY="2"
              rounded="full"
              cursor="pointer"
              borderWidth="1"
              borderColor={value !== role ? "gray100" : "blue900"}
              bgColor={value !== role ? "gray100" : "blue100"}
              color={value === role ? "blue900" : "gray500"}
            >
              <FormField
                label={
                  <Text as="span" fontWeight="medium" fontSize="base">
                    {title}
                  </Text>
                }
                type="radio"
                name={name}
                value={role}
                id={`${name}.${role}`}
                noMargin
                invisibleInput
                hideError
              />
            </Box>
          )
        })}
      </Inline>
      <Stack key={value} gap="4" paddingX="6" paddingBottom="6">
        <Heading as="h5" color="gray500">
          Permissions
        </Heading>
        <Stack as="ul" gap="4">
          {getRoleDetails(value).permissionsDescription.map((permission) => {
            if (value === "editor") {
              if (
                (hideEntriesByOthers &&
                  permission
                    .toLowerCase()
                    .includes("View entries by everyone".toLowerCase())) ||
                (hideNetBalance &&
                  permission
                    .toLowerCase()
                    .includes(
                      "View net balance & download PDF or Excel".toLowerCase()
                    ))
              )
                return null
            }
            return (
              <Inline as="li" key={permission} alignItems="center" gap="3">
                <CheckCircleSolidIcon color="green500" />
                <Text fontWeight="medium">{permission}</Text>
              </Inline>
            )
          })}
        </Stack>
        {getRoleDetails(value).restrictionsDescription.length ? (
          <Stack gap={"4"} paddingTop="4">
            <Heading as="h5" color="gray500">
              Restrictions
            </Heading>
            <Stack as="ul" gap="4">
              {getRoleDetails(value).restrictionsDescription.map(
                (restriction) => {
                  return (
                    <Inline
                      as="li"
                      key={restriction}
                      alignItems="center"
                      gap="3"
                    >
                      <CancelFilledIcon color="red500" />
                      <Text fontWeight="medium">{restriction}</Text>
                    </Inline>
                  )
                }
              )}
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  )
}
