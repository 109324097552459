import { getDeviceOS } from "@cashbook/device-info"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Modal,
  ModalBody,
  useOverlayTriggerState,
  Box,
  Stack,
  Heading,
  Text,
} from "@cashbook/web-components"
import React, { useCallback, useEffect, useRef } from "react"

const DEFAULT_KEYBOARD_SHORTCUT_CATEGORY = "Entry-Form Shortcuts"
const AVAILABLE_KEYBOARD_SHORTCUTS: Array<{
  category: string
  items: Array<{ shortcut: string; label: string; description?: string }>
}> = [
  {
    category: "General Shortcuts",
    items: [
      {
        shortcut: "?",
        label: "Open keyboard shortcut help",
      },
      {
        shortcut: "tab",
        label: "Move focus to the next input/button",
      },
      {
        shortcut: "shift+tab",
        label: "Move focus to the previous input/button",
      },
    ],
  },
  {
    category: DEFAULT_KEYBOARD_SHORTCUT_CATEGORY,
    items: [],
  },
]

const deviceOs = getDeviceOS()
export function ShowKeyboardShortcutsInDialog(props: {
  children: (props: { onShow: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({ defaultOpen: false })
  return (
    <>
      {props.children({
        onShow: () => {
          trackEvent(TrackingEvents.KEYBOARD_SHORTCUTS_SCREEN_VISIT)
          state.open()
        },
      })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title="Keyboard Shortcuts"
        size="lg"
        isDismissable
      >
        <ModalBody>
          <Stack gap="16">
            <Box className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {AVAILABLE_KEYBOARD_SHORTCUTS.map(({ category, items }) => (
                <Box key={category}>
                  <Box as="table">
                    <Box as="thead">
                      <Box as="tr">
                        <Box as="th" colSpan={2}></Box>
                        <Box as="th" paddingBottom="2">
                          <Text color="yellow800" fontSize="xs">
                            {category}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box as="tbody" fontSize="sm">
                      {items.map(({ shortcut, label, description }) => (
                        <Box as="tr" key={shortcut}>
                          <Box as="th" textAlign="right">
                            <Box
                              display="inlineBlock"
                              bgColor="yellow100"
                              borderWidth="1"
                              borderColor="yellow300"
                              paddingX="2"
                              paddingY="1"
                              rounded="md"
                            >
                              <Text
                                as="kbd"
                                color="yellow800"
                                fontWeight="medium"
                              >
                                {shortcut === "c+i" || shortcut === "c+o"
                                  ? shortcut.toUpperCase()
                                  : shortcut}
                              </Text>
                            </Box>
                          </Box>
                          <Box as="td" padding="2">
                            :
                          </Box>
                          <Box as="td" paddingY="2">
                            <Heading as="h5" fontWeight="medium">
                              {label}
                            </Heading>
                            {description ? <Text>{description}</Text> : null}
                          </Box>
                        </Box>
                      ))}
                      {category === DEFAULT_KEYBOARD_SHORTCUT_CATEGORY && (
                        <Box as="tr" key={"enter"}>
                          <Box as="th" textAlign="right">
                            <Box
                              display="inlineBlock"
                              bgColor="yellow100"
                              borderWidth="1"
                              borderColor="yellow300"
                              paddingX="2"
                              paddingY="1"
                              rounded="md"
                            >
                              <Text
                                as="kbd"
                                color="yellow800"
                                fontWeight="medium"
                              >
                                {deviceOs === "ios" ? "Return" : "Enter"}
                              </Text>
                            </Box>
                          </Box>
                          <Box as="td" padding="2">
                            :
                          </Box>
                          <Box as="td" paddingY="2">
                            <Heading as="h5" fontWeight="medium">
                              Save & Move to next entry
                            </Heading>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            <Stack paddingTop="8" borderTopWidth="1" gap="4">
              <Heading
                as="h5"
                fontSize="sm"
                color="gray500"
                fontWeight="medium"
              >
                Here are some guidelines on using keyboard shortcuts
              </Heading>
              <Stack as="ul" gap="2" marginLeft="4" className="list-disc">
                <Box as="li">
                  <Text fontSize="sm">
                    When a shortcut includes "
                    <Text as="kbd" fontWeight="medium" className="font-mono">
                      +
                    </Text>
                    " (plus), then the keys must be pressed simultaneously.
                  </Text>
                </Box>
                <Box as="li">
                  <Text fontSize="sm">
                    Some shortcuts might not be available on all screens e.g.{" "}
                    <Text as="kbd" fontWeight="medium" className="font-mono">
                      c+i
                    </Text>{" "}
                    will not be available on your profile page.
                  </Text>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
      </Modal>
    </>
  )
}

export function ShortcutForKeyboardShortcuts() {
  const [subscribe] = useKeyboardShortcuts("?")
  return (
    <ShowKeyboardShortcutsInDialog>
      {({ onShow }) => {
        subscribe(() => {
          trackEvent(TrackingEvents.KEYBOARD_SHORTCUT_USED, {
            shortcut: "shortcut",
          })
          onShow()
        })
        return null
      }}
    </ShowKeyboardShortcutsInDialog>
  )
}

export function addKeyboardShortcut(
  shortcut: string,
  label: string,
  category?: string,
  description?: string
) {
  category = category || DEFAULT_KEYBOARD_SHORTCUT_CATEGORY
  let categoryIndex = AVAILABLE_KEYBOARD_SHORTCUTS.findIndex(
    (s) => s.category === category
  )
  if (categoryIndex === -1) {
    AVAILABLE_KEYBOARD_SHORTCUTS.push({
      category,
      items: [],
    })
    categoryIndex = AVAILABLE_KEYBOARD_SHORTCUTS.length - 1
  }

  // shortcut already added in the category?
  if (
    AVAILABLE_KEYBOARD_SHORTCUTS[categoryIndex].items.findIndex(
      (item) => item.shortcut === shortcut
    ) !== -1
  ) {
    return
  }
  AVAILABLE_KEYBOARD_SHORTCUTS[categoryIndex].items.push({
    shortcut,
    label,
    description,
  })
}

type KeyboardShortcutListner = (
  key: number | string,
  e: React.KeyboardEvent
) => void

// useKeyboardShortcuts('c+i', handleAction)
export function useKeyboardShortcuts(
  keyCombo: string,
  callback?: KeyboardShortcutListner,
  bubble = false
): [
  subscribe: (subscriber: KeyboardShortcutListner) => void,
  unsubscribe: () => void
] {
  const subscriberRef = useRef<KeyboardShortcutListner | undefined>(callback)
  useEffect(() => {
    const { handleKeyDown, handleKeyUp } = getKeyboardHandlers(
      keyCombo,
      (...args) => subscriberRef.current?.(...args),
      bubble
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener("keydown", handleKeyDown as any)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener("keyup", handleKeyUp as any)
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      document.removeEventListener("keydown", handleKeyDown as any)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      document.removeEventListener("keyup", handleKeyUp as any)
    }
  }, [keyCombo, bubble])
  const subscribe = useCallback((subscriber: KeyboardShortcutListner) => {
    subscriberRef.current = subscriber
  }, [])
  const unsubscribe = useCallback(() => {
    subscriberRef.current = undefined
  }, [])
  return [subscribe, unsubscribe]
}

// handleKey('alt+d') => key combo
// handleKey('left,right') => multiple keys
function getKeyboardHandlers<T extends Element = Element>(
  keyCombo: string,
  callback?: (key: number | string, e: React.KeyboardEvent<T>) => void,
  bubble = false
) {
  let keySplit: Array<string> = []
  if (keyCombo.toString().indexOf(",") > -1) {
    //If multiple keys are selected
    const matched = keyCombo.match(/[a-zA-Z0-9+]+/gi)
    if (matched) keySplit = matched
  } else {
    keySplit = [keyCombo]
  }
  // holds the key codes for each keys
  const keyCodes: Array<Array<number> | number> = keySplit.map((_) => [])
  // for each key in the array
  for (let i = 0; i < keySplit.length; i++) {
    const key = String(keySplit[i])
    if (key.indexOf("+") > -1) {
      //Key selection by user is a key combo
      // Create a combo array and split the key combo
      const combo: Array<number> = []
      const comboSplit = key.split("+")
      // Save the key codes for each element in the key combo
      for (let j = 0; j < comboSplit.length; j++) {
        combo[j] = KEY_CODES[comboSplit[j]]
      }
      keyCodes[i] = combo
    } else {
      //Otherwise, it's just a normal, single key command
      keyCodes[i] = KEY_CODES[keySplit[i]]
    }
  }

  // Create active keys array
  // This array will store all the keys that are currently being pressed
  let activeKeys: { [key: number]: number | "" } = {}
  const handleKeyDown: React.KeyboardEventHandler<T> = function (e) {
    const keyCode = getKeyCode(e as unknown as KeyboardEvent)
    activeKeys[keyCode] = keyCode

    if (keyCodes.indexOf(keyCode) > -1) {
      // If the key the user pressed is matched with any key the developer set a key code with...
      if (typeof callback == "function") {
        //and they provided a callback function
        callback.call(undefined, KEY_CODES_SWITCHED[keyCode], e) //trigger call back and...
        if (bubble === false) {
          e.preventDefault() //cancel the normal
        }
      }
    } else {
      // Else, the key did  not match which means it's either a key combo or just dosn't exist
      // Check if the individual items in the key combo match what was pressed
      for (let i = 0; i < keyCodes.length; i++) {
        const codes = keyCodes[i]
        if (!Array.isArray(codes)) {
          continue
        }
        if (codes.indexOf(keyCode) > -1) {
          // Initiate the active variable
          let active: "unchecked" | boolean = "unchecked"
          // All the individual keys in the combo with the keys that are currently being pressed
          for (let j = 0; j < codes.length; j++) {
            if (active !== false) {
              if (activeKeys[codes[j]]) {
                active = true
              } else {
                active = false
              }
            }
          }

          // If all the keys in the combo are being pressed, active will equal true
          if (active === true) {
            if (typeof callback == "function") {
              //and they provided a callback function

              let activeString = ""

              for (const z in activeKeys) {
                if (activeKeys[z] !== "") {
                  activeString += KEY_CODES_SWITCHED[activeKeys[z]] + "+"
                }
              }
              activeString = activeString.substring(0, activeString.length - 1)
              callback.call(undefined, activeString, e) //trigger call back and...
              if (bubble === false) {
                e.preventDefault() //cancel the normal
              }
            }
          }
        }
      }
    }
  }
  const handleKeyUp: React.KeyboardEventHandler<T> = function (e) {
    activeKeys = {}
  }
  return { handleKeyDown, handleKeyUp }
}

function getKeyCode(e: KeyboardEvent) {
  let keyCode = e.keyCode
  if (e.shiftKey) {
    keyCode += 0.1
  }
  return keyCode
}

const KEY_CODES: { [key: string]: number } = {
  /* start the a-z keys */
  a: 65,
  b: 66,
  c: 67,
  d: 68,
  e: 69,
  f: 70,
  g: 71,
  h: 72,
  i: 73,
  j: 74,
  k: 75,
  l: 76,
  m: 77,
  n: 78,
  o: 79,
  p: 80,
  q: 81,
  r: 82,
  s: 83,
  t: 84,
  u: 85,
  v: 86,
  w: 87,
  x: 88,
  y: 89,
  z: 90,
  /* start number keys */
  "0": 48,
  "1": 49,
  "2": 50,
  "3": 51,
  "4": 52,
  "5": 53,
  "6": 54,
  "7": 55,
  "8": 56,
  "9": 57,
  /* start the f keys */
  f1: 112,
  f2: 113,
  f3: 114,
  f4: 115,
  f5: 116,
  f6: 117,
  f7: 118,
  f8: 119,
  f9: 120,
  f10: 121,
  f11: 122,
  f12: 123,
  /* start the modifier keys */
  shift: 16,
  ctrl: 17,
  control: 17,
  alt: 18,
  option: 18, //Mac OS key
  opt: 18, //Mac OS key
  cmd: 224, //Mac OS key
  command: 224, //Mac OS key
  fn: 255, //tested on Lenovo ThinkPad
  function: 255, //tested on Lenovo ThinkPad
  /* Misc. Keys */
  backspace: 8,
  osxdelete: 8, //Mac OS version of backspace
  enter: 13,
  return: 13, //Mac OS version of "enter"
  space: 32,
  spacebar: 32,
  esc: 27,
  escape: 27,
  tab: 9,
  capslock: 20,
  capslk: 20,
  super: 91,
  windows: 91,
  insert: 45,
  delete: 46, //NOT THE OS X DELETE KEY!
  home: 36,
  end: 35,
  pgup: 33,
  pageup: 33,
  pgdn: 34,
  pagedown: 34,
  /* Arrow keys */
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  /* Special char keys */
  "!": 49,
  "@": 50,
  "#": 51,
  $: 52,
  "%": 53,
  "^": 54,
  "&": 55,
  "*": 56,
  "(": 57,
  ")": 48,
  "`": 96,
  "~": 96.1, // "*.1 means shift is pressed"
  "-": 45,
  _: 45.1,
  "=": 187,
  "+": 187.1,
  "[": 219,
  "{": 219.1,
  "]": 221,
  "}": 221.1,
  "\\": 220, //it's actually a \ but there's two to escape the original
  "|": 220.1,
  ";": 59,
  ":": 59.1,
  "'": 222,
  '"': 222.1,
  ",": 188,
  "<": 188.1,
  ".": 190,
  ">": 190.1,
  "/": 191,
  "?": 191.1,
}

const KEY_CODES_SWITCHED = swapJsonKeyValues(KEY_CODES)

function swapJsonKeyValues(input: { [key: string]: string | number }) {
  let one: string
  const output: { [key: string]: string | number } = {}
  for (one in input) {
    if (input[one]) {
      output[input[one]] = one
    }
  }
  return output
}
