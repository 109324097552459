import {
  Alert,
  ArrowLeftIcon,
  DataLoadingFallback,
  PageMeta,
  Heading,
  Box,
  Inline,
  Stack,
} from "@cashbook/web-components"
import { Link, useNavigate, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import { AddMember } from "../Books"
import ErrorBoundary from "../ErrorBoundary"
import { useProfile } from "@cashbook/data-store/users"
import { useBook } from "@cashbook/data-store/books"

export default function AddMemberToBookPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_member_account_details"
      >
        <AddMemberToBook key={bookId} bookId={bookId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function AddMemberToBook({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book } = useBook(bookId)
  const { user } = useProfile()
  const amIAdmin = (book.admins || []).indexOf(user.uid) !== -1
  const navigate = useNavigate()
  return (
    <>
      <PageMeta>
        <title>Add Member to {book.name}</title>
      </PageMeta>
      <Box
        key={book.id}
        bgColor="white"
        paddingTop="6"
        paddingBottom="18"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        <Box maxWidth="xl">
          <Stack gap="10">
            <Box display={{ xs: "none", md: "block" }}>
              <Stack gap="6">
                <Heading fontSize="sm" fontWeight="medium" color="gray500">
                  Members | Add Member
                </Heading>
                <Inline gap="6" alignItems="center">
                  <Link
                    to={`/businesses/${businessId}/cashbooks/${book.id}/settings`}
                  >
                    <ArrowLeftIcon />
                  </Link>
                  <Box flex="1">
                    <Heading fontSize="md" fontWeight="semibold">
                      Add Member
                    </Heading>
                  </Box>
                </Inline>
              </Stack>
            </Box>
            <Box>
              {!amIAdmin ? (
                <Alert status="error">
                  <h3 className="text-sm mb-2">Not Authorized</h3>
                  <h2 className="text-xl mb-4">
                    You don't have permissions to add members to this book.
                  </h2>
                  <p>
                    Please ask admin members to grant you permissions to add
                    member.
                  </p>
                  <hr className="my-8 border-red-900" />
                  <p>
                    If you know you have permissions to add member, please{" "}
                    <Link to={`/docs`} className="text-blue-900">
                      contact our support
                    </Link>
                  </p>
                </Alert>
              ) : (
                <div>
                  <AddMember
                    book={book}
                    businessId={businessId}
                    onSuccess={() => {
                      navigate(
                        `/businesses/${businessId}/cashbooks/${book.id}/settings/members`
                      )
                    }}
                  />
                </div>
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  )
}
