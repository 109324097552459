import {
  DataLoadingFallback,
  InformationCircleIcon,
  UsersIcon,
  Time,
  PageMeta,
  Text,
  Heading,
  Box,
  Stack,
  Inline,
  ArrowLeftIcon,
} from "@cashbook/web-components"
import { Link, NavLink, useParams, Outlet } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import ErrorBoundary from "../../ErrorBoundary"
import Page from "./Page"
import { useBusiness } from "@cashbook/data-store/businesses"
import { useMemo } from "react"

export default function SmBookSettingsPage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading business details..." />}
        traceId="loading_business_details"
      >
        <BusinessSettingsLayout key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BusinessSettingsLayout({ businessId }: { businessId: string }) {
  const { business, authTeamMemberDetails } = useBusiness(businessId)
  const authUserRole = authTeamMemberDetails.role.id
  const businessSettingsOptions = useMemo(() => {
    return [
      {
        label: "Business Team",
        description:
          authUserRole === "staff"
            ? "List of business team members"
            : "Add, remove or change role",
        to: "team",
      },
      {
        label: "Business Profile",
        description:
          authUserRole === "staff"
            ? "View business details"
            : "Edit business details",
        to: "profile",
      },
      {
        label: "Settings",
        description:
          authUserRole === "owner"
            ? "Change owner or delete business"
            : "Leave Business",
        to: "settings",
      },
    ]
  }, [authUserRole])
  return (
    <>
      <PageMeta>
        <title>{business.name} Details</title>
      </PageMeta>
      <Page
        title="Business Details"
        backTo={`/m/businesses/${businessId}/cashbooks`}
        bgColor="white"
      >
        <Box paddingX="4" paddingY="8">
          <Stack gap="8">
            <Stack gap="3">
              <Heading
                as="h2"
                color="gray500"
                fontWeight="medium"
                fontSize="sm"
              >
                Business Details
              </Heading>
              <Box
                bgColor="white"
                borderWidth="1"
                borderColor="gray100"
                rounded="md"
                padding="4"
              >
                <Stack gap="2">
                  <Text fontSize="lg" fontWeight="medium">
                    {business.name}
                  </Text>
                  {business.createdAt ? (
                    <Inline gap="2">
                      <Text fontSize="sm">
                        Created on{" "}
                        <Time
                          timeStamp={business.createdAt}
                          format="dd/MM/yyyy"
                        />
                      </Text>
                      {business.sharedWith?.length > 1 ? (
                        <>
                          <Text as="span" fontSize="sm">
                            •
                          </Text>
                          <Inline gap="1" alignItems="center">
                            <UsersIcon size="3" />
                            <Text fontSize="sm">
                              {business.sharedWith.length}
                            </Text>
                          </Inline>
                        </>
                      ) : null}
                    </Inline>
                  ) : null}
                </Stack>
              </Box>
            </Stack>
            <Stack gap="3">
              <Heading
                as="h2"
                color="gray500"
                fontWeight="medium"
                fontSize="sm"
              >
                Settings
              </Heading>
              <Box
                as="ol"
                className="divide-y"
                borderWidth="1"
                bgColor="white"
                borderColor="gray100"
                rounded="md"
                marginBottom="24"
              >
                {businessSettingsOptions.map(({ label, description, to }) => (
                  <Box as="li" padding="2" key={label}>
                    <Inline
                      as={NavLink}
                      to={to}
                      padding="3"
                      gap="4"
                      alignItems="center"
                      justifyContent="between"
                    >
                      <Stack gap="2">
                        <Heading as="h4" fontWeight="semibold" fontSize="base">
                          {label}
                        </Heading>
                        <Text color="gray500" fontWeight="medium" fontSize="sm">
                          {description}
                        </Text>
                      </Stack>
                      <Box>
                        <ArrowLeftIcon color="blue900" rotate="180" />
                      </Box>
                    </Inline>
                  </Box>
                ))}
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Page>
    </>
  )
}

export function SmEnsureBookExistsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary
      errorRenderer={({
        isMissingPermissionsError,
        defaultRenderedMessage,
      }) => (
        <Page title="Book Details" backTo={`/m/books`}>
          {isMissingPermissionsError ? (
            <Stack
              paddingY={{ xs: "8", sm: "16" }}
              paddingX="4"
              maxWidth="2xl"
              gap="4"
            >
              <InformationCircleIcon size="12" />
              <Heading as="h2" fontSize="2xl" fontWeight="medium">
                Book Not Found
              </Heading>
              <Text>
                The book has been either deleted or you don't have access to it
                anymore. Please{" "}
                <Text
                  as={Link}
                  color="blue900"
                  fontWeight="medium"
                  to="/m/businesses"
                >
                  select any other book
                </Text>{" "}
                to view details and add entries
              </Text>
              <hr />
              <Box>
                <Text>
                  If you think this is an issue with the application, please
                  contact our{" "}
                  <Text
                    as={Link}
                    to="/docs"
                    color="blue900"
                    fontWeight="medium"
                  >
                    support
                  </Text>
                  .
                </Text>
              </Box>
            </Stack>
          ) : (
            defaultRenderedMessage
          )}
        </Page>
      )}
    >
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <Outlet />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}
