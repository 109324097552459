import { Channel } from "./contract"
import createConsoleChannel from "./console"
import createGAChannel from "./google"

let enabledChannels: Array<ReturnType<Channel>> = []

type ChannelKey = "console" | "google"

export function configureChannels({
  channels,
  config,
}: {
  channels: ChannelKey | Array<ChannelKey>
  config?: Record<string, unknown>
}) {
  enabledChannels = []
  if (typeof channels === "string") {
    channels = [channels]
  }
  channels.forEach((c) => {
    switch (c) {
      case "console":
        enabledChannels.push(createConsoleChannel(config))
        return
      case "google":
        enabledChannels.push(createGAChannel(config))
        return
      default:
        throw new Error(`Unknown channel [${c}]`)
    }
  })
}

export const channels: ReturnType<Channel> = {
  page(...args) {
    enabledChannels.forEach((c) => c.page(...args))
  },
  track(...args) {
    enabledChannels.forEach((c) => c.track(...args))
  },
  reset() {
    enabledChannels.forEach((c) => c.reset())
  },
  identify(...args) {
    enabledChannels.forEach((c) => c.identify(...args))
  },
}
