import { useEffect, useMemo, useRef, useState } from "react"
import { FieldProps, Form, Formik } from "formik"
import { useAuth } from "reactfire"
import { signInWithCustomToken } from "firebase/auth"
import type { User } from "firebase/auth"
import {
  useSendVerificationCode,
  useCheckUsersExists,
} from "@cashbook/data-store/auth"
import { Recaptcha } from "./VerifyPhoneNumber"
import * as Validator from "yup"
import {
  Box,
  FormField,
  Text,
  Stack,
  PhoneInput,
  parsePhoneNumber,
  SpinnerIcon,
  formikOnSubmitWithErrorHandling,
  Inline,
  ArrowLeftIcon,
  useOverlayTriggerState,
  Modal,
  ModalBody,
  Alert,
  Circle,
  ModalFooter,
  CBButton,
} from "@cashbook/web-components"
import { logInfo } from "@cashbook/util-logging"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import { $PropertyType } from "utility-types"
import toast from "react-hot-toast"
import {
  OTPValidator,
  PhoneNumberValidator,
  isVisitorIndian,
} from "@cashbook/util-general"
import TermsAndConditionsBox from "./TermsAndConditionsBox"
import { OtpVerification } from "./OtpVerification"
import { Country } from "react-phone-number-input"

type STEPS = "phoneInput" | "emailInput" | "socialAuthentication"
type LoginWithPhoneProps = {
  onSuccess?: (data: User) => void
  phoneNumber?: string | null
  country?: string | null
  sendOtp?: boolean
  setStep: (step: STEPS) => void
  onCountryChange?: (value: Country) => void
  onPhoneChange?: (
    newPhoneNumber: string,
    oldPhoneNumber: string
  ) => Promise<void>
}

export function LoginWithPhone({
  onSuccess,
  setStep,
  phoneNumber,
  country,
  sendOtp,
  onPhoneChange,
  onCountryChange,
}: LoginWithPhoneProps) {
  const auth = useAuth()
  const {
    verifyOtp,
    sendVerificationCode,
    secondsRemainingToResend,
    secondsRemainingToResendStr,
    resendVerificationCode,
    captchaVerifier,
    setCaptchaVerifier,
    attempts,
    sendingVerificationCode,
    verifyOtpViaWhatsApp,
    sendVerificationCodeViaWhatsApp,
    resendVerificationCodeViaWhatsApp,
  } = useSendVerificationCode()

  const { checkPhoneExists: checkIfPhoneExists } = useCheckUsersExists()

  const autoSendOtpRef = useRef(sendOtp || false)
  const parsedPhoneNumber = useMemo(() => {
    if (phoneNumber) {
      return parsePhoneNumber(
        phoneNumber,
        (country || "IN").toUpperCase() as never
      )
    }
  }, [phoneNumber, country])
  const [askForOTP, setAskForOTP] = useState<boolean>(false)
  const [countryCode, setCountyCode] = useState<Country>(
    parsedPhoneNumber?.country || "IN"
  )
  const [hasWhatsApp, setHasWhatsApp] = useState<boolean>(true)

  const newLoginModal = useOverlayTriggerState({})

  useEffect(() => {
    if (countryCode !== "IN") {
      setHasWhatsApp(true)
    }
  }, [countryCode])

  const isUserIndian = isVisitorIndian()

  //Login using mobile number
  const loginWithPhoneHandler = async (
    phoneNumber: string,
    code: string,
    setErrors: (code: string) => void
  ) => {
    if (askForOTP) {
      if (countryCode !== "IN" && hasWhatsApp) {
        try {
          const token = await verifyOtpViaWhatsApp(phoneNumber, code)
          const { user } = await signInWithCustomToken(auth, token)
          trackEvent(TrackingEvents.LOGIN_OTP_VERIFIED, {
            isAutoVerified: false,
            verificationMethod: "whatsapp",
          })
          if (!user.displayName) {
            trackEvent(TrackingEvents.OTP_VERIFIED_NEW_USER, {
              verificationMethod: "whatsapp",
            })
          }
          onSuccess?.(user)
          return
        } catch (e) {
          const error = e as Error
          setErrors(error.message)
          return
        }
      }
      try {
        const user = await verifyOtp(phoneNumber, code)
        trackEvent(TrackingEvents.LOGIN_OTP_VERIFIED, {
          isAutoVerified: false,
          verificationMethod: "phone",
        })
        if (!user.displayName) {
          trackEvent(TrackingEvents.OTP_VERIFIED_NEW_USER, {
            verificationMethod: "phone",
          })
        }
        onSuccess?.(user)
      } catch (e) {
        const error = e as Error
        logInfo("Phone Verification Failed", {
          tags: {
            code: (e as never as { code?: string }).code || error.message,
            attempts,
          },
        })
        setErrors(error.message)
      }
      return
    }
    if (
      onPhoneChange &&
      parsedPhoneNumber?.number &&
      phoneNumber.replace(/\s/gi, "") !==
        parsedPhoneNumber.number.replace(/\s/gi, "")
    ) {
      try {
        await onPhoneChange(phoneNumber, parsedPhoneNumber.number.toString())
      } catch (e) {
        const error = e as Error
        toast.error(error.message)
        return
      }
    }
    trackEvent(TrackingEvents.PHONE_SUBMITTED, {
      phone: phoneNumber,
      autoFilled: false,
    })
    if (countryCode !== "IN") {
      const userExist = await checkIfPhoneExists(phoneNumber)
      if (!userExist) {
        newLoginModal.open()
        trackEvent(TrackingEvents.PHONE_NOT_REGISTERED, {
          phoneNumber: phoneNumber,
          verificationMethod: hasWhatsApp ? "whatsapp" : "sms",
        })
        return
      }
    }
    try {
      if (countryCode !== "IN" && hasWhatsApp) {
        await sendVerificationCodeViaWhatsApp(phoneNumber)
        toast.success(`Verification OTP sent on WhatsApp!`)
        setAskForOTP(true)
        return
      }
      await sendVerificationCode(phoneNumber)
      toast.success(`OTP sent to ${phoneNumber}`)
      setAskForOTP(true)
    } catch (e) {
      const error = e as Error
      toast.error(error.message)
      return
    }
    return
  }

  //Resend login otp
  const resendVerificationOtp = async (
    phoneNumber: string,
    setErrors: (errorMessage: string) => void
  ) => {
    try {
      if (countryCode !== "IN" && hasWhatsApp) {
        await resendVerificationCodeViaWhatsApp(phoneNumber)
        toast.success(`OTP resent to ${phoneNumber} on WhatsApp`)
        trackEvent(TrackingEvents.OTP_RESENT, {
          from: "login",
          attemptCount: attempts,
          verificationMethod: "whatsapp",
        })
      } else {
        await resendVerificationCode(phoneNumber)
        toast.success(`OTP resent to ${phoneNumber}`)
        trackEvent(TrackingEvents.OTP_RESENT, {
          from: "login",
          attemptCount: attempts,
          verificationMethod: "phone",
        })
      }
    } catch (e) {
      const err = e as Error
      setErrors(err.message || "Something went wrong. Please try again later!")
    }
  }

  return (
    <Box
      id="loginWithPhoneForm"
      maxWidth="sm"
      marginX="auto"
      borderWidth="1"
      borderColor="gray100"
      rounded="md"
      padding="4"
    >
      <Recaptcha onLoad={setCaptchaVerifier} />
      <Stack gap="8">
        <Formik
          initialValues={{
            phoneNumber: (parsedPhoneNumber?.number || "") as string,
            code: "" as string,
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Validator.object().shape({
            phoneNumber: PhoneNumberValidator.required(),
            code: OTPValidator,
          })}
          onSubmit={formikOnSubmitWithErrorHandling(async (values, actions) => {
            await loginWithPhoneHandler(
              values.phoneNumber,
              values.code,
              (code: string) => actions.setErrors({ code })
            )
          })}
        >
          {({
            values,
            isSubmitting,
            setFieldError,
            setFieldValue,
            submitForm,
          }) => (
            <Form noValidate>
              {!askForOTP ? (
                <Stack gap="8" position="relative" padding="4">
                  <Box>
                    <Stack gap="8">
                      <Inline gap="4" alignItems="center">
                        {countryCode !== "IN" ? (
                          <CBButton
                            inline
                            onClick={() => setStep("emailInput")}
                          >
                            <ArrowLeftIcon color="iconHigh" />
                          </CBButton>
                        ) : null}
                        <Text
                          as="label"
                          htmlFor="phoneNumber"
                          fontSize="md"
                          fontWeight="medium"
                        >
                          Enter your mobile Number
                        </Text>
                      </Inline>
                      <FormField
                        name="phoneNumber"
                        renderInput={({
                          field: { onChange, ...otherFieldProps },
                          form,
                        }: FieldProps<string>) => (
                          <Box>
                            <PhoneInput
                              {...otherFieldProps}
                              id="phoneNumber"
                              onChange={(phoneNumber) =>
                                form.setFieldValue(
                                  otherFieldProps.name,
                                  phoneNumber
                                )
                              }
                              defaultCountry={countryCode}
                              onCountryChange={(value) => {
                                const country = value ? value : "IN"
                                onCountryChange?.(country)
                                setCountyCode(country)
                              }}
                              placeholder="e.g. 8772321230"
                              className="space-x-4 ml-4"
                              required
                              type="tel"
                              autoFocus
                            />
                          </Box>
                        )}
                      />
                    </Stack>
                    <Box
                      width="full"
                      display="flex"
                      flexDirection="col"
                      alignItems="center"
                    >
                      {countryCode === "IN" || !hasWhatsApp ? (
                        <CBButton
                          fullWidth
                          type="submit"
                          size="lg"
                          id="submitPhoneNumber"
                          loading={isSubmitting}
                          disabled={!values.phoneNumber?.length}
                        >
                          {isSubmitting
                            ? "Sending OTP..."
                            : `Send OTP ${
                                countryCode !== "IN" && !hasWhatsApp
                                  ? "On SMS"
                                  : ""
                              }`}
                        </CBButton>
                      ) : (
                        <Stack width="full" gap="2">
                          <CBButton
                            fullWidth
                            status="success"
                            type="submit"
                            size="lg"
                            id="submitPhoneNumberViaWhatsApp"
                            loading={isSubmitting}
                            disabled={!values.phoneNumber?.length}
                          >
                            Send OTP via WhatsApp
                          </CBButton>
                          <CBButton
                            size="lg"
                            level="tertiary"
                            disabled={isSubmitting}
                            onClick={() => setHasWhatsApp(false)}
                          >
                            I don't have WhatsApp
                          </CBButton>
                        </Stack>
                      )}

                      {countryCode !== "IN" ? null : (
                        <>
                          <Text className="py-4">OR</Text>
                          <CBButton
                            fullWidth
                            size="lg"
                            disabled={isSubmitting}
                            onClick={() =>
                              setStep(
                                isUserIndian
                                  ? "emailInput"
                                  : "socialAuthentication"
                              )
                            }
                          >
                            Other ways to login
                          </CBButton>
                        </>
                      )}
                    </Box>
                  </Box>
                  {countryCode !== "IN" ? null : (
                    <Box>
                      <TermsAndConditionsBox fromText />
                      {autoSendOtpRef.current ? (
                        <Stack
                          position="absolute"
                          inset="0"
                          alignItems="center"
                          justifyContent="center"
                          gap="4"
                          bgColor="white"
                          rounded="md"
                        >
                          <AutoSendOtp
                            captchaVerifier={captchaVerifier}
                            onSend={async () => {
                              await submitForm()
                              autoSendOtpRef.current = false
                            }}
                          />
                          <SpinnerIcon />
                          <Text color="gray500">Sending OTP</Text>
                        </Stack>
                      ) : null}
                    </Box>
                  )}
                </Stack>
              ) : (
                <OtpVerification
                  otp={values.code}
                  otpVerificationLoading={isSubmitting}
                  sendingVerificationCode={sendingVerificationCode}
                  secondsRemainingToResend={secondsRemainingToResend}
                  secondsRemainingToResendStr={secondsRemainingToResendStr}
                  phoneNumber={values.phoneNumber}
                  onBack={() => {
                    setAskForOTP(false)
                    setFieldValue("code", "")
                  }}
                  onResendOtpHandler={async () => {
                    setFieldValue("code", "")
                    await resendVerificationOtp(values.phoneNumber, (error) =>
                      setFieldError("code", error)
                    )
                  }}
                  onOtpChange={(value) => setFieldValue("code", value)}
                />
              )}
            </Form>
          )}
        </Formik>
        <Modal
          isOpen={newLoginModal.isOpen}
          onClose={newLoginModal.close}
          title="Login Failed"
        >
          <ModalBody>
            <Stack gap="8">
              <Alert marginBottom="0" status="warning">
                <Text fontSize="b3"> Could not find account</Text>
              </Alert>
              <Inline alignItems="center" gap="4">
                <Circle size="2" backgroundColor="iconLow" />
                <Text fontSize="b3">
                  Try logging in with email or create a new account
                </Text>
              </Inline>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <CBButton
              level="primary"
              size="lg"
              onClick={() => {
                newLoginModal.close()
                setStep("emailInput")
              }}
            >
              Create New Account
            </CBButton>
            <CBButton size="lg" level="tertiary" onClick={newLoginModal.close}>
              Ok, Got it
            </CBButton>
          </ModalFooter>
        </Modal>
      </Stack>
    </Box>
  )
}

function AutoSendOtp({
  captchaVerifier,
  onSend,
}: {
  captchaVerifier: $PropertyType<
    ReturnType<typeof useSendVerificationCode>,
    "captchaVerifier"
  >
  onSend: () => void
}) {
  const sentOtpRef = useRef(false)
  useEffect(() => {
    // only send otp once
    if (captchaVerifier && !sentOtpRef.current) {
      sentOtpRef.current = true
      onSend()
    }
  }, [captchaVerifier, onSend])
  return null
}
