import { useBusiness, useNewBusinesses } from "@cashbook/data-store/businesses"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  AdminVectorIcon,
  Alert,
  Box,
  CancelFilledIcon,
  CheckCircleSolidIcon,
  DataLoadingFallback,
  DataOperatorIcon,
  getButtonClassName,
  Heading,
  Inline,
  ModalFooter,
  PageMeta,
  Stack,
  Text,
} from "@cashbook/web-components"
import { Link, useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import { Header } from "../DashboardLayout"
import ErrorBoundary from "../ErrorBoundary"

export default function NewBusinessTransition() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading business details..." />}
        traceId="loading_book_details"
      >
        <EnsuredBusinessExistence businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function EnsuredBusinessExistence({ businessId }: { businessId: string }) {
  const { isDeleted } = useBusiness(businessId)
  if (isDeleted)
    return (
      <Alert status="error">
        <Text>
          You have been removed from the business/Or the business has been
          deleted.
        </Text>
      </Alert>
    )
  return <TransitionScreen key={businessId} businessId={businessId} />
}

function TransitionScreen({ businessId }: { businessId: string }) {
  const { business, businessOwner, authTeamMemberDetails } =
    useBusiness(businessId)
  const { updateToOldBusiness } = useNewBusinesses()
  const {
    name,
    role: { id, title, permissionsDescription, restrictionsDescription },
  } = authTeamMemberDetails
  return (
    <>
      <PageMeta>
        <title>New Business - {business.name}</title>
      </PageMeta>
      <Header />
      <Stack gap="6" width="full" paddingY="8">
        <Box maxWidth="lg" marginX="auto">
          <Box
            padding="8"
            bgColor="white"
            rounded="md"
            borderWidth="1"
            width="full"
            borderColor="gray100"
          >
            <Stack gap="8">
              <Stack borderBottomWidth="1" paddingBottom="4" gap="2">
                <Heading as="h1" fontSize="xl">
                  Welcome {name}
                </Heading>
                <Text fontSize="md">
                  {businessOwner?.name} added you to their business
                </Text>
              </Stack>

              <Box backgroundColor="blue100" rounded="md" padding="4">
                <Inline gap="3">
                  <Stack gap="3">
                    <Text as="span">Business name:</Text>
                    <Text as="span">Business role:</Text>
                  </Stack>
                  <Stack gap="3">
                    <Text fontWeight="semibold">{business.name}</Text>
                    <Text fontWeight="semibold">{title}</Text>
                  </Stack>
                </Inline>
              </Box>
              <Box borderWidth="1" rounded="md" padding="6">
                <Stack gap="3">
                  <Box>
                    {id === "partner" ? (
                      <AdminVectorIcon size="14" />
                    ) : (
                      <DataOperatorIcon size="14" />
                    )}
                  </Box>
                  <Heading as="h2" fontSize="lg">
                    {title} Permissions and Restrictions
                  </Heading>
                  <Box paddingTop="3">
                    <Stack gap="6">
                      {permissionsDescription.length ? (
                        <Stack gap="4">
                          <Text fontWeight="semibold" color="gray500">
                            Permissions
                          </Text>
                          <Stack as="ul" gap="4">
                            {permissionsDescription.map((permission) => (
                              <Inline
                                key={permission}
                                gap="3"
                                alignItems="center"
                              >
                                <Box>
                                  <CheckCircleSolidIcon color="green500" />
                                </Box>
                                <Text>{permission}</Text>
                              </Inline>
                            ))}
                          </Stack>
                        </Stack>
                      ) : null}
                      {restrictionsDescription.length ? (
                        <Stack gap="4">
                          <Text fontWeight="semibold" color="gray500">
                            Restrictions
                          </Text>
                          <Stack as="ul" gap="4">
                            {restrictionsDescription.map((restriction) => (
                              <Inline
                                key={restriction}
                                gap="3"
                                alignItems="center"
                              >
                                <Box>
                                  <CancelFilledIcon color="red500" />
                                </Box>
                                <Text>{restriction}</Text>
                              </Inline>
                            ))}
                          </Stack>
                        </Stack>
                      ) : null}
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <ModalFooter className="sticky w-full bottom-0 py-8 bg-[white]">
                <Link
                  className={getButtonClassName({
                    level: "primary",
                    size: "lg",
                  })}
                  onClick={() => {
                    updateToOldBusiness(businessId)
                    trackEvent(TrackingEvents.BUSINESS_ONBOARDING_NEXT_CLICKED)
                  }}
                  to={`/businesses/${business.id}/cashbooks`}
                >
                  Next
                </Link>
              </ModalFooter>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </>
  )
}
