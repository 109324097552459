import { Box, PageMeta } from "@cashbook/web-components"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useBook } from "@cashbook/data-store/books"
import { getLabelForTransactionType, LogTransaction } from "../../Transactions"
import Page from "./Page"

export default function SmAddTransactionPage() {
  const { bookId, businessId } = useParams()
  const [searchParams] = useSearchParams()
  const validTypes = ["cash-in", "cash-out"]
  let type: "cash-in" | "cash-out" = "cash-in"
  if (validTypes.indexOf(searchParams.get("type") || "") >= 0) {
    type = searchParams.get("type") as never as typeof type
  }
  if (!bookId || !businessId) return null
  return (
    <Page
      title={`Add ${getLabelForTransactionType(type)} Entry`}
      backTo={`/m/businesses/${businessId}/cashbooks/${bookId}/transactions`}
      bgColor="white"
    >
      {bookId ? (
        <AddTransactionLayout
          bookId={bookId}
          type={type}
          businessId={businessId}
        />
      ) : (
        <Box padding="4">Please select a book to add entries</Box>
      )}
    </Page>
  )
}

function AddTransactionLayout({
  bookId,
  type,
  businessId,
}: {
  bookId: string
  businessId: string
  type: "cash-in" | "cash-out"
}) {
  const { book } = useBook(bookId)
  const navigate = useNavigate()
  return (
    <>
      <PageMeta>
        <title>
          Add {getLabelForTransactionType(type)} Entry to {book.name}
        </title>
      </PageMeta>
      <Box padding="4">
        <LogTransaction
          book={book}
          onSuccess={(data) =>
            !data.usingSaveAndNext
              ? navigate(
                  `/m/businesses/${businessId}/cashbooks/${book.id}/transactions`
                )
              : undefined
          }
          type={type}
        />
      </Box>
    </>
  )
}
