import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  Alert,
  Button,
  ChevronDownIcon,
  FormField,
  formikOnSubmitWithErrorHandling,
  PageMeta,
  PhoneInput,
  isPossiblePhoneNumber,
  Box,
  Stack,
  Heading,
  DataLoadingFallback,
  ModalFooter,
  getButtonClassName,
} from "@cashbook/web-components"
import { updatePhoneNumber } from "firebase/auth"
import { FieldProps, Form, Formik } from "formik"
import toast from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom"
import { SuspenseWithPerf, useAuth } from "reactfire"
import * as Validator from "yup"
import { VerifyPhoneNumber } from "../Auth"
import ErrorBoundary from "../ErrorBoundary"
import { resolveAuthErrors } from "@cashbook/data-store/auth"
import {
  useProfile,
  useUpdateProfile,
  useCheckUsersExists,
} from "@cashbook/data-store/users"
import { useUserCountry } from "../support/Intl"
import config from "../config"
import { State } from "react-phone-number-input"

export default function ChangePhoneNumberContainer() {
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading profile details..." />}
        traceId="loading_member_account_details"
      >
        <Box width="full">
          <ChangePhoneNumber />
        </Box>
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

export function ChangePhoneNumber() {
  const { user } = useProfile()
  const updateProfile = useUpdateProfile()
  const { currentUser } = useAuth()
  const country = useUserCountry()
  const { checkIfPhonesExists } = useCheckUsersExists()
  const navigate = useNavigate()
  return (
    <>
      <PageMeta title="Update Mobile Number" />
      <Stack paddingY={{ xs: "0", md: "8" }} alignItems="center">
        <Stack gap="8" maxWidth="xl" bgColor="white" rounded="md" padding="4">
          <Box display={{ xs: "none", md: "block" }}>
            <Heading as="h3" fontSize="xl">
              Change Mobile Number
            </Heading>
          </Box>
          <VerifyPhoneNumber>
            {({ verifyPhoneNumber }) => (
              <Formik
                initialValues={{
                  oldPhoneNumber: user.phoneNumber || "",
                  newPhoneNumber: "",
                }}
                validateOnBlur={false}
                validationSchema={Validator.object().shape({
                  oldPhoneNumber: Validator.string().required(),
                  newPhoneNumber: Validator.string()
                    .required("Please provide a valid mobile number")
                    .when(
                      "oldPhoneNumber",
                      (
                        oldPhoneNumber: string,
                        schema: Validator.StringSchema
                      ) => {
                        if (!oldPhoneNumber) return schema
                        return schema.test(
                          "different-then-old",
                          "This is your current mobile number. Please choose a different mobile number to update.",
                          (newPhoneNumber) => oldPhoneNumber !== newPhoneNumber
                        )
                      }
                    )
                    .test({
                      name: `valid-phone-number`,
                      test: (value: string | undefined) => {
                        const currentLength = value
                          ? String(value).trim().length
                          : 0
                        if (currentLength === 0 || !value) {
                          // required attribute should handle the empty case
                          return true
                        }
                        return isPossiblePhoneNumber(value)
                      },
                      message: function test() {
                        return `Please enter a valid mobile number`
                      },
                    }),
                })}
                onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
                  const userExistance = await checkIfPhonesExists([
                    values.newPhoneNumber,
                  ])
                  if (userExistance.data[0].isAppUser) {
                    trackEvent(
                      TrackingEvents.PHONE_CHANGE_ACCOUNT_EXISTS_ERROR,
                      {
                        phone: values.newPhoneNumber,
                      }
                    )
                    throw new Error(
                      `Mobile number ${values.newPhoneNumber} is already registered on ${config.appTitle}.`
                    )
                  }
                  trackEvent(TrackingEvents.PHONE_CHANGE_SUBMITTED, {
                    phone: values.newPhoneNumber,
                  })
                  const credentials = await verifyPhoneNumber(
                    values.newPhoneNumber
                  )
                  if (currentUser) {
                    try {
                      await updatePhoneNumber(currentUser, credentials)
                    } catch (e) {
                      const error = e as Error
                      throw resolveAuthErrors(error)
                    }
                  }
                  await updateProfile({
                    phoneNumber: values.newPhoneNumber,
                  })
                  toast.success(
                    `Mobile numbers updated to ${values.newPhoneNumber}.`
                  )
                  trackEvent(TrackingEvents.PHONE_CHANGED)
                  navigate("/profile")
                })}
              >
                {({ status, isSubmitting }) => (
                  <Form noValidate>
                    <Stack gap="4">
                      <Box maxWidth="xs">
                        <FormField
                          name="oldPhoneNumber"
                          label="Current Mobile Number"
                          readOnly
                          type="text"
                        />
                      </Box>
                      <Alert status="warning">
                        Changing mobile number will transfer all your data from
                        old to new number. OTPs will be sent to your new number.
                      </Alert>
                      <FormField
                        name="newPhoneNumber"
                        label={<>New Mobile number</>}
                        renderInput={({
                          field: { onChange, ...otherFieldProps },
                          form,
                        }: FieldProps<string>) => (
                          <Box maxWidth="xs">
                            <PhoneInput
                              {...otherFieldProps}
                              id="phoneNumber"
                              onChange={(phoneNumber) =>
                                form.setFieldValue(
                                  otherFieldProps.name,
                                  phoneNumber
                                )
                              }
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              defaultCountry={country as State<any>["country"]}
                              required
                              autoFocus
                            />
                          </Box>
                        )}
                      />
                      {status ? <Alert status="error">{status}</Alert> : null}
                      <ModalFooter>
                        <Button type="submit" disabled={isSubmitting}>
                          {isSubmitting ? (
                            "Validating..."
                          ) : (
                            <>
                              Update <ChevronDownIcon rotate="270" />
                            </>
                          )}
                        </Button>
                        <Link
                          to="/profile"
                          className={getButtonClassName({ level: "secondary" })}
                        >
                          Cancel
                        </Link>
                      </ModalFooter>
                    </Stack>
                  </Form>
                )}
              </Formik>
            )}
          </VerifyPhoneNumber>
        </Stack>
      </Stack>
    </>
  )
}
