import { Box } from "@cashbook/web-components"
import { useEffect } from "react"
import { useLogout } from "../Auth"

export default function LogoutPage() {
  const logout = useLogout()
  useEffect(() => {
    logout("fromThisDevice")
  }, [logout])
  return <Box>Logging out. Please Wait...</Box>
}
