import { useBusiness } from "@cashbook/data-store/businesses"
import { isPhoneNumberIndian } from "@cashbook/util-general"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  AddPhotoIcon,
  Alert,
  Box,
  BusinessBuildingIcon,
  Button,
  DataLoadingFallback,
  Heading,
  Image,
  InformationWarningIcon,
  Inline,
  PageMeta,
  PencilOutlinedIcon,
  Stack,
  Text,
  VerifiedIcon,
} from "@cashbook/web-components"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import { BusinessInfoKeys, getBusinessProfileStrength } from "../Business"
import ErrorBoundary from "../ErrorBoundary"
import { NotInWeb } from "../support"

export default function BusinessProfilePage() {
  const { businessId } = useParams()
  if (!businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading business details..." />}
        traceId="loading_book_details"
      >
        <BusinessProfile key={businessId} businessId={businessId} />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BusinessProfile({ businessId }: { businessId: string }) {
  const { business, businessOwner, authTeamMemberDetails } =
    useBusiness(businessId)
  const isIndianUser = isPhoneNumberIndian(businessOwner?.phoneNumber || "")
  const {
    percentage,
    totalFieldsCompleted,
    totalFieldsToComplete,
    sectionedBusinessProfile,
  } = getBusinessProfileStrength(business, isIndianUser)
  const [activeTab, setActiveTab] = useState<BusinessInfoKeys>("basics")
  const isIncomplete = percentage < 67
  const gstInfoRef = useRef<HTMLInputElement>(null)
  const basicInfoRef = useRef<HTMLInputElement>(null)
  const businessInfoRef = useRef<HTMLInputElement>(null)
  const communicationRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    trackEvent(TrackingEvents.BUSINESS_PROFILE_VIEWED, {
      role: authTeamMemberDetails.role.id,
      from: "addOrViewDetails",
      profileStrength: percentage,
    })
  }, [authTeamMemberDetails.role.id, percentage])

  const onTabClickHandler = (type: BusinessInfoKeys) => {
    setActiveTab(type)
    switch (type) {
      case "communication":
        if (communicationRef.current) {
          return communicationRef.current.scrollIntoView({ behavior: "smooth" })
        }
        break
      case "gstInfo":
        if (gstInfoRef.current) {
          return gstInfoRef.current.scrollIntoView({ behavior: "smooth" })
        }
        break
      case "businessInfo":
        if (businessInfoRef.current) {
          return businessInfoRef.current.scrollIntoView({ behavior: "smooth" })
        }
        break
      case "basics":
        if (basicInfoRef.current) {
          return basicInfoRef.current.scrollIntoView({ behavior: "smooth" })
        }
        break
      default:
        return
    }
  }

  return (
    <>
      <PageMeta>
        <title>Business Profile - {business.name}</title>
      </PageMeta>
      <Box
        key={businessId}
        bgColor="white"
        paddingY="6"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        <Stack maxWidth="xl" gap="8">
          <BusinessBriefBox
            logo={business.logo}
            name={business.name}
            percentage={percentage}
            isIncomplete={isIncomplete}
            totalFieldsCompleted={totalFieldsCompleted}
            totalFieldsToComplete={totalFieldsToComplete}
            isStaffMember={authTeamMemberDetails.role.id === "staff"}
          />
          <Stack gap="6">
            <Inline
              as="ul"
              alignItems="center"
              justifyContent="between"
              borderBottomWidth="1"
            >
              {sectionedBusinessProfile.length
                ? sectionedBusinessProfile.map((data, index) => (
                    <Box
                      as="li"
                      key={data.id}
                      cursor="pointer"
                      borderColor="blue900"
                      paddingBottom="3"
                      onClick={() => onTabClickHandler(data.id)}
                      borderBottomWidth={activeTab === data.id ? "2" : "0"}
                    >
                      <Text
                        fontWeight={
                          activeTab === data.id ? "semibold" : undefined
                        }
                        color={activeTab === data.id ? "blue900" : undefined}
                      >
                        {data.title}
                      </Text>
                    </Box>
                  ))
                : null}
            </Inline>
            <Stack gap="8">
              {sectionedBusinessProfile.length
                ? sectionedBusinessProfile.map((section) => (
                    <Stack
                      gap="6"
                      key={section.id}
                      ref={
                        section.id === "basics"
                          ? basicInfoRef
                          : section.id === "businessInfo"
                          ? businessInfoRef
                          : section.id === "gstInfo"
                          ? gstInfoRef
                          : communicationRef
                      }
                    >
                      <Box
                        backgroundColor="gray100"
                        paddingX="2"
                        paddingY="1"
                        rounded="md"
                      >
                        <Text
                          color="gray500"
                          fontSize="sm"
                          fontWeight="semibold"
                        >
                          {section.title}
                        </Text>
                      </Box>
                      {section.fields?.length
                        ? section.fields.map((field) => (
                            <Stack gap="1" key={field.id}>
                              <Text color="gray500" fontSize="sm">
                                {field.title}
                              </Text>
                              <Text
                                fontSize="md"
                                color={field.data ? undefined : "gray500"}
                              >
                                {field.data ? field.data : "-"}
                              </Text>
                            </Stack>
                          ))
                        : null}
                    </Stack>
                  ))
                : null}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

function BusinessBriefBox({
  logo,
  name,
  percentage,
  isIncomplete,
  isStaffMember,
  totalFieldsCompleted,
  totalFieldsToComplete,
}: {
  logo?: { image_url: string; thumb_url: string }
  name: string
  percentage: number
  isIncomplete: boolean
  isStaffMember: boolean
  totalFieldsCompleted: number
  totalFieldsToComplete: number
}) {
  return (
    <Box borderWidth="1" rounded="md" padding="6">
      <Stack gap="4">
        <Inline alignItems="center" justifyContent="between">
          <Inline alignItems="center" gap="4">
            <Box
              borderWidth="2"
              padding={logo?.image_url ? "0" : "1"}
              rounded="lg"
            >
              {logo ? (
                <Image
                  imageUrl={logo.image_url}
                  thumbUrl={logo.thumb_url}
                  size="8"
                  imageLabel="Business Logo"
                  isNonDownloadable
                />
              ) : isStaffMember ? (
                <BusinessBuildingIcon color="gray500" size="8" />
              ) : (
                <NotInWeb>
                  {({ onDisplay }) => (
                    <AddPhotoIcon
                      color="gray500"
                      size="8"
                      cursor="pointer"
                      onClick={onDisplay}
                    />
                  )}
                </NotInWeb>
              )}
            </Box>
            <Stack gap="1">
              <Heading fontSize="md">{name}</Heading>
              {!isStaffMember && (
                <Inline alignItems="center" gap="1">
                  <Box>
                    {isIncomplete ? (
                      <InformationWarningIcon size="4" color="red900" />
                    ) : (
                      <VerifiedIcon size="4" color="green500" />
                    )}
                  </Box>
                  <Text
                    color={isIncomplete ? "red900" : "green500"}
                    fontSize="sm"
                    className="pt-0.5"
                  >
                    {isIncomplete
                      ? `Incomplete business profile`
                      : "Business Profile"}
                  </Text>
                </Inline>
              )}
            </Stack>
          </Inline>
          {!isStaffMember && (
            <NotInWeb>
              {({ onDisplay }) => (
                <Button inline onClick={onDisplay}>
                  <Box>
                    <PencilOutlinedIcon color="blue900" />
                  </Box>
                </Button>
              )}
            </NotInWeb>
          )}
        </Inline>
        {!isStaffMember && totalFieldsToComplete !== totalFieldsCompleted && (
          <Stack gap="2">
            <Inline
              fontWeight="semibold"
              fontSize="sm"
              alignItems="center"
              justifyContent="between"
            >
              <Inline gap="1" alignItems="center">
                <Text>Profile Strength:</Text>
                <Text
                  color={
                    percentage <= 33
                      ? "red900"
                      : percentage > 33 && percentage <= 67
                      ? "yellow600"
                      : "green500"
                  }
                >
                  {percentage <= 33
                    ? "Low"
                    : percentage > 33 && percentage <= 67
                    ? "Medium"
                    : "High"}
                </Text>
              </Inline>
              <Text
                color={
                  percentage <= 33
                    ? "red900"
                    : percentage > 33 && percentage <= 67
                    ? "yellow600"
                    : "green500"
                }
              >
                {percentage}%
              </Text>
            </Inline>
            <Box
              width="full"
              height="2"
              rounded="full"
              backgroundColor="gray100"
            >
              <Box
                height="2"
                rounded="full"
                bgColor={
                  percentage <= 33
                    ? "red900"
                    : percentage > 33 && percentage <= 67
                    ? "yellow600"
                    : "green500"
                }
                style={{ width: `${percentage}%` }}
              />
            </Box>
          </Stack>
        )}
        {!isStaffMember && totalFieldsToComplete !== totalFieldsCompleted && (
          <Alert status="info" marginBottom="0">
            <Text fontSize="sm">
              {totalFieldsToComplete - totalFieldsCompleted} out of{" "}
              {totalFieldsToComplete} fields are incomplete. Fill these to
              complete your profile
            </Text>
          </Alert>
        )}
      </Stack>
    </Box>
  )
}
