import { setLoggerIdentity } from "@cashbook/util-logging"
import {
  setAnanlyticsIdentity,
  setAnanlyticsIdentityProperties,
  trackEvent,
  TrackingEvents,
} from "@cashbook/util-tracking"
import {
  useCallback,
  useEffect,
  useMemo,
  Fragment,
  useRef,
  useState,
  useReducer,
} from "react"
import {
  useFirestore,
  useFirestoreDocData,
  useUser,
  useFunctions,
  useSigninCheck,
  useAuth,
} from "reactfire"
import { $Values, Optional } from "utility-types"
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore"
import type { CollectionReference } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import {
  getAuth,
  updateEmail,
  updateProfile,
  signInWithCustomToken,
  AuthErrorCodes,
  GoogleAuthProvider,
  AuthError,
  linkWithPopup,
  unlink,
} from "firebase/auth"
import { useAsyncFetchWithStatus } from "@cashbook/util-general"
import {
  TBusiness,
  CreateBusinessPayload,
} from "@cashbook/data-store/businesses"
import { TBook } from "@cashbook/data-store/books"

export const config = {
  cloudFunctionsUSRegion: "us-central1" as string,
}

export function configure(values: typeof config) {
  config.cloudFunctionsUSRegion = values.cloudFunctionsUSRegion
}

export type TUser = {
  displayName?: string
  email?: string | null
  emailVerified?: boolean
  isAnonymous: boolean
  phoneNumber?: string | null
  photoURL?: string | null
  providerId: "firebase"
  uid: string
  fcmTokens?: Array<string>
  desktopNotificationsAllowed?: boolean
  signupViaPlatform: "web" | "android" | "ios"
  metadata: {
    creationTime?: string
    lastSignInTime?: string
  }
  revokedAt?: number | null
}
function useUserCollection() {
  const store = useFirestore()
  return collection(store, "Users") as CollectionReference<TUser>
}

function useUserDocument(userId: string) {
  const usersCollection = useUserCollection()
  return doc(usersCollection, userId)
}

export function useCreateProfile() {
  const { data: authUser } = useUser()
  const userDoc = useUserDocument(authUser?.uid || "missing")
  return async function create(data: Optional<TUser>) {
    if (!authUser) throw new Error("Please login to create profile")
    if (authUser) {
      // update the auth profile
      updateProfile(authUser, {
        displayName: data.displayName,
      }).then(() => {
        if (data.email) {
          updateEmail(authUser, data.email)
        }
      })
    }
    await setDoc(userDoc, {
      email: authUser.email,
      emailVerified: authUser.emailVerified,
      isAnonymous: authUser.isAnonymous,
      metadata: {
        creationTime: authUser.metadata.creationTime,
        lastSignInTime: authUser.metadata.lastSignInTime,
      },
      phoneNumber: authUser.phoneNumber,
      photoURL: authUser.photoURL,
      providerId: "firebase",
      uid: authUser.uid,
      fcmTokens: [],
      signupViaPlatform: "web",
      ...data,
    })
  }
}

export function useUpdateProfile() {
  const { data: authUser } = useUser()
  const userDoc = useUserDocument(authUser?.uid || "missing")
  return useCallback(
    async function update(
      data: Omit<Optional<TUser>, "fcmTokens"> & {
        fcmTokens?: Array<string>
      }
    ) {
      if (!authUser) throw new Error("Please login to update profile")
      if (data.displayName) {
        // update the auth profile
        updateProfile(authUser, {
          displayName: data.displayName,
        })
      }
      await updateDoc(userDoc, data)
      reloadUserAuth()
    },
    [userDoc, authUser]
  )
}

export function useNotificationSubscriptionPreference() {
  const { user, update } = useProfile()
  const { uid, desktopNotificationsAllowed, fcmTokens } = user
  const existingTokens = useMemo(() => fcmTokens || [], [fcmTokens])
  const subscribe = useCallback(
    async (fCMToken: string) => {
      if (
        !desktopNotificationsAllowed ||
        existingTokens.indexOf(fCMToken) === -1
      ) {
        await update({
          desktopNotificationsAllowed: true,
          fcmTokens: arrayUnion(fCMToken) as unknown as Array<string>,
        })
        setAnanlyticsIdentityProperties(uid, {
          desktopNotificationsAllowed: true,
        })
      }
    },
    [update, existingTokens, desktopNotificationsAllowed, uid]
  )
  const unsubscribe = useCallback(
    async (fCMToken?: string) => {
      if (
        desktopNotificationsAllowed ||
        existingTokens.indexOf(fCMToken || "") !== -1
      ) {
        await update({
          desktopNotificationsAllowed: false,
          fcmTokens: arrayRemove(fCMToken || "") as unknown as Array<string>,
        })
        setAnanlyticsIdentityProperties(uid, {
          desktopNotificationsAllowed: false,
        })
      }
    },
    [update, desktopNotificationsAllowed, existingTokens, uid]
  )
  return {
    hasSubscribed: Boolean(desktopNotificationsAllowed),
    existingTokens,
    subscribe,
    unsubscribe,
  }
}

export function useProfile() {
  const { data: authUser } = useUser()
  const userDoc = useUserDocument(authUser?.uid || "missing")
  const { data: user } = useFirestoreDocData<TUser>(userDoc, {
    idField: "uid",
  })
  const update = useUpdateProfile()
  const { uid, displayName, phoneNumber, email } = user
  useEffect(() => {
    setAnanlyticsIdentity({ uid, displayName, email, phoneNumber })
  }, [uid, displayName, phoneNumber, email])
  useEffect(() => {
    setLoggerIdentity({ uid, displayName })
  }, [uid, displayName])
  return {
    user,
    update,
  }
}

export function useHasCompletedProfile(): "loading" | false | TUser {
  const { status, data: signinCheckResults } = useSigninCheck()
  const user = signinCheckResults.user
  const userDoc = useUserDocument(user?.uid || "missing")
  const { data: profile } = useFirestoreDocData(userDoc, {
    idField: "uid",
    // suspense: false,
  })
  if (status === "loading") {
    return "loading"
  }
  // ensure that we have user document in store as well
  if (!user || !user.displayName || !profile || !profile.displayName) {
    return false
  }
  return profile
}

export function useCheckUsersExists() {
  const fns = useFunctions()
  const checkIfPhonesExists = useCallback(
    async (
      phoneNumbers: string | Array<string>
    ): Promise<{
      data: Array<{
        phone: string
        name?: string
        isAppUser: boolean
        uid?: string
      }>
    }> => {
      if (typeof phoneNumbers === "string") {
        phoneNumbers = [phoneNumbers]
      }
      return httpsCallable(
        fns,
        "checkUsersExist"
      )({
        phoneNumbers,
      }) as never
    },
    [fns]
  )

  const checkIfEmailsExists = useCallback(
    async (
      emails: string | Array<string>
    ): Promise<{
      data: Array<{ email: string; name: string; isAppUser: boolean }>
    }> => {
      if (typeof emails === "string") {
        emails = [emails]
      }
      return httpsCallable(
        fns,
        "checkUsersExistByEmail"
      )({
        emails,
      }) as never
    },
    [fns]
  )

  return {
    checkIfEmailsExists,
    checkIfPhonesExists,
  }
}

function useOnMount(fn: () => void) {
  const isFunctionCalled = useRef(false)
  useEffect(() => {
    if (!isFunctionCalled.current) {
      isFunctionCalled.current = true
      fn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function IfProfileCompleted({
  children,
}: {
  children?: React.ReactNode
}) {
  const hasCompleted = useHasCompletedProfile()
  if (hasCompleted === "loading" || hasCompleted === false) {
    return null
  }
  return <Fragment>{children}</Fragment>
}

export function useVerifyEmailToken(token?: string | null) {
  const fns = useFunctions()
  if (!token) {
    throw new Error("Missing verification token")
  }
  const fetcher = useCallback(async () => {
    const { data } = await httpsCallable<{ token: string }, { status: string }>(
      fns,
      "confirmEmailVerificationToken"
    )({
      token,
    })
    return data
  }, [fns, token])
  const { error, status, fetchData } = useAsyncFetchWithStatus(fetcher)
  useOnMount(() => {
    fetchData()
  })
  return {
    error,
    status,
  }
}

type ResendOTPState = {
  secondsRemainingToResend: number
  attempts: number
  sendingVerificationCode: boolean
}

export function useVerifyEmail() {
  const auth = useAuth()
  const fns = useFunctions()

  const [
    { attempts, secondsRemainingToResend, sendingVerificationCode },
    dispatch,
  ] = useReducer(
    (state: ResendOTPState, action: Optional<ResendOTPState>) => ({
      ...state,
      ...action,
    }),
    {
      secondsRemainingToResend: 0,
      attempts: 0,
      sendingVerificationCode: false,
    }
  )

  const sendVerificationOtp = useCallback(
    async (email: string, config?: { secondsRemainingToResend?: number }) => {
      try {
        dispatch({
          attempts: attempts + 1,
          secondsRemainingToResend: config?.secondsRemainingToResend || 30,
          sendingVerificationCode: true,
        })
        const {
          data: { status, message },
        } = await httpsCallable<
          { email: string },
          { status: string; message: string }
        >(
          fns,
          "sendEmailVerificationOtp"
        )({ email })
        if (status !== "SUCCESS") {
          throw new Error(message)
        }
      } catch (e) {
        const error = e as Error
        throw error
      } finally {
        dispatch({
          sendingVerificationCode: false,
        })
      }
    },
    [attempts, fns]
  )

  const verifyEmailWithOtp = useCallback(
    async (email: string, otp: string) => {
      if (!auth.currentUser)
        throw new Error("Please login before performing this action.")
      try {
        const {
          data: {
            auth: { token },
            message,
          },
        } = await httpsCallable<
          { email: string; otp: string },
          { status: string; message: string; auth: { token: string } }
        >(
          fns,
          "confirmEmailVerificationOtp"
        )({ email, otp })
        if (!token) {
          throw new Error(message)
        }
        await signInWithCustomToken(auth, token)
        const isSignedInWithGoogle = auth.currentUser?.providerData.some(
          (provider) => provider.providerId === "google.com"
        )
        if (isSignedInWithGoogle) {
          unlink(auth.currentUser, "google.com")
          await httpsCallable(fns, "newSocialAccountLinked")({})
        }
        trackEvent(TrackingEvents.MEMBER_EMAIL_VERIFICATION_COMPLETED, {
          verificationMethod: "email",
        })
        reloadUserAuth()
        return true
      } catch (e) {
        const error = e as Error
        throw error
      }
    },
    [fns, auth]
  )

  const resendVerificationOtp = useCallback(
    async (email: string) => {
      const data = await sendVerificationOtp(email, {
        secondsRemainingToResend: 30 * (attempts || 1),
      })
      return data
    },
    [attempts, sendVerificationOtp]
  )

  const secondsRemainingToResendTimerRef = useRef<NodeJS.Timer | undefined>(
    undefined
  )
  useEffect(() => {
    if (secondsRemainingToResendTimerRef.current) {
      clearInterval(secondsRemainingToResendTimerRef.current)
      secondsRemainingToResendTimerRef.current = undefined
    }
    if (secondsRemainingToResend <= 0) {
      return
    }
    secondsRemainingToResendTimerRef.current = setInterval(() => {
      const n = secondsRemainingToResend - 1
      dispatch({
        secondsRemainingToResend: n,
      })
      if (n <= 0 && secondsRemainingToResendTimerRef.current)
        clearInterval(secondsRemainingToResendTimerRef.current)
    }, 1000)
    return () =>
      secondsRemainingToResendTimerRef.current
        ? clearInterval(secondsRemainingToResendTimerRef.current)
        : undefined
  }, [secondsRemainingToResend])

  return {
    sendingVerificationCode,
    secondsRemainingToResend,
    verifyEmailWithOtp,
    sendVerificationOtp,
    resendVerificationOtp,
  }
}

export function resolveSocialAuthErrors(e: unknown) {
  const error = e as AuthError
  const errorCode = error.code as $Values<typeof AuthErrorCodes>
  switch (errorCode) {
    case AuthErrorCodes.POPUP_CLOSED_BY_USER:
      return new Error("Please select a valid account!")
    case AuthErrorCodes.PROVIDER_ALREADY_LINKED:
    case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
    case AuthErrorCodes.EMAIL_EXISTS:
      return new Error("This email is already taken by other account!")
    default:
      if (errorCode) {
        return new Error(
          `[${errorCode}]: Can not verify. Please try after some time`
        )
      }
      return new Error(error.message)
  }
}

export function useVerifyWithSocials() {
  const auth = useAuth()
  const fns = useFunctions()

  const [loading, setLoading] = useState<boolean>(false)

  const verifyEmailWithGoogle = useCallback(async (): Promise<
    string | null
  > => {
    if (!auth.currentUser)
      throw new Error("Please login before to perform this action.")
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ prompt: "select_account" })
    setLoading(true)
    try {
      await linkWithPopup(auth.currentUser, provider)
      await httpsCallable(fns, "newSocialAccountLinked")({})
      setLoading(false)
      trackEvent(TrackingEvents.MEMBER_EMAIL_VERIFICATION_COMPLETED, {
        verificationMethod: "google",
      })
      reloadUserAuth()
      return auth.currentUser.email
    } catch (e) {
      setLoading(false)
      const error = resolveSocialAuthErrors(e)
      throw error
    }
  }, [auth, fns])

  return {
    loading,
    verifyEmailWithGoogle,
  }
}

export type CreateUserWithBusinessPayload = {
  displayName: string
  business?: CreateBusinessPayload
  dontCreateDefaultBusiness?: boolean
}
export function useRegisterUserWithBusiness() {
  const { data: authUser } = useUser()
  const fns = useFunctions()
  return useCallback(
    async function createUser(values: CreateUserWithBusinessPayload) {
      if (!authUser) throw new Error("Please login to create profile")
      const userDoc = {
        displayName: values.displayName,
        email: authUser.email,
        emailVerified: authUser.emailVerified,
        isAnonymous: authUser.isAnonymous,
        metadata: {
          creationTime: authUser.metadata.creationTime,
          lastSignInTime: authUser.metadata.lastSignInTime,
        },
        phoneNumber: authUser.phoneNumber,
        photoURL: authUser.photoURL,
        providerId: "firebase",
        uid: authUser.uid,
        fcmTokens: [],
        signupViaPlatform: "web",
      }
      if (values.business) {
        trackEvent(TrackingEvents.NEW_USER_SIGN_UP, {
          isBusinessNameAdded: Boolean(values.business.name),
          isBusinessCategoryAdded: Boolean(
            values.business.details?.category?.id
          ),
          isBusinessTypeAdded: Boolean(values.business.details?.type),
        })
      }
      const {
        data: { user, business, book },
      } = await httpsCallable<
        {
          user: typeof userDoc
          business?: CreateBusinessPayload
          dontCreateDefaultBusiness?: boolean
        },
        { user: TUser; business: TBusiness; book: TBook }
      >(
        fns,
        "registerUser"
      )({
        user: { ...userDoc },
        business: values.business,
        dontCreateDefaultBusiness: values.dontCreateDefaultBusiness,
      })
      if (!user.uid) {
        throw new Error(
          "Can not create business at the moment. Please try after some time"
        )
      }
      return { user, business, book }
    },
    [authUser, fns]
  )
}

export async function reloadUserAuth() {
  const authUser = getAuth().currentUser
  await authUser?.reload()
}
