import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"
import {
  DataLoadingFallback,
  DotsVerticalIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  PageMeta,
  Text,
  Heading,
  Box,
  useOverlayTriggerState,
  UserAddIcon,
  Stack,
  Inline,
  ModalFooter,
  parsePhoneNumber,
  Button,
  ArrowRightIcon,
} from "@cashbook/web-components"
import React, { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { SuspenseWithPerf } from "reactfire"
import {
  ShareInvitationActions,
  EditMemberRoleInDialog,
  PendingInvitationDetails,
  RemoveMemberFromBookInDialog,
  MemberAvatar,
  RoleDetails,
  AllRolesAndPermissionsInModal,
} from "../Books"
import {
  BOOK_PERMISSIONS,
  useBook,
  TBookMember,
  getRoleDetails,
  T_AVAILABLE_ROLES,
} from "@cashbook/data-store/books"
import { useProfile } from "@cashbook/data-store/users"
import ErrorBoundary from "../ErrorBoundary"
import { AddMemberToBookInDialog } from "../Books/AddMember"
import {
  TBusinessInvitation,
  useBusiness,
  useShareBusinessInvitations,
} from "@cashbook/data-store/businesses"

export default function BookMemberSettingsPage() {
  const { bookId, businessId } = useParams()
  if (!bookId || !businessId) return null
  return (
    <ErrorBoundary>
      <SuspenseWithPerf
        fallback={<DataLoadingFallback label="Loading book details..." />}
        traceId="loading_book_details"
      >
        <BookMemberSettings
          key={bookId}
          bookId={bookId}
          businessId={businessId}
        />
      </SuspenseWithPerf>
    </ErrorBoundary>
  )
}

function BookMemberSettings({
  bookId,
  businessId,
}: {
  bookId: string
  businessId: string
}) {
  const { book, members, authMemberDetails, checkIfAuthenticatedMemberCan } =
    useBook(bookId)
  const { business } = useBusiness(businessId)
  const role = authMemberDetails.role.id
  const { invitations } = useShareBusinessInvitations(businessId)
  const { user } = useProfile()
  const isBookShared = book.sharedWith.length > 1
  const commonMemberProps = {
    bookId,
  }
  useEffect(() => {
    if (book.name) {
      trackEvent(TrackingEvents.MEMBERS_SCREEN_VIEWED, {
        from: "bookSettingsIcon",
        role: role,
        bookType: isBookShared ? "shared" : "personal",
      })
    }
  }, [book.name, role, isBookShared])

  // The book has been deleted (but sub-collections are not!!)
  // https://firebase.google.com/docs/firestore/manage-data/delete-data#delete_documents

  const canAddMember = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.ADD_MEMBER
  )

  const canUpdatedOrResendInvitation = checkIfAuthenticatedMemberCan(
    BOOK_PERMISSIONS.UPDATE_OR_RESEND_INVITE
  )

  function getUpdateRolePermission(memberRole: T_AVAILABLE_ROLES) {
    if (memberRole === "admin") {
      return checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.UPDATE_ADMIN_ROLE)
    } else {
      return checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.UPDATE_MEMBER_ROLES)
    }
  }

  function geRemoveMemberPermission(memberRole: T_AVAILABLE_ROLES) {
    if (memberRole === "admin") {
      return checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.REMOVE_ADMIN_MEMBER)
    } else {
      return checkIfAuthenticatedMemberCan(BOOK_PERMISSIONS.REMOVE_MEMBER)
    }
  }

  const filteredInvites = useMemo(() => {
    return invitations.filter((invitation) => {
      if (invitation.role === "partner") {
        return { ...invitation }
      }
      const checkIfBookInvitationExists =
        invitation.sharedBooks?.findIndex(
          (sharedBook) => sharedBook.id === bookId
        ) !== -1
      if (checkIfBookInvitationExists) {
        return { ...invitation }
      }
      return false
    })
  }, [bookId, invitations])

  if (!book.name) return null
  return (
    <>
      <PageMeta>
        <title>Members - {book.name}</title>
      </PageMeta>
      <Stack
        key={bookId}
        bgColor="white"
        gap="6"
        paddingX={{ xs: "4", md: "8" }}
        minHeight={{ xs: "screen", sm: "0" }}
      >
        <Stack maxWidth="xl" paddingY="6" gap="6">
          {canAddMember ? (
            <Box padding="6" rounded="md" borderWidth="1" borderColor="gray100">
              <Inline
                alignItems={{ xs: "start", sm: "center" }}
                gap="8"
                collapseBelow="md"
              >
                <Stack gap="2" minWidth="min">
                  <Heading as="h3" fontSize="md" fontWeight="semibold">
                    Add Members
                  </Heading>
                  <Text fontWeight="medium" fontSize="sm" color="gray500">
                    Manage your cashflow together with your business partners,
                    family or friends by adding them as members
                  </Text>
                </Stack>
                <AddMemberToBookInDialog
                  bookId={book.id}
                  businessId={business.id}
                  businessName={business.name}
                >
                  {({ add }) => (
                    <Box className="w-full md:w-[80%]">
                      <Button onClick={add} fullWidth level="primary">
                        <Box>
                          <UserAddIcon />
                        </Box>
                        Add member
                      </Button>
                    </Box>
                  )}
                </AddMemberToBookInDialog>
              </Inline>
            </Box>
          ) : null}
          <Inline justifyContent="between" alignItems="center">
            <Heading as="h2" fontSize="md">
              Total Members (
              {members.length + (canAddMember ? filteredInvites.length : 0)})
            </Heading>
            <AllRolesAndPermissionsInModal
              userRole={authMemberDetails.role.id}
              hideNetBalance={book.preferences?.hideBalancesAndReports}
              hideEntriesByOthers={book.preferences?.hideEntriesByOthers}
            >
              {({ view }) => (
                <Button level="tertiary" type="button" onClick={view} inline>
                  <Heading as="h3">View roles & permissions</Heading>
                  <ArrowRightIcon />
                </Button>
              )}
            </AllRolesAndPermissionsInModal>
          </Inline>
          {filteredInvites.length > 0 && canAddMember ? (
            <Stack>
              <Heading as="h3" color="gray500" className="pb-2">
                Pending Invitations
              </Heading>
              <Stack as="ol">
                {filteredInvites.map((invitation, index) => (
                  <Box
                    as="li"
                    key={invitation.id}
                    paddingY="4"
                    borderTopWidth={index === 0 ? "0" : "1"}
                  >
                    <PendingInvitation
                      updateOrResendInvite={canUpdatedOrResendInvitation}
                      {...commonMemberProps}
                      invitation={invitation}
                    />
                  </Box>
                ))}
              </Stack>
            </Stack>
          ) : null}
          <Stack gap="10">
            {members.length > 0 ? (
              <Stack gap="6">
                <Heading as="h3" color="gray500">
                  Members in this book
                </Heading>
                <Stack as="ol" gap="4">
                  {members.map((u, index) => {
                    const isAuthMember = user.uid === u.uid
                    const canUpdateRole =
                      getUpdateRolePermission(u.role.id) && !isAuthMember
                    return (
                      <Box
                        as="li"
                        key={u.id}
                        paddingBottom="4"
                        borderBottomWidth={
                          index === members.length - 1 ? "0" : "1"
                        }
                      >
                        <Member
                          bookId={bookId}
                          member={u}
                          isAuthMember={isAuthMember}
                          canRemoveMember={
                            !isAuthMember && geRemoveMemberPermission(u.role.id)
                          }
                          canUpdateRole={canUpdateRole}
                        />
                      </Box>
                    )
                  })}
                </Stack>
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

function Member({
  bookId,
  member,
  isAuthMember,
  canUpdateRole,
  canRemoveMember,
}: {
  bookId: string
  member: TBookMember
  isAuthMember: boolean
  canUpdateRole?: boolean
  canRemoveMember?: boolean
}) {
  const { name, phoneNumber, email, id, role } = member
  return (
    <Inline alignItems="center" gap="4">
      <MemberAvatar id={id} name={name || email || phoneNumber} />
      <Stack flex="1" minWidth="0" gap="2">
        <Heading as="h5" fontSize="md" fontWeight="semibold">
          {isAuthMember ? "You" : name}
        </Heading>
        {phoneNumber ? (
          <Text fontWeight="medium" color="gray500">
            {phoneNumber}
          </Text>
        ) : null}
        {email ? (
          <Text fontWeight="medium" color="gray500">
            {email}
          </Text>
        ) : null}
      </Stack>
      <RoleDetails role={role} />
      {(canUpdateRole || canRemoveMember) &&
      role.id !== "owner" &&
      role.id !== "partner" ? (
        <Box>
          <EditMemberRoleInDialog bookId={bookId} member={member}>
            {({ onEdit: onEditRole }) => (
              <RemoveMemberFromBookInDialog
                bookId={bookId}
                member={{ ...member, userId: id }}
              >
                {({ onRemove }) => (
                  <Menu>
                    <MenuButton inline>
                      <DotsVerticalIcon size="4" />
                    </MenuButton>
                    <MenuList align="bottom-right" className="whitespace-pre">
                      {canUpdateRole ? (
                        <MenuItem action="promote-admin" onClick={onEditRole}>
                          Change Role
                        </MenuItem>
                      ) : null}
                      {canRemoveMember ? (
                        <MenuItem action="remove-member" onClick={onRemove}>
                          Remove
                        </MenuItem>
                      ) : null}
                    </MenuList>
                  </Menu>
                )}
              </RemoveMemberFromBookInDialog>
            )}
          </EditMemberRoleInDialog>
        </Box>
      ) : (
        <Box width="4">&nbsp;</Box>
      )}
    </Inline>
  )
}

function PendingInvitation({
  bookId,
  invitation,
  updateOrResendInvite,
}: {
  bookId: string
  invitation: TBusinessInvitation
  updateOrResendInvite: boolean
}) {
  const { id, guestPhone, guestEmail } = invitation
  const sharedBook = invitation.sharedBooks?.find(
    (sharedBook) => sharedBook.id === bookId
  )
  const invitationRole = invitation.role as T_AVAILABLE_ROLES
  const role = getRoleDetails(sharedBook?.role || invitationRole || "editor")
  const phoneNumber = parsePhoneNumber(guestPhone || "")
  const member = useMemo(() => {
    return {
      id: id,
      name: guestEmail || guestPhone,
      email: guestEmail,
      phoneNumber: guestPhone,
      role,
    }
  }, [guestPhone, id, role, guestEmail])
  const number = phoneNumber?.nationalNumber
  return (
    <Inline alignItems="center" gap="4">
      <MemberAvatar
        id={id}
        name={member?.email || number?.toString() || "CB"}
      />
      <Stack flex="1" minWidth="0" gap="2">
        <Heading as="h5" fontSize="md" fontWeight="medium">
          {member?.email || number}
        </Heading>
        <Text fontWeight="medium" color="gray500">
          {member?.email || member?.phoneNumber}
        </Text>
        {updateOrResendInvite ? (
          <ShowInvitationDetailsInDialog
            bookId={bookId}
            invitation={invitation}
          >
            {({ show }) => (
              <Button inline align="left" onClick={show}>
                Resend Invitation
              </Button>
            )}
          </ShowInvitationDetailsInDialog>
        ) : null}
      </Stack>
      <RoleDetails role={role} />
      {updateOrResendInvite && role.id !== "owner" && role.id !== "partner" ? (
        <Box>
          <EditMemberRoleInDialog bookId={bookId} member={{ ...member }}>
            {({ onEdit: onEditRole }) => (
              <RemoveMemberFromBookInDialog
                bookId={bookId}
                member={{ ...member, invitationId: id }}
              >
                {({ onRemove }) => (
                  <Menu>
                    <MenuButton inline>
                      <DotsVerticalIcon size="4" />
                    </MenuButton>
                    <MenuList align="bottom-right" className="whitespace-pre">
                      <MenuItem action="promote-admin" onClick={onEditRole}>
                        Change Role
                      </MenuItem>
                      <MenuItem action="remove-member" onClick={onRemove}>
                        Remove
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </RemoveMemberFromBookInDialog>
            )}
          </EditMemberRoleInDialog>
        </Box>
      ) : (
        <Box width="4">&nbsp;</Box>
      )}
    </Inline>
  )
}

function ShowInvitationDetailsInDialog({
  bookId,
  invitation,
  children,
}: {
  bookId: string
  invitation: TBusinessInvitation
  children: (props: { show: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({ defaultOpen: false })
  const { book } = useBook(bookId)
  return (
    <>
      {children({ show: state.open })}
      <Modal
        isOpen={state.isOpen}
        onClose={state.close}
        title="Share Invitation"
        placement="right"
        isDismissable
      >
        <ModalBody>
          <PendingInvitationDetails book={book} invitation={invitation} />
        </ModalBody>
        <ModalFooter>
          <ShareInvitationActions
            bookName={book.name}
            invitation={invitation}
          />
        </ModalFooter>
      </Modal>
    </>
  )
}
