import { EntryAttachmentInfo } from "@cashbook/data-store/books"
import { saveBlobAs } from "@cashbook/util-general"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import {
  Box,
  Image,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  PDFOutlineIcon,
  Stack,
  Text,
  Button,
  useOverlayTriggerState,
  CancelFilledIcon,
  DocumentDownloadIcon,
} from "@cashbook/web-components"
import classNames from "classnames"
import { useCallback, useMemo, useState } from "react"
import { toast } from "react-hot-toast"

export function BillImage(props: React.ComponentProps<typeof Image>) {
  return (
    <Image
      {...props}
      imageLabel={"Attachment Preview"}
      downloadLabel="Download Attachment"
      alt="Bill"
      onPreview={() => {
        trackEvent(TrackingEvents.BILL_IMAGE_VIEWED, {
          duringAttachment: false,
          type: "image",
        })
      }}
      onDownload={() => {
        trackEvent(TrackingEvents.BILL_IMAGE_DOWNLOAD_CLICKED)
      }}
    />
  )
}

export function BillImages({
  children,
  attachments,
}: {
  children?: (props: {
    open: (attachmentIndex: number) => void
  }) => React.ReactNode
  attachments: EntryAttachmentInfo[]
}) {
  const imagePreviewState = useOverlayTriggerState({})
  const {
    downloading,
    downloadingAll,
    downloadAttachment,
    downloadAllAttachments,
  } = useDownloadAttachments()

  const [attachment, setAttachment] = useState<number>(0)

  const entryAttachments = useMemo(() => {
    return attachments
  }, [attachments])

  function onClose() {
    setAttachment(0)
    imagePreviewState.close()
  }

  async function download(type: "current" | "all") {
    if (type === "all") {
      await downloadAllAttachments(
        entryAttachments.map((attachmentItem) => {
          return {
            url: attachmentItem.url,
            fileName: attachmentItem.fileName,
          }
        })
      )
    } else {
      const currentAttachment = {
        url: entryAttachments[attachment].url,
        fileName: entryAttachments[attachment].fileName,
      }
      await downloadAttachment(currentAttachment)
    }
  }

  return (
    <>
      {children ? (
        children({
          open: (attachmentIndex: number) => {
            setAttachment(attachmentIndex)
            trackEvent(TrackingEvents.BILL_IMAGE_VIEWED, {
              duringAttachment: false,
              type: entryAttachments[attachmentIndex]?.mimeType.includes("pdf")
                ? "pdf"
                : "image",
            })
            imagePreviewState.open()
          },
        })
      ) : (
        <Box
          size="8"
          borderWidth="1"
          borderColor="borderOutline"
          as="button"
          display="flex"
          alignItems="center"
          justifyContent="center"
          rounded="md"
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation()
            trackEvent(TrackingEvents.BILL_IMAGE_VIEWED, {
              duringAttachment: false,
              type: entryAttachments[attachment]?.mimeType.includes("pdf")
                ? "pdf"
                : "image",
            })
            imagePreviewState.open()
          }}
        >
          {entryAttachments[attachment]?.mimeType.includes("pdf") ? (
            <PDFOutlineIcon size="6" color="iconPrimary" />
          ) : (
            <img
              src={entryAttachments[attachment]?.thumbUrl}
              className="w-8 h-[30px] rounded"
              alt={entryAttachments[attachment]?.fileName}
            />
          )}
          {entryAttachments.length > 1 ? (
            <Box
              backgroundColor="surfaceDefault"
              rounded="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              size="5"
              className="shadow-[0_4px_12px_0px_rgba(0,0,0,0.16)] z-[0]"
            >
              <Text fontSize="c2">{entryAttachments.length}</Text>
            </Box>
          ) : null}
        </Box>
      )}
      <Modal
        title="Attachment Preview"
        isDismissable
        onClose={onClose}
        isOpen={imagePreviewState.isOpen}
      >
        <ModalBody>
          <Stack gap="4">
            <Text fontSize="c3">{entryAttachments[attachment]?.fileName}</Text>
            <Stack>
              <Box paddingBottom="12">
                {entryAttachments[attachment]?.mimeType.includes("pdf") ? (
                  <iframe
                    title={entryAttachments[attachment].fileName}
                    src={entryAttachments[attachment].url}
                    width="100%"
                    height="600px"
                  ></iframe>
                ) : (
                  <img
                    src={entryAttachments[attachment]?.url}
                    alt="Preview"
                    className="w-full h-full max-w-full"
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter position="relative">
          {entryAttachments.length > 1 ? (
            <Inline
              as="ul"
              position="absolute"
              gap="4"
              width="full"
              alignItems="center"
              backgroundColor="surfaceNeutralLowest"
              className="bottom-[100%]"
              left="0"
              paddingY="2"
              paddingX="8"
            >
              {entryAttachments.map((attachmentItem, i) => {
                const isSelected = attachment === i
                const isPdf = attachmentItem.mimeType.includes("pdf")
                return (
                  <Box
                    as="li"
                    size="10"
                    key={attachmentItem.id}
                    borderWidth={isSelected ? "2" : "1"}
                    borderColor={isSelected ? "borderPrimary" : "borderOutline"}
                    onClick={() => {
                      setAttachment(i)
                      trackEvent(TrackingEvents.BILL_IMAGE_VIEWED, {
                        duringAttachment: false,
                        type: entryAttachments[i]?.mimeType.includes("pdf")
                          ? "pdf"
                          : "image",
                      })
                    }}
                    cursor="pointer"
                    rounded="md"
                    backgroundColor="surfaceDefault"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    className={classNames({
                      "hover:bg-blue-100": !isSelected && isPdf,
                    })}
                  >
                    <Box
                      className={classNames("opacity-0", {
                        "hover:opacity-100": !isSelected && !isPdf,
                      })}
                    >
                      <Box
                        position="absolute"
                        width="full"
                        height="full"
                        rounded="md"
                        top="0"
                        left="0"
                        style={{
                          background: `linear-gradient(0deg, rgba(33, 33, 33, 0.08) 0%, rgba(33, 33, 33, 0.08) 100%)`,
                        }}
                      ></Box>
                    </Box>
                    {isPdf ? (
                      <PDFOutlineIcon color="iconPrimary" size="8" />
                    ) : (
                      <img
                        className="w-[38px] h-9 object-cover rounded"
                        src={attachmentItem.url}
                        alt={attachmentItem.fileName}
                      />
                    )}
                  </Box>
                )
              })}
              <Box flex="1">
                <Box display="flex" justifyContent="end">
                  <Button
                    inline
                    onClick={() => download("all")}
                    disabled={downloading || downloadingAll}
                  >
                    Download All
                  </Button>
                </Box>
              </Box>
            </Inline>
          ) : null}
          <Button
            onClick={() => download("current")}
            disabled={downloading || downloadingAll}
            level="primary"
          >
            <DocumentDownloadIcon />
            Download
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function generateUUID() {
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8
      return v.toString(16)
    }
  )
  return uuid
}

type AttachmentTypeForDownload = {
  fileName: string
  url: string
}
export function useDownloadAttachments() {
  const [downloading, setDownloading] = useState<boolean>(false)
  const [downloadingAll, setDownloadingAll] = useState<boolean>(false)
  const downloadAttachment = useCallback(
    async ({ url, fileName }: AttachmentTypeForDownload) => {
      try {
        setDownloading(true)
        fetch(url)
          .then((response) => response.blob())
          .then((file) => {
            saveBlobAs(file, fileName)
            setDownloading(false)
          })
          .catch((err) => {
            throw err
          })
      } catch {
        setDownloading(false)
        toast.error(
          "Something went wrong while downloading this attachment. Please try again later!"
        )
      }
    },
    []
  )

  const downloadAllAttachments = useCallback(
    async (attachments: AttachmentTypeForDownload[]) => {
      try {
        setDownloadingAll(true)
        const apis = attachments.map((file) => fetch(file.url))
        Promise.all(apis)
          .then(async (responses) => {
            const files = await Promise.all(
              responses.map((response) => response.blob())
            )
            files.forEach((file, i) => {
              saveBlobAs(file, attachments[i].fileName)
            })
            setDownloadingAll(false)
          })
          .catch((err) => {
            throw err
          })
      } catch {
        setDownloadingAll(false)
        toast.error(
          "Something went wrong while downloading your attachments. Please try again later!"
        )
      }
    },
    []
  )

  return {
    downloading,
    downloadingAll,
    downloadAttachment,
    downloadAllAttachments,
  }
}

type FileSizeTypes = "B" | "KB" | "MB" | "GB"
export const convertFileSize = (
  currentSize: number,
  currentSizeIn: FileSizeTypes,
  convertSizeTo: FileSizeTypes
) => {
  const sizeInBytes = {
    B: 1, // bytes
    KB: 1024, // kilobytes
    MB: 1024 * 1024, // megabytes
    GB: 1024 * 1024 * 1024, // gigabytes
  }
  return (currentSize * sizeInBytes[currentSizeIn]) / sizeInBytes[convertSizeTo]
}

export function Thumbnail({
  size,
  isDeleting,
  attachment,
  onDelete,
  onClick,
}: {
  size?: React.ComponentProps<typeof Box>["size"]
  isDeleting?: boolean
  attachment: EntryAttachmentInfo
  onDelete?: () => void
  onClick?: () => void
}) {
  return (
    <Box
      size={size || "16"}
      borderWidth="1"
      rounded="md"
      position="relative"
      borderColor="borderOutline"
      display="flex"
      alignItems="center"
      cursor="pointer"
      justifyContent="center"
      onClick={() => {
        onClick?.()
      }}
    >
      {onDelete ? (
        <Box
          rounded="full"
          position="absolute"
          as="button"
          type="button"
          disabled={isDeleting}
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation()
            onDelete()
          }}
          className="-right-2 -top-2 bg-[white]"
        >
          <CancelFilledIcon color="iconHigh" size="4" />
        </Box>
      ) : null}
      {attachment.mimeType.includes("pdf") ? (
        <Box className="z-[1]">
          <PDFOutlineIcon size="8" color="iconPrimary" />
        </Box>
      ) : (
        <img
          alt={attachment.fileName}
          src={attachment.signedUrl || attachment.thumbUrl || attachment.url}
          className="object-cover w-full h-full rounded-md"
        />
      )}
      <Box
        position="absolute"
        width="full"
        height="full"
        rounded="md"
        top="0"
        left="0"
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(192, 192, 192, 0.36) 34.90%, rgba(255, 255, 255, 0.00) 100%)`,
        }}
      ></Box>
      <Box
        position="absolute"
        bottom="0"
        paddingX="1"
        className="pb-[2px]"
        width={size || "14"}
        color="textOnSurface"
      >
        <Text
          fontWeight="medium"
          style={{ fontSize: 10 }}
          className="line-clamp-1"
        >
          {attachment.fileName}
        </Text>
      </Box>
    </Box>
  )
}
