import {
  ArrowLeftIcon,
  Inline,
  Stack,
  Text,
  Button,
  Box,
  FormField,
} from "@cashbook/web-components"
import React from "react"
import config from "../config"
import { trackEvent, TrackingEvents } from "@cashbook/util-tracking"

type OtpProps = {
  email?: string
  phoneNumber?: string
  otp: string
  otpVerificationLoading: boolean
  sendingVerificationCode: boolean
  secondsRemainingToResend: number
  secondsRemainingToResendStr: string
  onBack: () => void
  onResendOtpHandler: () => void
  onOtpChange: (value: string) => void
}
export function OtpVerification({
  otp,
  email,
  phoneNumber,
  otpVerificationLoading,
  sendingVerificationCode,
  secondsRemainingToResend,
  secondsRemainingToResendStr,
  onBack,
  onOtpChange,
  onResendOtpHandler,
}: OtpProps) {
  return (
    <Stack gap="8" padding="4">
      <Stack gap="6">
        <Stack gap="4">
          <Inline alignItems="center" gap="4">
            <ArrowLeftIcon cursor="pointer" onClick={onBack} />
            <Text
              as="label"
              htmlFor="phoneNumber"
              fontSize="md"
              fontWeight="medium"
            >
              Enter OTP
            </Text>
          </Inline>
          {email || phoneNumber ? (
            <Text fontSize="sm">
              Please enter the 6-digit OTP sent to {email ? email : phoneNumber}
            </Text>
          ) : null}
        </Stack>
        <FormField
          type="text"
          name="code"
          inputMode="numeric"
          autoFocus
          placeholder="e.g. 123456"
          noMargin
          autoComplete="off"
          onChange={(e) => onOtpChange(e.target.value)}
        />
        <Inline flexDirection="rowReverse" gap="4">
          <Button
            fullWidth
            type="submit"
            id="submitOTP"
            disabled={otpVerificationLoading || !otp.length}
          >
            {otpVerificationLoading ? "Verifying..." : "Verify"}
          </Button>
        </Inline>
        <Text color="gray500" fontSize="xs" fontWeight="medium">
          By continuing, you agree to our{" "}
          <Text
            as="a"
            href={config.tosUrl}
            target="_blank"
            rel="noopener noreferrer"
            color="blue900"
            onClick={() => {
              trackEvent(TrackingEvents.T_AND_C_VIEWED, {
                from: "otp",
              })
            }}
          >
            Terms
          </Text>{" "}
          and{" "}
          <Text
            as="a"
            href={config.privacyPolicyUrl}
            target="_blank"
            rel="noopener noreferrer"
            color="blue900"
            onClick={() => {
              trackEvent(TrackingEvents.PRIVACY_POLICY_VIEWED, {
                from: "otp",
              })
            }}
          >
            Policies
          </Text>
        </Text>
      </Stack>
      <Stack gap="1" alignItems="center">
        <Box width="full">
          {sendingVerificationCode ? (
            <Button
              fullWidth
              type="button"
              key="resending_otp"
              level="tertiary"
              disabled
            >
              Resending OTP
            </Button>
          ) : secondsRemainingToResend ? (
            <Button
              disabled
              fullWidth
              type="button"
              level="tertiary"
              key="resend_otp_wait"
            >
              Resend OTP in{" "}
              <span
                style={{
                  fontVariantNumeric: "tabular-nums",
                }}
              >
                {secondsRemainingToResendStr}
              </span>
            </Button>
          ) : (
            <Button
              fullWidth
              type="button"
              key="resend_otp"
              id="resendOTP"
              onClick={async () => onResendOtpHandler()}
            >
              Resend OTP
            </Button>
          )}
        </Box>
      </Stack>
    </Stack>
  )
}
